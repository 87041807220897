import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-online-assignment',
  templateUrl: './online-assignment.component.html',
  styleUrls: ['./online-assignment.component.css', '../languages/languages/languages.component.css']
})
export class OnlineAssignmentComponent implements OnInit {

  constructor(

    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

 

    ngOnInit() {
      var host=  location.pathname;
     
      this.metaService.createCanonicalURL(host);
    //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
      this.titleService.setTitle( "Get Professional Help With Assignments & Grab 10% Discount on First Order ✍️ | Ziyyara");
      this.meta.updateTag({name:"description", content:"👉Are you looking for help with assignments for your academics ✍️? Ziyyara provides global 🌍 assignment help online from experts ✅ with pocket friendly price."})
      this.meta.updateTag({name:"keywords", content:"assignment help, global assignment help, assignment help for students, assignment service provider, assignments help online, best online assignment help, professional assignment help, student assignment help, online assignment, help with assignment"})
      this.metaService.removeStructuredData();
     // this.metaService.insertSchema(this.websiteSchema);
       var ogTitle='Get Professional Help With Assignments & Grab 10% Discount on First Order ✍️ | Ziyyara';
       var ogDescription="👉Are you looking for help with assignments for your academics ✍️? Ziyyara provides global 🌍 assignment help online from experts ✅ with pocket friendly price."
       this.meta.updateTag({property:"og:title", content:ogTitle})
       this.meta.updateTag({property:"og:description", content:ogDescription})
       this.meta.updateTag({name:"twitter:title", content:ogTitle})
       this.meta.updateTag({name:"twitter:description", content:ogDescription});
   
   
   }
  //  currDiv = 'B';

  //  ShowDiv(divVal) {
  //    this.currDiv = divVal;
  //  }


    // for the next previous button
    panelOpenState = false;
    step = 0;
  
    setStep(index: number) {
      this.step = index;
    }
    nextStep() {
      this.step++;
    }
    prevStep() {
      this.step--;
    }
  
  
    cards1 = [
      
      {
        title: 'English',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-English.jpg'
      },
      {
        title: 'Arabic',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-Arabic.jpg'
      },
      {
        title: 'Spanish',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-Spanish.jpg'
      },
    
    ];
  
    
    cards2 = [
      
      {
        title: 'German',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-German.jpg'
      },
      {
        title: 'French',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-French.jpg'
      },
      {
        title: 'Hindi',
       // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
        buttonText: 'Read',
        img: '/assets/images/languages/online-Hindi.jpg'
      },
    
    ];
  
  
    slides1: any = [[]];
    slides2: any = [[]];
    chunk(arr, chunkSize) {
      let R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        R.push(arr.slice(i, i + chunkSize));
      }
      return R;
    }
  
    generateSubjectLink(sub:string){
  
      var subject=sub.toString().toLowerCase()
     
  
        return '/languages/'+subject
      }
    

  }


