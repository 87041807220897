<app-header-front>


</app-header-front>



<div class="section donsec">
    <div class="row">
        <div class="col-md-12 center">
            <h2 class="divider donotcross">ZIYYARA FOUNDATION</h2>
            <!-- <h1 class="heading">ZIYYARA FOUNDATION</h1> contenteditable -->
        </div>
    </div>
    <div class="container-for-content">
        <div class="row">
            <div class="col-md-12">
                <p>It is no new fact that it is a noble deed to donate for child education.</p>
                <p>
                    There are different types of people in the world. Not everyone is fortunate enough to provide funding for education of their children. It is the duty of the privileged ones to indulge in a donation online for underprivileged.
                </p>

            </div>

        </div>


    </div>

    <br>
    <div class="df">
        <div class="half-1 ">


            <div class="embed-responsive embed-responsive-16by9 surround">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Z9BwiirwULw" allowfullscreen></iframe>
            </div>





        </div>
        <div class="half-2">
            <div class="donationForm">
                <h3 class="para"> Select Amount (in USD)</h3>

                <ul class="list">
                    <li *ngFor="let price of prices; let i=index" (click)="select(i, price)" [ngClass]="{'active': selectedIndex == i}">
                        $ {{ price }}

                    </li>

                </ul>

                <p class="term"><input type="checkbox"> Yes I agree to pay the transaction fee charged by PayPal on included in the total amount. </p>
                <form [formGroup]="donationForm" (ngSubmit)="onSubmit(donationForm)">
                    <div class="form-group">
                        <label>  Name </label>
                        <input type="text" class="form-control" placeholder="Name" name="name" formControlName="name" />

                        <p *ngIf="submitted && donationForm.controls.name.invalid "><span class="error">Name is required!</span></p>
                    </div>

                    <div class="form-group">
                        <label> Amount </label>
                        <input type="text" class="form-control" placeholder="Amount in USD" formControlName="amount" />

                        <p *ngIf="submitted && donationForm.controls.amount.invalid "><span class="error">Amount is required!</span></p>
                    </div>
                    <button aria-label="" *ngIf="!showHonaree" (click)="showHonree()" tabindex="0" type="button" class="_fnavw99 _1xyg1pf btnlink"><span class="">▸ Click here to give in honor of another person</span></button>

                    <div *ngIf="showHonaree" class="form-group">
                        <label> Honoree's name (optional)
                           
                             </label>
                        <input type="text" class="form-control" placeholder="Honoree's name" formControlName="honoreeName" />


                    </div>
                    <div *ngIf="showHonaree" class="form-group">
                        <label>  Honoree's email (optional) </label>
                        <input type="text" class="form-control" placeholder="Honoree's email'" formControlName="honoreeEmail" />


                    </div>
                    <button class="btn btn-default pay">Pay With Paypal</button>

                    <p>By donating, you agree to our <a href="/terms-conditions" target="_blank"> terms of service </a> and <a href="/privacy-policy" target="_blank">privacy policy </a> . </p>

                </form>

            </div>

        </div>



    </div>

    <div>
        <h3> Below are certain points which highlight the importance of online Donations:</h3>
        <br>

        <h2>1. Basic human right</h2>

        <p>Education is the right of each and every human being. If we get a chance to catalyse this process by any means, we must definitely take the initiative. Ziyyara Foundation takes the responsibility to help many children who are devoid of this basic
            human right. Everytime you enroll for a course with Ziyyara, a percentage of all the proceeds go to such devoid humans.</p>
        <br>
        <h2>2. Better awareness</h2>

        <p>Education exercises our minds in tons of ways. It trains our brains to function in an organised manner and be better human beings. Online donation for children is a great way to mould someone’s life with just tiny gestures.
        </p>
        <br>
        <h2>3. Prevention of exploitation</h2>




        <p>Education highlights the primary roles, responsibilities and rights which a human being has!</p>

        <p>Child education foundation ensures that the tiny tots are made aware of how to safeguard their rights. This way, they are saved from the exploitation they would otherwise face, because of them being naïve.
        </p>
        <br>
        <h2>4. Promotes employment</h2>

        <p>When you help the child foundation, you ensure a better employment rate in the future! Education plays a very huge role in helping the people earn a decent amount of money to live their lives in a wholesome manner.
        </p>
        <br>
        <h2>5. Improves life quality of many</h2>

        <p>Indulging in an online donation for education is a very noble task, as the children get access to many other facilities than just education. Ziyyara provides help to the students in many ways. It is amazing how many you can help by just enrolling
            for some good quality tuition.</p>
        <br>
        <h2>6. Betterment of the world</h2>

        <p>Educated citizens make the world a better place! When you donate online to a foundation, you play your bit in letting this happen. A good mentoring and a decent upbringing definitely has a positive impact on the impressionable mind of the students.
        </p>
        <br>
        <h2>7. Sense of responsibility</h2>

        <p>When the students are taught about their duties towards the nation and the society, they become much more humane. When you raise fund for education, know that you are helping in more than one way. Several responsible citizens can shape the future
            of the nation in a holistic manner.</p>
        <br>
        <h2>8. Inner satisfaction</h2>

        <p>When you raise funds for education, what is priceless is the satisfaction you get from within. In the hustle and bustle of everyday life, everyone is in the search of real happiness. Probably we have not exploited each and every realm of happiness.
            Happiness truly increases when we share it with others.
        </p>

        <p>If you are wondering about your first step towards fundraising for education, you can donate at the Ziyyara foundation. Your little help could be a huge relief for someone in need.</p>
    </div>

</div>
<div class="_1e7llc8">
    <div id="po" class="_1vmbxul">Get in touch.</div>
    <div id="io" class="_1y8bg0l"><a href="mailto:donate@ziyyara.com" class="_dwmetq">donate@ziyyara.com</a><br><br>Noida-Sector 16 A<br>UP, India</div>
</div>



<app-footer-front></app-footer-front>