<div class="reola-banner" *ngIf="userType==1">
    <header>
        <div class="container">
            <div class="float-left">
                <div class="reola-logo">
                    <a routerLink="/"> <img src="assets/images/ZiyyaraLogo.png"></a>

                </div>

            </div>

            <p class="danger">*Note : Please use Google Chrome for betterunited experience. </p>
            <div class="float-right">

                <div class="reola-menu ">
                    <ul class="">
                        <li><a routerLink="/dashboard" [routerLinkActive]="['active']">Home</a></li>

                        <li><a routerLink="/profile" [routerLinkActive]="['active']">My Profile</a></li>
                        <li><a routerLink="/search" [routerLinkActive]="['active']">Search a Tutor</a></li>
                        <li><a routerLink="/request" [routerLinkActive]="['active']">Requests</a></li>
                        <li *ngIf="groupClassActive=='true' || quranWebinarActive=='true'"><a routerLink="/group-study" [routerLinkActive]="['active']">Group Learning</a></li>
                        <li><a *ngIf="this.userType==1" routerLink="/assignments/assignmentStatus">Assignments</a></li>
                        <li><a routerLink="/alerts" [routerLinkActive]="['active']">Alerts <span *ngIf="notification?.length>0">({{notification.length}})</span></a></li>
                        <li><a routerLink="./">{{property.name}} <i class="fa fa-angle-down"></i></a>
                            <ul class="reola-dropdown">

                                <li>
                                    <a routerLink="/wallet">
                                        <span class="icon-help_center"></span>
                                        <span>My Wallet</span>
                                    </a>
                                </li>

                                <!-- <li>
                                  <a (click)="changeType(2)">
                                      <span class="icon-tenant"></span>
                                      <span>Swich as a Tutor</span>
                                  </a>
                              </li> -->
                                <li>
                                    <a (click)="logout()">
                                        <span class="icon-signout"></span>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </header>

</div>

<div class="reola-banner" *ngIf="userType==2">
    <header>
        <div class="container">
            <div class="float-left">
                <div class="reola-logo">
                    <a routerLink="/">
                        <img src="assets/images/ZiyyaraLogo.png"></a>
                </div>

            </div>
            <p class="danger">*Note : Please use Google Chrome for better experience. </p>

            <div class="float-right">
                <div class="reola-menu ">
                    <ul class="">
                        <li><a routerLink="/booking" [routerLinkActive]="['active']">Booking</a></li>
                        <li *ngIf="groupClassActive=='true' || quranWebinarActive=='true'"><a routerLink="/group-study" [routerLinkActive]="['active']">Group Learning</a></li>
                        <li *ngIf="this.userType==2"><a routerLink="/assignments">Assignments</a></li>

                        <li><a routerLink="/alerts" [routerLinkActive]="['active']">Alerts <span *ngIf="notification?.length>0">({{notification.length}})</span></a></li>
                        <li><a routerLink="./">{{property.name}} <i class="fa fa-angle-down"></i></a>
                            <ul class="reola-dropdown">
                                <li>
                                    <a (click)="profile()">
                                        <span class="icon-help_center"></span>
                                        <span>My Profile</span>
                                    </a>
                                </li>


                                <li>
                                    <a (click)="logout()">
                                        <span class="icon-signout"></span>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </header>



</div>