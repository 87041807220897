import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';  

@Component({
  selector: 'app-benin',
  templateUrl: './benin.component.html',
  styleUrls: ['./benin.component.css','../donation/donation.component.css']
})
export class BeninComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {
  }
  ngOnInit() {

    var host=  location.pathname;
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "Help Children in Benin By Taking Classes With Ziyyara");
    this.meta.updateTag({name:"description", content:"Ziyyara foundation has been investing in better infrastructure for underprivileged children and continues to aid them with classrooms along with basic amenities in Benin."});
     var ogTitle='Help Children in Benin By Taking Classes With Ziyyara';
     var ogDescription="Ziyyara foundation has been investing in better infrastructure for underprivileged children and continues to aid them with classrooms along with basic amenities in Benin."
     this.meta.updateTag({property:"og:title", content:ogTitle});
     this.meta.updateTag({property:"og:description", content:ogDescription});



  }

}
