
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import {ThesisWritingComponent} from './thesis-writing.component';
const pageRoutes: Routes = [

  { path: '', component: ThesisWritingComponent },
 
];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    RouterModule.forChild(pageRoutes),
    
  ],
  declarations: [ThesisWritingComponent],
  entryComponents: []
})
export class ThesisWritingModule { }




