import { environment } from '../../environments/environment';

export const CONFIG = {
    NAME_LENGTH: 50,
    ADDRESS_LENGTH: 150,
    EMAIL_LENGTH: 100,
    PASSWORD_LENGTH: 30,
    MOBILE_LENGTH: 15,
    MOBILE_MIN_LENGTH: 8,
    MOBILE_MAX_LENGTH: 8,
    WEB_LENGTH: 150,
    MAX_KID: 2,
    MIN_LENGTH:3,
    MAX_ADULT: 2,
    MIN_KID: 1,
    MIN_ADULT: 1,
    MAX_PRICE: 7,
    MIN_PRICE: 1,
    LIMIT: 10,
    DESCRIPTION_LENGTH: 500,
    MAXOTP_LENGTH: 4,
    MINOTP_LENGTH: 4,
    MAX_BATHROOM: 2,
    SWIFT_LENGTH: 50
};
