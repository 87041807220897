<app-header-front></app-header-front>


<div class="how-it-works">

  <section id="title" class="light" style="padding: 10px;">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="pagetitle">
                      <h1> Why opt for online tuitions</h1>
                      
                      
                    </div>
                    
                    <div class="_par">
                    <p>In the fast-paced digital age, students and learners want to access content that is fully customized, learner-centric, and smartphone-friendly. 
                         At Ziyyara, we cater to the contemporary learning needs of the students by providing them with the latest e-learning tools, approaches, and methods. 
                        We employ the best tutors from all over the world who have several years of experience in dealing with online education mode as well as adeptly handing the e-learning tools.</p>
                  <p>Our chief objective is to ensure that our students are able to learn at their own pace so that they can use their knowledge and wisdom for achieving their educational goals.
                    
                    A student who chooses to register with Ziyyara’s web-based tutorial platform stands to reap the following benefits: - </p>
              </div>
                  </div>
          </div>
      </div>
  </section>

  <section class="allsteps">
      <div class="container">
          <div class="row">
              <div class="col-md-12 stepone" data-aos="fade-left">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">1</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Unlimited flexibility and mobility
                          </h3>

                          <ul>
                              <li>Our web-based tutorial is configured for logging into from desktop PC or laptop. All you need to have is an internet connection, and you’re ready to go. You can take tuitions from our experienced instructors in the comfort of your home or while you’re travelling. </li>
                         <li>You can register with our online portal and start receiving instructions from our tutors at a time that you find suitable. All you simply need to do is to complete an easy registration process following which you’ll be directed to Ziyyara’s landing page.</li>
                            </ul>
                      </div>
                     
                  </div>
              </div>

              <div class="col-md-12 stepone" data-aos="fade-right">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">2</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Ziyyara offers highly qualified and experienced tutors
                          </h3>
                          <ul>

                              <li>We have extremely experienced and academically qualified tutors and instructors who preside over tuition classes. Our tutors teach the students in a manner that makes the whole learning experience not only exciting but also effective in the long run. </li>

                          </ul>
                      </div>
                    
                  </div>
              </div>
              <div class="col-md-12 stepone" data-aos="fade-left">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">3</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Scalability                          </h3>
                          <ul>
                              <li> Our portal can be easily expanded in size or scale to accommodate new ideas and concepts, innovative training policies, and so on.</li>
                            


                          </ul>
                      </div>
                    
                  </div>
              </div>
              <div class="col-md-12 stepone " data-aos="fade-right">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">4</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Retaining information is easy
                          </h3>
                          <ul>
                              <li>Striking animated narratives, movie clips, eye-catching videos, and engaging textual content enable learners to imbibe and recollect information almost effortlessly.  </li>
                              
                          </ul>
                      </div>
                   
                  </div>
              </div>
              <div class="col-md-12 stepone " data-aos="fade-left">
                <div class="row">
                    <div class="col-md-2 mar45">
                        <span class="step">5</span>
                    </div>
                    <div class="col-md-10 pad30">
                        <h3>
                          Cost effective
                        </h3>
                        <ul>
                            <li> A student does not need to purchase notebooks, textbooks or hard copies of any study material.</li>
                          <li>  We provide audio and video recordings of tuition classes for future references of students. </li>
                            
                        </ul>
                    </div>
                 
                </div>
            </div>
            <div class="col-md-12 stepone " data-aos="fade-right">
              <div class="row">
                  <div class="col-md-2 mar45">
                      <span class="step">6</span>
                  </div>
                  <div class="col-md-10 pad30">
                      <h3>
                        Virtual support
                      </h3>
                      <ul>
                          <li> Our online tutors and educators are always willing to motivate students, help them solve their problems, and settle their doubts during doubt clearing sessions.</li>

                          
                      </ul>
                  </div>
               
              </div>
          </div>
          <div class="col-md-12 stepone " data-aos="fade-left">
            <div class="row">
                <div class="col-md-2 mar45">
                    <span class="step">7</span>
                </div>
                <div class="col-md-10 pad30">
                    <h3>
                      Connecting with a vast community of learners
                    </h3>
                    <ul>
                        <li>By registering with our online tutorial portal, a student gets unlimited scope to connect with a global community of learners.</li>
 
                        
                    </ul>
                </div>
             
            </div>
        </div>
          </div>
      </div>
  </section>
  

</div>

<app-footer-front></app-footer-front>

