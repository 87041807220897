import { Component, OnInit } from '@angular/core';

import { CommonUtil } from 'src/app/util';
import { HttpService } from 'src/app/services';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponse } from 'src/app/interfaces';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GroupClassModalComponent } from 'src/app/components/group-class-modal/group-class-modal/group-class-modal.component';
import { element } from 'protractor';

@Component({
  selector: 'app-group-study',
  templateUrl: './group-study.component.html',
  styleUrls: ['./group-study.component.css'],
  
})
export class GroupStudyComponent implements OnInit {

  constructor(
  
    public dialog: MatDialog,
    public _util: CommonUtil,
    private _api: HttpService,
    
    private _fb: FormBuilder,
    private router:Router
  ) {  }
  active;
  activeA;
  groupClassActive;
  loader;
  activeU;
  groupClass;
  quranClass
  user_id;
  userType;
  ngOnInit() {
    this.user_id=localStorage.getItem("user_id");
    this.userType=localStorage.getItem('user_type')
    this.groupClassActive=localStorage.getItem("group_class");
    
    ////console.log(this.groupClassActive)
    if(this.groupClassActive=='false'){
      ////console.log("inside if")
      this.router.navigate(['./dashboard']);
     
      //this._router.navigate(['./dashboard']);
    }
    this.property('teacher');
    //console.log("calling group calling")
    this._api.getReqUnauth('getAllGroupClassById?user_id='+this.user_id+'&u_type='+this.userType).subscribe(
      res => this.success(res),
      err => this.error(err),
      () => this.loader = false
    );


    this._api.getReqUnauth('getAllWebinarById?user_id='+this.user_id+'&u_type='+this.userType).subscribe(
      res => this.successQuranWebinar(res),
      err => this.error(err),
      () => this.loader = false
    );
   }

 
  success(res: ApiResponse) {
    // console.log(res.result)
    if (res.status == true) {

      if(res.result){
       
        this.groupClass=res.result.filter(elem => elem.state == 2);
       
        //console.log(this.groupClass)
      }
    else{
      res.result=[];
      this.groupClass=res.result
      ////console.log(this.groupClass)
    }

    }else{
      ////console.log('Something went wrong');
    }
  }


  successQuranWebinar(res: ApiResponse){
   
  if (res.status == true) {

    if(res.result){
     
    
      this.quranClass=res.result.filter(elem => elem.state == 2);
      console.log("quranClass")
      console.log(this.quranClass)
    }
  else{
    res.result=[];
    this.quranClass=res.result
    ////console.log(this.groupClass)
  }

  }else{
    ////console.log('Something went wrong');
  }

  }
  error(err: Error) {
    console.log(err.message);
  }
  
  property(type){

    if (type == "teacher") {
      this.active = "active";
      this.activeA = "";
      this.activeU=""
      //this.fetchData(type,'all');
    }
  else  if (type == "webinar") {
      this.activeU = "active";
      this.activeA = "";
      this.active=""
      
      //this.fetchData(type,'all');
    }
    else if (type == "school") {
      this.activeA = "active";
      this.active = "";
      this.activeU=""
     // this.activeR = "";
    
     // this.fetchData(type,2);
    } 
  }
name="kush";
animal="animal"
  joinGroup(){
   
      const dialogRef = this.dialog.open(GroupClassModalComponent, {
        width: '1000px',
        data: this.groupClass
      });
  
      dialogRef.afterClosed().subscribe(result => {
        ////console.log('The dialog was closed'+result);
        this.animal = result;
      });
    }
    getDate(date){
      return new Date(date);
      
    }
}
