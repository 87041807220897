import { Pipe, PipeTransform } from '@angular/core';

import {en } from './english';
import {ar } from './arabic';
import * as _ from "lodash"

/*
 * Raise the value exponentially 
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'mytranslate' })
export class MyTranslatePipe implements PipeTransform {    
    
    transform(str): String {
        let ltype = localStorage.getItem("mylanguage");
        if (ltype == "ar") {
            return _.get(ar, str);
        } else {
            return  _.get(en, str);
        }
    }
}