import { Component, HostListener,OnInit } from '@angular/core';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.css']
})
export class ReferenceComponent implements OnInit {

  //readmore variable, its true than read more string will print
  ReadMore:boolean = true

  //hiding info box
  visible:boolean = false


  //onclick toggling both
  onclick()
  {
    this.ReadMore = !this.ReadMore; //not equal to condition
    this.visible = !this.visible
  }

  showcontent(){
    this.visible;
  }
 submitted = false;
  registerForm = new FormGroup({
          
      firstName: new FormControl('',),
      yourPhone: new FormControl('',),
      yourEmail: new FormControl('',),
      references: new FormArray([

        new FormGroup({
          refUserName:new FormControl('',),
          refUserEmail: new FormControl('',),
          refUserPhone: new FormControl('',),
          refUserSubject: new FormControl('',),
        })

      ]),
      
  })

 references=<FormArray>this.registerForm.get('references');


 mouseUp($event){}

  constructor(private formBuilder: FormBuilder) {



  }

  

  ngOnInit() {
    
          
  }

  get f() { return this.registerForm.controls; }


  createItem() {
    return new FormGroup({
      refUserName:new FormControl('',),
      refUserEmail: new FormControl('',),
      refUserPhone: new FormControl('',),
      refUserSubject: new FormControl('',),
    })


  }
  // removeAddress() {
  //  // this.addresses.splice(this.addresses.length-1, 1);
  // }

  // logValue() {
  //   //console.log(this.addresses);
  // }





  // slideTheText(event) {
  //   //console.log(event)
 
  //  }

  //  stopTheText(event) {
  //   //console.log(event)
 
  //  }


   onSubmit() {
 this.submitted=true;  
 console.log(this.registerForm);



    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  addAddress() {
  const control = <FormArray>this.registerForm.get('references');
  control.push(this.createItem());
  }
  removeAddress() {
  const control = <FormArray>this.registerForm.get('references');
  control.removeAt(control.length - 1);
  }

}
