<section>
    <div class="subject_banner">
        <div class="register-section">
            <div class="row">
                <div class="col-md-6 sec sep">
                    <img src="assets/images/side-banner.jpg" alt="assignment help ">
                </div>
                <div class="col-md-6  sec sep nobr">

                    <form id="contact" [formGroup]="signupForm" class="assmt-form" (ngSubmit)="submitUser()">
                        <h3>Online Assignment Help</h3>
                        <h4>Top Quality, Plagiarism-free and Reliable Assignment Help</h4>
                        <div class="name-email-row">
                            <div class="name-email-div">
                                <fieldset>
                                    <input type="text" placeholder="Enter Student Name" [formControl]="stu_name" [maxlength]="CONFIG?.NAME_LENGTH">
                                    <mat-error>{{_util.errorMessage(stu_name,FORM_ERROR?.stu_name)}}</mat-error>
                                </fieldset>
                            </div>
                            <div class="name-email-div">

                                <fieldset>
                                    <input type="text" formControlName="stu_email" placeholder="Enter Your Email Id*" />
                                    <mat-error>{{_util.errorMessage(stu_email,FORM_ERROR?.stu_email)}}</mat-error>
                                </fieldset>
                            </div>
                        </div>
                        <div class="name-email-row">
                            <div class="name-email-div">

                                <fieldset>
                                    <mat-select placeholder="Search Country Code" class="form-control" (selectionChange)="onChangeCode($event)" [formControl]="countryCode" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                            {{bank.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error>
                                </fieldset>

                            </div>
                            <div class="name-email-div">
                                <fieldset>
                                    <input appOnlyNumber [formControl]="stu_mobile" placeholder="Enter Mobile no" type="text" required autofocus>
                                    <mat-error> {{_util.errorMessage(stu_mobile,FORM_ERROR?.stu_mobile)}}</mat-error>
                                </fieldset>
                            </div>
                        </div>
                        <div class="name-email-row">
                            <div class="name-email-div">
                                <fieldset>
                                    <mat-select class="form-control" placeholder="Please Choose Subject" [formControl]="stu_subject">


                                        <!-- <mat-option value="">Please Select</mat-option>-->
                                        <mat-option value="Accounting">Accounting</mat-option>
                                        <mat-option value="Biology">Biology</mat-option>
                                        <mat-option value="Business ">Business </mat-option>
                                        <mat-option value="Computer Science">Computer Science</mat-option>
                                        <mat-option value="Chemistry">Chemistry</mat-option>
                                        <mat-option value="Computer Science">Computer Science</mat-option>
                                        <mat-option value="Corporate Finance">Corporate Finance</mat-option>
                                        <mat-option value="English">English</mat-option>
                                        <mat-option value="Economics">Economics</mat-option>
                                        <mat-option value="Engineering">Engineering</mat-option>
                                        <mat-option value="Finance ">Finance </mat-option>
                                        <mat-option value="HR ">HR </mat-option>
                                        <mat-option value="History">History</mat-option>
                                        <mat-option value="Law">Law</mat-option>
                                        <mat-option value="MBA">MBA</mat-option>
                                        <mat-option value="Mathematics">Mathematics</mat-option>
                                        <mat-option value="Management">Management</mat-option>
                                        <mat-option value="Marketing">Marketing</mat-option>
                                        <mat-option value="Psychology">Psychology</mat-option>
                                        <mat-option value="Project Management">Project Management</mat-option>
                                        <mat-option value="Science">Science</mat-option>
                                        <mat-option value="Statistics">Statistics</mat-option>

                                    </mat-select>
                                    <mat-error> {{_util.errorMessage(stu_subject,FORM_ERROR?.stu_subject)}}</mat-error>
                                </fieldset>
                            </div>
                            <div class="name-email-div">
                                <fieldset>
                                    <mat-form-field class="mat-form-custom date-picker-size w-100 display-n ">

                                        <input matInput [matDatepicker]="picker" readonly [min]="minDate" [value]="" placeholder="Choose Assignment Deadline" [formControl]="submission_date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker [startAt]='minDate'></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error> {{_util.errorMessage(submission_date,FORM_ERROR?.submission_date)}}</mat-error>

                                </fieldset>
                            </div>
                        </div>
                        <div class="name-email-row">
                            <div class="name-email-div">
                                <fieldset>
                                    <input placeholder="Words Count" type="text" [formControl]="word_count">
                                    <mat-error> {{_util.errorMessage(word_count,FORM_ERROR?.word_count)}}</mat-error>
                                </fieldset>
                            </div>
                            <div class="name-email-div">
                                <fieldset>
                                    <div>

                                        <input type="file" class="file-upload" (change)="onSelectFile($event)" accept="image/*,.pdf">
                                        <mat-error>{{_util.errorMessage(imageUrl,FORM_ERROR?.imageUrl)}}</mat-error>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <fieldset>
                            <textarea type="text" placeholder="Type your message here...." [formControl]="stu_message"></textarea>
                        </fieldset>
                        <fieldset>
                            <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit">

                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</section>