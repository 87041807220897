import { Injectable } from '@angular/core';
import { Country } from './country';
import { State } from './state';

@Injectable()
export class SelectService {

  getCountries() {
    return [
    //  new Country(1, 'Afghanistan' ),         

    //  new Country(2, 'Albania' ),
    //  new Country(3, 'Algeria' ),
    //  new Country(4, 'Andorra' ),
    //  new Country(5, 'Angola' ),
     // states of   new Country(6, 'Anguilla' ),
    //  new Country(7, 'Antigua and Barbuda' ),
    //  new Country(8, 'Argentina' ),
    //  new Country(9, 'Armenia' ),
      // states of new Country(10, 'Aruba' ),
    //  new Country(11, 'Australia' ),
    //  new Country(12, 'Azerbaijanv' ),
    //  new Country(13, 'Bahamas' ),
     new Country(14, 'Bahrain' ),
    //  new Country(15, 'Bangladesh' ),
    //  new Country(16, 'Barbados' ), 
    //  new Country(17, 'Belgium' ),
    //  new Country(18, 'Belize' ),
    //  new Country(19, 'Benin' ),
    //  new Country(20, 'Bermuda' ),
    //  new Country(21, 'Bhutan' ),
    //  new Country(22, 'Bolivia' ),
    //  new Country(23, 'Bosnia and Herzegovina' ),
    //  new Country(24, 'Botswana' ),
    //  new Country(25, 'Brazil' ),
    //  new Country(26, 'Brunei' ),
    //  new Country(27, 'Bulgaria' ),
    //  new Country(28, 'Burkina Faso' ),
    //  new Country(29, 'Cambodia' ),
    //  new Country(30, 'Cameroon' ),
    //  new Country(31, 'Canada' ),
    // new Country(32, 'Cayman Islands' ),
    //  new Country(32, 'Chile' ),
    //  new Country(33, 'China' ),
    //  new Country(35, 'Colombia' ),
    //  new Country(36, 'Comoros and Mayotte' ),
    //  new Country(37, 'Congo' ),
    
    //  new Country(39, 'Costa Rica' ),
    //  new Country(38, 'Croatia' ),
    //  new Country(40, 'Cuba' ),
    //  new Country(41, 'Cyprus' ),
    //  new Country(42, 'Czech Republic' ),
    //  new Country(43, 'Denmark' ),
    //  new Country(44, 'Djibouti' ),
    //  new Country(45, 'Dominica' ),
    //  new Country(46, 'Dominican Republic' ),
    //  new Country(47, 'Ecuador' ),
     new Country(48, 'Egypt' ),
    //  new Country(49, 'El Salvador' ),
    //  new Country(50, 'Equatorial Guinea' ),
    //  new Country(51, 'Eritrea' ),
    //  new Country(52, 'Estonia' ),
    //  new Country(53, 'Ethiopia' ),
     //new Country(54, 'Falkland Islands' ),
    // new Country(55, 'Faroe Islands' ),
    //  new Country(56, 'Fiji' ),
    //  new Country(57, 'Finland' ),
    //  new Country(58, 'France' ),
    // new Country(59, 'French Guiana' ),
   //  new Country(60, 'French Polynesia' ),
    //  new Country(61, 'Gabon' ),
    //  new Country(62, 'Gambia' ),
    //  new Country(63, 'Georgia' ),
    //  new Country(64, 'Germany' ),
    //  new Country(65, 'Ghana' ),
    // new Country(66, 'Gibraltar' ),
    //  new Country(67, 'Greece' ),
    //  new Country(68, 'Greenland' ),
    //  new Country(69, 'Grenada' ),
    // new Country(70, 'Guadeloupe' ),
    // new Country(71, 'Guam' ),
    //  new Country(72, 'Guatemala' ),
    //   new Country(73, 'South Arica' ),
    //  new Country(74, 'Guinea' ),
    //  new Country(75, 'Guyana' ),
    //  new Country(76, 'Haiti' ),
    //  new Country(77, 'Honduras' ),
    //  new Country(78, 'Hong Kong' ),
    //  new Country(79, 'Hungary' ),
    //  new Country(80, 'Iceland' ),
     new Country(81, 'India' ),
    //  new Country(82, 'Indonesia' ),
    //  new Country(83, 'Iran' ),
    //  new Country(84, 'Iraq' ), 
    //  new Country(85, 'Ireland' ),
    //  new Country(86, 'Israel' ),
    //  new Country(87, 'Italy' ),
    //  new Country(88, 'Jamaica' ),
    //  new Country(89, 'Japan' ),
    //  new Country(90, 'Jersey' ),
    //  new Country(91, 'Jordan' ),
    //  new Country(92, 'Kazakhstan' ),
    //  new Country(93, 'Kenya' ),
    //  new Country(94, 'Kiribati' ),
      new Country(95, 'Kuwait' ),
    //  new Country(96, 'Kyrgyzstan' ),
    //  new Country(97, 'Latvia' ),
    //  new Country(98, 'Lebanon' ),
    //  new Country(99, 'Lesotho' ),
    //  new Country(100, 'Liberia' ),
    //  new Country(101, 'Libyan Arab Jamahiriya' ),
    //  new Country(102, 'Liechtenstein' ),
    //  new Country(103, 'Lithuania' ),
    //  new Country(104, 'Luxembourg' ),
    //  new Country(105, 'Macao' ),
    //  new Country(106, 'Macedonia' ),
    //  new Country(107, 'Madagascar' ),
    //  new Country(108, 'Malawi' ),
    //  new Country(109, 'Malaysia' ),
    //  new Country(110, 'Maldives' ),
    //  new Country(111, 'Mali' ),
    //  new Country(112, 'Malta' ),
    //  new Country(113, 'Marshall Island' ),
    //  new Country(114, 'Martinique' ),
    //  new Country(115, 'Mauritania' ),
    //  new Country(116, 'Mauritius' ), 
    //  new Country(117, 'Mayotte' ),
    //  new Country(118, 'Mexico' ),
    //  new Country(119, 'Moldova' ),
    //  new Country(120, 'Monaco' ),
    //  new Country(121, 'Mongolia' ),
    //  new Country(122, 'Montenegro' ),
    //  new Country(123, 'Montserrat' ),
    //  new Country(124, 'Morocco' ),
    //  new Country(125, 'Mozambique' ),
    //  new Country(126, 'Myanmar' ),
    //  new Country(127, 'Namibia' ),
    //  new Country(128, 'Nauru' ),
    //  new Country(129, 'Nepal' ),
    //  new Country(130, 'Netherlands' ),
    //  new Country(131, 'New Caledonia' ),
    //  new Country(132, 'New Zealand' ),
    //  new Country(133, 'Nicaragua' ),
    //  new Country(134, 'Niger' ),
    //  new Country(135, 'Nigeria' ),
    //  new Country(1136, 'Niue' ),
    //  new Country(137, 'Norfolk Island' ),
    //  new Country(138, 'Northern Mariana Islands' ),
    //  new Country(139, 'Norway' ),
     new Country(140, 'Oman' ),
    //  new Country(141, 'Pakistan' ),
    //  new Country(142, 'Palau' ),
    //  new Country(143, 'Palestinian Territory' ),
    //  new Country(144, 'Panama' ),
    //  new Country(145, 'Papua New Guinea' ),
    //  new Country(146, 'Paraguay' ),
    //  new Country(147, 'Peru' ),
    //  new Country(148, 'Philippines' ),
    //  new Country(149, 'Pitcairn' ),
    //  new Country(150, 'Poland' ),
    //  new Country(151, 'Portugal' ),
    //  new Country(152, 'Puerto Rico' ),
       new Country(153, 'Qatar' ),
    //  new Country(154, 'Reunion' ),
    //  new Country(155, 'Romania' ),
    //  new Country(156, 'Russian Federation' ),
    //  new Country(157, 'RWANDA' ),
    //  new Country(158, 'Samoa' ),
     new Country(159, 'Saudi Arabia' ),
    //  new Country(160, 'Senegal' ),
    //  new Country(161, 'Serbia' ),
    //  new Country(162, 'Seychelles' ),
    //  new Country(163, 'Sierra Leone' ),
    //  new Country(164, 'Singapore' ),
    //  new Country(165, 'Slovakia' ),
    //  new Country(166, 'Slovenia' ),
    //  new Country(167, 'Solomon Islands' ),
    //  new Country(168, 'Somalia' ),
    //  new Country(169, 'Spain' ),
    //  new Country(170, 'Sri Lanka' ),
    //  new Country(171, 'Sudan' ),
    //  new Country(172, 'Suriname' ),
    //  new Country(173, 'Swaziland' ),
    //  new Country(174, 'Sweden' ),
    //  new Country(175, 'Switzerland' ),
    //  new Country(176, 'Syrian Arab Republic' ),
    //  new Country(177, 'Taiwan' ),
    //  new Country(178, 'Tajikistan' ),
    //  new Country(179, 'Tanzania' ),
    //  new Country(180, 'Thailand' ),
    //  new Country(181, 'Togo' ),
    //  new Country(182, 'Tokelau' ),
    //  new Country(183, 'Tonga' ),
    //  new Country(184, 'Trinidad and Tobago' ), 
    //  new Country(185, 'Tunisia' ),
    //  new Country(186, 'Turkey' ),
    //  new Country(187, 'Turkmenistan' ),
    //  new Country(188, 'Tuvalu' ),
    //  new Country(189, 'Uganda' ),
    //  new Country(190, 'Ukraine' ),
     new Country(191, 'United Arab Emirates' ),
    //  new Country(192, 'United Kingdom' ),
    //  new Country(193, 'United States' ),
    //  new Country(194, 'Uruguay' ),
    //  new Country(195, 'Uzbekistan' ),
    //  new Country(196, 'Vanuatu' ),
    //  new Country(197, 'Venezuela' ),
    //  new Country(198, 'Viet Nam' ),
    //  new Country(199, 'Wallis and Futuna' ),
    //  new Country(200, 'Western Sahara' ),
    //  new Country(201, 'Yemen' ),
    //  new Country(202, 'Zambia' ),
    //  new Country(203, 'Zimbabwe' ),
    
    ];
  }
  
  getStates() {
   return [

     // states of   afghanistan
     new State(1, 1, 'Badakhshan' ),
     new State(2, 1, 'Badghis' ),
     new State(3, 1, 'Baghlan' ),
     new State(4, 1, 'Balkh' ),
     new State(5, 1, 'Bamian' ),
     new State(6, 1, 'Daykondi' ),
     new State(7, 1, 'Farah' ),
     new State(8, 1, 'Faryab' ),
     new State(9, 1, 'Ghazni' ),
     new State(10, 1, 'Ghowr' ),
     new State(1, 1, 'Helmand' ),
     new State(1, 1, 'Herat' ),
     new State(1, 1, 'Jowzjan' ),
     new State(1, 1, 'Kabul' ),
     new State(1, 1, 'Kandahar' ),
     new State(1, 1, 'Kapisa' ),
     new State(1, 1, 'Khost' ),
     new State(1, 1, 'Konar' ),
     new State(1, 1, 'Kondoz' ),
     new State(1, 1, 'Laghman' ),
     new State(1, 1, 'Lowgar' ),
     new State(1, 1, 'Nangarhar' ),
     new State(1, 1, 'Nimruz' ),
     new State(1, 1, 'Nurestan' ),
     new State(1, 1, 'Oruzgan' ),
     new State(1, 1, 'Paktia' ),
     new State(1, 1, 'Paktika' ),
     new State(1, 1, 'Panjshir' ),
     new State(1, 1, 'Parvan' ),
     new State(1, 1, 'Samangan' ),
     new State(1, 1, 'Sar-e Pol' ),
     new State(1, 1, 'Takhar' ),
     new State(1, 1, 'Vardak' ),
     new State(1, 1, 'Zabol' ),

      // states of   albainia
     new State(2, 2, 'Berat' ),
     new State(2, 2, 'Dibres' ),
     new State(2, 2, 'Durres' ),
     new State(2, 2, 'Elbasan' ),
     new State(2, 2, 'Fier' ),
     new State(2, 2, 'Gjirokastre' ),
     new State(2, 2, 'Korce' ),
     new State(2, 2, 'Kukes' ),
     new State(2, 2, 'Lezhe' ),
     new State(2, 2, 'Shkoder' ),
     new State(2, 2, 'Tirane' ),
     new State(2, 2, 'Vlore' ),
      // states of  algeria
     new State(3, 3, 'Adrar' ),
     new State(3, 3, 'Ain Defla' ),
     new State(3, 3, 'Ain Temouchent' ),
     new State(3, 3, 'Alger' ),
     new State(3, 3, 'Annaba' ),
     new State(3, 3, 'Batna' ),
     new State(3, 3, 'Bechar' ),
     new State(3, 3, 'Bejaia' ),
     new State(3, 3, 'Biskra' ),
     new State(3, 3, 'Blida' ),
     new State(3, 3, 'Bordj Bou Arreridj' ),
     new State(3, 3, 'Bouira' ),
     new State(3, 3, 'Boumerdes' ),
     new State(3, 3, 'Chlef' ),
     new State(3, 3, 'Constantine' ),
     new State(3, 3, 'Djelfa' ),
     new State(3, 3, 'El Bayadh' ),
     new State(3, 3, 'El Oued' ),
     new State(3, 3, 'El Tarf' ),
     new State(3, 3, 'Ghardaia' ),
     new State(3, 3, 'Guelma' ),
     new State(3, 3, 'Illizi' ),
     new State(3, 3, 'Jijel' ),
     new State(3, 3, 'Khenchela' ),
     new State(3, 3, 'Laghouat' ),
     new State(3, 3, 'Muaskar' ),
     new State(3, 3, 'Medea' ),
     new State(3, 3, 'Mila' ),
     new State(3, 3, 'Mostaganem' ),
     new State(3, 3, 'M Sila' ),
     new State(3, 3, 'Naama' ),
     new State(3, 3, 'Oran' ),
     new State(3, 3, 'Ouargla' ),
     new State(3, 3, 'Oum el Bouaghi' ),
     new State(3, 3, 'Relizane' ),
     new State(3, 3, 'Saida' ),
     new State(3, 3, 'Setif' ),
     new State(3, 3, 'Sidi Bel Abbes' ),
     new State(3, 3, 'Skikda' ),
     new State(3, 3, 'Souk Ahras' ),
     new State(3, 3, 'Tamanghasset' ),
     new State(3, 3, 'Tebessa' ),
     new State(3, 3, 'Tiaret' ),
     new State(3, 3, 'Tindouf' ),
     new State(3, 3, 'Tipaza' ),
     new State(3, 3, 'Tissemsilt' ),
     new State(3, 3, 'Tizi Ouzou' ),
     new State(3, 3, 'Tlemcen' ),

      // states of  4. Andorra 

     new State(4, 4, 'Andorra la Vella' ),
     new State(4, 4, 'Canillo' ),
     new State(4, 4, 'Encamp' ),
     new State(4, 4, 'Escaldes-Engordany' ),
     new State(4, 4, 'La Massana' ),
     new State(4, 4, 'Ordino' ),
     new State(4, 4, 'Sant Julia de Loria' ),
      // states of Angola
     new State(5, 5, 'Bengo' ),
     new State(5, 5, 'Benguela' ),
     new State(5, 5, 'Bie' ),
     new State(5, 5, 'Cabinda' ),
     new State(5, 5, 'Cuando Cubango' ),
     new State(5, 5, 'Cuanza Norte' ),
     new State(5, 5, 'Cuanza Sul' ),
     new State(5, 5, 'Cunene' ),
     new State(5, 5, 'Huambo' ),
     new State(5, 5, 'Huila' ),
     new State(5, 5, 'Luanda' ),
     new State(5, 5, 'Lunda Norte' ),
     new State(5, 5, 'Lunda Sul' ),
     new State(5, 5, 'Malanje' ),
     new State(5, 5, 'Moxico' ),
     new State(5, 5, 'Namibe' ),
     new State(5, 5, 'Uige' ),
     new State(5, 5, 'Zaire' ),

      // states of  7 Antigua and Barbuda
     new State(7, 7, 'Barbuda' ),
     new State(7, 7, 'Redonda' ),
     new State(7, 7, 'Saint George' ),
     new State(7, 7, 'Saint John' ),
    new State(7, 7, 'Saint Mary' ),
     new State(7, 7, 'Saint Paul' ),
    new State(7, 7, 'Saint Peter' ),
     new State(7, 7, 'Saint Philip' ),
      // states of argentina
    
     new State(8, 8, 'Buenos Aires' ),
     new State(8, 8, 'Buenos Aires Capital' ),
     new State(8, 8, 'Chaco' ),
     new State(8, 8, 'Chubut' ),
     new State(8, 8, 'Cordoba' ),
     new State(8, 8, 'Corrientes' ),
     new State(8, 8, 'Entre Rios' ),
     new State(8, 8, 'Formosa' ),
     new State(8, 8, 'Jujuy' ),
     new State(8, 8, 'La Pampa' ),
     new State(8, 8, 'La Rioja' ),
     new State(8, 8, 'Mendoza' ),
     new State(8, 8, 'Misiones' ),
     new State(8, 8, 'Neuquen' ),
     new State(8, 8, 'Rio Negro' ),
     new State(8, 8, 'Salta' ),
     new State(8, 8, 'San Juan' ),
     new State(8, 8, 'San Luis' ),
     new State(8, 8, 'Santa Cruz' ),
     new State(8, 8, 'Santa Fe' ),
     new State(8, 8, 'Santiago del Estero' ),
     new State(8, 8, 'Tierra del Fuego' ),
     new State(8, 8, 'Tucuman' ),
      // states of armenia

     new State(9, 9, 'Aragatsotn' ),
     new State(9, 9, 'Ararat' ),
     new State(9, 9, 'Armavir' ),
     new State(9, 9, 'Geghark' ),
     new State(9, 9, 'unik' ),
     new State(9, 9, 'Lorri' ),
      new State(9, 9, 'Shirak' ),
     new State(9, 9, 'Syunik' ),
     new State(9, 9, 'Tavush' ),
     new State(9, 9, 'Vayots' ),
     new State(9, 9, 'Dzor' ),
     new State(9, 9, 'Yerevan' ),
     // states of Australia
 
     new State(11, 11, 'Australian Capital Territory' ),
     new State(11, 11, 'New South Wales' ),
     new State(11, 11, 'Northern Territory' ),
     new State(11, 11, 'Queensland' ),
     new State(11, 11, 'Tasmania' ),
     new State(11, 11, 'Victoria' ),
     new State(11, 11, 'Western Australiay' ),
      // states of  12  Azerbaijan

     
     new State(12, 12, 'Abseron Rayonu' ),
     new State(12, 12, 'Agcabadi Rayonu' ),
     new State(12, 12, 'Agdam Rayonu' ),
     new State(12, 12, 'Agdas Rayonu' ),
     new State(12, 12, 'Agstafa Rayonu' ),
     new State(12, 12, 'Agsu Rayonu' ),
     new State(12, 12, 'Astara Rayonu' ),
     new State(12, 12, 'Balakan Rayonu' ),
     new State(12, 12, 'Barda Rayonu' ),
     new State(12, 12, 'Beylaqan Rayonu' ),
     new State(12, 12, 'Bilasuvar Rayonu' ),
     new State(12, 12, 'Cabrayil Rayonu' ),
     new State(12, 12, 'Calilabad Rayonu' ),
     new State(12, 12, 'Daskasan Rayonu' ),
     new State(12, 12, 'Davaci Rayonu' ),
     new State(12, 12, 'Fuzuli Rayonu' ),
     new State(12, 12, 'Gadabay Rayonu' ),
     new State(12, 12, 'Goranboy Rayonu' ),
     new State(12, 12, 'Goycay Rayonu' ),
     new State(12, 12, 'Haciqabul Rayonu' ),
     new State(12, 12, 'Imisli Rayonu' ),
     new State(12, 12, 'Ismayilli Rayonu' ),
     new State(12, 12, 'Kalbacar Rayonu' ),
     new State(12, 12, 'Kurdamir Rayonu' ),
     new State(12, 12, 'Lacin Rayonu' ),
     new State(12, 12, 'Lankaran Rayonu' ),
     new State(12, 12, 'Lerik Rayonu' ),
     new State(12, 12, 'Masalli Rayonu' ),
     new State(12, 12, 'Neftcala Rayonu' ),
     new State(12, 12, 'Oguz  Rayonu' ),
     new State(12, 12, 'Qabala  Rayonu' ),
     new State(12, 12, 'Qax  Rayonu' ),
     new State(12, 12, 'Qazax  Rayonu' ),
     new State(12, 12, 'Qobustan  Rayonu' ),
     new State(12, 12, 'Quba  Rayonu' ),
     new State(12, 12, 'Qubadli  Rayonu' ),
     new State(12, 12, 'Qusar  Rayonu' ),
     new State(12, 12, 'Saatli  Rayonu' ),
     new State(12, 12, 'Sabirabad  Rayonu' ),
     new State(12, 12, 'Saki  Rayonu' ),
     new State(12, 12, 'Salyan  Rayonu' ),
     new State(12, 12, 'Samaxi  Rayonu' ),
     new State(12, 12, 'Samkir  Rayonu' ),
     new State(12, 12, 'Samux  Rayonu' ),
     new State(12, 12, 'Siyazan  Rayonu' ),
     new State(12, 12, 'Susa  Rayonu' ),
     new State(12, 12, 'Tartar  Rayonu' ),
     new State(12, 12, 'Tovuz  Rayonu' ),
     new State(12, 12, 'Ucar  Rayonu' ),
     new State(12, 12, 'Xacmaz  Rayonu' ),
     new State(12, 12, 'Xanlar  Rayonu' ),
     new State(12, 12, 'Xizi  Rayonu' ),
     new State(12, 12, 'Xocavand  Rayonu' ),
     new State(12, 12, 'Yardimli  Rayonu' ),
     new State(12, 12, 'Yevlax  Rayonu' ),
     new State(12, 12, 'Zangilan  Rayonu' ),
     new State(12, 12, 'Zaqatala  Rayonu' ),
     new State(12, 12, 'Zardab  Rayonu' ),
     new State(12, 12, 'Xocali  Rayonu' ),

      // states of  13  Bahamas  
       new State(13, 13, ' Acklins and Crooked Islands' ),
       new State(13, 13, 'Bimini ' ),
       new State(13, 13, ' Cat Island' ),
       new State(13, 13, 'Exuma ' ),
       new State(13, 13, ' Freeport' ),
       new State(13, 13, ' Fresh Creek' ),
       new State(13, 13, 'Governors Harbour ' ),
       new State(13, 13, ' Green Turtle Cay' ),
       new State(13, 13, ' Harbour Island' ),
       new State(13, 13, ' High Rock' ),
       new State(13, 13, 'Inagua ' ),
       new State(13, 13, ' Kemps Bay' ),
       new State(13, 13, ' Long Island' ),
       new State(13, 13, 'Marsh Harbou ' ),
       new State(13, 13, 'Mayaguana ' ),
       new State(13, 13, 'Ragged Island ' ),
       new State(13, 13, 'Rock Sound ' ),
       new State(13, 13, 'Sandy Point ' ),
       new State(13, 13, 'San Salvador and Rum Cay' ),

         // states of 14  Bahrain
     
        new State(1, 14, ' Al Hadd' ),
        new State(2, 14, 'Al Manamah ' ),
        new State(3, 14, 'Al  Mintaqah al Gharbiyah' ),
        new State(4, 14, 'Al Mintaqah al Wusta ' ),
        new State(5, 14, 'Al Mintaqah ash Shamaliyah' ),
        new State(6, 14, 'Al Muharraq ' ),
        new State(7, 14, 'Ar Rifa  ' ),
        new State(8, 14, ' wa al Mintaqah al Janubiyah' ),
        new State(9, 14, 'Jidd Hafs ' ),
        new State(10, 14, 'Madinat Hamad' ),
        new State(11, 14, 'Madinat ' ),
        new State(12, 14, 'Isa ' ),
        new State(14, 14, 'Juzur Hawa' ),
        new State(14, 14, 'Sitra' ),
         // states of  15   Bangladesh
        new State(15, 15, ' Barisal' ),
        new State(15, 15, 'Chittagong ' ),
        new State(15, 15, 'Dhaka ' ),
        new State(15, 15, ' Khulna' ),
        new State(15, 15, 'Rajshahi ' ),
        new State(15, 15, 'Sylhet' ),
        // states of  16  Barbados
      new State(16, 16, ' Christ Church' ),
      new State(16, 16, 'Saint Andrew ' ),
      new State(16, 16, 'Saint George ' ),
      new State(16, 16, 'Saint James ' ),
      new State(16, 16, ' Saint John' ),
      new State(16, 16, ' Saint Joseph' ),
       new State(16, 16, 'Saint Lucy ' ),
      new State(16, 16, 'Saint Michael ' ),
      new State(16, 16, 'Saint Peter ' ),
      new State(16, 16, ' Saint Philip' ),
      new State(16, 16, ' Saint Thomas' ),
      // states of  17  Belgium
   
    new State(17, 17, ' Antwerpen ' ),
    new State(17, 17, ' Brabant Wallon ' ),
    new State(17, 17, ' Brussels ' ),
    new State(17, 17, '  Flanders' ),
    new State(17, 17, ' Hainaut ' ),
    new State(17, 17, ' Liege ' ),
    new State(17, 17, ' Liege ' ),
    new State(17, 17, ' Limburg ' ),
    new State(17, 17, ' Luxembourg ' ),
    new State(17, 17, ' Namur ' ),
    new State(17, 17, ' Oost-Vlaanderen ' ),
    new State(17, 17, ' Vlaams-Brabant ' ),
    new State(17, 17, ' Wallonia ' ),
    new State(17, 17, ' West-Vlaanderen ' ),
     // states of  18 Belize
    new State(18, 18, ' Belize ' ),
    new State(18, 18, ' Cayo ' ),
    new State(18, 18, ' Corozal ' ),
    new State(18, 18, ' Orange Walk ' ),
    new State(18, 18, ' Stann Cree ' ),
    new State(18, 18, ' Toledo ' ),
   // states of 19  Benin 
 
    new State(19, 19, ' Alibori ' ),
    new State(19, 19, 'Atakora  ' ),
    new State(19, 19, ' Atlantique ' ),
    new State(19, 19, ' Borgou ' ),
    new State(19, 19, ' Collines ' ),
    new State(19, 19, ' Donga ' ),
    new State(19, 19, 'Kouffo  ' ),
    new State(19, 19, 'Littoral  ' ),
    new State(19, 19, 'Mono  ' ),
    new State(19, 19, 'Oueme  ' ),
    new State(19, 19, 'Plateau  ' ),
    new State(19, 19, 'Zou  ' ),
 // states of 20  Bermuda

    new State(20, 20, ' Devonshire ' ),
    new State(20, 20, 'Hamilton  ' ),
    new State(20, 20, ' Paget ' ),
    new State(20, 20, ' Pembroke ' ),
    new State(20, 20, 'Saint George"  ' ),
    new State(20, 20, ' Sandys ' ),
    new State(20, 20, '  Southampton' ),
    new State(20, 20, ' Warwick ' ),

     // states of 21 Bhutan
 
    new State(21, 21, ' Bumthang ' ),
    new State(21, 21, ' Chukha ' ),
    new State(21, 21, ' Dagana ' ),
    new State(21, 21, '  Gasa' ),
    new State(21, 21, ' Haa ' ),
    new State(21, 21, ' Lhuntse ' ),
    new State(21, 21, 'Mongar  ' ),
    new State(21, 21, ' Paro ' ),
    new State(21, 21, ' Pemagatshel ' ),
    new State(21, 21, ' Punakha ' ),
      new State(21, 21, ' Samdrup Jongkhar ' ),
      new State(21, 21, 'Samtse  ' ),
      new State(21, 21, ' Sarpang ' ),
      new State(21, 21, ' Thimphu ' ),
      new State(21, 21, ' Trashigang ' ),
        new State(21, 21, ' Trashiyangste' ),
        new State(21, 21, 'Trongsa  ' ),
        new State(21, 21, ' Tsirang ' ),
        new State(21, 21, ' Wangdue Phodrang ' ),
        new State(21, 21, ' Zhemgang ' ),
          // states of Bolivia
           new State(22, 22, ' Chuquisaca ' ),
           new State(22, 22, ' Cochabamba ' ),
           new State(22, 22, ' Beni ' ),
           new State(22, 22, ' La Paz ' ),
           new State(22, 22, 'Oruro  ' ),
            new State(22, 22, ' Pando ' ),
            new State(22, 22, ' Potosi ' ),
             new State(22, 22, ' Santa Cruz ' ),
            new State(22, 22, ' Tarija ' ),
            // states of 
             new State(23, 23, ' Una-Sana ' ),
              new State(23, 23, ' Posavina ' ),
              new State(23, 23, 'Tuzla  ' ),
              new State(23, 23, 'Zenica-Doboj  ' ),
              new State(23, 23, ' Bosnian Podrinje  ' ),
              new State(23, 23, ' Central Bosnia ' ),
              new State(23, 23, ' Herzegovina-Neretva ' ),
              new State(23, 23, 'West Herzegovina  ' ),
              new State(23, 23, ' Sarajevo ' ),
              new State(23, 23, 'West Bosnia  ' ),
              new State(23, 23, 'Banja Luka  ' ),
              new State(23, 23, 'Bijeljina   ' ),
              new State(23, 23, 'Doboj  ' ),
              new State(23, 23, 'Sarajevo-Romanija  ' ),
              new State(23, 23, 'Trebinje  ' ),
              new State(23, 23, 'Vlasenica  ' ),

               // states of  24  
              new State(24, 24, 'Central  ' ),
              new State(24, 24, '  Ghanzi' ),
              new State(24, 24, ' Kgalagadi ' ),
              new State(24, 24, 'Kweneng  ' ),
              new State(24, 24, 'North East  ' ),
              new State(24, 24, ' North West ' ),
              new State(24, 24, ' South East ' ),
              new State(24, 24, ' Southern ' ),
               // states of   Brazil   
             new State(25, 25, ' Acre ' ),
              new State(25, 25, 'Alagoas  ' ),
              new State(25, 25, ' Amapa ' ),
              new State(25, 25, 'Amazonas  ' ),
              new State(25, 25, ' Bahia ' ),
              new State(25, 25, 'Ceara  ' ),
              new State(25, 25, ' Distrito Federal ' ),
              new State(25, 25, ' Espirito Santo ' ),
              new State(25, 25, 'Goias  ' ),
              new State(25, 25, 'Maranhao ' ),
              new State(25, 25, 'Mato Grosso  ' ),
              new State(25, 25, 'Mato Grosso do Sul  ' ),
              new State(25, 25, 'Minas Gerais  ' ),
              new State(25, 25, 'Para  ' ),
              new State(25, 25, 'Paraiba  ' ),
              new State(25, 25, 'Parana  ' ),
              new State(25, 25, 'Pernambuco  ' ),
              new State(25, 25, 'Piaui  ' ),
              new State(25, 25, 'Rio de Janeiro  ' ),
              new State(25, 25, 'Rio Grande do Norte  ' ),
              new State(25, 25, 'Rio Grande do Sul  ' ),
              new State(25, 25, 'Rondonia  ' ),
              new State(25, 25, 'Roraima  ' ),
              new State(25, 25, 'Santa Catarina  ' ),
              new State(25, 25, 'Sao Paulo  ' ),
              new State(25, 25, 'Sergipe  ' ),
              new State(25, 25, 'Tocantins  ' ),
             
               // states of Brunei
              new State(26, 26, 'Belait  ' ),
              new State(26, 26, 'Brunei and Muara  ' ),
              new State(26, 26, ' Temburong ' ),
              new State(26, 26, 'Tutong  ' ),
               // states of 27
             
              new State(27, 27, 'Blagoevgrad  ' ),
              new State(27, 27, 'Burgas  ' ),
              new State(27, 27, 'Dobrich  ' ),
              new State(27, 27, 'Gabrovo  ' ),
              new State(27, 27, 'Khaskovo  ' ),
              new State(27, 27, ' Kurdzhali ' ),
              new State(27, 27, 'Kyustendil  ' ),
              new State(27, 27, 'Lovech  ' ),
              new State(27, 27, 'Montana  ' ),
              new State(27, 27, ' Pazardzhik ' ),
              new State(27, 27, ' Pernik ' ),
              new State(27, 27, ' Pleven ' ),
              new State(27, 27, 'Plovdiv  ' ),
              new State(27, 27, 'Razgrad  ' ),
              new State(27, 27, ' Ruse ' ),
              new State(27, 27, 'Shumen  ' ),
              new State(27, 27, 'Silistra  ' ),
              new State(27, 27, ' Sliven ' ),
              new State(27, 27, ' Smolyan ' ),
              new State(27, 27, 'Sofiya  ' ),
              new State(27, 27, ' Sofiya-Grad ' ),
              new State(27, 27, ' Stara Zagora ' ),
              new State(27, 27, ' Turgovishte ' ),
              new State(27, 27, 'Varna  ' ),
              new State(27, 27, ' Veliko Turnovo ' ),
              new State(27, 27, ' Vidin ' ),
              new State(27, 27, 'Vratsa  ' ),
              new State(27, 27, 'Yambol  ' ),

               // states of   28 Burkina Faso

              new State(28, 28, 'Bale  ' ),
              new State(28, 28, 'Bam  ' ),
              new State(28, 28, ' Banwa ' ),
              new State(28, 28, 'Bazega  ' ),
              new State(28, 28, 'Bougouriba  ' ),
              new State(28, 28, 'Boulgou  ' ),
              new State(28, 28, 'Boulkiemde  ' ),
              new State(28, 28, 'Comoe  ' ),
              new State(28, 28, 'Ganzourgou  ' ),
              new State(28, 28, 'Gnagna  ' ),
              new State(28, 28, 'Gourma  ' ),
              new State(28, 28, 'Houet  ' ),
              new State(28, 28, 'Ioba  ' ),
              new State(28, 28, 'Kadiogo  ' ),
              new State(28, 28, 'Kenedougou  ' ),
              new State(28, 28, 'Komondjari  ' ),
              new State(28, 28, 'Kompienga  ' ),
              new State(28, 28, 'Kossi  ' ),
              new State(28, 28, 'Koulpelogo  ' ),
              new State(28, 28, ' Kouritenga ' ),
              new State(28, 28, ' Kourweogo ' ),
              new State(28, 28, 'Leraba  ' ),
              new State(28, 28, ' Loroum ' ),
              new State(28, 28, 'Mouhoun  ' ),
              new State(28, 28, 'Namentenga ' ),
              new State(28, 28, 'Nahouri  ' ),
              new State(28, 28, 'Nayala  ' ),
              new State(28, 28, 'Noumbiel  ' ),
              new State(28, 28, 'Oubritenga  ' ),
              new State(28, 28, 'Oudalan  ' ),
              new State(28, 28, 'Passore  ' ),
              new State(28, 28, 'Poni  ' ),
              new State(28, 28, 'Sanguie  ' ),
              new State(28, 28, 'Sanmatenga  ' ),
              new State(28, 28, 'Seno  ' ),
              new State(28, 28, 'Sissili  ' ),
              new State(28, 28, 'Soum  ' ),
              new State(28, 28, 'Sourou  ' ),
              new State(28, 28, 'Tapoa  ' ),
              new State(28, 28, 'Tuy  ' ),
              new State(28, 28, 'Yagha  ' ),
              new State(28, 28, 'Yatenga  ' ),
              new State(28, 28, 'Yatenga  ' ),
              new State(28, 28, 'Ziro  ' ),
              new State(28, 28, 'Zondoma  ' ),
               // states of  29   Cambodia
            
              new State(29, 29, ' Banteay Mean Chey' ),
              new State(29, 29, 'Batdambang ' ),
              new State(29, 29, 'Kampong Cham ' ),
              new State(29, 29, 'Kampong Chhnang ' ),
              new State(29, 29, 'Kampong Spoe ' ),
              new State(29, 29, 'Kampong Thum ' ),
              new State(29, 29, 'Kampot ' ),
              new State(29, 29, 'Kandal ' ),
              new State(29, 29, 'Koh Kong ' ),
              new State(29, 29, 'Kracheh ' ),
              new State(29, 29, 'Mondol Kiri ' ),
              new State(29, 29, 'Otdar Mean Chey ' ),
              new State(29, 29, 'Pouthisat ' ),
              new State(29, 29, 'Preah Vihear ' ),
              new State(29, 29, 'Prey Veng ' ),
              new State(29, 29, 'Rotanakir ' ),
              new State(29, 29, 'Siem Reab ' ),
              new State(29, 29, 'Stoeng Treng ' ),
              new State(29, 29, 'Svay Rieng ' ),
              new State(29, 29, 'Takao ' ),
              new State(29, 29, 'Keb ' ),
              new State(29, 29, 'Pailin ' ),
              new State(29, 29, 'Phnom Penh ' ),
              new State(29, 29, 'Preah Seihanu ' ),
               // states of 30 Cameroon
              new State(30, 30, 'Adamaoua ' ),
              new State(30, 30, 'Centre ' ),
              new State(30, 30, 'Est ' ),
              new State(30, 30, 'Extreme-Nord ' ),
              new State(30, 30, 'Littoral ' ),
              new State(30, 30, 'Nord ' ),
              new State(30, 30, 'Nord-Ouest ' ),
              new State(30, 30, 'Ouest ' ),
              new State(30, 30, 'Sud ' ),
              new State(30, 30, 'Sud-Ouest ' ),
              // states of  Canada
              new State(31, 31, 'Alberta ' ),
              new State(31, 31, 'British Columbia ' ),
              new State(31, 31, 'Manitoba ' ),
              new State(31, 31, 'New Brunswick ' ),
              new State(31, 31, 'Newfoundland and Labrado ' ),
              new State(31, 31, 'Northwest Territories ' ),
              new State(31, 31, 'Nova Scotia ' ),
              new State(31, 31, 'Nunavut ' ),
              new State(31, 31, 'Ontario ' ),
              new State(31, 31, 'Prince Edward Island ' ),
              new State(31, 31, 'Quebec ' ),
              new State(31, 31, 'Saskatchewan ' ),
              new State(31, 31, 'Yukon Territory ' ),
              // states of  Chile
              new State(32, 32, 'Aysen ' ),
              new State(32, 32, 'Antofagasta ' ),
              new State(32, 32, 'Araucania ' ),
              new State(32, 32, 'Atacama ' ),
              new State(32, 32, 'Bio-Bio ' ),
              new State(32, 32, 'Coquimbo ' ),
              new State(32, 32, 'O Higgins ' ),
              new State(32, 32, 'Los Lagos ' ),
              new State(32, 32, 'Magallanes y la Antartica Chilen ' ),
              new State(32, 32, 'Maule ' ),
              new State(32, 32, 'Santiago Region Metropolitana ' ),
              new State(32, 32, 'Tarapaca ' ),
              new State(32, 32, 'Valparaiso ' ),
                 // states of  China
              new State(33, 33, 'Anhui ' ),
              new State(33, 33, 'Fujian ' ),
              new State(33, 33, 'Gansu ' ),
              new State(33, 33, 'Guangdong ' ),
              new State(33, 33, 'Guizhou ' ),
              new State(33, 33, 'Hainan ' ),
              new State(33, 33, 'Hebei ' ),
              new State(33, 33, 'Heilongjiang ' ),
              new State(33, 33, 'Henan ' ),
              new State(33, 33, 'Hubei ' ),
              new State(33, 33, 'Hunan ' ),
              new State(33, 33, 'Jiangsu ' ),
              new State(33, 33, 'Jiangxi ' ),
              new State(33, 33, 'Jilin ' ),
              new State(33, 33, 'Liaoning ' ),
              new State(33, 33, 'Qinghai ' ),
              new State(33, 33, 'Shaanxi ' ),
              new State(33, 33, 'Shandong ' ),
              new State(33, 33, 'Shanxi ' ),
              new State(33, 33, 'Sichuan ' ),
              new State(33, 33, 'Yunnan ' ),
              new State(33, 33, 'Zhejiang ' ),
              new State(33, 33, 'Guangxi ' ),
              new State(33, 33, 'Nei Mongol ' ),
              new State(33, 33, 'Ningxia ' ),
              new State(33, 33, 'Xinjiang ' ),
              new State(33, 33, 'Xizang (Tibet) ' ),
              new State(33, 33, 'Beijing ' ),
              new State(33, 33, 'Chongqing ' ),
              new State(33, 33, 'Shanghai ' ),
              new State(33, 33, 'Tianjin ' ),
               // states of Colombia
              new State(35, 35, 'Amazonas ' ),
              new State(35, 35, 'Antioquia ' ),
              new State(35, 35, 'Arauca ' ),
              new State(35, 35, 'Atlantico ' ),
              new State(35, 35, 'Bogota District Capital ' ),
              new State(35, 35, 'Bolivar ' ),
              new State(35, 35, 'Boyaca ' ),
              new State(35, 35, 'Caldas ' ),
              new State(35, 35, 'Caqueta ' ),
              new State(35, 35, 'Casanare ' ),
              new State(35, 35, 'Cauca ' ),
              new State(35, 35, 'Cesar ' ),
              new State(35, 35, 'Choco ' ),
              new State(35, 35, 'Cordoba ' ),
              new State(35, 35, 'Cundinamarca ' ),
              new State(35, 35, 'Guainia ' ),
              new State(35, 35, 'Guaviare ' ),
              new State(35, 35, 'Huila ' ),
              new State(35, 35, 'La Guajira ' ),
              new State(35, 35, 'Magdalena ' ),
              new State(35, 35, 'Meta ' ),
              new State(35, 35, 'Narino ' ),
              new State(35, 35, 'Norte de Santander ' ),
              new State(35, 35, 'Putumayo ' ),
              new State(35, 35, 'Quindio ' ),
              new State(35, 35, 'Risaralda ' ),
              new State(35, 35, 'San Andres & Providencia ' ),
              new State(35, 35, 'Santander ' ),
              new State(35, 35, 'Sucre ' ),
              new State(35, 35, 'Tolima ' ),
              new State(35, 35, 'Valle del Cauca ' ),
              new State(35, 35, 'Vaupes ' ),
              new State(35, 35, 'Vichada ' ),
            // States of Comoros
             new State(36, 36, 'Grande Comore (Njazidja) ' ),
             new State(36, 36, 'Anjouan (Nzwani)' ),
             new State(36, 36, 'Moheli (Mwali) ' ),


     //Congo
   
     new State(37, 37, 'Bandundu ' ),
     new State(37, 37, 'Bas-Congo ' ),
     new State(37, 37, 'Equateur ' ),
     new State(37, 37, 'Kasai-Occidental ' ),
     new State(37, 37, 'Kasai-Oriental ' ),
     new State(37, 37, 'Katanga ' ),
     new State(37, 37, 'Kinshasa ' ),
     new State(37, 37, 'Maniema ' ),
     new State(37, 37, 'Nord-Kivu ' ),
     new State(37, 37, 'Orientale ' ),
     new State(37, 37, 'Sud-Kivu ' ),

     //  Croatia
      new State(38, 38, 'Bjelovarsko-Bilogorska ' ),
     new State(38, 38, 'Brodsko-Posavska ' ),
     new State(38, 38, 'Dubrovacko-Neretvanska ' ),
     new State(38, 38, 'Istarska ' ),
     new State(38, 38, 'Karlovacka ' ),
     new State(38, 38, 'Koprivnicko-Krizevacka ' ),
     new State(38, 38, 'Krapinsko-Zagorska ' ),
     new State(38, 38, 'Licko-Senjska ' ),
     new State(38, 38, 'Medimurska ' ),
     new State(38, 38, 'Osjecko-Baranjska ' ),
     new State(38, 38, 'Pozesko-Slavonska ' ),
     new State(38, 38, 'Primorsko-Goranska ' ),
     new State(38, 38, 'Sibensko-Kninska ' ),
     new State(38, 38, 'Sisacko-Moslavacka ' ),
     new State(38, 38, 'Splitsko-Dalmatinska ' ),
     new State(38, 38, 'Varazdinska' ),
     new State(38, 38, 'Viroviticko-Podravska ' ),
     new State(38, 38, 'Primorsko-Goranska ' ),
     new State(38, 38, 'Vukovarsko-Srijemska' ),
     new State(38, 38, 'Zadarska' ),
     new State(38, 38, 'Zagreb ' ),
     new State(38, 38, 'Zagrebacka' ),

     // 39 Costa Rica

   
     new State(39, 39, 'Alajuela ' ),
     new State(39, 39, 'Cartago ' ),
     new State(39, 39, 'Guanacaste ' ),
     new State(39, 39, 'Heredia ' ),
     new State(39, 39, 'Limon ' ),
     new State(39, 39, 'Puntarenas ' ),
     new State(39, 39, 'San Jose ' ),

     //Cuba
     new State(40, 40, 'Camaguey ' ),
     new State(40, 40, 'Ciego de Avila ' ),
     new State(40, 40, 'Cienfuegos ' ),
     new State(40, 40, 'Ciudad de La Habana ' ),
     new State(40, 40, 'Granma ' ),
     new State(40, 40, 'Guantanamo ' ),
     new State(40, 40, 'Holguin ' ),
     new State(40, 40, 'Isla de la Juventud ' ),
     new State(40, 40, 'La Habana ' ),
     new State(40, 40, 'Las Tunas ' ),
     new State(40, 40, 'Matanzas ' ),
     new State(40, 40, 'Pinar del Rio" ' ),
     new State(40, 40, 'Sancti Spiritus ' ),
     new State(40, 40, 'Santiago de Cuba ' ),
     new State(40, 40, 'Villa Clara ' ),
   // 41 Cyprus

     new State(41, 41, 'Famagusta ' ),
     new State(41, 41, 'Kyrenia ' ),
     new State(41, 41, 'Larnaca ' ),
     new State(41, 41, 'Limassol ' ),

     new State(41, 41, 'Nicosia ' ),
     new State(41, 41, 'Paphos ' ),
// 42  Czech Republic


     new State(42, 42, 'Jihocesky Kraj ' ),
     new State(42, 42, 'Jihomoravsky Kraj ' ),
     new State(42, 42, 'Karlovarsky Kraj ' ),
     new State(42, 42, 'Kralovehradecky Kraj ' ),
     new State(42, 42, 'Liberecky Kraj ' ),
     new State(42, 42, 'Moravskoslezsky Kraj ' ),
     new State(42, 42, 'Olomoucky Kraj ' ),
     new State(42, 42, 'Pardubicky Kraj ' ),
     new State(42, 42, 'Plzensky Kraj ' ),
     new State(42, 42, 'Praha ' ),
     new State(42, 42, 'Stredocesky Kraj ' ),
     new State(42, 42, 'Ustecky Kraj ' ),
     new State(42, 42, 'Vysocina ' ),

     new State(42, 42, 'Zlinsky Kraj ' ),

     //43  Denmark
   
     new State(43, 43, 'Arhus ' ),
     new State(43, 43, 'Bornholm ' ),
     new State(43, 43, 'Frederiksberg ' ),
     new State(43, 43, 'Frederiksborg ' ),
     new State(43, 43, 'Fyn ' ),
     new State(43, 43, 'Kobenhavn ' ),
     new State(43, 43, 'Kobenhavns ' ),
     new State(43, 43, 'Nordjylland ' ),
     new State(43, 43, 'Ribe ' ),
     new State(43, 43, 'Ringkobing ' ),
     new State(43, 43, 'Roskilde ' ),
     new State(43, 43, 'Sonderjylland ' ),
     new State(43, 43, 'Storstrom ' ),
     new State(43, 43, 'Vejle ' ),
     new State(43, 43, 'Vestsjalland ' ),
     new State(43, 43, 'Viborg ' ),

  // 44  Djibouti
 

     new State(44, 44, 'Ali Sabih ' ),
     new State(44, 44, 'Dikhil ' ),
     new State(44, 44, 'Djibouti ' ),
     new State(44, 44, 'Obock ' ),
     new State(44, 44, 'Tadjoura ' ),

     //45 Dominica
     
    
     new State(45, 45, 'Saint Andrew ' ),
     new State(45, 45, 'Saint David ' ),
     new State(45, 45, 'Saint George ' ),
     new State(45, 45, 'Saint John ' ),
     new State(45, 45, 'Saint Joseph ' ),
     new State(45, 45, 'Saint Luke ' ),
     new State(45, 45, 'Saint Mark' ),
     new State(45, 45, 'Saint Patrick ' ),
     new State(45, 45, 'Saint Paul ' ),
     new State(45, 45, 'Saint Peter ' ),
     //46  Dominican Republic
  
     new State(46, 46, 'Azua ' ),
     new State(46, 46, 'Baoruco ' ),
     new State(46, 46, 'Barahona ' ),
     new State(46, 46, 'Dajabon ' ),
     new State(46, 46, 'Distrito Nacional ' ),
     new State(46, 46, 'Duarte ' ),
     new State(46, 46, 'Elias Pina ' ),
     new State(46, 46, 'El Seibo ' ),
     new State(46, 46, 'Espaillat ' ),
     new State(46, 46, 'Hato Mayor ' ),
     new State(46, 46, 'Independencia ' ),
     new State(46, 46, 'La Altagracia ' ),
     new State(46, 46, 'La Romana ' ),
     new State(46, 46, 'La Vega ' ),
     new State(46, 46, 'Maria Trinidad Sanchez ' ),
     new State(46, 46, 'Monsenor Nouel ' ),
     new State(46, 46, 'Monte Cristi ' ),
     new State(46, 46, 'Monte Plata ' ),
     new State(46, 46, 'Pedernales ' ),
     new State(46, 46, 'Peravia ' ),
     new State(46, 46, 'Puerto Plata ' ),
     new State(46, 46, 'Salcedo ' ),
     new State(46, 46, 'Samana ' ),
     new State(46, 46, 'Sanchez Ramirez ' ),
     new State(46, 46, 'San Cristobal' ),
     new State(46, 46, 'San Jose de Ocoa ' ),
     new State(46, 46, 'San Juan ' ),
     new State(46, 46, 'San Pedro de Macoris' ),
     new State(46, 46, 'Santiago ' ),
     new State(46, 46, 'Santiago Rodriguez' ),
     new State(46, 46, 'Santo Domingo ' ),
     new State(46, 46, 'Valverde ' ),

     //   
   
     new State(47, 47, 'Azuay ' ),
     new State(47, 47, ' Bolivar' ),
     new State(47, 47, 'Canar ' ),
     new State(47, 47, 'Carchi ' ),
     new State(47, 47, 'Chimborazo ' ),
     new State(47, 47, 'Cotopaxi ' ),
     new State(47, 47, 'El Oro ' ),
     new State(47, 47, 'Esmeraldas ' ),
     new State(47, 47, ' Galapagos' ),
     new State(47, 47, 'Guayas ' ),
     new State(47, 47, 'Imbabura ' ),
     new State(47, 47, ' Loja' ),
     new State(47, 47, ' Los Rios' ),
     new State(47, 47, 'Manabi ' ),
     new State(47, 47, 'Morona-Santiago ' ),
     new State(47, 47, 'Napo ' ),
     new State(47, 47, 'Orellana ' ),
     new State(47, 47, ' Pastaza' ),
     new State(47, 47, ' Pichincha' ),
     new State(47, 47, 'Sucumbios ' ),
     new State(47, 47, 'Tungurahua ' ),
     new State(47, 47, 'Zamora-Chinchipe' ),

     // 48  Egypt

    
     new State(48, 48, 'Ad Daqahliyah' ),
     new State(48, 48, 'Al Bahr al Ahmar' ),
     new State(48, 48, 'Al Buhayrah' ),
     new State(48, 48, 'Al Fayyum' ),
     new State(48, 48, 'Al Gharbiyah' ),
     new State(48, 48, 'Al Iskandariyah' ),
     new State(48, 48, 'Al Isma iliyah' ),
     new State(48, 48, 'Al Jizah' ),
     new State(48, 48, 'Al Minufiyah' ),
     new State(48, 48, 'Al Minya' ),
     new State(48, 48, 'Al Qahirah' ),
     new State(48, 48, 'Al Qalyubiyah' ),
     new State(48, 48, 'Al Wadi al Jadid' ),
     new State(48, 48, 'Ash Sharqiyah' ),
     new State(48, 48, 'As Suways' ),
     new State(48, 48, 'Aswan' ),
     new State(48, 48, 'Asyut' ),
     new State(48, 48, 'Bani Suwayf' ),
     new State(48, 48, 'Bur Said' ),
     new State(48, 48, 'Dumyat' ),
     new State(48, 48, 'Janub Sina' ),
     new State(48, 48, 'Kafr ash Shaykh' ),
     new State(48, 48, 'Matruh' ),
     new State(48, 48, 'Qina' ),
     new State(48, 48, 'Shamal Sina' ),
     new State(48, 48, 'Suhaj' ),

     // 49  El Salvador


     new State(49, 49, 'Ahuachapan' ),
     new State(49, 49, 'Cabanas' ),
     new State(49, 49, 'Chalatenango' ),
     new State(49, 49, 'Cuscatlan' ),
     new State(49, 49, 'La Libertad' ),
     new State(49, 49, 'La Paz' ),
     new State(49, 49, 'La Union' ),
     new State(49, 49, 'Morazan' ),
     new State(49, 49, 'San Miguel' ),
     new State(49, 49, 'San Salvado' ),
     new State(49, 49, 'Santa Ana' ),
     new State(49, 49, 'San Vicent' ),
     new State(49, 49, 'Sonsonate' ),
     new State(49, 49, 'Usulutan' ),
// Equatorial Guinea

     new State(50, 50, 'Annobon' ),
     new State(50, 50, 'Bioko Norte' ),
     new State(50, 50, 'Bioko Sur' ),
     new State(50, 50, 'Centro Sur' ),
     new State(50, 50, 'Kie-Ntem' ),
     new State(50, 50, 'Litora' ), 
      new State(50, 50, 'Wele-Nzas' ),

      // 51   Eritrea
     
     new State(51, 51, 'Anseba' ),
     new State(51, 51, 'Debub' ),
     new State(51, 51, 'Debubawi K eyih Bahri' ),
     new State(51, 51, 'Gash Barka' ),
     new State(51, 51, 'Ma akel' ),
     new State(51, 51, 'Semenawi Keyih Bahri' ),
    // 52   

    
     new State(52, 52, 'Harjumaa (Tallinn)' ),
     new State(52, 52, 'Hiiumaa (Kardla)' ),
     new State(52, 52, 'Ida-Virumaa (Johvi)' ),
     new State(52, 52, 'Jarvamaa (Paide)' ),
     new State(52, 52, 'Jogevamaa (Jogeva)' ),
     new State(52, 52, 'Laanemaa (Haapsalu)' ),
     new State(52, 52, 'Laane-Virumaa (Rakvere)' ),
     new State(52, 52, 'Parnumaa (Parnu)' ),
     new State(52, 52, 'Polvamaa (Polva)' ),
     new State(52, 52, 'Raplamaa (Rapla)' ),
     new State(52, 52, 'Saaremaa (Kuressaare)' ),
     new State(52, 52, 'Tartumaa (Tartu)' ),
     new State(52, 52, 'Valgamaa (Valga)' ),
     new State(52, 52, 'Viljandimaa (Viljandi)' ),
     new State(52, 52, 'Vorumaa (Voru)' ),

   
      new State(53, 53, 'Addis Ababa' ),
      new State(53, 53, 'Afar' ),
      new State(53, 53, 'Amhara' ),
      new State(53, 53, 'Binshangul Gumuz' ),
      new State(53, 53, 'Dire Dawa' ),
      new State(53, 53, 'Gambela Hizboch' ),
      new State(53, 53, 'Harari' ),
      new State(53, 53, 'Oromia' ),
      new State(53, 53, 'Somali' ),
      new State(53, 53, 'Tigray' ),
      new State(53, 53, 'Southern Nation' ),
       new State(53, 53, 'Nationalities' ),
       new State(53, 53, 'Peoples Region' ),
     
      //Fiji
       new State(56, 56, 'Central (Suva)' ),
       new State(56, 56, 'Eastern (Levuka)' ),
       new State(56, 56, 'Northern (Labasa)' ),
       new State(56, 56, 'Rotuma' ),
       new State(56, 56, 'Western (Lautoka)' ),
       // 

      // Finland
       new State(57, 57, 'Aland' ),
       new State(57, 57, 'Etela-Suomen Laani' ),
       new State(57, 57, 'Ita-Suomen Laani' ),
       new State(57, 57, 'Lansi-Suomen Laani' ),
       new State(57, 57, 'Lappi' ),
       new State(57, 57, 'Oulun Laani' ),
      // France
        new State(58, 58, 'Alsace' ),
        new State(58, 58, 'Aquitaine' ),
        new State(58, 58, 'Auvergne' ),
        new State(58, 58, 'Basse-Normandie' ),
        new State(58, 58, 'Bourgogne' ),
        new State(58, 58, 'Bretagne' ),
        new State(58, 58, 'Centre' ),
        new State(58, 58, 'Champagne-Ardenne' ),
        new State(58, 58, 'Corse' ),
        new State(58, 58, 'Franche-Comte' ),
        new State(58, 58, 'Haute-Normandi' ),
        new State(58, 58, 'Ile-de-France' ),
        new State(58, 58, 'Languedoc-Roussillon' ),
          new State(58, 58, 'Limousin' ),
          new State(58, 58, 'Lorraine' ),
          new State(58, 58, 'Midi-Pyrenees' ),
          new State(58, 58, 'Nord-Pas-de-Calais' ),
          new State(58, 58, 'Pays de la Loire' ),
           new State(58, 58, 'Picardie' ),
           new State(58, 58, 'Poitou-Charentes' ),
           new State(58, 58, 'Provence-Alpes-Cote d Azur' ),
           new State(58, 58, 'Rhone-Alpes' ),
       /// Gabon

     
           new State(61, 61, ' Estuaire' ),
           new State(61, 61, 'Haut-Ogooue ' ),
           new State(61, 61, 'Moyen-Ogooue ' ),
           new State(61, 61, 'Ngounie ' ),
           new State(61, 61, 'Nyanga ' ),
           new State(61, 61, 'Ogooue-Ivindo ' ),
           new State(61, 61, 'Ogooue-Lolo ' ),
           new State(61, 61, 'Ogooue-Maritime ' ),
           new State(61, 61, ' Woleu-Ntem' ),


//   Gambia

           new State(62, 62, ' Banjul' ),
           new State(62, 62, 'Central River ' ),
           new State(62, 62, ' Lower River' ),
           new State(62, 62, ' North Bank' ),
           new State(62, 62, ' Upper River' ),
           
           new State(62, 62, 'Western ' ),
           new State(63, 63, 'Georgia ' ),
          
           new State(64, 64, ' Baden-Wuerttemberg' ),
           new State(64, 64, 'Bayern ' ),
           new State(64, 64, 'Berlin ' ),
           new State(64, 64, ' Brandenburg' ),
           new State(64, 64, 'Bremen ' ),
           new State(64, 64, 'Hamburg ' ),
           new State(64, 64, 'Hessen ' ),
           new State(64, 64, 'Mecklenburg-Vorpommern ' ),
           new State(64, 64, ' Niedersachsen' ),
           new State(64, 64, 'Nordrhein-Westfalen ' ),
           new State(64, 64, 'Rheinland-Pfalz ' ),
           new State(64, 64, 'Saarland ' ),
         
           new State(64, 64, ' Sachsen' ),
           new State(64, 64, 'Sachsen-Anhalt ' ),
           new State(64, 64, 'Schleswig-Holstein ' ),
           new State(64, 64, 'Thueringen ' ),

           //Ghana

          
           new State(65, 65, 'Ashanti ' ),
           new State(65, 65, 'Brong-Ahafo" ' ),
           new State(65, 65, 'Central ' ),
           new State(65, 65, 'Eastern ' ),
           new State(65, 65, 'Greater Accra ' ),
           new State(65, 65, 'Greater Accra ' ),
           new State(65, 65, 'Upper East ' ),
           new State(65, 65, ' Upper West"' ),
           new State(65, 65, 'Volta ' ),
           new State(65, 65, 'Western ' ),
           // Greece
        
           new State(67, 67, 'Agion Oros ' ),
           new State(67, 67, 'Achaia ' ),
           new State(67, 67, 'Aitolia kai Akarmania ' ),
           new State(67, 67, ' Argolis' ),
           new State(67, 67, ' Arkadia' ),
           new State(67, 67, ' Arta' ),
           new State(67, 67, 'Attiki ' ),
           new State(67, 67, 'Chalkidiki ' ),
           new State(67, 67, 'Chanion ' ),
           new State(67, 67, 'Chios ' ),
           new State(67, 67, 'Dodekanisos ' ),
           new State(67, 67, 'Drama ' ),
           new State(67, 67, 'Evros ' ),
           new State(67, 67, 'Evrytania ' ),
           new State(67, 67, 'Evrytania ' ),
           new State(67, 67, ' Florina' ),
           new State(67, 67, 'Fokidos ' ),
           new State(67, 67, ' Fthiotis' ),
           new State(67, 67, ' Grevena' ),
           new State(67, 67, ' Ileia' ),
           new State(67, 67, 'Imathia ' ),

           new State(67, 67, 'Ioannina ' ),
           new State(67, 67, 'Irakleion ' ),
           new State(67, 67, 'Karditsa ' ),
           new State(67, 67, 'Kastoria ' ),
           new State(67, 67, 'Kavala ' ),
           new State(67, 67, ' Kefallinia' ),
           new State(67, 67, 'Kerkyra ' ),
           new State(67, 67, 'Kilkis ' ),
           new State(67, 67, ' Korinthia' ),
           new State(67, 67, 'Kozani ' ),
           new State(67, 67, ' Kyklades' ),
           new State(67, 67, 'Lakonia ' ),
           new State(67, 67, 'Larisa ' ),
           new State(67, 67, ' Lasithi' ),
           new State(67, 67, 'Lefkas ' ),
           new State(67, 67, 'Lesvos ' ),
           new State(67, 67, 'Magnisia ' ),
           new State(67, 67, ' Messinia' ),
           new State(67, 67, ' Pella' ),
           new State(67, 67, 'Pieria ' ),
           new State(67, 67, ' Preveza' ),
           new State(67, 67, 'Rethynnis ' ),
           new State(67, 67, 'Rodopi ' ),
           new State(67, 67, 'Samos ' ),
           new State(67, 67, 'Serrai ' ),
           new State(67, 67, ' Thesprotia' ),
           new State(67, 67, 'Thessaloniki ' ),
           new State(67, 67, ' Trikala' ),
           new State(67, 67, ' Voiotia' ),
           new State(67, 67, 'Voiotia ' ),
           new State(67, 67, 'Zakynthos ' ),
            
           // Greenland
           new State(68, 68, 'Avannaa (Nordgronland) ' ),
           new State(68, 68, ' Tunu (Ostgronland)' ),
           new State(68, 68, 'Tunu (Ostgronland) ' ),
           //Grenada
           
           new State(69, 69, 'Carriacou and Petit Martinique ' ),
           new State(69, 69, 'Saint Andrew ' ),
           new State(69, 69, ' Saint David' ),
           new State(69, 69, 'Saint Georg ' ),
           new State(69, 69, 'Saint John ' ),
           new State(69, 69, 'Saint Mark ' ),
           new State(69, 69, 'Saint Patrick ' ),
           new State(72, 72, 'Alta Verapaz ' ),
           new State(72, 72, 'Baja Verapaz ' ),
           new State(72, 72, 'Chimaltenango ' ),
           new State(72, 72, 'Chiquimula ' ),
           new State(72, 72, 'El Progreso ' ),
           new State(72, 72, 'Escuintla ' ),
           new State(72, 72, 'Guatemala ' ),
           new State(72, 72, 'Huehuetenango ' ),
           new State(72, 72, 'Izabal ' ),
           new State(72, 72, 'Jalapa ' ),
           new State(72, 72, 'Jutiapa ' ),
           new State(72, 72, 'Peten ' ),
           new State(72, 72, 'Quetzaltenango ' ),
           new State(72, 72, ' Quiche' ),
           new State(72, 72, 'Retalhuleu ' ),
           new State(72, 72, 'Sacatepequez ' ),
           new State(72, 72, 'San Marcos ' ),
           new State(72, 72, ' Santa Rosa' ),
           new State(72, 72, 'Solola ' ),
           new State(72, 72, ' Suchitepequez' ),
           new State(72, 72, ' Totonicapan' ),
           new State(72, 72, 'Zacapa ' ),

           
           new State(74, 74, 'Beyla ' ),
           new State(74, 74, 'Boffa ' ),
           new State(74, 74, 'Boke ' ),
           new State(74, 74, 'Conakry ' ),
           new State(74, 74, ' Coyah' ),
           new State(74, 74, 'Dabola ' ),
           new State(74, 74, 'Dalaba ' ),
           new State(74, 74, 'Dinguiraye ' ),
          new State(74, 74, ' Dubreka' ),
           new State(74, 74, ' Faranah' ),
           new State(74, 74, 'Forecariah ' ),
           new State(74, 74, 'Fria ' ),
        
           new State(74, 74, 'Gaoual ' ),
           new State(74, 74, 'Gueckedou ' ),
           new State(74, 74, 'Kankan ' ),
          new State(74, 74, ' Kerouane' ),
           new State(74, 74, ' Kerouane' ),
           new State(74, 74, 'Kindia ' ),
           new State(74, 74, 'Kissidougou ' ),

           new State(74, 74, 'Koubia ' ),
           new State(74, 74, 'Koundara ' ),
           new State(74, 74, 'Kouroussa ' ),
          new State(74, 74, ' Labe' ),
           new State(74, 74, ' Lelouma' ),
           new State(74, 74, 'Lola ' ),
           new State(74, 74, 'Macenta ' ),

           new State(74, 74, 'Mali ' ),
           new State(74, 74, 'Mamou ' ),
           new State(74, 74, 'Mandiana ' ),
          new State(74, 74, ' Nzerekore' ),
           new State(74, 74, ' Pita' ),
           new State(74, 74, 'Siguiri ' ),
           new State(74, 74, 'Telimele ' ),

           new State(74, 74, 'Tougue ' ),
           new State(74, 74, 'Yomou ' ),
       
          new State(75, 75, 'Barima-Waini ' ),
          new State(75, 75, 'Cuyuni-Mazaruni ' ),
          new State(75, 75, 'Demerara-Mahaica ' ),
          new State(75, 75, 'East Berbice-Corentyne ' ),
          new State(75, 75, 'Essequibo Islands-West Demerara ' ),
          new State(75, 75, 'Mahaica-Berbice ' ),
          new State(75, 75, 'Pomeroon-Supenaam ' ),
          new State(75, 75, 'Potaro-Siparuni ' ),
          new State(75, 75, 'Upper Demerara-Berbice ' ),
          new State(75, 75, 'Upper Takutu-Upper Essequibo ' ),

          //76
          new State(76, 76, 'Artibonite ' ),
          new State(76, 76, 'Centre ' ),
          new State(76, 76, ' Grand' ),
          new State(76, 76, 'Grand ' ),
          new State(76, 76, ' Nord' ),
          new State(76, 76, ' Nord-Est' ),
          new State(76, 76, 'Nord-Ouest ' ),
          new State(76, 76, 'Ouest ' ),
          new State(76, 76, 'Sud ' ),
          new State(76, 76, 'Sud-Est ' ),

          //77
          new State(77, 77, 'Atlantida ' ),
          new State(77, 77, 'Choluteca ' ),
          new State(77, 77, 'Colon ' ),
          new State(77, 77, 'Comayagua ' ),
          new State(77, 77, 'Copan ' ),
          new State(77, 77, 'Cortes ' ),
          new State(77, 77, 'El Paraiso ' ),
          new State(77, 77, 'Francisco Morazan ' ),
          new State(77, 77, 'Gracias a Dios ' ),
          new State(77, 77, ' Intibuca' ),
          new State(77, 77, ' Islas de la Bahia' ),
          new State(77, 77, ' La Paz' ),
          new State(77, 77, 'Lempira ' ),
          new State(77, 77, ' Ocotepeque' ),
          new State(77, 77, 'Olancho ' ),
          new State(77, 77, 'Santa Barbara ' ),
          new State(77, 77, 'Valle ' ),
          new State(77, 77, 'Yoro ' ),
          //78
          
          new State(78, 78, 'Hong Kong' ),
          new State(79, 79, 'Bacs-Kiskun ' ),
          new State(79, 79, ' Baranya' ),
          new State(79, 79, ' Bekes' ),
          new State(79, 79, 'Borsod-Abauj-Zemplen ' ),
          new State(79, 79, 'Csongrad ' ),
          new State(79, 79, 'Fejer ' ),
          new State(79, 79, 'Gyor-Moson-Sopron ' ),
          new State(79, 79, 'Hajdu-Bihar ' ),
          new State(79, 79, 'Heves ' ),
          new State(79, 79, 'Jasz-Nagykun-Szolnok ' ),
          new State(79, 79, 'Komarom-Esztergom ' ),
          new State(79, 79, 'Nograd ' ),
          new State(79, 79, 'Pest ' ),
          new State(79, 79, 'Somogy ' ),
          new State(79, 79, 'Szabolcs-Szatmar-Bereg ' ),
          new State(79, 79, 'Tolna ' ),
          new State(79, 79, 'Vas ' ),
          new State(79, 79, 'Veszprem ' ),
          new State(79, 79, 'Zala ' ),
          new State(79, 79, 'Bekescsaba ' ),
          new State(79, 79, 'Debrecen ' ),
          new State(79, 79, 'Dunaujvaros ' ),
          new State(79, 79, 'Eger ' ),
          new State(79, 79, 'Gyor ' ),
          new State(79, 79, ' Hodmezovasarhely' ),
          new State(79, 79, 'Kaposvar ' ),
          new State(79, 79, 'Kecskemet ' ),
          new State(79, 79, ' Miskolc' ),
          new State(79, 79, 'Nagykanizsa ' ),
          new State(79, 79, 'Nyiregyhaza ' ),
          new State(79, 79, 'Pecs ' ),
          new State(79, 79, ' Sopron' ),
          new State(79, 79, 'Szeged ' ),
          new State(79, 79, 'Szekesfehervar ' ),
          new State(79, 79, 'Szolnok ' ),
          new State(79, 79, 'Szombathely ' ),
          new State(79, 79, 'Tatabanya ' ),
          new State(79, 79, ' Veszprem' ),
          new State(79, 79, 'Zalaegerszeg ' ),

          // 80 
         
          new State(80, 80, 'Austurland ' ),
          new State(80, 80, 'Hofudhborgarsvaedhi ' ),
          new State(80, 80, 'Nordhurland Eystra ' ),
          new State(80, 80, 'Nordhurland Vestra ' ),
          new State(80, 80, 'Sudhurland ' ),
          new State(80, 80, 'Sudhurnes ' ),
          new State(80, 80, ' Vestfirdhir' ),
          new State(80, 80, 'Vesturland ' ),
         
             
          new State(1, 81, 'Andaman and Nicobar Islands ' ),
          new State(2, 81, 'Andhra Pradesh ' ),
          new State(3, 81, 'Arunachal Pradesh ' ),
          new State(4, 81, 'Assam ' ),
          new State(5, 81, 'Bihar ' ),
          new State(6, 81, 'Chandigarh ' ),
          new State(7, 81, 'Chhattisgarh ' ),
          new State(8, 81, 'Dadra and Nagar Haveli ' ),
          new State(9, 81, 'Daman and Diu ' ),
          new State(10, 81, 'Delhi' ),
          
          new State(11, 81, 'Goa ' ),
          new State(12, 81, 'Gujarat ' ),
          new State(13, 81, 'Haryana ' ),
          new State(14, 81, 'Himachal Pradesh ' ),
          new State(15, 81, 'Jammu and Kashmir ' ),
          
          new State(16, 81, ' Jharkhand' ),
          new State(17, 81, 'Karnataka ' ),
          new State(18, 81, ' Kerala' ),
          new State(19, 81, 'Lakshadweep ' ),
          new State(20, 81, 'Madhya Pradesh ' ),
         
          new State(21, 81, 'Maharashtra ' ),
          new State(22, 81, 'Manipur ' ),
          new State(23, 81, 'Meghalaya ' ),
          new State(24, 81, 'Mizoram ' ),
          new State(25, 81, 'Nagaland ' ),
          
          new State(26, 81, 'Orissa ' ),
          new State(27, 81, 'Pondicherry ' ),
          new State(28, 81, 'Punjab ' ),
          new State(29, 81, 'Rajasthan ' ),
          new State(30, 81, 'Sikkim ' ),
          new State(31, 81, 'Tamil Nadu ' ),
          new State(32, 81, 'Telangana ' ),
          new State(33, 81, 'Tripura ' ),
          
          new State(34, 81, 'Uttaranchal ' ),
          new State(35, 81, 'Uttar Pradesh ' ),
          new State(36, 81, 'West Bengal ' ),
          
          new State(82, 82, 'Aceh ' ),  new State(82, 82, ' Bali' ),  new State(82, 82, 'Banten ' ),
          new State(82, 82, 'Bengkulu ' ),  new State(82, 82, 'Gorontalo ' ),  new State(82, 82, 'Irian Jaya Barat ' ),
          new State(82, 82, 'Jakarta Raya ' ),  new State(82, 82, 'Jambi ' ),  new State(82, 82, 'Jawa Barat ' ),
          new State(82, 82, ' Jawa Tengah' ),  new State(82, 82, 'Jawa Timur ' ),  new State(82, 82, 'Kalimantan Barat ' ),
           new State(82, 82, 'Kalimantan Selatan ' ),  new State(82, 82, 'Kalimantan Tengah ' ),  new State(82, 82, 'Kalimantan Timur ' ),
           new State(82, 82, 'Kepulauan Bangka Belitung ' ),  new State(82, 82, 'Kepulauan Riau ' ),  new State(82, 82, 'Lampung ' ),
           new State(82, 82, 'Maluku ' ),  new State(82, 82, ' Maluku Utara' ),  new State(82, 82, 'Nusa Tenggara Barat ' ),
           new State(82, 82, 'Nusa Tenggara Timur ' ),  new State(82, 82, 'Papua ' ),  new State(82, 82, 'Riau ' ),
           new State(82, 82, 'Sulawesi Barat ' ),  new State(82, 82, 'Sulawesi Selatan ' ),  new State(82, 82, 'Sulawesi Tengah ' ),
           new State(82, 82, ' Sulawesi Tenggara' ),  new State(82, 82, 'Sulawesi Utara ' ),  new State(82, 82, ' Sumatera Barat' ),
           new State(82, 82, 'Sumatera Selatan ' ),  new State(82, 82, 'Sumatera Utara ' ),  new State(82, 82, 'Yogyakarta ' ),

       
           new State(83, 83, 'Ardabil ' ),  new State(83, 83, '"Azarbayjan-e Gharbi ' ),  new State(83, 83, 'Azarbayjan-e Sharqi ' ),
           new State(83, 83, ' Bushehr' ),  new State(83, 83, 'Chahar Mahall va Bakhtiari ' ),  new State(83, 83, ' Esfahan' ),
           new State(83, 83, 'Fars ' ),  new State(83, 83, 'Gilan ' ),  new State(83, 83, ' Golestan' ),
           new State(83, 83, 'Hamadan ' ),  new State(83, 83, ' Hormozgan' ),  new State(83, 83, 'Ilam ' ),
           new State(83, 83, 'Kerman ' ),  new State(83, 83, 'Kermanshah ' ),  new State(83, 83, ' "Khorasan-e Janubi' ),
           new State(83, 83, 'Khorasan-e Razavi ' ),  new State(83, 83, 'Khorasan-e Shemali ' ),  new State(83, 83, 'Khuzestan ' ),
           new State(83, 83, 'Kohgiluyeh va Buyer Ahmad ' ),  new State(83, 83, 'Kordestan ' ),  new State(83, 83, 'Lorestan ' ),
           new State(83, 83, 'Markazi ' ),  new State(83, 83, 'Mazandaran ' ),  new State(83, 83, ' Qazvin' ),
       
           new State(83, 83, 'Qom ' ),  new State(83, 83, 'Semnan ' ),  new State(83, 83, 'Sistan va Baluchestan ' ),
           new State(83, 83, 'Tehran ' ),  new State(83, 83, ' Yazd' ),  new State(83, 83, 'Zanjan' ),
          
           new State(84, 84, 'Al Anbar ' ),  new State(84, 84, 'Al Basrah ' ),  new State(84, 84, 'Al Muthanna ' ),
           new State(84, 84, 'Al Qadisiyah ' ),  new State(84, 84, ' An Najaf' ),  new State(84, 84, ' Arbil' ),
           new State(84, 84, 'As Sulaymaniyah ' ),  new State(84, 84, 'At Ta mim ' ),  new State(84, 84, 'Babil ' ),
           new State(84, 84, ' Baghdad' ),  new State(84, 84, ' Dahuk' ),  new State(84, 84, 'Dhi Qar ' ),
           new State(84, 84, 'Diyala ' ),  new State(84, 84, ' Karbala' ),  new State(84, 84, 'Maysan ' ),
           new State(84, 84, ' Ninawa' ),  new State(84, 84, 'Salah ad Din ' ),  new State(84, 84, ' Wasit' ),
         
           new State(85, 85, 'Carlow ' ),  new State(85, 85, 'Cavan ' ), new State(85, 85, 'Clare ' ), 
           new State(85, 85, 'Cork ' ),  new State(85, 85, ' Donegal' ), new State(85, 85, ' Dublin' ), 
           new State(85, 85, 'Galway ' ),  new State(85, 85, 'Kerry ' ), new State(85, 85, 'Kildare ' ), 
           new State(85, 85, 'Kilkenny ' ),  new State(85, 85, ' Laois' ), new State(85, 85, 'Leitrim ' ), 
           new State(85, 85, 'Limerick ' ),  new State(85, 85, 'Longford ' ), new State(85, 85, 'Louth ' ),  
          new State(85, 85, ' Mayo' ),  new State(85, 85, 'Meath ' ), new State(85, 85, 'Monaghan ' ), 
           new State(85, 85, 'Offaly ' ),  new State(85, 85, 'Roscommon ' ), new State(85, 85, 'Sligo ' ), 
           new State(85, 85, 'Tipperary ' ),  new State(85, 85, ' Waterford' ), new State(85, 85, 'Westmeath ' ),
           new State(85, 85, 'Wexford ' ),  new State(85, 85, 'Wexford ' ),

           new State(86, 86, 'Central ' ), new State(86, 86, 'Haifa ' ), new State(86, 86, 'Jerusalem ' ),
           
           new State(86, 86, 'Northern ' ), new State(86, 86, ' Southern' ), new State(86, 86, 'Tel Aviv ' ),

           
       
         
           new State(87, 87, 'Abruzzo ' ),  new State(87, 87, 'Basilicata ' ),  new State(87, 87, ' Calabria' ),
           new State(87, 87, ' Campania' ),  new State(87, 87, 'Campania' ),  new State(87, 87, ' Emilia-Romagna' ), 
           new State(87, 87, 'Friuli-Venezia Giulia ' ),  new State(87, 87, 'Lazio ' ),  new State(87, 87, 'Liguria ' ),
           new State(87, 87, 'Lombardia ' ),  new State(87, 87, 'Marche ' ),  new State(87, 87, 'Molise ' ),
           new State(87, 87, ' Piemonte' ),  new State(87, 87, 'Puglia ' ),  new State(87, 87, 'Sardegna ' ),
           new State(87, 87, 'Sicilia ' ),  new State(87, 87, 'Toscana ' ),  new State(87, 87, 'Trentino-Alto Adige ' ),
           new State(87, 87, 'Umbria ' ),  new State(87, 87, 'Valle d Aosta ' ),  new State(87, 87, 'Veneto ' ),
          
           new State(88, 88, 'Clarendon ' ),  new State(88, 88, ' Hanover' ),new State(88, 88, 'Kingston ' ),
           new State(88, 88, 'Manchester ' ),  new State(88, 88, 'Portland ' ),new State(88, 88, 'Saint Andrew ' ),
           new State(88, 88, 'Saint Ann ' ),  new State(88, 88, 'Saint Catherine ' ),new State(88, 88, 'Saint Elizabeth ' ),
           new State(88, 88, 'Saint James ' ),  new State(88, 88, 'Saint Mary ' ),new State(88, 88, 'Saint Thomas ' ),
           new State(88, 88, 'Trelawny ' ),  new State(88, 88, 'Westmoreland ' ),

         // Saudi Arabia
           new State(159, 159, 'Al Bahah ' ), new State(159, 159, 'Al Hudud ash Shamaliyah ' ), new State(159, 159, 'Al Jawf ' ),
           new State(159, 159, 'Al Madinah ' ), new State(159, 159, 'Al Qasim ' ), new State(159, 159, 'Ar Riyad ' ),
           new State(159, 159, 'Ash Sharqiyah ' ), new State(159, 159, 'Asir ' ), new State(159, 159, 'Ha il ' ),
           new State(159, 159, 'Jizan ' ), new State(159, 159, 'Makkah ' ), new State(159, 159, ' Najran' ),
           new State(159, 159, ' Tabuk' ),
         // Kuwait
         
           new State(95, 95, 'Al Ahmadi ' ),new State(95, 95, 'Al Farwaniyah ' ),new State(95, 95, ' Al Asimah' ),
           new State(95, 95, 'Al Jahra ' ),new State(95, 95, ' Hawalli' ),new State(95, 95, ' Mubarak Al-Kabeer' ),

         
          //United Arab Emirates
           new State(191, 191, ' Abu Dhabi' ),new State(191, 191, 'Ajman ' ),new State(191, 191, ' Al Fujayrah' ),
           new State(191, 191, ' Sharjah' ),new State(191, 191, 'Dubai ' ),new State(191, 191, 'Ras al Khaymah ' ),
           new State(191, 191, 'Umm al Qaywayn ' ),
           //Qatar

          
           new State(153, 153, 'Ad Dawhah ' ),new State(153, 153, 'Al Ghuwayriyah ' ),new State(153, 153, 'Al Jumayliyah ' ),
           new State(153, 153, 'Al Khawr ' ),new State(153, 153, 'Al Wakrah ' ),new State(153, 153, 'Ar Rayyan ' ),
           new State(153, 153, 'Jarayan al Batinah ' ),new State(153, 153, 'Madinat ash Shamal ' ),new State(153, 153, 'Umm Said ' ),
           new State(153, 153, ' Umm Salal' ),
         
           new State(140, 140, 'Ad Dakhiliyah ' ),new State(140, 140, 'Al Batinah ' ),new State(140, 140, 'Al Wusta ' ),
           new State(140, 140, 'Ash Sharqiyah ' ),new State(140, 140, 'Az Zahirah ' ),new State(140, 140, 'Masqat ' ),
           new State(140, 140, ' Musandam' ),new State(140, 140, 'Dhofar ' ),
           //
           
           new State(73, 73, 'Eastern Cape ' ),new State(73, 73, 'Free State ' ),new State(73, 73, 'Gauteng ' ),
           new State(73, 73, 'KwaZulu-Natal ' ),new State(73, 73, 'Limpopo ' ),new State(73, 73, 'Mpumalanga ' ),
           new State(73, 73, 'North-West ' ),new State(73, 73, 'Northern Cape ' ),new State(73, 73, 'Western Cape ' ),
        

          


    ];
  }

}