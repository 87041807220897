import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-cbse-usa',
  templateUrl: './cbse-usa.component.html',
  styleUrls: ['./cbse-usa.component.css','../../languages/languages/languages.component.css','../../cbse-nigeria/cbse-nigeria/cbse-nigeria.component.css']
})
export class CbseUSAComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {

    
  }

  ngOnInit() {
    var host=  location.pathname;
   
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "CBSE Online  Tutoring in USA | Tutoring in USA");
    this.meta.updateTag({name:"description", content:" Looking for the best online tutoring for CBSE Board in USA. Avail Ziyyara’s one-on-one live online tuition classes for CBSE in USA from the comfort of your home."})
    this.metaService.removeStructuredData();
   // this.metaService.insertSchema(this.websiteSchema);
 this.meta.updateTag({name:"keywords", content:" home tuition for cbse board in usa, home tuition near me for cbse board in usa, home tuitions for cbse board in usa, home tutor for cbse board in usa, home tutors near me for cbse board in usa, online classes for cbse in usa, online tuition classes for cbse in usa, online tuition for cbse board in usa, online tutor for cbse board in usa, online tutoring for cbse board in usa, private tuition for cbse board in usa, private tutor for cbse board in usa, tuition at home for cbse board in usa, tuition centre for cbse board in usa, tuition classes for cbse in usa"})
 
 
   var ogTitle='CBSE Online  Tutoring in USA | Tutoring in USA';
   var ogDescription="Looking for the best online tutoring for CBSE Board in USA. Avail Ziyyara’s one-on-one live online tuition classes for CBSE in USA from the comfort of your home."
     this.meta.updateTag({property:"og:title", content:ogTitle})
     this.meta.updateTag({property:"og:description", content:ogDescription})
     this.meta.updateTag({name:"twitter:title", content:ogTitle})
     this.meta.updateTag({name:"twitter:description", content:ogDescription});

  }

}
