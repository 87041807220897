<div class="col-md-12">
    <div class="popup-form">
        <div class="popup-form-inner">
            <div class="popup-form-heading">
                <div class="d-flex align-items-center justify-content-between">
                    <h2>login {{"login.login_title" | mytranslate}}
                        <!--<sup>Beta</sup>--></h2>

                    <span (click)="no()">
            <i class="fa fa-close color-primary cursor-pointer"></i>
          </span>
                </div>
                <!-- <span>Please Sign in to your Ziyyara Account</span> -->
            </div>
            <form id="Login" autocomplete="nope">
                <p style="color:red">{{errorMessage}}</p>
                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" class="mat-form-custom ">
                            <mat-label>{{"login.email_title" | mytranslate}}</mat-label>
                            <input matInput type="email" [formControl]="email" autocomplete="nope" placeholder="">
                        </mat-form-field>
                        <mat-error class="error">{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                    </div>
                </div>
                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" class="mat-form-custom ">
                            <mat-label>{{"login.password_title" | mytranslate}}</mat-label>
                            <input matInput [formControl]="password" autocomplete="new0password" type="password" placeholder="">
                        </mat-form-field>
                        <mat-error>{{_util.errorMessage(password,FORM_ERROR?.password)}}</mat-error>
                    </div>
                </div>
                <!-- <div class="form-group">
          <div class="mat-app-background basic-container">
            <mat-select matInput placeholder='Please Select Your City' class="form-control"
                      [formControl]="city"  class="form-control">
                      <mat-option *ngFor="let city of cities" [value]="city.city">{{city.city}}
                      </mat-option>
                    </mat-select>
          </div>
        </div> -->

                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <button type="submit" [disabled]="disabled" (click)="submit()" class="btn m-0 btn-ziyyara">{{"login.login_title" | mytranslate}}</button>
                    <a (click)="forgot()" class="pad">{{"login.forgot_password" | mytranslate}}</a>
                </div>
            </form>
            <div class="forgot pull-right">
            </div>

            <div class=" text-center">
                <a (click)="socialSignIn('google',1)" class="btn btn-block btn-success font-weight-bold mb-2"><i
            class="fa fa-google"></i>
          <span class="network-name">&nbsp;&nbsp;{{"login.login_google_stu" | mytranslate}}</span>
        </a>
                <a (click)="socialSignIn('google',2)" class="btn btn-block font-weight-bold btn-primary"><i
            class="fa fa-google"></i>
          <span class="network-name">&nbsp;&nbsp;{{"login.login_google_tutor" | mytranslate}}</span>
        </a>
            </div>

            <p class="text-center mt-3"><span class="reola-tc-size-bold">{{"login.dont_have_account" | mytranslate}}</span>&nbsp; <a class="hrefclass befault-color" (click)="signup()">{{"login.reister_title" | mytranslate}}</a></p>
        </div>
    </div>