import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import { GetOnlineTuitionComponent } from './get-online-tuition/get-online-tuition.component';


const pageRoutes: Routes = [

  { path: '', component: GetOnlineTuitionComponent },

];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    
    RouterModule.forChild(pageRoutes),

  ],
  declarations: [GetOnlineTuitionComponent],
  entryComponents: []
})
export class GetOnlineTuitionModule { }


