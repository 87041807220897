import { Component, OnInit, Inject } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  

@Component({
  selector: 'app-best-online-home-tuition',
  templateUrl: './best-online-home-tuition.component.html',
  styleUrls: ['./best-online-home-tuition.component.css']
})
export class BestOnlineHomeTuitionComponent implements OnInit {
  constructor( 
    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

  ngOnInit() {
   
    this.createSEOServices();
  }
  createSEOServices(){
    var host=  location.pathname;
   
    this.metaService.createCanonicalURL(host);
  //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
    this.titleService.setTitle( "What is Ziyyara | What's Online Education Platform");
    this.meta.updateTag({name:"description", content:"Ziyyara is an online education platform which offers one-on-one live tutoring for all subjects to all grades students and lets them learn at their own pace."})
    this.meta.updateTag({name:"keywords", content:"Ziyyara is an online education platform which offers one-on-one live tutoring for all subjects to all grades students and lets them learn at their own pace. "})
    this.metaService.removeStructuredData();
   // this.metaService.insertSchema(this.websiteSchema);
     var ogTitle='What is Ziyyara | What\'s Online Education Platform';
     var ogDescription="Ziyyara is an online education platform which offers one-on-one live tutoring for all subjects to all grades students and lets them learn at their own pace.."
     this.meta.updateTag({property:"og:title", content:ogTitle})
     this.meta.updateTag({property:"og:description", content:ogDescription})
     this.meta.updateTag({name:"twitter:title", content:ogTitle})
     this.meta.updateTag({name:"twitter:description", content:ogDescription});

 
 
}

}
