
import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material";

import { Router, ActivatedRoute } from "@angular/router";
import { SignupComponent } from '../signup/signup.component';
import { ForgotComponent } from '../forgot/forgot.component';
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { HttpService, AppService } from "../../../../services";

import { Store, select } from "@ngrx/store";
import { ApiResponse, AppState, IPROPERTY } from "../../../../interfaces";
import { AppStateService } from "../../../../services/app-state.service";
import * as AppActions from "../../../../store/actions/app.actions";


import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from "angular-6-social-login";
import { OtpComponent } from "../otp/otp.component";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  CONFIG = CONFIG;
  loader;
  disabled=false;
  off = "off";
  errorMessage;
  FORM_ERROR = {
    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`
    },
    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`
    }
  }; 
  constructor( public _matDialogRef: MatDialogRef<LoginComponent>,
    public dialogRef: MatDialogRef<LoginComponent>,
    private appState: AppStateService,
    private appSer: AppService,
    private store: Store<AppState>,
    private socialAuthService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private _fb: FormBuilder, private _router: Router, private _api: HttpService, private _route: ActivatedRoute, public _util: CommonUtil) { }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`
    };
    this._matDialogRef.updatePosition(matDialogConfig.position);
    // let stateObj = { id: "200" }; 
    // window.history.replaceState(stateObj, 
    //             "index", "/login");
    this.getCities();
    this.createForm();
    
  }
  cities;
  no() {
    this.dialogRef.close(false);
  }
  /*****************Show signup and login popup************************* */
  signup(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(SignupComponent, {
      width: '425px',
      height: 'auto',
    });

    

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  forgot(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(ForgotComponent, {
      width: '425px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getCities() : any {
    this._api.getCities('cities')
    .subscribe((data)=>{
      // ////////////console.log(data.result);
      this.cities = data.result;
    })
  }
  /*****************End Show signup and login popup************************* */
  createForm() {
    this.loginForm = this._fb.group({
      email: ["", [Validators.required, Validators.pattern(Regex.email)]],
      password: ["", [Validators.required]],
      city: ["", null],
    });
  }

  get email(): FormControl {
    return this.loginForm.get("email") as FormControl;
  }

  get password(): FormControl {
    return this.loginForm.get("password") as FormControl;
  }

  get city(): FormControl{
    return this.loginForm.get('cities') as FormControl;
  }
  submit() {
    console.log(this.loginForm)
    if (this.loginForm.valid) {
      this.disabled=true;
      this.loginForm.value.email = this.loginForm.value.email.toString().trim();
      this.loader = true;
      this._api
        .postReqUnauth("users_basic_login", this.loginForm.value)
        .subscribe(
          res => this.success(res),
          err => this.error(err),
          () => ( this.disabled=false)
        );
    } else {
      this._util.markError(this.loginForm);
    }
  }

  success(res: ApiResponse) {
    console.log(res)
    if (res.status) {
      res.result['_id']=res.result.id;
    var  email=res.result.email;
    localStorage.setItem("user_id", res.result.id);
      this.store.dispatch(new AppActions.PropertySignup(res.result));
     if(res.result.verification==1){
      localStorage.setItem("token1", res.result.auth_token);
      localStorage.setItem("ziyyara_token", res.result.identity);
      localStorage.setItem("user_name", res.result.name);
      localStorage.setItem("user_dp", res.result.dp);
      localStorage.setItem("group_class", res.result.isEnabledForGroup);
      localStorage.setItem("quran_webinar", res.result.isEnabledForQuranWebinar);
      localStorage.setItem("country", res.result.country);
      localStorage.setItem('user_type',res.result.u_type)
      this._router.navigate(['./dashboard'])
      console.log("logged in")
      this.dialogRef.close(true);

      this.update_login_count(res.result._id)
      
     }else{
      this._api
      .postReqUnauth("users_send_verification_code", {
        email: email,
        
      })
      .subscribe(
        res => this.successsResend(res),
        err => this.error(err.message),
      
      );
      this.popupmessage(res.result);
     }
    } else {
      this.appSer.addSnackBar({ message: res.message })
    }
  }
  successsResend(res){

  }
  popupmessage(data) {

    const value = "Sign Up";
    const content =
        "Please enter the verification code which is sent to your email.";
    const dialogRef = this.dialog.open(OtpComponent, {
        // maxWidth: "500px",
        width: "500px",
        height: "auto",
        data: {
            content: { title: content, heading: value, message1: 1, ok:data.email },
            action: "active"
        },
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(false);
    });

}
  success1(res: ApiResponse) {
    if (res.status) {
      res.result['id']=res.result._id;
      console.log(res.result)
    
      localStorage.setItem("user_id", res.result._id);
      this.store.dispatch(new AppActions.PropertySignup(res.result));
       localStorage.setItem("token1", res.result._id);
       localStorage.setItem("ziyyara_token", res.result.identity);
       localStorage.setItem("group_class", res.result.isEnabledForGroup);
       localStorage.setItem("user_name", res.result.name);
      if(res.result.dp){
         localStorage.setItem("user_dp", res.result.dp);
      }else{
         localStorage.setItem("user_dp", '');
      }
      if(res.result.country){
        localStorage.setItem("country", res.result.country);
      }
      else {
        localStorage.setItem("country", 'India');
      }
     ////////console.log('YOUR ARE LOGIN SUCCESFULLY')
     this._router.navigate(['./dashboard']);

      this.dialogRef.close(true);
      this.update_login_count(res.result._id)

    } else {
      this.appSer.addSnackBar({ message: res.message })
    }
  }

  error(err) {
    this.appSer.addSnackBar({ message: err })
  }

  public socialSignIn(socialPlatform: string, type) {
    ////////////console.log("social platform", socialPlatform);
    let socialPlatformProvider;
    // if (socialPlatform == "facebook") {
    //   socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    // } else if (socialPlatform == "google") {
    //   socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    // }

    if (socialPlatform == "google") {
        socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
       }
    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      if (socialPlatform == "google") {
        const data = {
          social_type: "2",
          social_key: userData.id,
          name: userData.name,
          email: userData.email,
          u_type: type
        };

        ////////console.log(socialPlatform + " sign in data : ", data);

        this._api
          .postReqUnauth("users_basic_social", data)
          .subscribe(
            res => this.success1(res),
            err => this.error(err),
            () => (this.loader = false)
          );
      }
      // } else if (socialPlatform == "facebook") {
      //   const data = {
      //     socialType: "1",
      //     socialId: userData.id,
      //     name: userData.name,
      //     email: userData.email
      //   };

      //   //////////console.log(socialPlatform + " sign in data : ", data);
      //   this._api
      //     .postReqUnauth("user/socialLogin", data)
      //     .subscribe(
      //       res => this.successs(res),
      //       err => this.error(err),
      //       () => (this.loader = false)
      //     );
      // }
    });
  }


  update_login_count(user_id){


    this._api
          .postReqUnauth("update_login_count",{"user_id":user_id} )
          .subscribe(
            res => this.update_login_count_success(res),
            err => this.update_login_count_error(err),
            () => (this.loader = false)
          );
  }


update_login_count_success(res){

  ////////console.log('login count updated')
////////console.log(res.result)

}

update_login_count_error(err){


}
}