<app-header-front>
</app-header-front>

<div class="how-it-works">

    <div class="container">

        <div class="mdlle">
            <h1>GENDER BAIS: A PERPETUAL REASON FOR GROWING ILLITERACY</h1>
        </div>
        <p>When it comes to providing education, there’s nothing more relevant than the gap between those who have a chance towards education, and those who don’t. But that’s not it; is it? Among all the factors affecting education, the most common is gender
            bias. Even today, we see that the notion of educating a girl is shunned, in parts of the world.</p>

        <p>This story is about the same factor, and how it determines a child’s future. It revolves around a teenage girl named Yasmin Naem, who is a fatherless child living with her drunkard mother. She’s a fourteen-year-old kid who goes to a school that’s
            almost five kilometres away from her home. Her mother was once a responsible one, but after the death of her husband, Yusuf, she developed a habit of drinking, in hope that the whole process of coping up from his death, seem easy; and it surely
            went.
        </p>
        <p>The mother, who went by the name Yamina, loved her husband so much that she chose to leave her job as a manager in a real-estate firm, and settled down with Yusuf, who was still serving as a technician in a company that was into hydro projects.
            Yusuf always thought that marrying Yamina was a far-fetched dream; but when she came to his hometown in Tanzania, Yusuf understood that they’ll live happily ever after. He often used to consider himself as a non-worthy marriage candidate for
            Yasmin, but she reassured him that it’s only him and his presence that makes a difference in her life.</p>
        <p>They both got married almost three years before their first and only child, Yasmin was born. Almost six months after their marriage, Yusuf started getting paranoid about the economic status of the two of them. Whenever he used to look at his wife,
            he would always remember that if it wasn’t for him, she would’ve been living her best days with some businessman or a government officer who would match her social stature as well.</p>
        <p>Two years had gone by, but Yusuf was still stuck on the same thought. One fine morning, Yusuf woke up, only to find a note that explained his wife’s sudden absence. It said that she was heading for a walk-in interview, to a place that was not
            very far from their village. But it was only half-an-hour later that Yamina returned home. Yusuf asked her if the interview went well, to which she smiled, and said that she never made it to the interview. Yusuf then asked her about her health,
            and if there’s anything that he can do to help her. She then held his hand, hugged him and said that she was pregnant.</p>
        <p>It’s almost 9 years now, but Yamina still remembers the day she announced her pregnancy to Yusuf. Yasmin never saw her father, but heard a great deal about him. She always thought of asking her mother about the piece of paper that she keeps with
            herself all the time. And finally one day, she found the window she had been looking for. Yamina was passed out on her bed, and the note that Yasmin was looking for was at the side of her table. In that note, she read how her father killed
            himself over the fact that he could not afford her mother’s pregnancy. But he loved her too much to abandon her. Therefore, the easiest for him was to take his own life.</p>
        <p>Yasmin folded the paper back as it was, and kept it right where she found it from. She then tried to wake her mother up, but failed. She then decided to check her pulse. Yamina was breathing. Yasmin kept a bottle of water along with a note that
            she wrote herself. An hour later, when Yamina woke up, she found in her palm, a note that told her about Yasmin, and her dream of becoming a scholar, so that she can give her mother all the happiness in the world.</p>
        <p>Yamina stood up, and left the house to look for her baby girl. A few moments later, Yamina saw Yasmin walking towards her. She went up to her and hugged her tightly. She asked where she had been all this long, to which Yasmin replied that she
            was looking for a public school near the village, but when she couldn’t find one, she started coming back home. It was then when she was stopped by a strange looking man who offered her a folded sheet of paper and said to her that it’s the
            only thing she would need.</p>


        <p>She was hesitant at first, but when the man promised her to sponsor her education, she readily agreed, and without a second thought, took the sheet. Yasmin brought her balled up fists in front of her mother, and showed the sheet of paper. Yamina
            was certain that something was fishy. She opened the paper and was not surprised at all. It read that Yasmin will have to start working for him on the construction project, or else, she’ll be tossed away from the slum where she had come from.</p>
        <p>Yamina looked at her daughter’s innocent eyes, and started sobbing. Yasmin was so happy that she’ll finally go to school, that she didn’t notice her mother’s tears. Yamina, on the other hand, blamed herself for being so naive that she never thought
            about educating her daughter, despite knowing the gender-biassed public that roamed the streets of her neighbourhood.</p>
        <br>
        <h2>Knowing Yasmin, and raising a helping hand</h2>
        <p>When Ziyyara Foundation started off with the project of providing education to all the unprivileged and underprivileged children in Tanzania, we heard a lot about how child labour and child marriage are one of the most common factors due to which,
            illiteracy rate of the country has been dropping dramatically. But when we came to know about Yasmin’s story, we understood that all that we’ve heard was not faux at all. Gender bias is a serious issue that sees no boundaries.</p>

        <p>Our foundation wanted to have a plan that is based on a solid ground, and this story gave us the heads-up that we needed. With the aim to provide education to children of all ages, genders, and economic class, we are starting off our school development
            projects where girls aren’t seen as an unavoidable taboo. Soon, every kid, be it a boy or a girl in Tanzania, will be able to study, and dream of competing at a global level. You too can contribute to it, by joining hands with us on the ground
            level, or by taking Ziyyara’s online tuition classes, and helping us raise more funds.</p>

        <br>
        <div class="btnn-cntr">
            <a routerLink="/ziyyara-foundation" class="anchor">Help Them Learn</a>
        </div>
        <br>
    </div>


    <app-footer-front></app-footer-front>