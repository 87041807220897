import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import { CommonUtil } from "./util";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { LoginComponent } from './learning/shared/components/login/login.component';
import { SignupComponent } from './learning/shared/components/signup/signup.component';
import { ForgotComponent } from './learning/shared/components/forgot/forgot.component';
import { ResetComponent } from './learning/shared/components/reset/reset.component';
import { StoreModule } from "@ngrx/store";
import {SharedModule} from "./learning/shared/shared.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  MatSnackBarModule,
  MatDialog,
  MatDialogModule, MatFormFieldModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule
} from "@angular/material";
// import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { AngularMaterialModule } from './angular-material/angular-material.module';
import {
  SocialLoginModule,
  AuthServiceConfig, 
  GoogleLoginProvider,
  FacebookLoginProvider
} from "angular-6-social-login";

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LayoutModule } from '@angular/cdk/layout';

// import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER } from 'ngx-ui-loader';
import { appReducer } from "./store/reducers/app.reducer";
import { HttpService, AppService , MetaServicesService} from "./services";

import { LogoutModalComponent } from "./components/logout-modal/logout-modal.component";


import {HttpClient} from '@angular/common/http';
// import ngx-translate and the http loader
//import {TranslateLoader, TranslateModule, TranslateCompiler} from '@ngx-translate/core';
//import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// import ngx-translate-messageformat-compiler
//import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GroupStudyModule } from './learning/group-study/group-study.module';
import { GroupClassModalComponent } from './components/group-class-modal/group-class-modal/group-class-modal.component';
import { OnlineTuitionAnytimeModule } from './learning/Online-Tuition-Anytime/OnlineTuitionAnytime.module';
import { GetOnlineTuitionModule } from './learning/Get-Online-Tuition/GetOnlineTuition.module';
import { WhyStudentsModule } from './learning/WhyStudents/WhyStudents.module';
import { OnlineHomeTuitionsForAllSubjectsModule } from './learning/Online Home-Tuitions-for-All-Subjects/OnlineHomeTuitionsforAllSubjects.module';
import { BestOnlineHomeTuitionModule } from './learning/Best-Online-Home-Tuitions/BestOnlineHomeTuitions.module';
import { ReferenceModule } from './learning/reference/reference.module';

import { LanguagesModule } from './learning/languages/language.module';
import { RegisterModule } from './learning/register/register.module';
import { MathAssignmentsModule } from './learning/math-assignment/math-assignment/math-assignment.module';
import { ExamTipsModule } from './learning/exam-tips/exam-tips.module';
import { OnlineAssignmentModule } from './learning/online-assignment/online-assignment.module';
import { ThesisWritingModule } from './learning/thesis-writing/thesis-writing.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommerceSyllabusModule } from './learning/commerce-syllabus/commerce-syllabus.module';
import { ScienceStreamCModule } from './learning/science-stream/science-stream.module';
import { DonationModule } from './learning/dontation/donation.module';
import { CBSENigeriaModule } from './learning/cbse-nigeria/cbse-nigeria.module';
import { CBSEIndiaModule } from './learning/cbse-india/cbse-india.module';
import { CBSEUSAModule } from './learning/cbse-usa/cbse-usa.module';
import { OurTeamModule } from './learning/our-team/our-team.module';
import { LogInNewModule } from './learning/register/login-new.module';
import { TeacherRegisterModule } from './learning/register/teacher-register.module';
import { ReferModule } from './learning/refer/refer.module';
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("jjkkkkk")
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "562329509737-743uf7384er5199ud3c46j6h34a7du87.apps.googleusercontent.com"
      )
    }
  ]);
  return config;
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsType: SPINNER.threeStrings,
  fgsType: SPINNER.threeStrings
}

@NgModule({

 

  declarations: [
    AppComponent,
    LogoutModalComponent,
    LoginComponent,
    SignupComponent,
    ResetComponent,
    ForgotComponent,
    GroupClassModalComponent
   ],

  imports: [
    // NgxUiLoaderModule
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SocialLoginModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    GroupStudyModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    WhyStudentsModule,
GetOnlineTuitionModule,
OnlineTuitionAnytimeModule,
BestOnlineHomeTuitionModule,
OnlineHomeTuitionsForAllSubjectsModule,
ReferenceModule,
ReferModule,
LanguagesModule,
RegisterModule,
OnlineAssignmentModule,
ThesisWritingModule,
DonationModule,
CBSEIndiaModule,
CBSEUSAModule,
OurTeamModule,
TeacherRegisterModule,
LogInNewModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    // },

     // compiler configuration
  //    compiler: {
  //     provide: TranslateCompiler,
  //     useClass: TranslateMessageFormatCompiler
  // }
  // }),
    MatDialogModule,
    MatFormFieldModule, MatInputModule,
    AngularMaterialModule,
    MathAssignmentsModule,
    MDBBootstrapModule.forRoot(),
    // MatDatepickerModule,
    // MatNativeDateModule,,
    StoreModule.forRoot({
      applicationState: appReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    // ServiceWorkerModule.register("/ngsw-worker.js", {
    //   enabled: environment.production
    // }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    ExamTipsModule,
    ScienceStreamCModule,
    CommerceSyllabusModule,
    NgxMatSelectSearchModule,
    CBSENigeriaModule,
 
    ModalModule.forRoot()
  ],
  providers: [
    CommonUtil,
    HttpService,
    AppService,
    MetaServicesService,
    
   // UploaderService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }

  ],
  bootstrap: [AppComponent],
  entryComponents: [LogoutModalComponent, LoginComponent, SignupComponent, GroupClassModalComponent,ResetComponent, ForgotComponent]
})
export class AppModule { }
// required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
 
// }