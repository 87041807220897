<app-header-front></app-header-front>

<div class="padd_top"></div>
<section class="inner_bg">
    <div class="container">
        <div class="top_sec">


            <marquee>
                <h1><strong>Earn </strong> &#x20B9;500 for each successful reference</h1>

            </marquee>



        </div>
    </div>


</section>


<section class="cust_back">
    <h1 class="text-center">Share Details</h1>



    <form class="container mt-3" [formGroup]="registerForm" (ngSubmit)="onSubmit()">





        <div class="row justify-content-center ">
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Your Name</label>
                            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Your Name" />
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">Your Name is required</div>
                            </div>
                        </div>

                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label>Your Phone</label>
                            <input type="text" formControlName="yourPhone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.yourPhone.errors }" placeholder="Your Phone" />
                            <div *ngIf="submitted && f.yourPhone.errors" class="invalid-feedback">
                                <div *ngIf="f.yourPhone.errors.required">Your Phone is required</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Your  Email </label>
                            <input type="text" formControlName="yourEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.yourEmail.errors }" placeholder="Your Email" />
                            <div *ngIf="submitted && f.yourEmail.errors" class="invalid-feedback">
                                <div *ngIf="f.yourEmail.errors.required">Your Email is required</div>
                            </div>
                        </div>

                    </div>

                </div>
                <button (click)="onclick()" type="button" class="btn  btn-danger">
                    {{ReadMore?"Read More":"Read Less"}}
                  </button>

            </div>
            <div class="col-12" *ngIf="visible">
                <section class="container border mb-4 p-3">
                    <ng-container formArrayName="references">
                        <div *ngFor="let _ of references.controls; index as i">
                            <ng-container [formGroupName]="i">

                                <div class="row">

                                    <h3>Fill in your reference details</h3>

                                    <div class="col-6">
                                        <h4>Reference {{i + 1}}</h4>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" class="form-control" formControlName="refUserName" [ngClass]="{ 'is-invalid': submitted && references.controls[i]['controls'].refUserName.errors }" placeholder="Name" />

                                            <div *ngIf="submitted && references.controls[i]['controls'].refUserName.errors" class="invalid-feedback">
                                                <div *ngIf="references.controls[i]['controls'].refUserName.errors.required">Your Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">

                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" class="form-control" formControlName="refUserEmail" [ngClass]="{ 'is-invalid': submitted && references.controls[i]['controls'].refUserEmail.errors }" placeholder="South Street" />
                                            <div *ngIf="submitted &&  references.controls[i]['controls'].refUserEmail.errors" class="invalid-feedback">
                                                <div *ngIf="references.controls[i]['controls'].refUserEmail.errors.required">Your Email is required</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="text" class="form-control" formControlName="refUserPhone" [ngClass]="{ 'is-invalid': submitted && references.controls[i]['controls'].refUserPhone.errors }" placeholder="Mumbai" />
                                            <div *ngIf="submitted &&  references.controls[i]['controls'].refUserPhone.errors" class="invalid-feedback">
                                                <div *ngIf="references.controls[i]['controls'].refUserPhone.errors.required">Your Phone is required</div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Subject</label>
                                            <select class="form-control" placeholder="country" formControlName="refUserSubject" [ngClass]="{ 'is-invalid': submitted && references.controls[i]['controls'].refUserSubject.errors }" placeholder="India">
                                                <option value="">Choose country</option>
                                                <option value="india">India</option>
                                            <option value="usa">USA</option>
                                            <option value="england">England</option>
                                            </select>

                                            <div *ngIf="submitted &&  references.controls[i]['controls'].refUserSubject.errors" class="invalid-feedback">
                                                <div *ngIf=" references.controls[i]['controls'].refUserSubject.errors.required">Your Subject is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                    </ng-container>
                </section>

                <div class="col-12 text-center">
                    <button type="button" class="btn btn-primary mb-2" (click)="addAddress()"> + Reference</button>
                    <button type="button" class="btn btn-warning mb-2" (click)="removeAddress()"> - Reference</button>
                    <input type="submit" class="btn btn-success mb-2" value="Submit" />
                </div>

            </div>
        </div>

    </form>

</section>


<!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div formArrayName="items"
      *ngFor="let item of form.controls['items'].controls; let i = index">
      <h4>Reference {{i + 1}}</h4>
      <div [formGroupName]="i">
          <label>Name</label>
        <input formControlName='name'>
        <label>Sur Name</label>
        <input formControlName='surname'>
      </div>
    </div>
    <button type="button" (click)="addNext()">Add Next</button>
    <button type="submit">Submit</button>
  </form> -->


<app-footer-front></app-footer-front>