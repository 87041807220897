<div class="wrapper">
    <nav class="hembergur-menu-nav">


        <input type="checkbox" id="menu" name="menu" class="m-menu__checkbox">
        <label class="m-menu__toggle" for="menu">
            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2"stroke-linecap="butt" stroke-linejoin="arcs">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
        </label>
        <label class="m-menu__overlay" for="menu"></label>

        <a href="https://ziyyara.com/">

            <img src="https://ziyyara.com/assets/images/ZiyyaraLogo.png" alt="" width="40">
        </a>
        <a class="mobile-phone" href="tel:9654271931"><span> <i class="fa fa-phone"></i> +91 9654271931</span></a>



        <div class="m-menu">
            <div class="m-menu__header">
                <label class="m-menu__toggle" for="menu">
                    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2"stroke-linecap="butt" stroke-linejoin="arcs">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </label>
                <span>MENU</span>
            </div>
            <ul>
                <!-- <li><a href="#">Boards</a></li> -->
                <li>
                    <label class="a-label__chevron" for="item-2">Boards</label>
                    <input type="checkbox" id="item-2" name="item-2" class="m-menu__checkbox">
                    <div class="m-menu">
                        <div class="m-menu__header">
                            <label class="m-menu__toggle" for="item-2">
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                </svg>
                            </label>
                            <span>Boards</span>
                        </div>
                        <ul>
                            <li><a routerlink="/tuition/cbse-online-tuition-tutoring-in-all-countries">CBSE</a></li>
                            <li>
                                <label class="a-label__chevron" for="item-2-3">Cambridge</label>
                                <input type="checkbox" id="item-2-3" name="item-2" class="m-menu__checkbox">
                                <div class="m-menu">
                                    <div class="m-menu__header">
                                        <label class="m-menu__toggle" for="item-2-3">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                                <path d="M19 12H6M12 5l-7 7 7 7" />
                                            </svg>
                                        </label>
                                        <span>Cambridge Board</span>
                                    </div>
                                    <ul>
                                        <li><a routerLink="/cambridge-a-level-tuition">A Level</a></li>
                        <li><a routerLink="/cambridge-o-level-tuition">O Level</a></li>
                        <li><a routerLink="/cambridge-as-level-tuition">AS Level</a></li>
                                        <!-- <li><a routerLink="/curriculum-boards">Curriculum </a></li> -->

                                    </ul>
                                </div>
                            </li>
                            <li>
                                <label class="a-label__chevron" for="item-2-4">International Baccalaureate</label>
                                <input type="checkbox" id="item-2-4" name="item-2" class="m-menu__checkbox">
                                <div class="m-menu">
                                    <div class="m-menu__header">
                                        <label class="m-menu__toggle" for="item-2-4">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                                <path d="M19 12H6M12 5l-7 7 7 7" />
                                            </svg>
                                        </label>
                                        <span>IB Board</span>
                                    </div>
                                    <ul>
                                        <li><a routerLink="/ib-curriculum/ib-pyp-tuition">PYP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-myp-tuition">MYP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-dp-tuition">DP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-cp-tuition">CP</a></li>

                                    </ul>
                                </div>
                            </li>

                        </ul>
                    </div>
                </li>

                <li>
                    <label class="a-label__chevron" for="item-8">Countries</label>
                    <input type="checkbox" id="item-8" name="item-8" class="m-menu__checkbox">
                    <div class="m-menu">
                        <div class="m-menu__header">
                            <label class="m-menu__toggle" for="item-8">
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                </svg>
                            </label>
                            <span>Countries</span>
                        </div>
                        <ul>
                            <li><a routerLink="/tuition/online-home-tuition-in-saudi-arabia">Saudi Arabia</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-qatar">Qatar</a></li>
                <li><a routerLink="/tuition/online-home-tuition-oman">Oman</a></li>
                <li><a routerLink="/tuition/best-online-tuition-in-bahrain">Bahrain</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-united-arab-emirates">UAE</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-kuwait">Kuwait</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-hong-kong">Hong Kong</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-maldives">Maldives</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-malaysia">Malaysia</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-singapore">Singapore</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-thailand">Thailand</a></li>
                        </ul>
                    </div>
                </li>

                <li>
                    <a routerLink="/find-tutors">Search a Tutor</a>
                </li>

                <li>
                    <label class="a-label__chevron" for="item-2-9">Classes</label>
                    <input type="checkbox" id="item-2-9" name="item-2" class="m-menu__checkbox">
                    <div class="m-menu">
                        <div class="m-menu__header">
                            <label class="m-menu__toggle" for="item-2-9">
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                </svg>
                            </label>
                            <span>Classes</span>
                        </div>
                        <ul>
                            
                            <li><a routerLink="/tuition/online-tuition-for-class-1">Class-1</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-2">Class-2</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-3">Class-3</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-4">Class-4</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-5">Class-5</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-6">Class-6</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-7">Class-7</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-8">Class-8</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-9">Class-9</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-10">Class-10</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-11">Class-11</a></li>
                            <li><a routerLink="/tuition/online-tuition-for-class-12">Class-12</a></li>

                        

                        </ul>
                    </div>
                </li>


                <li>
                    <label class="a-label__chevron" for="item-2-7">Offered Programmes</label>
                    <input type="checkbox" id="item-2-7" name="item-2" class="m-menu__checkbox">
                    <div class="m-menu">
                        <div class="m-menu__header">
                            <label class="m-menu__toggle" for="item-2-7">
                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                </svg>
                            </label>
                            <span>Offered Programmes</span>
                        </div>
                        <ul>
                            <li><a routerLink="/exam-preparation-tips-and-tricks">IELTS</a></li>
                            <li>
                                <label class="a-label__chevron" for="item-2-9">Classes</label>
                                <input type="checkbox" id="item-2-9" name="item-2" class="m-menu__checkbox">
                                <div class="m-menu">
                                    <div class="m-menu__header">
                                        <label class="m-menu__toggle" for="item-2-9">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                                <path d="M19 12H6M12 5l-7 7 7 7" />
                                            </svg>
                                        </label>
                                        <span>Classes</span>
                                    </div>
                                    <ul>
                                        <li><a routerLink="/tuition/online-tuition-for-class-1">Class-1</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-2">Class-2</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-3">Class-3</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-4">Class-4</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-5">Class-5</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-6">Class-6</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-7">Class-7</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-8">Class-8</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-9">Class-9</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-10">Class-10</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-11">Class-11</a></li>
                                        <li><a routerLink="/tuition/online-tuition-for-class-12">Class-12</a></li>

                                    </ul>
                                </div>
                            </li>

                            <li>
                                <label class="a-label__chevron" for="item-2-8">Languages</label>
                                <input type="checkbox" id="item-2-8" name="item-2" class="m-menu__checkbox">
                                <div class="m-menu">
                                    <div class="m-menu__header">
                                        <label class="m-menu__toggle" for="item-2-8">
                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#ffffff"stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                                                <path d="M19 12H6M12 5l-7 7 7 7" />
                                            </svg>
                                        </label>
                                        <span>Languages</span>
                                    </div>
                                    <ul>
                                        <li><a routerLink="/languages/arabic"> Arabic </a></li>
                                        <li><a routerLink="/languages/english">English </a></li>
                                        <li> <a routerLink="/languages/french">French </a></li>
                                        <li> <a routerLink="/languages/german">German  </a></li>
                                        <li><a routerLink="/languages/hindi">Hindi </a></li>
                                        <li><a routerLink="/languages/spanish">Spanish </a></li>

                                    </ul>
                                </div>
                            </li>
                            <li><a routerLink="/tuition">Online Tutoring</a></li>
                <li><a routerLink="/thesis-writing-services">Thesis Writing</a></li>
                <li><a routerLink="/professional-online-assignment-help-and-academic">Assignment Help</a></li>
                <li><a routerLink="/exam-preparation-tips-and-tricks">Exam Preparation</a></li>
                <li><a routerLink="/online-maths-assignment-help-and-tutoring">Math Help</a></li>
                <li><a href="https://ziyyara.com/digital-marketing/">Digital Marketing</a></li>

                        </ul>
                    </div>
                </li>

                <li><a routerLink="/ziyyara-foundation">Ziyyara Foundation</a></li>
                <li> <a routerLink="/studentSignup">Register</a></li>
                <li><a routerLink="/login">Login</a></li>
            </ul>
        </div>
    </nav>
</div>