<div class="wave-xontainer">
    <div class="top-wave"></div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#96c7cd" fill-opacity="1" d="M0,288L48,277.3C96,267,192,245,288,229.3C384,213,480,203,576,170.7C672,139,768,85,864,64C960,43,1056,53,1152,53.3C1248,53,1344,43,1392,37.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
    <div class="free-registration-container">
        <div class="FR-img">
            <img src="/assets/images/free-registration-img.png" class="img-fluid" alt="image missing">
        </div>
        <div class="FR-right">
            <div class="FR-form">
                <h3 style="text-align:center; color:#fff;">Book a free demo</h3>
                <form [formGroup]="signupForm" autocomplete="off" (ngSubmit)="submitUser()">
                    <div class="mb-3 divinput-mar">
                        <label for="exampleInputEmail1" class="form-label">Name*</label>
                        <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Enter your name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH" name="" required="">
                        <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
                    </div>
                    <div class="mb-3 divinput-mar">
                        <label for="exampleInputPassword1" class="form-label">Email*</label>
                        <input type="email" class="form-control" [formControl]="email" name="" required="" placeholder="Enter your Email">
                        <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                    </div>

                    <div class="mb-3 divinput-mar">
                        <label for="exampleInputPassword1" class="form-label">Mobile No.*</label>
                        <input class="form-control" placeholder="Enter your Mobile no." appOnlyNumber [formControl]="contactNo" name="" required="">
                        <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                    </div>
                    <div class="FR-btn mt-2">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>