import { Component, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { CommonUtil } from "../../../util";
import { Router, ActivatedRoute } from "@angular/router";
import { ERROR_MESSAGES, Regex, CONFIG } from "../../../constants";
import { ApiResponse, AppState } from "../../../interfaces";
import { AppService, HttpService } from "../../../services";
import { MatDialog } from '@angular/material';
import { SuccessComponent } from '../../shared/components/success/success.component';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements OnInit {
  referForm: FormGroup;
    //readmore variable, its true than read more string will print
    Reference:boolean = true

    //hiding info box
    visible:boolean = false
  
  
    //onclick toggling both
    onclick()
    {
      this.Reference = !this.Reference; //not equal to condition
      this.visible = !this.visible
    }
  
  // Experiences;
  // languages;
  // Topics;
  // message;
  // id;

//   teacher_id;
//   assignmentForm: FormGroup;
//   images;
//   imgSrc;
//   property;
//   userType;
//   id: any;
//  studentList:any;
//  newstudentList:any;
//  Subjects;
loader;

  studentName: any;
  constructor(
    private _api: HttpService,
    private _router: Router,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    public _util: CommonUtil,
    private _appSer: AppService,
    private _route: ActivatedRoute,
    private store: Store<AppState>
    //  private uploadSer: UploaderService,
  ) { }

  CONFIG = CONFIG;
  isHovered: HTMLElement;
  FORM_ERROR = {
    fullname: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
        maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
        pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },
    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
        pattern: ERROR_MESSAGES.INVALID_EMAIL,
        maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`
    },
    mobile_no: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
        pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
        maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
        minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`
    },
    refName: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
        maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
        pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },
    refEmail: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`
    },
    refPhone: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`
    },
    refSubject: {
      required: ERROR_MESSAGES.COUNTRYCODE_REQUIRED_MAIN,
    },
  
  };

  ngOnInit() {

      // this.checkEditParam();
      this.createForm();
  }

  
  createForm() {
    this.referForm = this._fb.group({
      fullname: ["", [Validators.required, ]],
      email: ["", [Validators.required, Validators.pattern(Regex.email)]],
      mobile_no: ["", [Validators.required, Validators.pattern(Regex.phoneNumbers)]],

      refName: ["", [Validators.required, ]],
      refEmail: ["", [Validators.required, Validators.pattern(Regex.email)]],
      refPhone: ["", [Validators.required, Validators.pattern(Regex.phoneNumbers)]],
      refSubject: ['', [Validators.required]],
    
    });
  }

  submit() {
    if (this.referForm.valid) {
      console.log(this.referForm.value);

       // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.referForm.value, null, 4));
      this._api.postReqUnauth("referAndEarn", this.referForm.value).subscribe(
        (res) => this.success(res),
        (err) => this.error(err.message)
      );
    } else {
      this._util.markError(this.referForm);
    }
  }


  success(res: ApiResponse) {
    if (res.status == true) {
      this._util.message("you have referred successfully");
      const content = "You have referred a friend successfully! You will get rewards soon.";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active"
        }
        // disableClose: true

        
      });
      dialogRef.afterClosed().subscribe(result => {
        this.referForm.reset();
       dialogRef.close(false);
      });
      // this._router.navigate(["refer-a-friend"]);
    } else {
      this._appSer.addSnackBar({ message: res.message });
    }
  }

  get fullname(): FormControl {
    return this.referForm.get("fullname") as FormControl;
  }
  get email(): FormControl {
    return this.referForm.get("email") as FormControl;
  }
  get mobile_no(): FormControl {
    return this.referForm.get("mobile_no") as FormControl;
  }
  get refName(): FormControl {
    return this.referForm.get("refName") as FormControl;
  }
  get refEmail(): FormControl {
    return this.referForm.get("refEmail") as FormControl;
  }
  get refPhone(): FormControl {
    return this.referForm.get("refPhone") as FormControl;
  }
  get refSubject(): FormControl {
    return this.referForm.get("refSubject") as FormControl;
  }


  error(err) {}

}
