<div class="col-md-12">
    <div class="popup-form">
        <div class="popup-form-inner">
            <div class="popup-form-heading">
                <div class="d-flex align-items-center justify-content-between">
                    <h2>{{"signup.register" | mytranslate}}</h2>
                    <span (click)="no()">
            <i class="fa fa-close color-primary cursor-pointer"></i>
          </span>
                </div>
                <!-- <span>Please  to your Ziyyara Account</span> -->
            </div>
            <form id="Login" [formGroup]="signupForm" (ngSubmit)="submit()">
                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                            <mat-label>{{"signup.ful_name" | mytranslate}}</mat-label>
                            <!-- <input matInput nfNoSpaces type="text" placeholder=""` [formControl]="fullName"
                [maxlength]="CONFIG?.NAME_LENGTH"> -->
                            <input matInput type="text" placeholder="" [formControl]="fullName" [maxlength]="CONFIG?.NAME_LENGTH">
                        </mat-form-field>
                        <mat-error>{{_util.errorMessage(fullName,FORM_ERROR?.fullName)}}</mat-error>
                    </div>
                </div>
                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                            <mat-label>{{"signup.email" | mytranslate}}</mat-label>
                            <input matInput type="email" [formControl]="email" placeholder="" [maxlength]="CONFIG?.EMAIL_LENGTH">
                        </mat-form-field>
                        <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                    </div>
                </div>
                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" autocomplete='disabled' class="mat-form-custom padding-10px">
                            <mat-label>Mobile no</mat-label>
                            <input matInput appOnlyNumber [formControl]="contactNo" [maxlength]="CONFIG?.MOBILE_LENGTH">
                        </mat-form-field>
                        <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                    </div>
                </div>

                <div class="form-group">
                    <div class="mat-app-background basic-container">
                        <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                            <mat-label>Country Code </mat-label>
                            <input matInput type="text" placeholder="" name="extra_country" value="{{countryCode}} {{country}}" readonly>
                        </mat-form-field>

                    </div>
                </div>


                <div class="mb-2">
                    <button type="submit" [disabled]="disabled" class="btn m-0 btn-ziyyara">{{"signup.register" | mytranslate}}</button>
                </div>
            </form>
            <p class="text-center pd-22"><span class="reola-tc-size-bold">{{"signup.already_registered" | mytranslate}} </span>&nbsp; <a class="hrefclass befault-color" (click)="login()">{{"signup.login_title" | mytranslate}}
        </a></p>
        </div>
    </div>
</div>