import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, ApiResponse } from '../../interfaces';
import * as AppActions from '../../store/actions/app.actions';
import { AppService, HttpService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanLoad {
  constructor(private store: Store<AppState>, private router: Router,
    private appSer: AppService, private api: HttpService) {

  }
  
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
   return new Promise((resolve) => {

      // If no token found

      console.log(">>>>>>>>>>>token"+localStorage.getItem('token1'))
      
      if (localStorage.getItem('token1')) {
        

        const token= localStorage.getItem('token1');

        this.api.getReqAuth('users/'+token).subscribe(
  
          (res: ApiResponse) => {
           console.log(res)
          if (res && res.status == true) {
            if (res.result.u_type == "1") {
               res.result['_id']=res.result.id?res.result.id:res.result._id;
               this.store.dispatch(new AppActions.PropertySignup(res.result));
               localStorage.setItem('token1', res.result.auth_token);
            
               resolve(true);
             } else if (res.result.u_type == "2") {
              // res.result['id']=res.result._id;
               this.store.dispatch(new AppActions.PropertySignup(res.result));
               localStorage.setItem('token1', res.result.auth_token);
            
               resolve(true);
            }else {
              resolve(true);
            }
          } else {
           resolve(true);
          }
        },
        (err) => {
          resolve(true);
        }
      );
      }else{
       // alert();
        resolve(true);
      }

    });
  }
  
}
