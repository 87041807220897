<app-header-front>
</app-header-front>

<div class="how-it-works">

    <div class="container">

        <div class="mdlle">
            <h1> CHILDREN AMIDST EDUCATION AND ITS REPERCUSSIONS </h1>
        </div>
        <p>Rafael Rahim is one of the many children who go uneducated for their entire life, while somewhere around the world, some kids are going to schools in fancy uniforms. Brilliant minds around the world concur that pen is mightier than the sword.
            However, they seem to ignore the fact that the pen isn’t available everywhere. And places where it isn’t available, are being ignored more frequently than ever.</p>

        <p>Rafael is a twelve-year-old, who used to go to school a couple of years ago when his father was still alive. His father, Usman, was a construction worker who died due to an accident from a thirteen-storied building. The accident initially broke
            his spine, and then due to excessive internal bleeding, took his life on the spot. As soon as his father died, his mother and autistic elder brother hung on to him for survival. Even though the mother wasn’t feeling good about it, she knew
            that she can’t afford to educate both her kids. And because it was important for her to take care of Rehman (her autistic elder son), Rafael automatically rejoined where his father left off.</p>
        <p>We think that child labour and slavery is a serious issue, but when it comes to knowing exactly how people are being affected, most of us don’t want to indulge into it.</p>
        <p>And this is the reason why illiteracy among kids like Rafael, is growing with each passing year.</p>
        <p>As soon as he started his job, Rafael could gauge the risks involved with it as well. But when he understood that it was his duty to complete the assigned job, he told himself that he can do it, even if it takes a lot of guts to face the difficulties.
            This was the first time when Rafael felt a sense of responsibility. He worked hard with all the other workers, and used to break bread alone. One fine morning, when Rafael was headed towards the construction site, he was stopped by a kid.
            It was a boy, roughly of the same age. He walked up to him, and asked him if he was going to the same site. Rafael agreed, after which, the kid started walking with him. He told him that his name was Abdul, and he had just started working.</p>
        <p>The next day, Rafael was late to the construction site, and was petrified of the supervisor. That’s why as soon as he entered the site, he went straight to him. The man was happy to see the kid. He hugged Rafael, and asked him if he wanted a raise,
            not much, but substantial. Rafael asked about the reason; to which, the supervisor added that Abdul wasn’t there in the morning shift which means, he would be joining the night shift, as he was asked to. He was happy to see that a young boy
            like Rafael, could convince a kid of his own age.</p>
        <p>As a good friend, Rafael offered him help. He went to the supervisor, and asked him if he can exchange his and Abdul’s shift timings. The man gave him a slight pat on his back, and asked him to stay away from the matters that don’t concern him.
            Alas! Abdul’s shift remained unchanged. That day, Rafael saw Abdul’s crying face for the very first time. He went to him, and asked if he’s going to be okay. Abdul hugged him and said that he might leave the job as he won’t be able to continue
            in such a hectic schedule. Rafael couldn’t understand why Abdul is ready to give up the job that pays for his food and shelter.</p>
        <p>The next day, Rafael was late to the construction site, and was petrified of the supervisor. That’s why as soon as he entered the site, he went straight to him. The man was happy to see the kid. He hugged Rafael, and asked him if he wanted a raise,
            not much, but substantial. Rafael asked about the reason; to which, the supervisor added that Abdul wasn’t there in the morning shift which means, he would be joining the night shift, as he was asked to. He was happy to see that a young boy
            like Rafael, could convince a kid of his own age.</p>


        <p>Rafael couldn’t understand what was going on with his friend, but one thing that he was sure of, was that Abdul won’t come to the night shift. He headed out of the supervisor’s tent, when he saw a lady entering it. She was carrying a little girl
            with her. The girl seemed older than him.</p>
        <p>An hour later, the supervisor once again addressed all the workers, but this time, for traumatising news. He said that Abdul died of some kind of a shock last night, and in his place, there’s a girl who’s going to join their team. Rafael couldn’t
            believe his ears, but in a way, knew that it was coming. In a place where children are treated as filth, none of the sane ones can survive.</p>
        <p>We should understand that illiteracy is not an issue that takes place with a few people. Rather, it should be considered as a global threat. It is due to illiteracy that even adults grow emotionless. And it’s not about DRC, or a specific country.
            Ignorance towards education is a phenomenon in which every nation plays its role. Some take initiatives to eradicate it from the face of the earth, whereas others promise to minimise it as much as possible.</p>
        <p>But the Ziyyara foundation doesn’t believe in any of the two. On the contrary. We believe in working silently to achieve the goal that we have aimed for. We aim for none of the Abduls to take their lives due to lack of education, and we have worked
            quite hard to keep on chasing our goal. Education is such an integral part of human society, that it should be promoted at all cost. That said, the Ziyyara foundation has taken the initiative to do the same. We’ve not only progressed with
            school development projects in DRC, but have also managed to start traditional offline classes for underprivileged children. But how are we able to do it?</p>
        <p>The intervention of people in this dire situation calls for immediate help, with the support of endowments or donations for children in need to bridge the gap between poverty and education. No amount is inconsequential because as small as $1 can
            prove to be a significant amount even to buy supplies for a kid hence it will surely strengthen the goal of education for the underprivileged. </p>

        <p>Ziyyara is a successful edtech firm that has excelled in the niche of online education, and is still managing to do so. And it is the profits we gain from our online tuition service that we invest in this project, and make sure that everything
            is streamlined. For example, sitting arrangements for children, a place for them to study, and a curriculum that helps these children in learning and understanding their subjects/courses. Even though our aim of providing education to children
            in Democratic Republic of Congo and other underprivileged areas is still a long shot, we’re proud that we have the courage to take necessary steps in this regard.</p>

        <br>
        <div class="btnn-cntr">
            <a routerLink="/ziyyara-foundation" class="anchor">Help Them Learn</a>
        </div>
        <br>
    </div>


    <app-footer-front></app-footer-front>