export const countries = [ 
    {
        CountryID: 1,
        CountryEnglishName: 'Afghanistan',
        ISOCode: 'AF',
        CountryCode: '+93',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 110,
        MobileRank: '',
        SortIndex: 1
    },
    {
        CountryID: 3,
        CountryEnglishName: 'Algeria',
        ISOCode: 'DZ',
        CountryCode: '+213',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 49,
        MobileRank: 29,
        SortIndex: 2
    },
    {
        CountryID: 4,
        CountryEnglishName: 'American Samoa',
        CountryLocalName: '',
        ISOCode: 'AS',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 201,
        MobileRank: '',
        SortIndex: 3
    },
    {
        CountryID: 5,
        CountryEnglishName: 'Andorra',
        CountryLocalName: '',
        ISOCode: 'AD',
        CountryCode: '+376',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 167,
        MobileRank: '',
        SortIndex: 4
    },
    {
        CountryID: 6,
        CountryEnglishName: 'Angola',
        CountryLocalName: '',
        ISOCode: 'AO',
        CountryCode: '+244',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 61,
        MobileRank: '',
        SortIndex: 5
    },
    {
        CountryID: 7,
        CountryEnglishName: 'Anguilla',
        CountryLocalName: '',
        ISOCode: 'AI',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 205,
        MobileRank: '',
        SortIndex: 6
    },
    {
        CountryID: 8,
        CountryEnglishName: 'Antigua and Barbuda',
        CountryLocalName: '',
        ISOCode: 'AG',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 188,
        MobileRank: '',
        SortIndex: 7
    },
    {
        CountryID: 9,
        CountryEnglishName: 'Argentina',
        CountryLocalName: '',
        ISOCode: 'AR',
        CountryCode: '+54',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 12,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 25,
        MobileRank: 25,
        SortIndex: 8
    },
    {
        CountryID: 10,
        CountryEnglishName: 'Armenia',
        CountryLocalName: 'Armenia',
        ISOCode: 'AM',
        CountryCode: '+374',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 134,
        MobileRank: '',
        SortIndex: 9
    },
    {
        CountryID: 11,
        CountryEnglishName: 'Aruba',
        CountryLocalName: '',
        ISOCode: 'AW',
        CountryCode: '+297',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 170,
        MobileRank: '',
        SortIndex: 10
    },
    {
        CountryID: 13,
        CountryEnglishName: 'Australia',
        CountryLocalName: '',
        ISOCode: 'AU',
        CountryCode: '+61',
        CountryExitCode: 11,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 12,
        MobileRank: 38,
        SortIndex: 12
    },
    {
        CountryID: 15,
        CountryEnglishName: 'Azerbaijan',
        CountryLocalName: 'Azərbaycan',
        ISOCode: 'AZ',
        CountryCode: '+994',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 69,
        MobileRank: 55,
        SortIndex: 13
    },
    {
        CountryID: 16,
        CountryEnglishName: 'Bahamas',
        CountryLocalName: '',
        ISOCode: 'BS',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 142,
        MobileRank: '',
        SortIndex: 14
    },
    {
        CountryID: 17,
        CountryEnglishName: 'Bahrain',
        ISOCode: 'BH',
        CountryCode: '+973',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 97,
        MobileRank: '',
        SortIndex: 15
    },
    {
        CountryID: 18,
        CountryEnglishName: 'Bangladesh',
        CountryLocalName: 'Bangladesh',
        ISOCode: 'BD',
        CountryCode: '+880',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 60,
        MobileRank: 12,
        SortIndex: 16
    },
    {
        CountryID: 19,
        CountryEnglishName: 'Barbados',
        CountryLocalName: '',
        ISOCode: 'BB',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 163,
        MobileRank: '',
        SortIndex: 17
    },
    {
        CountryID: 20,
        CountryEnglishName: 'Belarus',
        CountryLocalName: 'Беларусь',
        ISOCode: 'BY',
        CountryCode: '+375',
        CountryExitCode: 810,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 76,
        MobileRank: '',
        SortIndex: 18
    },
    {
        CountryID: 21,
        CountryEnglishName: 'Belgium',
        CountryLocalName: 'België',
        ISOCode: 'BE',
        CountryCode: '+32',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 23,
        MobileRank: 48,
        SortIndex: 19
    },
    {
        CountryID: 22,
        CountryEnglishName: 'Belize',
        CountryLocalName: '',
        ISOCode: 'BZ',
        CountryCode: '+501',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 183,
        MobileRank: '',
        SortIndex: 20
    },
    {
        CountryID: 23,
        CountryEnglishName: 'Benin',
        CountryLocalName: 'Bénin',
        ISOCode: 'BJ',
        CountryCode: '+229',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 144,
        MobileRank: '',
        SortIndex: 21
    },
    {
        CountryID: 24,
        CountryEnglishName: 'Bermuda',
        CountryLocalName: '',
        ISOCode: 'BM',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 152,
        MobileRank: '',
        SortIndex: 22
    },
    {
        CountryID: 25,
        CountryEnglishName: 'Bhutan',
        CountryLocalName: 'Bhutan',
        ISOCode: 'BT',
        CountryCode: '+975',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 182,
        MobileRank: '',
        SortIndex: 23
    },
    {
        CountryID: 26,
        CountryEnglishName: 'Bolivia',
        CountryLocalName: '',
        ISOCode: 'BO',
        CountryCode: '+591',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 102,
        MobileRank: '',
        SortIndex: 24
    },
    {
        CountryID: 28,
        CountryEnglishName: 'Bosnia and Herzegovina',
        CountryLocalName: 'Босна и Херцеговина',
        ISOCode: 'BA',
        CountryCode: '+387',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 112,
        MobileRank: '',
        SortIndex: 26
    },
    {
        CountryID: 29,
        CountryEnglishName: 'Botswana',
        CountryLocalName: '',
        ISOCode: 'BW',
        CountryCode: '+267',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 114,
        MobileRank: '',
        SortIndex: 27
    },
    {
        CountryID: 30,
        CountryEnglishName: 'Brazil',
        CountryLocalName: 'Brasil',
        ISOCode: 'BR',
        CountryCode: '+55',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 6,
        MobileRank: 4,
        SortIndex: 28
    },
    {
        CountryID: 31,
        CountryEnglishName: 'Brunei',
        CountryLocalName: '',
        ISOCode: 'BN',
        CountryCode: '+673',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 115,
        MobileRank: '',
        SortIndex: 29
    },
    {
        CountryID: 32,
        CountryEnglishName: 'Bulgaria',
        CountryLocalName: 'България',
        ISOCode: 'BG',
        CountryCode: '+359',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 77,
        MobileRank: 52,
        SortIndex: 30
    },
    {
        CountryID: 33,
        CountryEnglishName: 'Burkina Faso',
        CountryLocalName: '',
        ISOCode: 'BF',
        CountryCode: '+226',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 135,
        MobileRank: '',
        SortIndex: 31
    },
    {
        CountryID: 35,
        CountryEnglishName: 'Cambodia',
        CountryLocalName: 'Cambodia',
        ISOCode: 'KH',
        CountryCode: '+855',
        CountryExitCode: '00x',
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 125,
        MobileRank: '',
        SortIndex: 33
    },
    {
        CountryID: 36,
        CountryEnglishName: 'Cameroon',
        CountryLocalName: 'Cameroun',
        ISOCode: 'CM',
        CountryCode: '+237',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 96,
        MobileRank: '',
        SortIndex: 34
    },
    {
        CountryID: 37,
        CountryEnglishName: 'Canada',
        CountryLocalName: '',
        ISOCode: 'CA',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 11,
        MobileRank: 35,
        SortIndex: 35
    },
    {
        CountryID: 39,
        CountryEnglishName: 'Cayman Islands',
        CountryLocalName: '',
        ISOCode: 'KY',
        CountryCode: '+1',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 168,
        MobileRank: '',
        SortIndex: 36
    },
    {
        CountryID: 42,
        CountryEnglishName: 'Chile',
        CountryLocalName: '',
        ISOCode: 'CL',
        CountryCode: '+56',
        CountryExitCode: '1xx0',
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 39,
        MobileRank: 41,
        SortIndex: 37
    },
    {
        CountryID: 43,
        CountryEnglishName: 'China',
        CountryLocalName: '中国',
        ISOCode: 'CN',
        CountryCode: '+86',
        CountryExitCode: 0,
        'Min NSN': 11,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 2,
        MobileRank: 1,
        SortIndex: 38
    },
    {
        CountryID: 44,
        CountryEnglishName: 'Colombia',
        CountryLocalName: '',
        ISOCode: 'CO',
        CountryCode: '+57',
        CountryExitCode: '00x',
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 32,
        MobileRank: 27,
        SortIndex: 39
    },
    {
        CountryID: 45,
        CountryEnglishName: 'Comoros and Mayotte',
        CountryLocalName: '',
        ISOCode: 'KM',
        CountryCode: '+269',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 228,
        MobileRank: '',
        SortIndex: 40
    },
    {
        CountryID: 46,
        CountryEnglishName: 'Congo',
        CountryLocalName: '',
        ISOCode: 'CG',
        CountryCode: '+242',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 122,
        MobileRank: '',
        SortIndex: 41
    },
    {
        CountryID: 47,
        CountryEnglishName: 'Congo (Democratic Republic)',
        CountryLocalName: '',
        ISOCode: 'CD',
        CountryCode: '+243',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 117,
        MobileRank: '',
        SortIndex: 42
    },
    {
        CountryID: 48,
        CountryEnglishName: 'Cook Islands',
        CountryLocalName: '',
        ISOCode: 'CK',
        CountryCode: '+682',
        CountryExitCode: 0,
        'Min NSN': 5,
        'Max NSN': 5,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 206,
        MobileRank: '',
        SortIndex: 43
    },
    {
        CountryID: 49,
        CountryEnglishName: 'Costa Rica',
        CountryLocalName: '',
        ISOCode: 'CR',
        CountryCode: '+506',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 85,
        MobileRank: '',
        SortIndex: 44
    },
    {
        CountryID: 52,
        CountryEnglishName: 'Cuba',
        CountryLocalName: '',
        ISOCode: 'CU',
        CountryCode: '+53',
        CountryExitCode: 119,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 66,
        MobileRank: 62,
        SortIndex: 45
    },
    {
        CountryID: 54,
        CountryEnglishName: 'Cyprus',
        CountryLocalName: 'Cyprus',
        ISOCode: 'CY',
        CountryCode: '+357',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 99,
        MobileRank: '',
        SortIndex: 47
    },
    {
        CountryID: 55,
        CountryEnglishName: 'Czech Republic',
        CountryLocalNa62me: 'Czech Republic',
        ISOCode: 'CZ',
        CountryCode: '+420',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 47,
        MobileRank: '',
        SortIndex: 48
    },
    {
        CountryID: 135,
        CountryEnglishName: 'Montenegro',
        CountryLocalName: 'Crna Gora',
        ISOCode: 'ME',
        CountryCode: '+382',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 161,
        MobileRank: '',
        SortIndex: 49
    },
    {
        CountryID: 181,
        CountryEnglishName: 'Serbia',
        CountryLocalName: 'Serbia',
        ISOCode: 'RS',
        CountryCode: '+381',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 84,
        MobileRank: '',
        SortIndex: 50
    },
    {
        CountryID: 221,
        CountryEnglishName: 'Vanuatu',
        CountryLocalName: '',
        ISOCode: 'VU',
        CountryCode: '+678',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 198,
        MobileRank: '',
        SortIndex: 51
    },
    {
        CountryID: 56,
        CountryEnglishName: 'Denmark',
        CountryLocalName: 'Danmark',
        ISOCode: 'DK',
        CountryCode: '+45',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 33,
        MobileRank: 54,
        SortIndex: 52
    },
    {
        CountryID: 58,
        CountryEnglishName: 'Djibouti',
        CountryLocalName: '',
        ISOCode: 'DJ',
        CountryCode: '+253',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 184,
        MobileRank: '',
        SortIndex: 54
    },
    {
        CountryID: 59,
        CountryEnglishName: 'Dominica',
        CountryLocalName: '',
        ISOCode: 'DM',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 203,
        MobileRank: '',
        SortIndex: 55
    },
    {
        CountryID: 77,
        CountryEnglishName: 'Germany',
        CountryLocalName: 'Deutschland',
        ISOCode: 'DE',
        CountryCode: '+49',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 4,
        MobileRank: 9,
        SortIndex: 56
    },
    {
        CountryID: 61,
        CountryEnglishName: 'Ecuador',
        CountryLocalName: '',
        ISOCode: 'EC',
        CountryCode: '+593',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 67,
        MobileRank: 45,
        SortIndex: 57
    },
    {
        CountryID: 62,
        CountryEnglishName: 'Egypt',
        ISOCode: 'EG',
        CountryCode: '+20',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 44,
        MobileRank: 19,
        SortIndex: 58
    },
    {
        CountryID: 63,
        CountryEnglishName: 'El Salvador',
        CountryLocalName: '',
        ISOCode: 'SV',
        CountryCode: '+503',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 104,
        MobileRank: '',
        SortIndex: 59
    },
    {
        CountryID: 65,
        CountryEnglishName: 'Eritrea',
        CountryLocalName: '',
        ISOCode: 'ER',
        CountryCode: '+291',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 171,
        MobileRank: '',
        SortIndex: 60
    },
    {
        CountryID: 66,
        CountryEnglishName: 'Estonia',
        CountryLocalName: 'Eesti',
        ISOCode: 'EE',
        CountryCode: '+372',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 106,
        MobileRank: 59,
        SortIndex: 61
    },
    {
        CountryID: 67,
        CountryEnglishName: 'Ethiopia',
        CountryLocalName: '',
        ISOCode: 'ET',
        CountryCode: '+251',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 93,
        MobileRank: 42,
        SortIndex: 62
    },
    {
        CountryID: 79,
        CountryEnglishName: 'Greece',
        CountryLocalName: 'Ελλάδα',
        ISOCode: 'GR',
        CountryCode: '+30',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 35,
        MobileRank: '',
        SortIndex: 63
    },
    {
        CountryID: 192,
        CountryEnglishName: 'Spain',
        CountryLocalName: 'España',
        ISOCode: 'ES',
        CountryCode: '+34',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 13,
        MobileRank: 23,
        SortIndex: 64
    },
    {
        CountryID: 68,
        CountryEnglishName: 'Falkland Islands',
        CountryLocalName: '',
        ISOCode: 'FK',
        CountryCode: '+500',
        CountryExitCode: 0,
        'Min NSN': 5,
        'Max NSN': 5,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 210,
        MobileRank: '',
        SortIndex: 65
    },
    {
        CountryID: 69,
        CountryEnglishName: 'Faroe Islands',
        CountryLocalName: 'Føroyar',
        ISOCode: 'FO',
        CountryCode: '+298',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 176,
        MobileRank: '',
        SortIndex: 66
    },
    {
        CountryID: 70,
        CountryEnglishName: 'Fiji',
        CountryLocalName: '',
        ISOCode: 'FJ',
        CountryCode: '+679',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 165,
        MobileRank: '',
        SortIndex: 67
    },
    {
        CountryID: 72,
        CountryEnglishName: 'France',
        CountryLocalName: '',
        ISOCode: 'FR',
        CountryCode: '+33',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 5,
        MobileRank: 18,
        SortIndex: 68
    },
    {
        CountryID: 64,
        CountryEnglishName: 'Equatorial Guinea',
        CountryLocalName: 'Guinea Ecuatorial',
        ISOCode: 'GQ',
        CountryCode: '+240',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 116,
        MobileRank: '',
        SortIndex: 69
    },
    {
        CountryID: 74,
        CountryEnglishName: 'Gabon',
        CountryLocalName: '',
        ISOCode: 'GA',
        CountryCode: '+241',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 100,
        MobileRank: '',
        SortIndex: 70
    },
    {
        CountryID: 75,
        CountryEnglishName: 'Gambia',
        CountryLocalName: '',
        ISOCode: 'GM',
        CountryCode: '+220',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 186,
        MobileRank: '',
        SortIndex: 71
    },
    {
        CountryID: 76,
        CountryEnglishName: 'Georgia',
        CountryLocalName: 'Georgia',
        ISOCode: 'GE',
        CountryCode: '+995',
        CountryExitCode: '00, 8xx',
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 8,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 120,
        MobileRank: '',
        SortIndex: 72
    },
    {
        CountryID: 78,
        CountryEnglishName: 'Ghana',
        CountryLocalName: 'Gaana',
        ISOCode: 'GH',
        CountryCode: '+233',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 86,
        MobileRank: '',
        SortIndex: 73
    },
    {
        CountryID: 81,
        CountryEnglishName: 'Grenada',
        CountryLocalName: '',
        ISOCode: 'GD',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 196,
        MobileRank: '',
        SortIndex: 74
    },
    {
        CountryID: 82,
        CountryEnglishName: 'Guadeloupe',
        CountryLocalName: '',
        ISOCode: 'GP',
        CountryCode: '+590',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 226,
        MobileRank: '',
        SortIndex: 75
    },
    {
        CountryID: 84,
        CountryEnglishName: 'Guatemala',
        CountryLocalName: '',
        ISOCode: 'GT',
        CountryCode: '+502',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 79,
        MobileRank: 43,
        SortIndex: 77
    },
    {
        CountryID: 85,
        CountryEnglishName: 'Guinea',
        CountryLocalName: 'Guinée',
        ISOCode: 'GN',
        CountryCode: '+224',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 157,
        MobileRank: '',
        SortIndex: 78
    },
    {
        CountryID: 86,
        CountryEnglishName: 'Guinea-Bissau',
        CountryLocalName: 'Guiné-Bissau',
        ISOCode: 'GW',
        CountryCode: '+245',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 194,
        MobileRank: '',
        SortIndex: 79
    },
    {
        CountryID: 87,
        CountryEnglishName: 'Guyana',
        CountryLocalName: '',
        ISOCode: 'GY',
        CountryCode: '+592',
        CountryExitCode: 1,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 172,
        MobileRank: '',
        SortIndex: 80
    },
    {
        CountryID: 51,
        CountryEnglishName: 'Croatia',
        CountryLocalName: 'Hrvatska',
        ISOCode: 'HR',
        CountryCode: '+385',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 70,
        MobileRank: '',
        SortIndex: 81
    },
    {
        CountryID: 88,
        CountryEnglishName: 'Haiti',
        CountryLocalName: '',
        ISOCode: 'HT',
        CountryCode: '+509',
        CountryExitCode: 11,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 147,
        MobileRank: '',
        SortIndex: 82
    },
    {
        CountryID: 89,
        CountryEnglishName: 'Honduras',
        CountryLocalName: '',
        ISOCode: 'HN',
        CountryCode: '+504',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 113,
        MobileRank: '',
        SortIndex: 83
    },
    {
        CountryID: 90,
        CountryEnglishName: 'Hong Kong',
        CountryLocalName: '香港',
        ISOCode: 'HK',
        CountryCode: '+852',
        CountryExitCode: '00x, 00xx',
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 41,
        MobileRank: 47,
        SortIndex: 84
    },
    {
        CountryID: 92,
        CountryEnglishName: 'Iceland',
        CountryLocalName: 'Ísland',
        ISOCode: 'IS',
        CountryCode: '+354',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 121,
        MobileRank: '',
        SortIndex: 85
    },
    {
        CountryID: 93,
        CountryEnglishName: 'India',
        CountryLocalName: 'India',
        ISOCode: 'IN',
        CountryCode: '+91',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 9,
        MobileRank: 2,
        SortIndex: 86
    },
    {
        CountryID: 94,
        CountryEnglishName: 'Indonesia',
        CountryLocalName: '',
        ISOCode: 'ID',
        CountryCode: '+62',
        CountryExitCode: '00x',
        'Min NSN': 9,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 16,
        MobileRank: 6,
        SortIndex: 87
    },
    {
        CountryID: 95,
        CountryEnglishName: 'Iran',
        ISOCode: 'IR',
        CountryCode: '+98',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 22,
        MobileRank: 13,
        SortIndex: 88
    },
    {
        CountryID: 96,
        CountryEnglishName: 'Iraq',
        ISOCode: 'IQ',
        CountryCode: '+964',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 59,
        MobileRank: '',
        SortIndex: 89
    },
    {
        CountryID: 97,
        CountryEnglishName: 'Ireland',
        CountryLocalName: '',
        ISOCode: 'IE',
        CountryCode: '+353',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 46,
        MobileRank: '',
        SortIndex: 90
    },
    {
        CountryID: 98,
        CountryEnglishName: 'Israel',
        ISOCode: 'IL',
        CountryCode: '+972',
        CountryExitCode: '01x',
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 42,
        MobileRank: 53,
        SortIndex: 91
    },
    {
        CountryID: 99,
        CountryEnglishName: 'Italy',
        CountryLocalName: 'Italia',
        ISOCode: 'IT',
        CountryCode: '+39',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 8,
        MobileRank: 15,
        SortIndex: 92
    },
    {
        CountryID: 100,
        CountryEnglishName: 'Jamaica',
        CountryLocalName: '',
        ISOCode: 'JM',
        CountryCode: '+1876',
        CountryExitCode: 11,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 118,
        MobileRank: '',
        SortIndex: 93
    },
    {
        CountryID: 101,
        CountryEnglishName: 'Japan',
        CountryLocalName: '日本',
        ISOCode: 'JP',
        CountryCode: '+81',
        CountryExitCode: 10,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 3,
        MobileRank: 8,
        SortIndex: 94
    },
    {
        CountryID: 102,
        CountryEnglishName: 'Jordan',
        ISOCode: 'JO',
        CountryCode: '+962',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 94,
        MobileRank: 56,
        SortIndex: 95
    },
    {
        CountryID: 38,
        CountryEnglishName: 'Cape Verde',
        CountryLocalName: 'Kabu Verdi',
        ISOCode: 'CV',
        CountryCode: '+238',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 181,
        MobileRank: '',
        SortIndex: 96
    },
    {
        CountryID: 80,
        CountryEnglishName: 'Greenland',
        CountryLocalName: 'Kalaallit Nunaat',
        ISOCode: 'GL',
        CountryCode: '+299',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 174,
        MobileRank: '',
        SortIndex: 97
    },
    {
        CountryID: 103,
        CountryEnglishName: 'Kazakhstan',
        CountryLocalName: 'Казахстан',
        ISOCode: 'KZ',
        CountryCode: '+7',
        CountryExitCode: 810,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 51,
        MobileRank: '',
        SortIndex: 98
    },
    {
        CountryID: 104,
        CountryEnglishName: 'Kenya',
        CountryLocalName: '',
        ISOCode: 'KE',
        CountryCode: '+254',
        CountryExitCode: '00x',
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 89,
        MobileRank: 31,
        SortIndex: 99
    },
    {
        CountryID: 107,
        CountryEnglishName: 'Kuwait',
        ISOCode: 'KW',
        CountryCode: '+965',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 56,
        MobileRank: '',
        SortIndex: 100
    },
    {
        CountryID: 108,
        CountryEnglishName: 'Kyrgyzstan',
        CountryLocalName: '',
        ISOCode: 'KG',
        CountryCode: '+996',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 155,
        MobileRank: '',
        SortIndex: 101
    },
    {
        CountryID: 109,
        CountryEnglishName: 'Laos',
        CountryLocalName: 'Laos',
        ISOCode: 'LA',
        CountryCode: '+856',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 141,
        MobileRank: '',
        SortIndex: 102
    },
    {
        CountryID: 110,
        CountryEnglishName: 'Latvia',
        CountryLocalName: 'Latvija',
        ISOCode: 'LV',
        CountryCode: '+371',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 95,
        MobileRank: '',
        SortIndex: 103
    },
    {
        CountryID: 111,
        CountryEnglishName: 'Lebanon',
        ISOCode: 'LB',
        CountryCode: '+961',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 87,
        MobileRank: 60,
        SortIndex: 104
    },
    {
        CountryID: 112,
        CountryEnglishName: 'Lesotho',
        CountryLocalName: '',
        ISOCode: 'LS',
        CountryCode: '+266',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 173,
        MobileRank: '',
        SortIndex: 105
    },
    {
        CountryID: 113,
        CountryEnglishName: 'Liberia',
        CountryLocalName: '',
        ISOCode: 'LR',
        CountryCode: '+231',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 187,
        MobileRank: '',
        SortIndex: 106
    },
    {
        CountryID: 114,
        CountryEnglishName: 'Libya',
        ISOCode: 'LY',
        CountryCode: '+218',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 91,
        MobileRank: '',
        SortIndex: 107
    },
    {
        CountryID: 115,
        CountryEnglishName: 'Liechtenstein',
        CountryLocalName: '',
        ISOCode: 'LI',
        CountryCode: '+423',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 151,
        MobileRank: '',
        SortIndex: 108
    },
    {
        CountryID: 116,
        CountryEnglishName: 'Lithuania',
        CountryLocalName: 'Lietuva',
        ISOCode: 'LT',
        CountryCode: '+370',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 83,
        MobileRank: 61,
        SortIndex: 109
    },
    {
        CountryID: 117,
        CountryEnglishName: 'Luxembourg',
        CountryLocalName: '',
        ISOCode: 'LU',
        CountryCode: '+352',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 71,
        MobileRank: '',
        SortIndex: 110
    },
    {
        CountryID: 91,
        CountryEnglishName: 'Hungary',
        CountryLocalName: 'Magyarország',
        ISOCode: 'HU',
        CountryCode: '+36',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 57,
        MobileRank: 49,
        SortIndex: 111
    },
    {
        CountryID: 118,
        CountryEnglishName: 'Macau',
        CountryLocalName: '澳門',
        ISOCode: 'MO',
        CountryCode: '+853',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 88,
        MobileRank: '',
        SortIndex: 112
    },
    {
        CountryID: 119,
        CountryEnglishName: 'Macedonia',
        CountryLocalName: 'Македонија',
        ISOCode: 'MK',
        CountryCode: '+389',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 133,
        MobileRank: '',
        SortIndex: 113
    },
    {
        CountryID: 120,
        CountryEnglishName: 'Madagascar',
        CountryLocalName: 'Madagasikara',
        ISOCode: 'MG',
        CountryCode: '+261',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 137,
        MobileRank: '',
        SortIndex: 114
    },
    {
        CountryID: 121,
        CountryEnglishName: 'Malawi',
        CountryLocalName: '',
        ISOCode: 'MW',
        CountryCode: '+265',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 153,
        MobileRank: '',
        SortIndex: 115
    },
    {
        CountryID: 122,
        CountryEnglishName: 'Malaysia',
        CountryLocalName: '',
        ISOCode: 'MY',
        CountryCode: '+60',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 36,
        MobileRank: 40,
        SortIndex: 116
    },
    {
        CountryID: 123,
        CountryEnglishName: 'Maldives',
        CountryLocalName: '',
        ISOCode: 'MV',
        CountryCode: '+960',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 178,
        MobileRank: '',
        SortIndex: 117
    },
    {
        CountryID: 124,
        CountryEnglishName: 'Mali',
        CountryLocalName: '',
        ISOCode: 'ML',
        CountryCode: '+223',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 131,
        MobileRank: '',
        SortIndex: 118
    },
    {
        CountryID: 125,
        CountryEnglishName: 'Malta',
        CountryLocalName: '',
        ISOCode: 'MT',
        CountryCode: '+356',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 138,
        MobileRank: '',
        SortIndex: 119
    },
    {
        CountryID: 126,
        CountryEnglishName: 'Marshall Islands',
        CountryLocalName: '',
        ISOCode: 'MH',
        CountryCode: '+692',
        CountryExitCode: 11,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 209,
        MobileRank: '',
        SortIndex: 120
    },
    {
        CountryID: 127,
        CountryEnglishName: 'Martinique',
        CountryLocalName: '',
        ISOCode: 'MQ',
        CountryCode: '+596',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 225,
        MobileRank: '',
        SortIndex: 121
    },
    {
        CountryID: 128,
        CountryEnglishName: 'Mauritania',
        ISOCode: 'MR',
        CountryCode: '+222',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 162,
        MobileRank: '',
        SortIndex: 122
    },
    {
        CountryID: 129,
        CountryEnglishName: 'Mauritius',
        CountryLocalName: 'Moris',
        ISOCode: 'MU',
        CountryCode: '+230',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 130,
        MobileRank: '',
        SortIndex: 123
    },
    {
        CountryID: 130,
        CountryEnglishName: 'Mexico',
        CountryLocalName: 'México',
        ISOCode: 'MX',
        CountryCode: '+52',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 14,
        MobileRank: 14,
        SortIndex: 124
    },
    {
        CountryID: 131,
        CountryEnglishName: 'Micronesia',
        CountryLocalName: '',
        ISOCode: 'FM',
        CountryCode: '+691',
        CountryExitCode: 11,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 191,
        MobileRank: '',
        SortIndex: 125
    },
    {
        CountryID: 133,
        CountryEnglishName: 'Monaco',
        CountryLocalName: '',
        ISOCode: 'MC',
        CountryCode: '+377',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 153,
        MobileRank: '',
        SortIndex: 126
    },
    {
        CountryID: 134,
        CountryEnglishName: 'Mongolia',
        CountryLocalName: 'Монгол',
        ISOCode: 'MN',
        CountryCode: '+976',
        CountryExitCode: 1,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 140,
        MobileRank: '',
        SortIndex: 127
    },
    {
        CountryID: 136,
        CountryEnglishName: 'Montserrat',
        CountryLocalName: '',
        ISOCode: 'MS',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 212,
        MobileRank: '',
        SortIndex: 128
    },
    {
        CountryID: 137,
        CountryEnglishName: 'Morocco',
        ISOCode: 'MA',
        CountryCode: '+212',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 63,
        MobileRank: 36,
        SortIndex: 129
    },
    {
        CountryID: 138,
        CountryEnglishName: 'Mozambique',
        CountryLocalName: 'Moçambique',
        ISOCode: 'MZ',
        CountryCode: '+258',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 126,
        MobileRank: '',
        SortIndex: 130
    },
    {
        CountryID: 139,
        CountryEnglishName: 'Myanmar',
        CountryLocalName: 'Myanmar',
        ISOCode: 'MM',
        CountryCode: '+95',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 75,
        MobileRank: '',
        SortIndex: 131
    },
    {
        CountryID: 140,
        CountryEnglishName: 'Namibia',
        CountryLocalName: '',
        ISOCode: 'NA',
        CountryCode: '+264',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 127,
        MobileRank: '',
        SortIndex: 132
    },
    {
        CountryID: 141,
        CountryEnglishName: 'Nauru',
        CountryLocalName: '',
        ISOCode: 'NR',
        CountryCode: '+674',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 211,
        MobileRank: '',
        SortIndex: 133
    },
    {
        CountryID: 142,
        CountryEnglishName: 'Nepal',
        CountryLocalName: 'Nepal',
        ISOCode: 'NP',
        CountryCode: '+977',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 111,
        MobileRank: 42,
        SortIndex: 134
    },
    {
        CountryID: 143,
        CountryEnglishName: 'Netherlands',
        CountryLocalName: 'Nederland',
        ISOCode: 'NL',
        CountryCode: '+31',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 17,
        MobileRank: 37,
        SortIndex: 135
    },
    {
        CountryID: 144,
        CountryEnglishName: 'New Caledonia',
        CountryLocalName: 'Nouvelle-Calédonie',
        ISOCode: 'NC',
        CountryCode: '+687',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 136,
        MobileRank: '',
        SortIndex: 136
    },
    {
        CountryID: 145,
        CountryEnglishName: 'New Zealand',
        CountryLocalName: '',
        ISOCode: 'NZ',
        CountryCode: '+64',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 55,
        MobileRank: 58,
        SortIndex: 137
    },
    {
        CountryID: 146,
        CountryEnglishName: 'Nicaragua',
        CountryLocalName: '',
        ISOCode: 'NI',
        CountryCode: '+505',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 143,
        MobileRank: '',
        SortIndex: 138
    },
    {
        CountryID: 147,
        CountryEnglishName: 'Niger',
        CountryLocalName: 'Nijar',
        ISOCode: 'NE',
        CountryCode: '+227',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 149,
        MobileRank: '',
        SortIndex: 139
    },
    {
        CountryID: 148,
        CountryEnglishName: 'Nigeria',
        CountryLocalName: '',
        ISOCode: 'NG',
        CountryCode: '+234',
        CountryExitCode: 9,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 40,
        MobileRank: 11,
        SortIndex: 140
    },
    {
        CountryID: 149,
        CountryEnglishName: 'Niue',
        CountryLocalName: '',
        ISOCode: 'NU',
        CountryCode: '+683',
        CountryExitCode: 0,
        'Min NSN': 4,
        'Max NSN': 4,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 224,
        MobileRank: '',
        SortIndex: 141
    },
    {
        CountryID: 150,
        CountryEnglishName: 'Norfolk Island',
        CountryLocalName: '',
        ISOCode: 'NF',
        CountryCode: '+6723',
        CountryExitCode: 0,
        'Min NSN': 5,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 223,
        MobileRank: '',
        SortIndex: 142
    },
    {
        CountryID: 105,
        CountryEnglishName: 'North Korea',
        CountryLocalName: '조선 민주주의 인민 공화국',
        ISOCode: 'KP',
        CountryCode: '+850',
        CountryExitCode: 99,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 129,
        MobileRank: 63,
        SortIndex: 143
    },
    {
        CountryID: 151,
        CountryEnglishName: 'Northern Mariana Islands',
        CountryLocalName: '',
        ISOCode: 'MP',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 222,
        MobileRank: '',
        SortIndex: 144
    },
    {
        CountryID: 152,
        CountryEnglishName: 'Norway',
        CountryLocalName: 'Norge',
        ISOCode: 'NO',
        CountryCode: '+47',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 25,
        MobileRank: '',
        SortIndex: 145
    },
    {
        CountryID: 14,
        CountryEnglishName: 'Austria',
        CountryLocalName: 'Österreich',
        ISOCode: 'AT',
        CountryCode: '+43',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 13,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 28,
        MobileRank: '',
        SortIndex: 146
    },
    {
        CountryID: 153,
        CountryEnglishName: 'Oman',
        ISOCode: 'OM',
        CountryCode: '+968',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 65,
        MobileRank: '',
        SortIndex: 147
    },
    {
        CountryID: 73,
        CountryEnglishName: 'French Polynesia',
        CountryLocalName: 'Polynésie française',
        ISOCode: 'PF',
        CountryCode: '+689',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 145,
        MobileRank: '',
        SortIndex: 148
    },
    {
        CountryID: 154,
        CountryEnglishName: 'Pakistan',
        ISOCode: 'PK',
        CountryCode: '+92',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 48,
        MobileRank: 7,
        SortIndex: 149
    },
    {
        CountryID: 155,
        CountryEnglishName: 'Palau',
        CountryLocalName: '',
        ISOCode: 'PW',
        CountryCode: '+680',
        CountryExitCode: 11,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 208,
        MobileRank: '',
        SortIndex: 150
    },
    {
        CountryID: 156,
        CountryEnglishName: 'Palestine',
        ISOCode: 'PS',
        CountryCode: '+970',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 221,
        MobileRank: '',
        SortIndex: 151
    },
    {
        CountryID: 157,
        CountryEnglishName: 'Panama',
        CountryLocalName: 'Panamá',
        ISOCode: 'PA',
        CountryCode: '+507',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 92,
        MobileRank: '',
        SortIndex: 152
    },
    {
        CountryID: 158,
        CountryEnglishName: 'Papua New Guinea',
        CountryLocalName: '',
        ISOCode: 'PG',
        CountryCode: '+675',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 128,
        MobileRank: '',
        SortIndex: 153
    },
    {
        CountryID: 160,
        CountryEnglishName: 'Peru',
        CountryLocalName: 'Perú',
        ISOCode: 'PE',
        CountryCode: '+51',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 52,
        MobileRank: 33,
        SortIndex: 154
    },
    {
        CountryID: 161,
        CountryEnglishName: 'Philippines',
        CountryLocalName: '',
        ISOCode: 'PH',
        CountryCode: '+63',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 45,
        MobileRank: 10,
        SortIndex: 155
    },
    {
        CountryID: 162,
        CountryEnglishName: 'Poland',
        CountryLocalName: 'Polska',
        ISOCode: 'PL',
        CountryCode: '+48',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 24,
        MobileRank: 26,
        SortIndex: 156
    },
    {
        CountryID: 163,
        CountryEnglishName: 'Portugal',
        CountryLocalName: '',
        ISOCode: 'PT',
        CountryCode: '+351',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 43,
        MobileRank: 46,
        SortIndex: 157
    },
    {
        CountryID: 164,
        CountryEnglishName: 'Puerto Rico',
        CountryLocalName: '',
        ISOCode: 'PR',
        CountryCode: '+1787',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 62,
        MobileRank: '',
        SortIndex: 158
    },
    {
        CountryID: 167,
        CountryEnglishName: 'Russia',
        CountryLocalName: 'Россия',
        ISOCode: 'RU',
        CountryCode: '+7',
        CountryExitCode: 810,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 10,
        MobileRank: 5,
        SortIndex: 159
    },
    {
        CountryID: 165,
        CountryEnglishName: 'Qatar',
        ISOCode: 'QA',
        CountryCode: '+974',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 53,
        MobileRank: '',
        SortIndex: 160
    },
    {
        CountryID: 40,
        CountryEnglishName: 'Central African Republic',
        CountryLocalName: 'République centrafricaine',
        ISOCode: 'CF',
        CountryCode: '+236',
        CountryExitCode: 11,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 177,
        MobileRank: '',
        SortIndex: 161
    },
    {
        CountryID: 60,
        CountryEnglishName: 'Dominican Republic',
        CountryLocalName: 'República Dominicana',
        ISOCode: 'DO',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 74,
        MobileRank: '',
        SortIndex: 162
    },
    {
        CountryID: 132,
        CountryEnglishName: 'Moldova',
        CountryLocalName: 'Republica Moldova',
        ISOCode: 'MD',
        CountryCode: '+373',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 146,
        MobileRank: '',
        SortIndex: 163
    },
    {
        CountryID: 166,
        CountryEnglishName: 'Romania',
        CountryLocalName: 'România',
        ISOCode: 'RO',
        CountryCode: '+40',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 50,
        MobileRank: 34,
        SortIndex: 164
    },
    {
        CountryID: 168,
        CountryEnglishName: 'Rwanda',
        CountryLocalName: '',
        ISOCode: 'RW',
        CountryCode: '+250',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 150,
        MobileRank: '',
        SortIndex: 165
    },
    {
        CountryID: 2,
        CountryEnglishName: 'Albania',
        CountryLocalName: 'Shqipëria',
        ISOCode: 'AL',
        CountryCode: '+355',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 124,
        MobileRank: '',
        SortIndex: 166
    },
    {
        CountryID: 71,
        CountryEnglishName: 'Finland',
        CountryLocalName: 'Suomi',
        ISOCode: 'FI',
        CountryCode: '+358',
        CountryExitCode: '00, 99x, 99xx, 99xxx',
        'Min NSN': 9,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 37,
        MobileRank: '',
        SortIndex: 167
    },
    {
        CountryID: 169,
        CountryEnglishName: 'Saint Barthélemy',
        CountryLocalName: 'Saint-Barthélémy',
        ISOCode: 'BL',
        CountryCode: '+590',
        CountryExitCode: 0,
        'Min NSN': 4,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 220,
        MobileRank: '',
        SortIndex: 168
    },
    {
        CountryID: 170,
        CountryEnglishName: 'Saint Helena',
        CountryLocalName: '',
        ISOCode: 'SH',
        CountryCode: '+290',
        CountryExitCode: 0,
        'Min NSN': 4,
        'Max NSN': 4,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 219,
        MobileRank: '',
        SortIndex: 169
    },
    {
        CountryID: 171,
        CountryEnglishName: 'Saint Kitts and Nevis',
        CountryLocalName: '',
        ISOCode: 'KN',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 199,
        MobileRank: '',
        SortIndex: 170
    },
    {
        CountryID: 172,
        CountryEnglishName: 'Saint Lucia',
        CountryLocalName: '',
        ISOCode: 'LC',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 185,
        MobileRank: '',
        SortIndex: 171
    },
    {
        CountryID: 173,
        CountryEnglishName: 'Saint Martin',
        CountryLocalName: 'Saint-Martin [partie française]',
        ISOCode: 'MF',
        CountryCode: '+590',
        CountryExitCode: 0,
        'Min NSN': 4,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 218,
        MobileRank: '',
        SortIndex: 172
    },
    {
        CountryID: 174,
        CountryEnglishName: 'Saint Pierre and Miquelon',
        CountryLocalName: 'Saint-Pierre-et-Miquelon',
        ISOCode: 'PM',
        CountryCode: '+508',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 217,
        MobileRank: '',
        SortIndex: 173
    },
    {
        CountryID: 175,
        CountryEnglishName: 'Saint Vincent Grenadines',
        CountryLocalName: '',
        ISOCode: 'VC',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 199,
        MobileRank: '',
        SortIndex: 174
    },
    {
        CountryID: 176,
        CountryEnglishName: 'Samoa',
        CountryLocalName: '',
        ISOCode: 'WS',
        CountryCode: '+685',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 201,
        MobileRank: '',
        SortIndex: 175
    },
    {
        CountryID: 177,
        CountryEnglishName: 'San Marino',
        CountryLocalName: '',
        ISOCode: 'SM',
        CountryCode: '+378',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 179,
        MobileRank: '',
        SortIndex: 176
    },
    {
        CountryID: 178,
        CountryEnglishName: 'Sao Tome and Principe',
        CountryLocalName: 'São Tomé e Príncipe',
        ISOCode: 'ST',
        CountryCode: '+239',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 207,
        MobileRank: '',
        SortIndex: 177
    },
    {
        CountryID: 179,
        CountryEnglishName: 'Saudi Arabia',
        ISOCode: 'SA',
        CountryCode: '+966',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 20,
        MobileRank: 39,
        SortIndex: 178
    },
    {
        CountryID: 180,
        CountryEnglishName: 'Senegal',
        CountryLocalName: 'Sénégal',
        ISOCode: 'SN',
        CountryCode: '+221',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 119,
        MobileRank: '',
        SortIndex: 179
    },
    {
        CountryID: 182,
        CountryEnglishName: 'Seychelles',
        CountryLocalName: '',
        ISOCode: 'SC',
        CountryCode: '+248',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 190,
        MobileRank: '',
        SortIndex: 180
    },
    {
        CountryID: 183,
        CountryEnglishName: 'Sierra Leone',
        CountryLocalName: '',
        ISOCode: 'SL',
        CountryCode: '+232',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 169,
        MobileRank: '',
        SortIndex: 181
    },
    {
        CountryID: 184,
        CountryEnglishName: 'Singapore',
        CountryLocalName: '',
        ISOCode: 'SG',
        CountryCode: '+65',
        CountryExitCode: '00x',
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 38,
        MobileRank: 57,
        SortIndex: 182
    },
    {
        CountryID: 185,
        CountryEnglishName: 'Sint Maarten',
        CountryLocalName: '',
        ISOCode: 'SX',
        CountryCode: '+1',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 197,
        MobileRank: '',
        SortIndex: 183
    },
    {
        CountryID: 186,
        CountryEnglishName: 'Slovakia',
        CountryLocalName: 'Slovensko',
        ISOCode: 'SK',
        CountryCode: '+421',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 64,
        MobileRank: '',
        SortIndex: 184
    },
    {
        CountryID: 187,
        CountryEnglishName: 'Slovenia',
        CountryLocalName: 'Slovenija',
        ISOCode: 'SI',
        CountryCode: '+386',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 78,
        MobileRank: '',
        SortIndex: 185
    },
    {
        CountryID: 188,
        CountryEnglishName: 'Solomon Islands',
        CountryLocalName: '',
        ISOCode: 'SB',
        CountryCode: '+677',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 195,
        MobileRank: '',
        SortIndex: 186
    },
    {
        CountryID: 189,
        CountryEnglishName: 'Somalia',
        CountryLocalName: 'Soomaaliya',
        ISOCode: 'SO',
        CountryCode: '+252',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 189,
        MobileRank: '',
        SortIndex: 187
    },
    {
        CountryID: 190,
        CountryEnglishName: 'South Africa',
        CountryLocalName: '',
        ISOCode: 'ZA',
        CountryCode: '+27',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 29,
        MobileRank: 28,
        SortIndex: 188
    },
    {
        CountryID: 106,
        CountryEnglishName: 'South Korea',
        CountryLocalName: '대한민국',
        ISOCode: 'KR',
        CountryCode: '+82',
        CountryExitCode: '001, 002',
        'Min NSN': 9,
        'Max NSN': 12,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 15,
        MobileRank: 24,
        SortIndex: 189
    },
    {
        CountryID: 191,
        CountryEnglishName: 'South Sudan',
        ISOCode: 'SS',
        CountryCode: '+211',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 123,
        MobileRank: '',
        SortIndex: 190
    },
    {
        CountryID: 193,
        CountryEnglishName: 'Sri Lanka',
        CountryLocalName: 'Sri Lanka',
        ISOCode: 'LK',
        CountryCode: '+94',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 72,
        MobileRank: 44,
        SortIndex: 191
    },
    {
        CountryID: 194,
        CountryEnglishName: 'Sudan',
        ISOCode: 'SD',
        CountryCode: '+249',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 73,
        MobileRank: '',
        SortIndex: 192
    },
    {
        CountryID: 195,
        CountryEnglishName: 'Suriname',
        CountryLocalName: '',
        ISOCode: 'SR',
        CountryCode: '+597',
        CountryExitCode: 0,
        'Min NSN': 7,
        'Max NSN': 7,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 159,
        MobileRank: '',
        SortIndex: 193
    },
    {
        CountryID: 196,
        CountryEnglishName: 'Swaziland',
        CountryLocalName: '',
        ISOCode: 'SZ',
        CountryCode: '+268',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 164,
        MobileRank: '',
        SortIndex: 194
    },
    {
        CountryID: 197,
        CountryEnglishName: 'Sweden',
        CountryLocalName: 'Sverige',
        ISOCode: 'SE',
        CountryCode: '+46',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 21,
        MobileRank: 51,
        SortIndex: 195
    },
    {
        CountryID: 198,
        CountryEnglishName: 'Switzerland',
        CountryLocalName: 'Schweiz',
        ISOCode: 'CH',
        CountryCode: '+41',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 19,
        MobileRank: '',
        SortIndex: 196
    },
    {
        CountryID: 199,
        CountryEnglishName: 'Syria',
        ISOCode: 'SY',
        CountryCode: '+963',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 68,
        MobileRank: '',
        SortIndex: 197
    },
    {
        CountryID: 41,
        CountryEnglishName: 'Chad',
        CountryLocalName: 'Tchad',
        ISOCode: 'TD',
        CountryCode: '+235',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 132,
        MobileRank: '',
        SortIndex: 198
    },
    {
        CountryID: 159,
        CountryEnglishName: 'Paraguay',
        CountryLocalName: 'Tetã Paraguáipe',
        ISOCode: 'PY',
        CountryCode: '+595',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 105,
        MobileRank: '',
        SortIndex: 199
    },
    {
        CountryID: 200,
        CountryEnglishName: 'Taiwan',
        CountryLocalName: '台灣',
        ISOCode: 'TW',
        CountryCode: '+886',
        CountryExitCode: 2,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 27,
        MobileRank: 30,
        SortIndex: 200
    },
    {
        CountryID: 201,
        CountryEnglishName: 'Tajikistan',
        CountryLocalName: 'Tajikistan',
        ISOCode: 'TJ',
        CountryCode: '+992',
        CountryExitCode: 810,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 148,
        MobileRank: '',
        SortIndex: 201
    },
    {
        CountryID: 202,
        CountryEnglishName: 'Tanzania',
        CountryLocalName: '',
        ISOCode: 'TZ',
        CountryCode: '+255',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 103,
        MobileRank: '',
        SortIndex: 202
    },
    {
        CountryID: 203,
        CountryEnglishName: 'Thailand',
        CountryLocalName: 'ไทย',
        ISOCode: 'TH',
        CountryCode: '+66',
        CountryExitCode: 1,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 30,
        MobileRank: 20,
        SortIndex: 203
    },
    {
        CountryID: 204,
        CountryEnglishName: 'Timor-Leste',
        CountryLocalName: '',
        ISOCode: 'TL',
        CountryCode: '+670',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 156,
        MobileRank: '',
        SortIndex: 204
    },
    {
        CountryID: 205,
        CountryEnglishName: 'Togo',
        CountryLocalName: '',
        ISOCode: 'TG',
        CountryCode: '+228',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 166,
        MobileRank: '',
        SortIndex: 205
    },
    {
        CountryID: 206,
        CountryEnglishName: 'Tokelau',
        CountryLocalName: '',
        ISOCode: 'TK',
        CountryCode: '+690',
        CountryExitCode: 0,
        'Min NSN': 4,
        'Max NSN': 4,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 216,
        MobileRank: '',
        SortIndex: 206
    },
    {
        CountryID: 207,
        CountryEnglishName: 'Tonga',
        CountryLocalName: '',
        ISOCode: 'TO',
        CountryCode: '+676',
        CountryExitCode: 0,
        'Min NSN': 5,
        'Max NSN': 5,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 204,
        MobileRank: '',
        SortIndex: 207
    },
    {
        CountryID: 208,
        CountryEnglishName: 'Trinidad and Tobago',
        CountryLocalName: '',
        ISOCode: 'TT',
        CountryCode: '+1868',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 107,
        MobileRank: '',
        SortIndex: 208
    },
    {
        CountryID: 209,
        CountryEnglishName: 'Tunisia',
        CountryLocalName: 'تونس',
        ISOCode: 'TN',
        CountryCode: '+216',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 81,
        MobileRank: '',
        SortIndex: 209
    },
    {
        CountryID: 210,
        CountryEnglishName: 'Turkey',
        CountryLocalName: 'Türkiye',
        ISOCode: 'TR',
        CountryCode: '+90',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 18,
        MobileRank: 21,
        SortIndex: 210
    },
    {
        CountryID: 211,
        CountryEnglishName: 'Turkmenistan',
        CountryLocalName: '',
        ISOCode: 'TM',
        CountryCode: '+993',
        CountryExitCode: 810,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 98,
        MobileRank: '',
        SortIndex: 211
    },
    {
        CountryID: 212,
        CountryEnglishName: 'Turks and Caicos Islands',
        CountryLocalName: '',
        ISOCode: 'TC',
        CountryCode: '+1',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 192,
        MobileRank: '',
        SortIndex: 212
    },
    {
        CountryID: 213,
        CountryEnglishName: 'Tuvalu',
        CountryLocalName: '',
        ISOCode: 'TV',
        CountryCode: '+688',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 213,
        MobileRank: '',
        SortIndex: 213
    },
    {
        CountryID: 34,
        CountryEnglishName: 'Burundi',
        CountryLocalName: 'Uburundi',
        ISOCode: 'BI',
        CountryCode: '+257',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: '',
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 175,
        MobileRank: '',
        SortIndex: 214
    },
    {
        CountryID: 214,
        CountryEnglishName: 'Uganda',
        CountryLocalName: '',
        ISOCode: 'UG',
        CountryCode: '+256',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 108,
        MobileRank: '',
        SortIndex: 215
    },
    {
        CountryID: 215,
        CountryEnglishName: 'Ukraine',
        CountryLocalName: 'Ukraine',
        ISOCode: 'UA',
        CountryCode: '+380',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 54,
        MobileRank: 22,
        SortIndex: 216
    },
    {
        CountryID: 216,
        CountryEnglishName: 'United Arab Emirates',
        ISOCode: 'AE',
        CountryCode: '+971',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 31,
        MobileRank: 50,
        SortIndex: 217
    },
    {
        CountryID: 217,
        CountryEnglishName: 'United Kingdom',
        CountryLocalName: '',
        ISOCode: 'GB',
        CountryCode: '+44',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 7,
        MobileRank: 16,
        SortIndex: 218
    },
    {
        CountryID: 218,
        CountryEnglishName: 'United States',
        CountryLocalName: '',
        ISOCode: 'US',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 1,
        MobileRank: 3,
        SortIndex: 219
    },
    {
        CountryID: 219,
        CountryEnglishName: 'Uruguay',
        CountryLocalName: '',
        ISOCode: 'UY',
        CountryCode: '+598',
        CountryExitCode: 0,
        'Min NSN': 8,
        'Max NSN': 8,
        TrunkCode: 1,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 80,
        MobileRank: '',
        SortIndex: 220
    },
    {
        CountryID: 220,
        CountryEnglishName: 'Uzbekistan',
        CountryLocalName: 'Uzbekistan',
        ISOCode: 'UZ',
        CountryCode: '+998',
        CountryExitCode: 810,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 8,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 82,
        MobileRank: '',
        SortIndex: 221
    },
    {
        CountryID: 222,
        CountryEnglishName: 'Vatican City',
        CountryLocalName: 'Città del Vaticano',
        ISOCode: 'VA',
        CountryCode: '+379',
        CountryExitCode: 0,
        'Min NSN': 11,
        'Max NSN': 11,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 215,
        MobileRank: '',
        SortIndex: 222
    },
    {
        CountryID: 223,
        CountryEnglishName: 'Venezuela',
        CountryLocalName: '',
        ISOCode: 'VE',
        CountryCode: '+58',
        CountryExitCode: 0,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 34,
        MobileRank: 32,
        SortIndex: 223
    },
    {
        CountryID: 224,
        CountryEnglishName: 'Vietnam',
        CountryLocalName: 'Việt Nam',
        ISOCode: 'VN',
        CountryCode: '+84',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 58,
        MobileRank: 17,
        SortIndex: 224
    },
    {
        CountryID: 225,
        CountryEnglishName: 'Virgin Islands (British)',
        CountryLocalName: '',
        ISOCode: 'VG',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 1,
        GoogleStore: 0,
        AppleStore: 1,
        UNRank: 193,
        MobileRank: '',
        SortIndex: 225
    },
    {
        CountryID: 226,
        CountryEnglishName: 'Virgin Islands (US)',
        CountryLocalName: '',
        ISOCode: 'VI',
        CountryCode: '+1',
        CountryExitCode: 11,
        'Min NSN': 10,
        'Max NSN': 10,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 180,
        MobileRank: '',
        SortIndex: 226
    },
    {
        CountryID: 227,
        CountryEnglishName: 'Wallis and Futuna',
        CountryLocalName: '',
        ISOCode: 'WF',
        CountryCode: '+681',
        CountryExitCode: 0,
        'Min NSN': 6,
        'Max NSN': 6,
        TrunkCode: 0,
        GoogleStore: 0,
        AppleStore: 0,
        UNRank: 214,
        MobileRank: '',
        SortIndex: 227
    },
    {
        CountryID: 228,
        CountryEnglishName: 'Yemen',
        ISOCode: 'YE',
        CountryCode: '+967',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 90,
        MobileRank: '',
        SortIndex: 228
    },
    {
        CountryID: 229,
        CountryEnglishName: 'Zambia',
        CountryLocalName: '',
        ISOCode: 'ZM',
        CountryCode: '+260',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 0,
        UNRank: 109,
        MobileRank: '',
        SortIndex: 229
    },
    {
        CountryID: 230,
        CountryEnglishName: 'Zimbabwe',
        CountryLocalName: '',
        ISOCode: 'ZW',
        CountryCode: '+263',
        CountryExitCode: 0,
        'Min NSN': 9,
        'Max NSN': 9,
        TrunkCode: 0,
        GoogleStore: 1,
        AppleStore: 1,
        UNRank: 139,
        MobileRank: '',
        SortIndex: 230
    }
];
