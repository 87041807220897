import { Component, OnInit } from '@angular/core';
import {   FormBuilder,
  FormControl,
  FormGroup,
  Validators,} from '@angular/forms';
  import { Regex, ERROR_MESSAGES, CONFIG } from "../../../constants";
  import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';

  
@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent implements OnInit {
  CONFIG = CONFIG;
submitted = false;
selectedItem;
  donationForm: FormGroup;
 constructor(
  private fb: FormBuilder,
  private titleService: Title, 
  private metaService: MetaServicesService , 
  private meta: Meta) { }

  FORM_ERROR = {
    name: {
      required:"name is required",
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: "Please enter valid name",
    },
    amount: {required:"amount is required",pattern:'amount should be digit only'}

 
  };
  prices=[10, 20, 30, 40, 50]
  showHonaree=false;
  ngOnInit() {
  
    this.createSEOServices();
    this.donationForm= this.fb.group({
       name: ['', [Validators.required ]],
       amount: ['', [Validators.required]],
       honoreeEmail:[''],
       honoreeName:['']
    }) 
  }

  onSubmit(data){
    this.submitted=true;
  if(this.donationForm.valid){
  
      if (this.donationForm.valid) {
       let value=this.donationForm.value.amount;
       let honoree_name=this.donationForm.value.honoree_name;
       let honoree_email=this.donationForm.value.honoree_email;
       let name=this.donationForm.value.name.toString().replace(' ','');
       var res = this.donationForm.value.amount.split(".");
        if (res[1] == undefined) {
  
         value = value + '.00';
       }
       var url="https://ziyyara.com/ziyyara-donation";
       var url1="https://ziyyara.com/ziyyara-donation";
       var newdata="https://appi.ziyyara.com/ziyyara_donation_pay?amount="+value+"&user_name="+name+"&honoree_name"+honoree_name+"&honoree_email"+honoree_email+"&return_url="+btoa(url)+"&cancel_url="+btoa(url1);
       window.location.href=newdata;
    } 
    }
  
  }

  selectedIndex: number;
  select(index: number, value) {
     console.log(index+'>>>>..'+value);
      this.selectedIndex = index;
      this.donationForm.controls.amount.patchValue(value);
  }

  showHonree(){
    this.showHonaree=true;
  }


  createSEOServices(){
       
    var host=  location.pathname;
   
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( " Take Classes with Ziyyara & Fund Education | Help to Child  ");
    this.meta.updateTag({name:"description", content:"Fund a Child's Education by taking Classes with Ziyyara as we aim to create a brighter and progressive world by helping those kids who need education."})
    this.metaService.removeStructuredData();
    this.meta.updateTag({name:'keywords', content:"Fund a Child's Education by taking Classes with Ziyyara as we aim to create a brighter and progressive world by helping those kids who need education."})
   // this.metaService.insertSchema(this.websiteSchema);
   var ogTitle='  Take Classes with Ziyyara & Fund Education | Help to Child';
   var ogDescription="Fund a Child's Education by taking Classes with Ziyyara as we aim to create a brighter and progressive world by helping those kids who need education."
     this.meta.updateTag({property:"og:title", content:ogTitle});
     this.meta.updateTag({property:"og:description", content:ogDescription});
     this.meta.updateTag({name:"twitter:title", content:ogTitle})
     this.meta.updateTag({name:"twitter:description", content:ogDescription});

  }
  }

  


