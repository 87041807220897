
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common'; 
// import { ReferenceComponent } from './reference/reference.component';
import { SharedModule } from '../shared/shared.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { FormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoginNewComponent } from './login-new/login-new.component';
const pageRoutes: Routes = [
  
   { path: '', component: LoginNewComponent },

  
]; 

@NgModule({
  imports: [
    CommonComponentModule,
    CommonModule,
    SharedModule,
    FormsModule,
    NgxMatSelectSearchModule,
    AngularMaterialModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [LoginNewComponent],
  entryComponents: []
})
export class LogInNewModule { }
