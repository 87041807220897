import { Component, OnInit, AfterViewChecked, AfterViewInit ,ViewEncapsulation} from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AppService, HttpService,MetaServicesService } from '../app/services';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
// import { TranslateService } from '@ngx-translate/core';



// import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  loader = true;
  loaddd = false; config: any;
  loaderMain = true;
  constructor(
    public snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private _appSer: AppService,
    private http:HttpService,
    private metaService:MetaServicesService
    // private translate: TranslateService
  ) {
    //translate.setDefaultLang('en');
  }

  useLanguage(language: string) {
    ////////console.log("hello translate",language);
  //  this.translate.use(language);
  }
  ngOnInit() {
    document.body.scrollTop = 0;
   // //console.log("homepagecaleed")
 // //console.log(window.location.href)
  // if((window.location.href).includes('login')|| (window.location.href).includes('register')){

  //   window.location.href='/'
   
  // }

    this.subcribeToSnack();
    this.loaderSub();
    this.subscribeToBlocked();
  
    var host= location.host+""+location.pathname;
     console.log(host);
    // this.metaService.createCanonicalURL(host); 
  
      
  }

  subcribeToSnack() {
    this._appSer.message.subscribe(message => this.openSnackbar(message));
  }

 
  openSnackbar(snackMessage: any) {
 
    if(snackMessage.message){
      this.snackbar.open(snackMessage.message, snackMessage.action, { duration: 5000 });
    } else {
      this.snackbar.open("Internal Server Error", snackMessage.action, { duration: 5000 });
    }
    
  }

  closeSnackbar() {
    if (this.snackbar) {
      this.snackbar.dismiss();
    }
  }
  subscribeToBlocked() {
    this._appSer.blocked.subscribe((block) => {
      if (block) {
        this.openLogoutPopUp();
      }
    })
  }
  openLogoutPopUp() {
    const content = '';

    const dialogRef = this.dialog.open(LogoutModalComponent, {
      width: '500px',
      maxHeight: 'Auto',
      data: {
        content: { title: content },
        action: 'active'
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      // alert();
      this._appSer.logout();
    });
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loaderMain = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loaderMain = false;
        }
      });
  }

  loaderSub() {
    this._appSer.load.subscribe((item) => {
      console.log(item)
      this.loader = item ? false : item;
    });
  }
  scroltop(){
    document.body.scroll({ 
      top: 0,
      behavior: 'smooth' 
  })}
  onActivate(event) {
    // window.scroll(0,0);
     document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    
}
 
}
