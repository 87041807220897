import { Injectable } from '@angular/core';
import { CanLoad, Router, ActivatedRoute, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState, IPROPERTY } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate, CanLoad {
  constructor(private store: Store<AppState>, private _router: Router,
    private _route: ActivatedRoute) {
    
  }
  canActivate(): Observable<boolean> | boolean {

   if (localStorage.getItem("ziyyara_token")) {
      this._router.navigate(['/profile']);
      return false;
    } else {
      return true;
    }
  }


  canLoad(): Observable<boolean> | boolean {
    
    if (localStorage.getItem("ziyyara_token")) {
      this._router.navigate(['/profile']);
      return false;
    } else {
      

      return true;
    }
  }

}
