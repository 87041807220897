import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-commerce-syllabus',
  templateUrl: './commerce-syllabus.component.html',
  styleUrls: ['./commerce-syllabus.component.css']

})
export class CommerceSyllabusComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {

    
  }

  addStickyHeader() {
    // When the user scrolls the page, execute myFunction
window.onscroll = function() {addStickyHeader()};

// Get the header
var header = document.getElementById("stickyHeader");

// Get the offset position of the navbar
var sticky = header.offsetTop;


function addStickyHeader() {
  if (window.pageYOffset > sticky) {
    header.classList.add("stickyHeader");
  } else {
    header.classList.remove("stickyHeader");
  }
}
  }
  ngOnInit() {
    this.addStickyHeader();
    var host=  location.pathname;
   
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "  Commerce Stream Tuition | Commerce Stream Subjects");
    this.meta.updateTag({name:"description", content:" Ziyyara Edutech provides Online Tuition for Commerce Stream to 11th & 12th grade students which offers one-on-one live classes for all Subjects of Commerce Stream."})
    this.metaService.removeStructuredData();
    this.meta.updateTag({name:'keywords', content:"Ziyyara Edutech provides Online Tuition for Commerce Stream to 11th & 12th grade students which offers one-on-one live classes for all Subjects of Commerce Stream."})
   // this.metaService.insertSchema(this.websiteSchema);
   var ogTitle='  Commerce Stream Tuition | Commerce Stream Subjects';
   var ogDescription="Ziyyara Edutech provides Online Tuition for Commerce Stream to 11th & 12th grade students which offers one-on-one live classes for all Subjects of Commerce Stream."
     this.meta.updateTag({property:"og:title", content:ogTitle});
     this.meta.updateTag({property:"og:description", content:ogDescription});
     this.meta.updateTag({name:"twitter:title", content:ogTitle})
     this.meta.updateTag({name:"twitter:description", content:ogDescription});

  }


}
