
 
export const ERROR_MESSAGES = {
    ACCOUNT_REQUIRED:'Account number is required',
    IFSC_REQUIRED:'IFSC Code is required',
    NAME_REQUIRED:'Name of account is required',
    ANAME_REQUIRED:'Bank Name of account is required',
    SUBJECT_REQUIRED:"Subject is required",
    QUESTION_REQUIRED:"Question/Query is required",
    INVESTOR_CATEGORY_REQUIRED_MAIN:"Category is required",
    BANK_CHANGED:'Your account updated successfully',
    AMOUNT_CHANGED:'Your amount added in your wallet successfully',
    OTPREQUIRED:'OTP is required',
    AMOUNT_REQUIRED:'Amount is required',
    AGREEMENT: 'You must agree to the terms and conditions before registration',
    PASSWORD_REQUIRED: 'Password is required',
    USERNAME_REQUIRED: 'Username is required',
    FULLNAME_REQUIRED: 'Full name is required',
    FIRSTNAME_REQUIRED: 'Full name is required',
    LASTNAME_REQUIRED: 'Last name is required',
    EMAIL_REQUIRED: 'Email is required',
    OLD_PASSWORD_REQUIRED: 'Old Password is required',
    PHONE_NUMBER_REQUIRED: 'Phone number is required',
    CONFIRM_PASSWORD: 'Confirm password is required',
    OLD_PASSWORD: 'Old password is required',
    PROFILE_PIC_REQUIRED:"Profile pic is mandatory",
    PASSWORD_CONFIRMPASSWORD_MISMATCH: 'Password and confirm password does not match',
    INVALID_EMAIL: 'Enter a valid email',
    INVALID_WEB_ADDRESS: 'Invalid web format',
    INVALID_MOBILE_NUMBER: 'Please enter number without "0" in beginning.',
    INVALID_INPUT: 'Not a valid input',
    MAX_LENGTH: 'Max length is ',
    MIN_LENGTH: 'Min length is ',
    MAX_SUBJECT_LENGTH:"You can choose maximum 3 subjects",
    USER_TYPES:"Please select option",
    OTP_RESENT: 'OTP has been successfully sent to provided mobile number',
    FORGOT_PASSWORD: 'Resent link has been sent to your email',
    PASSWORD_CHANGED: 'Password has been changed successfully',
    ROOM_NAME_REQUIRED: 'Room name is required',
    REASON_REQUIRED: 'Reason is required',
    BASE_PRICE_REQUIRED: 'Base price is required',
    PRICE_PER_ADILT: 'Price per adult is required',
    ADULT_REQUIRED: 'Number of adults is required',
    KIDS_REQUIRED: 'Number of kids is required',
    ONLY_NUMBER_ALLOWED: 'Only numbers are allowed',
    REQUIRED:'The Field is required',
    TITLE_REQUIRED: 'Title is required',
    DESCRIPTION_REQUIRED: 'Description is required',
    ASSIGNMENT_TYPE_REQUIRED: 'Assignment type is required',
    ASSIGNMENT_DOC_REQUIRED: 'Assignment document is required',
    DELETE_ASSIGN:"Are you sure you want to delete this assignment",
    // PASSWORD_REGEX: 'At least one Uppercase,one Lowercase,one digit and one special character is required : e.g. YourPass@123',
    PASSWORD_REGEX: 'Password should contains minimum 8 letters and numbers only',
    DELETE_PROPERTY:'Are you sure you want to delete this Property?',
    INVALID_SPACES:'Characters & spaces are not allowed',
    INVALID_CSPACES:'Only alphabets are allowed',
    PROPERTY_NAME_REQUIRED:"Property name is required",
    PROPERTY_TYPE_REQUIRED:"Property type is required",
    PROPERTY_AREA_REQUIRED:"Property area is required",
    PROPERTY_BEDROOM_REQUIRED:"Bedroom is required",
    PROPERTY_BATHROOM_REQUIRED:"Bathroom is required",
    PROPERTY_FTYPE_REQUIRED:"Furnish type is required",
    PROPERTY_ADDRESS_REQUIRED:"Address is required",
    PROPERTY_STATE_REQUIRED:"State is required",
    PROPERTY_CITY_REQUIRED:"City is required",
    PROPERTY_ZONE_REQUIRED:"Timezone is required",
    PROPERTY_ZIPCODE_REQUIRED:"Zipcode is required",
    PROPERTY_AMENTIES_REQUIRED:"Amenities is required",
    PROPERTY_DESC_REQUIRED:"Description is required",
    PROPERTY_SA_REQUIRED:"Security Amount is required",
    PROPERTY_RT_REQUIRED:"Rent Type Amount is required",
    PROPERTY_MINAMOUNT_REQUIRED:"Min Amount is required",
    PROPERTY_MAXAMOUNT_REQUIRED:"Max Amount is required",
    REPOST_PROPERTY:'Are you sure you want to repost this property',
    REJECT_REQUEST:'Are you sure you want to reject this request?',
    ACCEPT_REQUEST:'Are you sure you want to accept this request?',
    ACCEPT_REQUESTS:'Are you sure you want to save the changes?',
    RENTAMOUNT_REQUIRED:'Rent amount is required',
    USER_TYPE:'Tenant Type is required',
    RULESTYPE:'Rules and regulations is required',
    STARTDATE:'Start date is required',
    ENDDATE:'End date is required',
    LANDLORDNAME:'Landlord name is required',
    TENANTNAME:'Tenant name is required',
    PROPERTY_RENTAMOUNT_REQUIRED:'Rent amount is required',
    CONTRACTTYPE:'Contract type is required',
    PROPERTY_COUNTRY_REQUIRED:'Allow your location',
    AGREEMENTC:"You must agree to the terms and conditions ",
    DOB:"Please select Date of Birth",
    HIGHESTQ:"Please enter highest qualification",
    TOTALE:"Please select total experience",
    TOTALTE:"Please select teaching experience",
    AVAILABLEHOUR:"Please select availbale hour",
    AVAILABLETEACH:"Please select available teaching",
    EXPECTION:"Please enter expection amount per hour",
    OCCUPATION:"Please select occupation",
    SOURCE:"Please select souce of income",
    SUBJECT:'Please select subjects',
    INTERNET:"Please select type of internet",
    DAY_REQUIRED:'Please select days',
    SDAY_REQUIRED:'Please select package',
    DATE_REQUIRED:'Please select dates',
    TIME_REQUIRED:'Please select available timing',
    GRADE_REQUIRED:'Please select a grade',
    COUNTRYCODE_REQUIRED:'Please clear your cache ,refresh the page and allow your location',
    COUNTRYCODE_REQUIRED_MAIN:"Please select your country code",
    CITYCODE_REQUIRED_MAIN:"Please select your city code",
    LANGUAGE_REQUIRED:'Please select a language',
    BOARD_REQUIRED:'Please select a board',
    SPECIFY_REQUIRED:'Please specify',
    SELECT_FILE:'please choose a file',
    SCHOOL_NAME_REQUIRED:'School name is required',
    CITY_REQUIRED:'Please enter your city',
    WORD_COUNT:'Please enter words count',
};
 


