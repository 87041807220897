<div class="modal-wrapper text-center">
  <div class="header-popup"> {{data?.content?.title}}
    &nbsp;
    <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a>
  </div>
  <div class="mt-2">
    <h2 class="modal-heading">

    </h2>
  </div>
  <div class="mb-2">
    <p class="modal-para p-2">
      <strong>{{data?.content?.message}}</strong>
    </p>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="reasonForm" novalidate *ngIf="data?.content?.reason">
        <!-- <mat-form-field class="example-full-width"> -->
        <input type="text" matInput [formControl]="reason" placeholder="Reason" class="w-100" required>
        <mat-error>{{util.errorMessage(reason,FORM_ERROR?.reason)}}</mat-error>
        
      
        <!-- </mat-form-field> -->
      </form>
    </div>
  </div>

  <div class="button-wraper modal-btn-wrapper ">
    <div class="clearfix mt-3">
      <div class="float-left yesfloat">
        <button class="btn btn-reola-blank-primary" type="button" (click)="yes()" mat-raised-button>Yes</button>
      </div>
      <div class="float-right nofloat">
        <button class="btn btn-ziyyara-alt" mat-raised-button (click)="no()" type="button" mat-button>No</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>