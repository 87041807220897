import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyGuard } from './route-guards';



const appRoutes: Routes = [


    { path: '', loadChildren: './learning/front-home/home.module#HomeModule' },
    { path: 'index', loadChildren: './learning/front-home/home.module#HomeModule' },
    // { path: 'login', loadChildren: './learning/front-home/home.module#HomeModule' },
    // { path: 'register', loadChildren: './learning/front-home/home.module#HomeModule' },
    { path: 'profile', canLoad: [PropertyGuard], loadChildren: './learning/profile/profile.module#ProfileModule' },
    { path: 'search', canLoad: [PropertyGuard], loadChildren: './learning/search/search.module#SearchModule' },
    { path: 'dashboard', canLoad: [PropertyGuard], loadChildren: './learning/dashboard/dashboard.module#DashboardModule' },
    { path: 'group-study', canLoad: [PropertyGuard], loadChildren: './learning/group-study/group-study.module#GroupStudyModule' },
    { path: 'wallet', canLoad: [PropertyGuard], loadChildren: './learning/wallet/wallet.module#WalletModule' },
    { path: 'request', canLoad: [PropertyGuard], loadChildren: './learning/request/request.module#RequestModule' },
    { path: 'booking', canLoad: [PropertyGuard], loadChildren: './learning/booking/booking.module#BookingModule' },
    { path: 'alerts', canLoad: [PropertyGuard], loadChildren: './learning/notification/notification.module#NotificationModule' },
    { path: 'assignments', canLoad: [PropertyGuard], loadChildren: './learning/assignment/assignment.module#AssignmentModule' },
    { path: 'privacy-policy', loadChildren: './learning/privacy/privacy.module#PrivacyModule' },
    { path: 'cancellation-and-refund', loadChildren: './learning/refund-and-cancellation/refund-and-cancellation.module#RefundAndCancellationModule' },
    
    { path: 'about-us', loadChildren: './learning/about/about.module#AboutModule' },
    { path: 'faq', loadChildren: './learning/faq/faq.module#FaqModule' },
    { path: 'terms-conditions', loadChildren: './learning/terms/terms.module#TermsModule' },
    { path: 'disclaimer', loadChildren: './learning/desclaimer/desclaimer.module#DesclaimerModule' },
    { path: 'intellectual-property-rights', loadChildren: './learning/ip-ownership/ip-ownership.module#IpOwnershipModule' },

    { path: 'career', loadChildren: './learning/career/career.module#CareerModule' },
    { path: 'meet-tutors', loadChildren: './learning/meetmytutors/meet-tutors.module#MeetTutorsModule' },
    // { path: 'leadership-team', loadChildren: './learning/management-team/management-team.module#ManagementTeamModule' },
    { path: 'contact-us', loadChildren: './learning/contact/contact.module#ContactModule' },
    { path: 'packages', loadChildren: './learning/pricing/pricing.module#PricingModule' },
    { path: 'pricing', loadChildren: './learning/cost/cost.module#CostModule' },
    { path: 'how-it-works', loadChildren: './learning/how-its-work/how-its-work.module#HowItsWorkModule' },
    { path: 'help', loadChildren: './learning/help/help.module#HelpModule' },
    { path: 'investor-relations', loadChildren: './learning/invester/invester-relations.module#InvesterRelationsModule' },
    
    { path: 'tuition', loadChildren: './learning/oman-tuition/oman-tuition.module#OnlineHomeTuitionOmanModule' },
    { path: 'ad-contact', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-oman', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-uae', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-cbse-uae-1', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-cbse-uae-2', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-saudi', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-bahrain', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-kuwait', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-hongkong', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-maldives', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-malaysia', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-singapore', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-thailand', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-qatar', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-qatar', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-saudi-arabia', loadChildren: './learning/advtpage/AddModule#AddModule' },

    { path: 'ad-contact-arabic-bangladesh', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-australia', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-canada', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-indonesia', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-jordan', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-malaysia', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-turkey', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-uk', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-us', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-bahrain', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-kuwait', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-oman', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-uae', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-saudi', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-qatar', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-french-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-german-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-spanish-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },

    { path: 'ad-contact-thesis-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-hindi-banner', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-arabic-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-german-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-french-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-spanish-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-hindi-book-a-fee-demo-btn', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-humanities', loadChildren: './learning/advtpage/AddModule#AddModule' },
    
    { path: 'ad-contact', loadChildren: './learning/advtpage/AddModule#AddModule' },
    
    //class 1-12
    { path: 'ad-contact-online-tuition-for-class-1', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-2', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-3', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-4', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-5', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-6', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-7', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-8', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-9', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-10', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-11', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-online-tuition-for-class-12', loadChildren: './learning/advtpage/AddModule#AddModule' },

    //class 1-2 for call to action button
    { path: 'ad-contact-btn-online-tuition-for-class-1', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-2', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-3', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-4', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-5', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-6', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-7', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-8', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-9', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-10', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-11', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-tuition-for-class-12', loadChildren: './learning/advtpage/AddModule#AddModule' },

    // ib and cb
    { path: 'ad-contact-pyp', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-myp', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-dp', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-cp', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-a-level', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-o-level', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-as-level', loadChildren: './learning/advtpage/AddModule#AddModule' },


   // ib and cb and pyp on home page
   { path: 'ad-contact-home-pyp', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-home-myp', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-home-dp', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-home-cp', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-a-home-level', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-o-home-level', loadChildren: './learning/advtpage/AddModule#AddModule' },
   { path: 'ad-contact-as-home-level', loadChildren: './learning/advtpage/AddModule#AddModule' },


    // all boards
    { path: 'ad-contact-for-all-boards', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-online-maths-assignment-help-and-tutoring', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-banner-online-maths-assignment-help-and-tutoring', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-professional-online-assignment-help-and-academic', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-btn-exam-preparation-tips-and-tricks', loadChildren: './learning/advtpage/AddModule#AddModule' },
    // all home page call to action
    { path: 'book-now-bottom', loadChildren: './learning/advtpage/AddModule#AddModule' },




    { path: 'ad-contact**', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'free-registration', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'pricing-silver', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'pricing-gold', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'pricing-platinum', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-tuition', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'quran-classes', loadChildren: './learning/quran-webinar/quran-webinar.module#QuranWebinarModule'},
    { path: 'find-tutors', loadChildren: './learning/findtutors/find-tutors.module#FindTutorsModule' },
    { path: 'why-students', loadChildren: './learning/WhyStudents/WhyStudents.module#WhyStudentsModule' },
    { path: 'why-opt-for-online-tuitions', loadChildren: './learning/Get-Online-Tuition/GetOnlineTuition.module#GetOnlineTuitionModule' },
    { path: 'online-Tuition-Anytime', loadChildren: './learning/Online-Tuition-Anytime/OnlineTuitionAnytime.module#OnlineTuitionAnytimeModule' },
    { path: 'what-is-ziyyara', loadChildren: './learning/Best-Online-Home-Tuitions/BestOnlineHomeTuitions.module#BestOnlineHomeTuitionModule' },
    { path: 'online-Home-Tuitions-For-All-Subjects', loadChildren: './learning/Online Home-Tuitions-for-All-Subjects/OnlineHomeTuitionsforAllSubjects.module#OnlineHomeTuitionsForAllSubjectsModule' },
    { path: 'not-found', loadChildren: './learning/not-found/not-found.module#NotFoundModule' },
    { path: 'online-petition', loadChildren: './learning/online-petition/online-petition.module#OnlinePetitionModule' },
    // { path: 'why-students', loadChildren: './learning/WhyStudents/WhyStudents.module#WhyStudentsModule' },
    { path: 'reference', loadChildren: './learning/reference/reference.module#ReferenceModule'},
    { path: 'refer-a-friend', loadChildren: './learning/refer/refer.module#ReferModule'},
    { path: 'get-started', loadChildren: './learning/register/register.module#RegisterModule'},
    { path: 'studentSignup', loadChildren: './learning/register/register.module#RegisterModule'},
    { path: 'teacherSignup', loadChildren: './learning/register/teacher-register.module#TeacherRegisterModule'},
    { path: 'login', loadChildren: './learning/register/login-new.module#LogInNewModule'},
    { path: 'languages', loadChildren: './learning/languages/language.module#LanguagesModule'},
    { path: 'online-maths-assignment-help-and-tutoring', loadChildren: './learning/math-assignment/math-assignment/math-assignment.module#MathAssignmentsModule'},
    { path: 'exam-preparation-tips-and-tricks', loadChildren: './learning/exam-tips/exam-tips.module#ExamTipsModule'},
    { path: 'professional-online-assignment-help-and-academic', loadChildren: './learning/online-assignment/online-assignment.module#OnlineAssignmentModule'},
    { path: 'thesis-writing-services', loadChildren: './learning/thesis-writing/thesis-writing.module#ThesisWritingModule'},
    { path: 'how-can-a-student-succeed-with-online-earning', loadChildren: './learning/succeed-with-online-learning/succeed-with-online-learning/online-learning.module#OnlineLearningModule'},
    { path: 'the-best-online-summer-camp-activities-for-kids', loadChildren: './learning/online-summer-camp/online-summer-camp/online-summer-camp.module#OnlineSummerCampModule'},
    { path: 'humanities', loadChildren: './learning/humanities/humanities.module#ArtAndHumanitiesModule'},
    { path: 'commerce-stream', loadChildren: './learning/commerce-syllabus/commerce-syllabus.module#CommerceSyllabusModule'},
    { path: 'science-stream', loadChildren: './learning/science-stream/science-stream.module#ScienceStreamCModule'},
    { path: 'tuition/cbse-online-tuition-tutoring-in-all-countries', loadChildren: './learning/cbse-all-board/cbse-all-board.module#CBSEAllBoardModule'},
    { path: 'tuition/cbse-online-tuition-in-nigeria', loadChildren: './learning/cbse-nigeria/cbse-nigeria.module#CBSENigeriaModule'},
    { path: 'tuition/cbse-online-tuition-in-india', loadChildren: './learning/cbse-india/cbse-india.module#CBSEIndiaModule'},
    { path: 'tuition/cbse-online-tuition-in-usa', loadChildren: './learning/cbse-usa/cbse-usa.module#CBSEUSAModule'},
    { path: 'tuition/cbse-online-tuition-in-canada', loadChildren: './learning/cbse-canada/cbse-canada.module#CBSECanadaModule'},
    { path: 'ziyyara-foundation', loadChildren: './learning/dontation/donation.module#DonationModule'},
    { path: 'our-team', loadChildren: './learning/our-team/our-team.module#OurTeamModule'},
    // { path: 'curriculum-boards', loadChildren: './learning/curriculum-boards/curriculum-boards.module#CurriculumBoardsModule'},
    { path: 'ib-curriculum', loadChildren: './learning/ib-curriculum-main/ib-curriculum-main.module#IbCurriculumMainModule'},
    { path: '', loadChildren: './learning/cb-curriculum-main/cb-curriculum-main.module#CbCurriculumMainModule'},
    { path: 'uae', loadChildren: './learning/uae/uae.module#UaeModule'},
    { path: 'phonics-online-classes', loadChildren: './learning/phonics-online-classes/phonics-online-classes.module#PhonicsOnlineClassesModule'},

     // all external forms links
    { path: 'ad-contact-ielts-book-now', loadChildren: './learning/ielts/ielts.module#IeltsModule'},
    { path: 'ad-contact-ielts-book-assessment-now', loadChildren: './learning/ielts/ielts.module#IeltsModule'},
    { path: 'ad-contact-dm-book-now', loadChildren: './learning/ielts/ielts.module#IeltsModule'},
    // { path: 'ad-contact-ielts-book-assessment-now', loadChildren: './learning/ielts/ielts.module#IeltsModule'},
    


// refer and earning

  
    { path: '**', loadChildren: './learning/not-found/not-found.module#NotFoundModule'},

];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes, { useHash: false }
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [
        PropertyGuard

    ]

})

export class AppRoutingModule {

}
