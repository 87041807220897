<app-header-front></app-header-front>

<section class="pricing ">

    <app-assignment-form></app-assignment-form>
    <div class="container">

        <h1 class="mt-5">Get Professional Help with Assignment</h1>

        <h3>Doing Assignments was never too tough, just that you didn’t have the right assistance!</h3>

        <p> Students often look for assignment help online so that they could get help whenever they are stuck while doing an assignment. Some three decades ago, when the internet was not popular, we didn’t have this luxury. Either we could take assignment
            help from a friend or a tutor. However, while finding a tutor all the time isn’t possible, you don’t always have a friend who knows everything.</p>
        <p> But then, the advent of the internet has completely changed the education landscape. It allows you to take assignment writing help at any time from anywhere. Through our assignment help service, we provide every homework help seeker the instant
            services.
        </p>
        <p>The believer in goodwill begets business, our team for assignments help online ensures every student gets more than what they are looking for. Every student's contentment is our priority. So, join the Ziyyara community of students and stand out
            from the crowd.</p>

        <H2> Why Choose Us? </H2>



        <!-- Why choose us section starts -->
        <div class="why-choose-us">
            <div class="why-choose-us-1">
                <div class="why-choose-us-img">
                    <img class="icon-sz why-choose-us-img-icon" src="https://thestudenthelpline.com/images/cnt-3.png">
                </div>
                <div class="center-align">
                    <p class="why-choose-us-p">Certified Experts</p>
                </div>
            </div>
            <div class="why-choose-us-2">
                <div class="why-choose-us-img">
                    <img class="icon-sz why-choose-us-img-icon" src="https://thestudenthelpline.com/images/cnt-4.png">
                </div>
                <div class="center-align">
                    <p class="why-choose-us-p">100% Unique Assignments</p>
                </div>
            </div>
            <div class="why-choose-us-3">
                <div class="why-choose-us-img">
                    <img class="icon-sz why-choose-us-img-icon" src="https://thestudenthelpline.com/images/cnt-1.png">
                </div>
                <div class="center-align">
                    <p class="why-choose-us-p">Timely Delivery</p>
                </div>
            </div>
            <div class="why-choose-us-4">
                <div class="why-choose-us-img">
                    <img class="icon-sz why-choose-us-img-icon" src="https://thestudenthelpline.com/images/cnt-2.png">
                </div>
                <div class="center-align">
                    <p class="why-choose-us-p">24*7 Customer Support</p>
                </div>
            </div>
        </div>
        <!-- Why choose us section ends -->

        <H2>3 Easy Steps To Place Order </H2>

        <!-- cards 3-step starts -->
        <div class="why-choose-us">
            <div class="three-step-card">
                <div class="three-step-card-img">
                    <img class="three-step-card-img" src="assets/images/submit-assignment.png" alt="">
                </div>
                <p class="three-step-card-head">
                    Submit Assignments
                </p>
                <p class="three-step-card-para">
                    For the first step, submit your assignment topic by filling 'Order Now' form or inbox us. Fill in all the relevant details and requirements for the project.
                </p>
            </div>
            <div class="three-step-card">
                <div class="three-step-card-img">
                    <img class="three-step-card-img" src="assets/images/pay-for-assignment.png" alt="">
                </div>
                <p class="three-step-card-head">
                    Pay for Assignments
                </p>
                <p class="three-step-card-para">
                    Make the most affordable payment for your assignments. The transaction will take place in the secure gateway. Once we receive it, our professionals will start working on the project. </p>
            </div>
            <div class="three-step-card">
                <div class="three-step-card-img">
                    <img class="three-step-card-img" src="assets/images/get-solutions.png" alt="">
                </div>
                <p class="three-step-card-head">
                    Get Solutions
                </p>
                <p class="three-step-card-para">
                    Our professionals deliver the solutions exactly on your mentioned date. Our qualified writers ensure that you don't receive the assignment late. </p>
            </div>
        </div>
        <!-- cards 3-step ends -->

        <!-- <div class="myyy">
                    <a routerLink="/ad-contact-btn-professional-online-assignment-help-and-academic" class="btn btn-danger myy-btn">Get Assignment Help</a>
                </div> -->
        <div class="help-ass-btn-cotainer">
            <a routerLink="/ad-contact-btn-professional-online-assignment-help-and-academic" class="help-ass-btn">Get Assignment Help </a>
        </div>

        <!-- nav vertical pill section strats -->
        <div class="bg-of-v-pill">
            <div class="row box-shhh">
                <div class="col-3">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="nav-link pill-verti btn-hover-1 active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><ul class="pl-tworem"><li class="licolor_style">Why is an Assignment and Why Should You Take Assignment Help?</li></ul></button>
                        <button class="nav-link pill-verti btn-hover-2 " id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><ul class="pl-tworem"><li class="licolor_style">We Provide Customized Assignment Help at Affordable Rates</li></ul></button>
                        <button class="nav-link pill-verti btn-hover-2 " id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><ul class="pl-tworem"><li class="licolor_style">How to Pick a Good Service to Get Assignment Help</li></ul></button>
                        <button class="nav-link pill-verti btn-hover-2 !important" id="v-pills-settings-tab" data-toggle="pill" data-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><ul class="pl-tworem"><li class="licolor_style">Why Best Assignment Experts Have Good Competence and Dependability</li></ul></button>

                        <button class="nav-link pill-verti btn-hover-2" id="v-pills-fifth-tab" data-toggle="pill" data-target="#v-pills-fifth" type="button" role="tab" aria-controls="v-pills-fifth" aria-selected="false"><ul class="pl-tworem"><li class="licolor_style">Get Reliable Assignment Help From Anywhere in Australia, the UK, and the USA</li></ul></button>

                        <button class="nav-link pill-verti btn-hover-buttom " id="v-pills-sixth-tab" data-toggle="pill" data-target="#v-pills-sixth" type="button" role="tab" aria-controls="v-pills-sixth" aria-selected="false"><ul class="pl-tworem"><li class="licolor_style">Get Assignment Help Online for Almost Any Subject!</li></ul></button>
                    </div>
                </div>
                <div class="col-9">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active pill-mar" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <p>Seeing a pile of assignments is a student's worst nightmare. These stressful tasks are given by their teachers, who ask students to complete them within the specified time frame. However, the procrastination habit of students
                                leads to the homework starting to stack up. This is where a student needs professional assignment help.</p>

                            <p>Even if the deadline isn't the case, a student needs assignment help from experts for the following reasons: </p>
                            <ul>
                                <li> Most teachers give tedious tasks to their pupils. And let's be fair, nobody wants to spend time doing these boring assignments. Our assignment help experts will take away the boredom and help complete them. </li>
                                <li> An online assignment service provider can help you save time by doing the homework for you. This way, you can focus more on the high-priority tasks. </li>
                                <li> Students sometimes don't have the required knowledge to complete the homework. Since our professionals know their subject, they will provide you with everything to complete the assignment. </li>
                                <li> The primary reason for choosing assignment help is to achieve the best grades possible. You do not want to let your parents down, and our student assignment help services won't let you do so!</li>
                            </ul>
                        </div>

                        <div class="tab-pane fade pill-mar" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <p>We know students cannot afford to spend too much on their assignments. That is why we provide customized assignment help for students at cheap and affordable rates. By choosing our online assignment writing services, students
                                get these benefits: </p>
                            <ul>
                                <li>Students receive quality work at affordable rates. </li>
                                <li>In addition to inexpensive rates, we provide various offers that increase the satisfaction of our clients. </li>
                                <li> Our affordable services result in happy faces with satiated pockets.</li>
                            </ul>
                            <p>We will help you to achieve the best results for your academic endeavors. You just have to contact us to get the best services available on the internet!</p>

                        </div>

                        <div class="tab-pane fade pill-mar" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <p>Picking a company that provides exceptional assignment writing services is a hectic but crucial task. You definitely do not want to mess up with your grades. That is why, when choosing a student assignment help provider, also
                                look for these things: </p>
                            <ul>
                                <li>The service provider should provide plagiarism-free assignments. Our services excel in this case as you can trust us blindfolded for zero plagiarism works. </li>
                                <li>Another feature to consider is if the service provider has 24/7 customer support. This feature is crucial as it will help you discuss details and contact them at your convenience. </li>
                                <li>Make sure that the company has assignment writers who are experts in their fields. Our team has graduates, post-graduates, and Ph.D. holders who help students write their online assignments. </li>
                                <p>Having expert writers is a must-have for service providers. Why? Because they won't need to research the content; hence, saving a lot of time.</p>
                            </ul>
                        </div>

                        <div class="tab-pane fade pill-mar" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab ">
                            <p>The result of our assignment experts’ hard work is reliable, dependable, and competent. We assure you that our team provides work of the best quality, which helps you get high grades. </p>
                            <p>They are highly educated with the best knowledge in their area of study. Their knowledge and experience make them dependable and reliable.</p>
                        </div>

                        <div class="tab-pane fade pill-mar" id="v-pills-fifth" role="tabpanel" aria-labelledby="v-pills-fifth-tab ">
                            <p>Fortunately, people can avail of our services from anywhere in Australia, the UK, and the USA. You don't have to worry about your location and pockets, as we will deliver your assignment at reasonable rates anywhere in Australia,
                                the USA, and the UK.</p>
                            <p>If you are looking for help with assignment, online thesis writing, or online essay writing, several tutoring platforms are providing that. One of the names that stand out from the crowd due to its excellent global assignment
                                help is Ziyyara. You can get the best assignment help from the tutoring platform.</p>
                            <p>Irrespective of the subjects, we provide the best assignment help in various countries including Australia, America, etc. However, if you are searching for assignment help in Australia, we could be the one-stop solution for
                                you. Every country has its specification when it comes to assignment help, and Australian assignment help is no different. Ziyyara provides online assignment help according to the assignment specifications of Australia
                                like it does for other countries.</p>
                        </div>

                        <div class="tab-pane fade pill-mar" id="v-pills-sixth" role="tabpanel" aria-labelledby="v-pills-sixth-tab ">
                            <p>If a subject is taught in a school, college or university, we provide assignment help for that subject. So, no matter what subject it may be, you can rest assured your issue of “where will I find my assignment help?” is resolved.
                                Our expert tutors serve the best global assignment help as they thoroughly understand the requirements of your assignment and deliver the quality paper to you. Some of the best online assignment help students often search
                                us for, are:</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- nav vertical pill section ends -->




        <!-- subject expertise section starts -->
        <!-- <div class="myyy">
                    <a routerLink="/ad-contact-btn-professional-online-assignment-help-and-academic" class="btn btn-danger myy-btn">Get Free Assignment Help</a>
                </div> -->



        <H2>Our Subject Expertise </H2>


        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item judtify-c-so active">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/biology_assignment.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Biology Assignment
                            </p>
                            <p class="expertise-card-para">
                                In biology, students often need to mug up so many concepts that make the subject even tougher. Here, you are provided with the biology assignments in such a way that swotting up things becomes easier for you.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/statistics.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Statistics Assignment
                            </p>
                            <p class="expertise-card-para">
                                Statistics is not an easy science for sure. It involves real data crunching. You may not have sufficient time to complete your homework within the deadline. The high-quality statistics assignment help offered by our experts also takes away all your statistical
                                worries.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/hr.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Physics Assignment
                            </p>
                            <p class="expertise-card-para">
                                Most students find Physics difficult. However, if your concepts are clear, applying those won’t be difficult. The Physics assignment help offered by us put special attention towards helping students get their concepts clear. </p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/computer-science.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Computer Science Assignment
                            </p>
                            <p class="expertise-card-para">
                                With a broad range of subjects, computer science could be too complex to understand. Here, you are delivered with Computer Science assignment help by experts who have years of experience working in the computer science industry. </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/english.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                English Assignment
                            </p>
                            <p class="expertise-card-para">
                                For some when English is easy, for some it’s tough. Whatever it may seem, if you dive deep into it you will realize the subject is as vast as an ocean. Knowing thousands of poets and authors is not everyone’s cup of tea. But then, by opting for our English
                                assignment help, you aren’t required to waste time mugging them up. </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/psychology.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Psychology Assignment
                            </p>
                            <p class="expertise-card-para">
                                While Psychology is an interesting subject to learn, not everyone finds it easy to master its technicalities. With our Psychology assignment assistance, you get the best quality solution for your concern. </p>
                        </div>
                    </div>
                </div>

                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/chemistry.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Chemistry Assignment
                            </p>
                            <p class="expertise-card-para">
                                Not everyone finds Chemistry interesting. Most students find doing chemistry assignments the most boring task. However, our experts not only solve your assignment completion purpose but makes learning interesting as well. </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/management.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Management Assignment
                            </p>
                            <p class="expertise-card-para">
                                The vast academic curriculum is already a burden on the management students. On top of it, they need to complete various assignments that too within the allocated deadlines. They often find it hard to squeeze time out of their busy schedule due to the
                                intense academic pressure. Our experts provide creative and unique Management assignment help so that the students could fetch high marks. </p>
                        </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/math.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Mathematics Assignment
                            </p>
                            <p class="expertise-card-para">
                                Most students find mathematics a difficult subject. The difficulty increases when there are complex assignments with impending deadlines. Moreover, at times you don’t get a second chance to do it. Our mathematics experts know your concern very well. </p>
                        </div>
                    </div>


                </div>


                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/economics.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Economics Assignment
                            </p>
                            <p class="expertise-card-para">
                                Usually considered a complicated subject by students, Economics includes the study of market demands, business, governance, and social justice. Even after spending hours on economics assignments, students sometimes don’t get the result they intended.
                                However, as long as you have our Economic assignment guidance by your side, you needn’t worry. </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/law.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Law Assignment
                            </p>
                            <p class="expertise-card-para">
                                One needs to do a lot of reading and learning while pursuing a Law course to pass various tests and complete multiple assignments. Mastering laws and statutes of different topics like civil law, constitutional law, etc. aren’t easy. However, getting a
                                Law assignment help can lessen their burden to a great extent. </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/business.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Business Assignment
                            </p>
                            <p class="expertise-card-para">
                                More or less every business student looks for business assignment help. Writing business assignments demands a lot of effort. Moreover, with a vast syllabus to study, business students are unable to devote sufficient time to the assignment completion.
                                However, they can always rely on our platform for business assignment help. </div>
                    </div>


                </div>


                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/finance.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Finance Assignment
                            </p>
                            <p class="expertise-card-para">
                                Usually considered a complicated subject by students, Economics includes the study of market demands, business, governance, and social justice. Even after spending hours on economics assignments, students sometimes don’t get the result they intended.
                                However, as long as you have our Economic assignment guidance by your side, you needn’t worry.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/accounting.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Accounting Assignment
                            </p>
                            <p class="expertise-card-para">
                                Most students find accounting the most difficult subject of commerce. Numerous assignments are important to gain better clarity of the subjects. However, doing these assignments within the deadlines can take your peace. Our global expert tutors not only
                                provide you with Accounting assignment help but also helps you gain new knowledge.
                            </p>
                        </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/marketing.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Marketing Assignment
                            </p>
                            <p class="expertise-card-para">
                                Facing issues while completing your marketing assignment is normal as marketing involves a plethora of concepts. However, as long as marketing assignment help providers are there, you needn’t panic. Simply, find the best marketing assignment help provider
                                based on market reviews and testimonials.
                            </p>
                        </div>
                    </div>


                </div>

                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/mba.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                MBA Assignment
                            </p>
                            <p class="expertise-card-para">
                                Though MBA is interesting yet complicated as it encompasses multiple disciplines. It involves the study of various topics such as Market Research, Marketing Strategy Planning, etc. However, you can always rely on a reputed tuition platform as far as the
                                MBA assignment help is concerned. Our professionals have years of experience in delivering MBA assignment help.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/project-management.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Project Management Assignment
                            </p>
                            <p class="expertise-card-para">
                                Completing project management assignments with market-relevant information could be a tough task, especially for those who either are multiple subjects or working part-time. However, they can always take the help from our online tutoring platform. We
                                provide data-driven, in-depth, and plagiarism-free project management assignment help.
                            </p>
                        </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/hr.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                HR Assignment
                            </p>
                            <p class="expertise-card-para">
                                Though most students find HRM (Human Resource Management) an interesting and easy subject to study, not all find assignment writing easy. However, we always have various HR assignment help providers at our disposal. Various online tutoring platforms provide
                                excellent HR assignment help.
                            </p>
                        </div>
                    </div>


                </div>


                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/history.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                History Assignment
                            </p>
                            <p class="expertise-card-para">
                                History is another subject in which though a good understanding can help, still you need to cram things. There are dates, events, and a lot more that should be correct while you are writing the assignments. This is why most students seek history assignment
                                help. The best part is there is no dearth of history assignment help providers in the industry.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/engineering.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Engineering Assignment
                            </p>
                            <p class="expertise-card-para">
                                Engineering is a stream of education that the majority of students pursue. Still, when it comes to writing engineering assignments, students face issues. However, our online tutoring platform can solve the problem for you. The education company provides
                                ultimate engineering assignment help.
                            </p>
                        </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/corporate-finance.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Corporate Finance Assignment
                            </p>
                            <p class="expertise-card-para">
                                It is not easy to analyze the financial issues various companies face as you need to pay special attention to practical applications and relevance. And, that’s what most corporate finance assignments are. But no worries! You can always rely on a reputed
                                corporate finance assignment help provider for completing your assignments. This is how you would also gain better clarity into the subject.
                            </p>
                        </div>
                    </div>


                </div>


                <div class="carousel-item judtify-c-so">

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/business.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Sociology Assignment
                            </p>
                            <p class="expertise-card-para">
                                Learning sociology involves a lot of writing that could be in the form of essays, articles, and assignments. While working on any sociology assignments you need to know certain points that are hard to remember. However, when you have us as a sociology
                                assignments help provider, you needn’t worry about that.
                            </p>
                        </div>
                    </div>

                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/science.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Science Assignment
                            </p>
                            <p class="expertise-card-para">
                                In Science, you study the world around you and beyond with the help of discoveries, observations, evidence, inferences, and experimentation. Sometimes writing the science assignment can seem too difficult. Our online tutors are specialized in providing
                                science assignment help. They have expertise in almost all the fields of science and technology.
                            </p>
                        </div>
                    </div>


                    <div class="expertise-cant">
                        <img class="crousla-img-card" src="/assets/images/corporate-finance.png" alt="">
                        <div class="expertise-card ">
                            <p class="expertise-card-head">
                                Corporate Finance Assignment
                            </p>
                            <p class="expertise-card-para">
                                It is not easy to analyze the financial issues various companies face as you need to pay special attention to practical applications and relevance. And, that’s what most corporate finance assignments are. But no worries! You can always rely on a reputed
                                corporate finance assignment help provider for completing your assignments. This is how you would also gain better clarity into the subject.
                            </p>
                        </div>
                    </div>


                </div>


            </div>
            <div class="crousl-btn">
                <button class="prev-nxt-btn" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </button>
                <button class="prev-nxt-btn" type="button" data-target="#carouselExampleControls" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </button>
            </div>
        </div>
        <!-- subject expertise section ends -->


        <p class="mt-5">Have you got a pending online assignment, or are you stuck on some of its questions? If yes, you may need assignment help from experts that know the topic from the core. That is why we supply the much-needed assignment help for students in numerous
            subjects, including Math, Physics, etc.</p>

        <!-- achivement starts -->
        <div class="achivem">
            <div class="achivem-delivered">
                <p class="achivem-head">
                    1500+
                </p>
                <p class="achivem-para">
                    DELIVERED ORDERS
                </p>
            </div>
            <div class="achivem-expert">
                <p class="achivem-head">
                    112
                </p>
                <p class="achivem-para">
                    EXPERTS
                </p>
            </div>
            <div class="achivem-client">
                <p class="achivem-head">
                    4.5
                </p>
                <p class="achivem-para">
                    CLIENT RATING
                </p>
            </div>
        </div>
        <!-- achivement starts -->



        <h2>Testimonials</h2>

        <!-- new Testimonials starts  -->
        <div id="carouselExampleIndicators" class="carousel slide mb-five" data-ride="carousel">
            <ol class="carousel-indicators top-23">
                <!-- <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item judtify-c-so active">

                    <div class="testim-card">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Oliver</p>
                        </div>
                        <div>
                            <p class="testim-para1">It's my first time getting assignment done from Ziyyara and I am not regretting it a bit. I got my finance Assignment done and it was well written. From the vocabulary to the paraphrasing it was perfect. I will definitely recommend
                                this service to all my friends.</p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Finance Assignment</p>
                            </div>
                        </div>
                    </div>

                    <div class="testim-card card-none">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Elijah</p>
                        </div>
                        <div>
                            <p class="testim-para1">I had the best experience with Ziyyara with all my assignments. I am a frequent user of this website now and I would highly recommend their Assignment Help. Thanks for helping me to complete all my assignments on time.</p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Law Assignment</p>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- -------------------------------------- -->

                <div class="carousel-item judtify-c-so">

                    <div class="testim-card">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Mohit Rathore</p>
                        </div>
                        <div>
                            <p class="testim-para1">I have ordered 800 words’ assignment from this website and they offered reasonable price. I am happy with the writing and editing services. Remarkable service!!</p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Accounting Assignment</p>
                            </div>
                        </div>
                    </div>

                    <div class="testim-card card-none">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Vikramjeet</p>
                        </div>
                        <div>
                            <p class="testim-para1">I had no time to write my business assignment so I ordered it from this website. It was done so professionally and at reasonable price, even before the deadline. </p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Marketing Assignment</p>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- -------------------------------------- -->

                <div class="carousel-item judtify-c-so">

                    <div class="testim-card">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Amara Gashi</p>
                        </div>
                        <div>
                            <p class="testim-para1">Great service! I had an English assignment to be done and wasn't sure what exactly needed to be done. Then I ordered assignment from this site and honestly I am very satisfied with their service.</p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Project Management Assignment</p>
                            </div>
                        </div>
                    </div>

                    <div class="testim-card card-none">
                        <div class="testim-user">
                            <img class="testim-img" src="/assets/images/user-logo.png" alt="">
                            <p class="testim-cmt">Mary Delvina</p>
                        </div>
                        <div>
                            <p class="testim-para1">I had an urgent need for a management assignment expert and there is no doubt I had received an excellent quality assignment within 3 days. The quality of the assignment was really good and I recommend this site.</p>
                        </div>
                        <div class="write-choice">
                            <div>
                                <img class="write-choice-img" src="/assets/images/notes-test.png" alt="">
                            </div>
                            <div>
                                <p class="write-choice-1">Write Choice</p>
                                <p class="write-choice-2">Engineering Assignment</p>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- -------------------------------------- -->


            </div>
            <!-- <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev" style="display:none">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next" style="display:none">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </button> -->
        </div>


        <!-- new Tesrimonials ends -->




        <!-- --------------------F&QS starts---------------------------------- -->
        <h3 class="center">FAQs</h3>
        <mat-accordion id="faqs" class="example-headers-align term-condition-wrapper">
            <!-- 1 -->
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0);panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2>1. Why do students look for assignment help online?</h2>

                    </mat-panel-title>


                </mat-expansion-panel-header>
                <div class="conditions">
                    <p>
                        The primary reason students look for help for their assignments online is to meet deadlines and achieve better grades. In fact, with our online service, it is almost certain that you will get the highest marks for your project.
                    </p>
                </div>
                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <!-- 2 -->
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1);panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2>2. Where can I get help with my assignment?</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="conditions">
                    <p>
                        If you need help completing your assignments, Ziyyara is a perfect choice. Our qualified team will finish the work before your deadline. With our minimal costs, we ensure that you receive quality writing services.
                    </p>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <!-- 3 -->
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2);panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2>3. Can someone do my assignment for me?</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="conditions">
                    <p>
                        Yes! Our experts can do all your assignments with the best quality. You can trust our team blindfolded as they know their work and have years of experience. However, you will have to pay an affordable amount to use our services.
                    </p>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <!-- 4 -->
            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3);panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2>4. What is the best assignment help website?</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="conditions">
                    <p>
                        The best website which provides quality work at affordable rates is Ziyyara. Furthermore, we ensure that our delivery is on your mentioned date. Due to these reasons, we have established our place as one of the most reliable writing services providers.
                    </p>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <!-- 5 -->
            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4);panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2>5. What is the best assignment help website?
                        </h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="conditions">
                    <p>
                        It is the help that you get to complete your assignments before deadlines from online websites like Ziyyara. Our main goal is to provide services that help you pursue your academic
                    </p>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>



        </mat-accordion>

        <!-- --------------------F&QS ends---------------------------------- -->






        <div class="row">
            <div class="col-sm-4">
                <p>
                    <a routerLink="/online-maths-assignment-help-and-tutoring"> <img class="img-responsive" src="/assets/images/math-help.jpg"></a>
                </p>
                <h6><a routerLink="/online-maths-assignment-help-and-tutoring"> Online Maths Assignment Help And Tutoring </a></h6>
            </div>
            <div class="col-sm-4">
                <p>
                    <a routerLink="/exam-preparation-tips-and-tricks"> <img class="img-responsive" src="/assets/images/exam-preparation-tips.jpg"></a>
                </p>
                <h6><a routerLink="/exam-preparation-tips-and-tricks">Exam Preparation Tips  </a></h6>
            </div>
            <!-- <div class="col-sm-4">
            <p><a routerLink="/professional-online-assignment-help-and-academic" > <img class="img-responsive" src="/assets/images/assignment-help.jpg"></a></p>
            <h6 ><a routerLink="/professional-online-assignment-help-and-academic"  >Professional Assignment Help </a></h6>
            </div> -->
            <div class="col-sm-4">
                <p>
                    <a href="https://ziyyara.com/blog/algebraic-expressions-and-algebra-basics.html"> <img class="img-responsive" height="172px" src="https://ziyyara.com/blog/assets/images/Algebric.jpg"></a>
                </p>
                <h6><a href="https://ziyyara.com/blog/algebraic-expressions-and-algebra-basics.html">Algebraic Expressions </a></h6>
            </div>

        </div>
    </div>
</section>




<app-footer-front></app-footer-front>