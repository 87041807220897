import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from "@angular/material";
import * as AOS from 'aos';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-get-online-tuition',
  templateUrl: './get-online-tuition.component.html',
  styleUrls: ['./get-online-tuition.component.css'],
 
})
export class GetOnlineTuitionComponent implements OnInit {

  constructor( 
    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

  ngOnInit() {
   
    this.createSEOServices();
    AOS.init({
      duration: 1200,
      delay: 200,
      once: true
    });
  }

  createSEOServices(){
      var host=  location.pathname;
     
      this.metaService.createCanonicalURL(host);
    //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
      this.titleService.setTitle( "Why opt for Online Tuitions at Ziyyara | Experienced Tutor");
      this.meta.updateTag({name:"description", content:"You can find an experienced tutor for your kids to let them learn via 1-on-1 live sessions is one of the main reasons why opt for Online Tuitions at Ziyyara."})
      this.meta.updateTag({name:"keywords", content:"You can find an experienced tutor for your kids to let them learn via 1-on-1 live sessions is one of the main reasons why opt for Online Tuitions at Ziyyara. "})
      this.metaService.removeStructuredData();
     // this.metaService.insertSchema(this.websiteSchema);
       var ogTitle=' Why opt for Online Tuitions at Ziyyara | Experienced Tutor';
       var ogDescription="You can find an experienced tutor for your kids to let them learn via 1-on-1 live sessions is one of the main reasons why opt for Online Tuitions at Ziyyara."
       this.meta.updateTag({property:"og:title", content:ogTitle})
       this.meta.updateTag({property:"og:description", content:ogDescription})
       this.meta.updateTag({name:"twitter:title", content:ogTitle})
       this.meta.updateTag({name:"twitter:description", content:ogDescription});
  
   
   
  }

}
