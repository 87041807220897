
<app-header-front></app-header-front>




<div class="how-it-works">

  <section id="title" class="light" style="padding: 10px;">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="pagetitle">
                      <h2> Versatility of e-learning: Online tuitions whenever and from wherever you wish</h2>
                      
                      
                    </div>
                    
                    <div class="_par">
                    <p>Ziyyara offers online tuitions via its web-based platform that students can attend in real- time from anywhere in the world and at any time.  At Ziyyara, our online instructors and tutors who have amassed extensive experience in teaching over the internet outline the following distinct benefits of e-learning: -  </p>
              </div>
                  </div>
          </div>
      </div>
  </section>

  <section class="allsteps">
      <div class="container">
          <div class="row">
              <div class="col-md-12 stepone" data-aos="fade-left">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">1</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Wide-ranging flexibility
                          </h3>

                          <ul>
                              <li>learning happens round the clock from anywhere</li>
                        
                            </ul>
                      </div>
                     
                  </div>
              </div>

              <div class="col-md-12 stepone" data-aos="fade-right">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">2</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Instructions and assistance from highly qualified and experienced tutors
                          </h3>
                          <ul>

                              <li>We always make sure that our enrolled students receive high-quality (learning) from highly qualified and experienced teachers.  </li>

                          </ul>
                      </div>
                    
                  </div>
              </div>
              <div class="col-md-12 stepone" data-aos="fade-left">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">3</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                          Delivers high-quality and clear information in a consistent manner</h3>
                          <ul>
                              <li> Ziyyara makes available interactive whiteboards equipped with versatile features like audio-video calls, live chatting, screen sharing, file transfer, lesson recording, notes, and discussions. The delivery of virtual classroom reinforced by intuitive whiteboards helps boost real-time communication and interaction between our tutors and students, thereby simplifying the entire learning process. </li>
                            


                          </ul>
                      </div>
                    
                  </div>
              </div>
              <div class="col-md-12 stepone " data-aos="fade-right">
                  <div class="row">
                      <div class="col-md-2 mar45">
                          <span class="step">4</span>
                      </div>
                      <div class="col-md-10 pad30">
                          <h3>
                            Supremely affordable 
                          </h3>
                          <ul>
                              <li>The student does not need to separately buy any physical copies of textbooks or notebooks which help him or her to save a lot of money upfront. </li>
                              
                          </ul>
                      </div>
                   
                  </div>
              </div>
              <div class="col-md-12 stepone " data-aos="fade-left">
                <div class="row">
                    <div class="col-md-2 mar45">
                        <span class="step">5</span>
                    </div>
                    <div class="col-md-10 pad30">
                        <h3>
                          	Wide scope and leeway in settling doubts and issues
                        </h3>
                        <ul>
                            <li> Many students feel intimidated or shy to ask questions in a physical classroom. Our online tutorial platform serves as the ideal forum for students to clear their doubts without any inhibitions</li>
                        
                            
                        </ul>
                    </div>
                 
                </div>
            </div>
            <div class="col-md-12 stepone " data-aos="fade-right">
              <div class="row">
                  <div class="col-md-2 mar45">
                      <span class="step">6</span>
                  </div>
                  <div class="col-md-10 pad30">
                      <h3>
                        Eco-friendly
                      </h3>
                      <ul>
                          <li> The e-learning mode has a clear edge over the traditional education system in that the former is very eco-friendly. According to one estimate, at least 100 trees need to be chopped for creating 50,000 sheets of paper or pages. So, if more and more students switch to the web-based learning system, they’ll contribute hugely to the conservation of forests and the protection of the global ecosystems.</li>

                          
                      </ul>
                  </div>
               
              </div>
          </div>
          <div class="col-md-12 stepone " data-aos="fade-left">
            <div class="row">
                <div class="col-md-2 mar45">
                    <span class="step">7</span>
                </div>
                <div class="col-md-10 pad30">
                    <h3>
                      High-speed delivery of data and thorough access to updated data
                    </h3>
                    <ul>
                        <li>There is no waiting time involved as learners start attending classes almost immediately following registration and also receive feedbacks in real time. </li>
 
                        
                    </ul>
                </div>
             
            </div>
        </div>
          </div>
      </div>
  </section>
  

</div>




<app-footer-front></app-footer-front>

