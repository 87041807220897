
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// Modules
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Material modules
import {
  MatSelectModule,
  
  MatSnackBarModule,
  MatInputModule,
  MatDialogModule,
  MatMenu,
  MatMenuModule,
  MatButton,
  MatButtonModule,
  MatSidenavModule,
  MatTab,
  MatTabsModule,
  MatTooltipModule,
  MatChipsModule,
  MatIconModule,
  MatAutocompleteModule,
  MatCardModule,
  MatRadioModule,
  MatSliderModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { LoaderButtonComponent } from './components/loader-button/loader-button.component';
import { LoaderButtonSmallComponent } from './components/loader-button-small/loader-button-small.component';
import { FileUploaderProfileComponent } from './components/file-uploader-profile/file-uploader-profile.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { OtpComponent } from './components/otp/otp.component';
import { SuccessComponent } from './components/success/success.component';
import { DetailsComponent } from './components/details/details.component';
import { MyTranslatePipe } from 'src/app/pipes/mytanslate.pipe';
import { OurTutorsCardComponent } from './our-tutors-card/our-tutors-card.component';
import { OurTeamComponent } from '../our-team/our-team.component';
import { AssignmentDetailsComponent } from './components/assignment-details/assignment-details.component';
import { AssignmentfileInfoComponent } from './components/assignmentfile-info/assignmentfile-info.component';
import { GetmobileNoComponent } from './components/getmobile-no/getmobile-no.component';




@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModule.forRoot(),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl:'never'}),
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    FormsModule,
    MatCardModule,
    MatRadioModule,
    MatSliderModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    OnlyNumberDirective, ConfirmationBoxComponent,
    LoaderButtonComponent,
    FileUploaderProfileComponent,
    MyTranslatePipe, 
    LoaderButtonSmallComponent,
    FileUploaderComponent,OtpComponent,SuccessComponent,DetailsComponent, OurTutorsCardComponent, AssignmentDetailsComponent,AssignmentfileInfoComponent, GetmobileNoComponent

  ],
  exports: [
    MDBBootstrapModule,
    MyTranslatePipe,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MatChipsModule,
    MatSidenavModule,
    MatIconModule,
    FormsModule,
    MatAutocompleteModule,
    MatCardModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OnlyNumberDirective, 
    ConfirmationBoxComponent,
    LoaderButtonComponent,
    LoaderButtonSmallComponent,
    FileUploaderProfileComponent,
    FileUploaderComponent,OtpComponent,SuccessComponent,DetailsComponent, OurTutorsCardComponent,AssignmentDetailsComponent,AssignmentfileInfoComponent

  ],

  providers: [
  ],
  entryComponents: [
    OtpComponent,SuccessComponent,DetailsComponent, OurTutorsCardComponent,AssignmentDetailsComponent,AssignmentfileInfoComponent  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
