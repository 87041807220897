import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig } from '@angular/material';
import { PopupMessage } from '../../../../interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtil } from '../../../../util';
import { AppStateService } from '../../../../services/app-state.service';
import { AppService, HttpService } from '../../../../services';
import { Store, select } from '@ngrx/store';
import * as AppActions from '../../../../store/actions/app.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ERROR_MESSAGES, Regex, CONFIG, countries } from '../../../../constants';
import { Observable, Subscription } from 'rxjs';
import * as SignupActions from '../../../../store/actions/signup.actions';
import { IPROPERTY, AppState, ApiResponse } from '../../../../interfaces';
import { MatDialog } from '@angular/material';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  showDownload=false;
  constructor(
    //public _matDialogRef: MatDialogRef<ForgotComponent>,
    public dialogRef: MatDialogRef<DetailsComponent>,
    public _util: CommonUtil,
    private appState: AppStateService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private appSer: AppService,
    private _api: HttpService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
   
   
  }

  ngOnInit() {
console.log(this.data)

    this.downloadFile1 (this.data)
  }
  no() {
    this.dialogRef.close(false);
  }
  test(str){
    str = str.replace( /-/g, "" );
    return str;
  }
  saveFile = (data: Blob, filename, content) => {
    //////////console.log("->filename",filename)
    //////////console.log("->content",content)
    var file = new Blob([data], { type: content });
    saveAs(file,filename);
  };

  downloadFile1(data) {
   // alert(' called')
    //////////console.log(data)
    const booking_id = data._id;
    const user_id = data.user_id;
    this._api.getBooking(data._id)
      .subscribe(result => {
        //////////console.log(data._id)
        //////////console.log("result-->", result)
        this._api.getDownloadFile('uploads?user_id=' + user_id + '&request_id=' +booking_id)
          .subscribe(response => {
            
           const fileContent=  response.fileContent;
           const fileName = response.fileName;
           const contentType = response.contentType;
             //////////console.log('saving file')
             //////////console.log(response.fileName)
             if(response.fileName !=='not choosen'){
              this.showDownload=true;
             }
             else{
              this.showDownload=false;

            
             }
          //  this.saveFile(fileContent,fileName,contentType)
          })
      })
  }
  
  downloadFile(data) {
   ////// console.log(data)
    const booking_id = data._id;
    const user_id = data.user_id;
    this._api.getBooking(data._id)
      .subscribe(result => {
        //////////console.log(data._id)
        //////////console.log("result-->", result)
        this._api.getDownloadFile('uploads?user_id=' + user_id + '&request_id=' +booking_id)
          .subscribe(response => {
            
           const fileContent=  response.fileContent;
           const fileName = response.fileName;
           const contentType = response.contentType;
             //////////console.log(response)
            this.saveFile(fileContent,fileName,contentType)
          })
      })
  }
} 
 
// saveFile() {
//   const headers = new Headers();
//   headers.append('Accept', 'text/plain');
//   this.http.get('/api/files', { headers: headers })
//     .toPromise()
//     .then(response => this.saveToFileSystem(response));
// }

// private saveToFileSystem(response) {
//   const contentDispositionHeader: string = response.headers.get('Content-Disposition');
//   const parts: string[] = contentDispositionHeader.split(';');
//   const filename = parts[1].split('=')[1];
//   const blob = new Blob([response._body], { type: 'text/plain' });
//   saveAs(blob, filename);
// }
// }


