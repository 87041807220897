import { Component, OnInit, Inject,ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from "@angular/material";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { HttpService, AppService } from "../../../../services";

import { LoginComponent } from "../login/login.component";
import { IPROPERTY, AppState, ApiResponse } from "../../../../interfaces";
import { SuccessComponent } from "../success/success.component";
import { OtpComponent } from "../otp/otp.component";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {

  tmzone:any;
  value2: any;
  value3: any;
  value4: number;
  value5: number;
  value6:number;
  tmzoninnumber: number;
  tmzoninnumber2: number;
  finalvalue: any ;
  finalvalues:string;

  Location: any;
  country: any;
  countryCode: any

  signupForm: any;
  loaderButton;
  disabled = false;
  CONFIG = CONFIG;
  FORM_ERROR = {
    fullName: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },

    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`,
    },
    contactNo: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`,
    },
    // countryCode: {
    //   required: ERROR_MESSAGES.COUNTRYCODE_REQUIRED_MAIN,
    // },
    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    },
    // confirmPassword: {
    //   required: ERROR_MESSAGES.CONFIRM_PASSWORD,
    //   maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
    //   pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    //   matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH,
    // },
    type: {
      required: ERROR_MESSAGES.USER_TYPES,
    },
  };

  
  constructor(
    public _matDialogRef: MatDialogRef<LoginComponent>,
    public dialogRef: MatDialogRef<LoginComponent>,
    private _appSer: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _router: Router,
    private _api: HttpService,
    private _route: ActivatedRoute,
    public _util: CommonUtil
  ) {}

  ngOnInit() {
    this.createForm();
    this._api.getLocation().subscribe((Response)=>{
      this.Location=Response;
      this.country=this.Location.country_name;
      this.countryCode=this.Location.country_calling_code;
  
      this.tmzone=this.Location.utc_offset;
      this.tmzoninnumber= parseInt(this.tmzone);
  
      if(this.tmzoninnumber>0){
      this.value2=(this.tmzoninnumber / 100).toFixed(2);
      this.tmzoninnumber2=this.value2;
      this.value3= (this.tmzoninnumber2 + "").split(".");
      this.value4=parseInt(this.value3[1]);
      this.value6=this.value4 / 60;
      this.value5=parseInt(this.value3[0]);
      this.finalvalue=this.value5+this.value6;
      this.finalvalues=this.finalvalue.toString();
      //  console.log(this.finalvalue);
  
      }else if(this.tmzoninnumber<0){
        this.value2=(this.tmzoninnumber / 100).toFixed(2);
        this.tmzoninnumber2=this.value2;
  
        this.value3= (this.tmzoninnumber2 + "").split(".");
        this.value4=parseInt(this.value3[1]);
        this.value6=this.value4 / 60;
        this.value5=parseInt(this.value3[0]);
        this.finalvalue=this.value5-this.value6;
        this.finalvalues=this.finalvalue.toString();
        // console.log(this.finalvalue);
  
      }
  
    })
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`,
    };

    this._matDialogRef.updatePosition(matDialogConfig.position);

    // let stateObj = { id: "100" };
    // window.history.replaceState(stateObj, "index", "/register");
  

 
  }

  no() {
    this.dialogRef.close(false);
  }

  login(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "425px",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  createForm() {
    this.signupForm = this._fb.group(
      {
        fullName: [
          "",
          [
            Validators.required,
            Validators.maxLength(200),
            Validators.pattern(Regex.spacecharacter),
          ],
        ],
        email: ["", [Validators.required, Validators.pattern(Regex.email)]],
        // countryCode: ["", [Validators.required]],
        // country: ["", [Validators.required]],
        contactNo: [
          "",
          [
            Validators.required,
            Validators.minLength(CONFIG.MOBILE_MIN_LENGTH),
            Validators.pattern(Regex.phoneNumbers),
          ],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(Regex.password),
            Validators.pattern(Regex.spaces),
          ],
        ],

        type: ["1", [Validators.required]],
      },

    );
  }


  generatePassword(length){

    var digits = '0123456789'; 
    let pass = ''; 
    for (let i = 0; i < 8; i++ ) { 
      pass += digits[Math.floor(Math.random() * 10)]; 
    } 
    return pass; 
    }
  submit() {
  
    
    this.signupForm.get("password").setValue(this.generatePassword(9));


    if (this.signupForm.valid) {

      this.disabled = true;
     
      this.signupForm.value.origin_page = "https://ziyyara.com"+location.pathname;
      this.signupForm.value.u_type = this.signupForm.value.type;
      this.signupForm.value.name = this.signupForm.value.fullName.toString().trim();
      this.signupForm.value.country=this.country.trim();
      this.signupForm.value.countryCode=this.countryCode.trim();
      this.signupForm.value.timezone=this.finalvalues;

      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      this._api.postReqUnauth("users", this.signupForm.value).subscribe(
        (res) => this.success(res),
        (err) => this.error(err.message),
        () => ((this.loaderButton = false), (this.disabled = false))
      );
    } else {
      this._util.markError(this.signupForm);
    }
  }
  get fullName(): FormControl {
    return this.signupForm.get("fullName") as FormControl;
  }
  get email(): FormControl {
    return this.signupForm.get("email") as FormControl;
  }
  get password(): FormControl {
    return this.signupForm.get("password") as FormControl;
  }

  get type(): FormControl {
    return this.signupForm.get("type") as FormControl;
  }

  get contactNo(): FormControl {
    return this.signupForm.get("contactNo") as FormControl;
  }

  success(res: ApiResponse) {

  
    ////////console.log(res)
    if (res.status == true) {
      // this.dialogRef.close(false);
     
       const value = "Sign Up";
       
       const content = "Your registration has been done successfully! Please check your email for auto generated password to login";
       const dialogRef = this.dialog.open(SuccessComponent, {
         // maxWidth: "500px",
         width: "700px",
         height: "auto",
         data: {
           content: { title: content, heading: "Success" },
           action: "active"
         }
         // disableClose: true

         
       });
 
       this.sendEmailtoUser()
       dialogRef.afterClosed().subscribe(result => {
         this.signupForm.reset();
        dialogRef.close(false);
       });
     } 
       else{
         const message = res.message;
         this._appSer.addSnackBar({ message });
       }
      
     
   }

  successsResend(res) {

    this.signupForm.reset();
  }

  error(message) {
    this._appSer.addSnackBar({ message });
  }



  sendEmailtoUser(){
    ////console.log("sending email to suer")
    this._api.postReqUnauth('sendEmailToUser', this.signupForm.value)
    .subscribe(
      res => this.successEmail(res),
      err => this.errorEmail(err.message),
      () => this.loaderButton = false
    );
  }
  successEmail(res){
    //console.log("email sent")
  }
  errorEmail(msg){
    //console.log("")
  }

  ngAfterViewInit() {
    
   var firstElem= document.getElementsByClassName("mat-form-field-appearance-fill");
 
   var secondElem=document.getElementsByClassName("mat-form-field-flex");

   var thirdElem= document.getElementsByClassName("mat-form-field-infix");
   
   
    // .mat-form-field-appearance-fill .mat-form-field-infix
   
    for (var i = 0; i < firstElem.length; i++ ) {
      firstElem[i].classList.add("custom-flex");
    }
    for (var i = 0; i < secondElem.length; i++ ) {
      secondElem[i].classList.add("custom-flex");
    }


    for (var i = 0; i < firstElem.length; i++ ) {
      thirdElem[i].classList.add("custom-infix");
    }



  }




}
