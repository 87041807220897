import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-math-assignment',
  templateUrl: './math-assignment.component.html',
  styleUrls: ['./math-assignment.component.css', '../../languages/languages/languages.component.css']
})
export class MathAssignmentComponent implements OnInit {

  constructor(

    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

 

    ngOnInit() {
      var host=  location.pathname;
     
      this.metaService.createCanonicalURL(host);
    //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
      this.titleService.setTitle( " Maths help and Mathematics Assignment help Easily ");
   
     
      this.meta.updateTag({name:"description", content:"Ziyyara’s online tutors imparts various maths solving concept and problem solving tricks, that let students prepare mathematics assignment help easily."})
      this.meta.updateTag({name:"keywords", content:"maths problem solving, mathematics assignment help, maths assignment help, maths homework help, help me in maths, maths help online, online maths problem solving      "})
      this.metaService.removeStructuredData();``
     // this.metaService.insertSchema(this.websiteSchema);
     var ogTitle='Maths help and Mathematics Assignment help Easily';
     var ogDescription='Ziyyara’s online tutors imparts various maths solving concept and problem solving tricks, that let students prepare mathematics assignment help easily.'
       this.meta.updateTag({property:"og:title", content:ogTitle})
       this.meta.updateTag({property:"og:description", content:ogDescription})
       this.meta.updateTag({name:"twitter:title", content:ogTitle})
       this.meta.updateTag({name:"twitter:description", content:ogDescription});

   
   }
  }


