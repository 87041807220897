import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-exam-tips-assignment',
  templateUrl: './exam-tips.component.html',
  styleUrls: ['./exam-tips.component.css', '../languages/languages/languages.component.css']
})
export class ExamTipsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

 

    ngOnInit() {
      var host=  location.pathname;
     
      this.metaService.createCanonicalURL(host);
    //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
      this.titleService.setTitle( "Exam Preparation Tips and Tricks");
   
     
      this.meta.updateTag({name:"description", content:"Ziyyara's online tutors are helping students to excel in the exams, by providing examination preparation tips & tricks and exam preparation strategies."})
      this.meta.updateTag({name:"keywords", content:"exam preparation tips, tips for studying for exams, tips for preparing for exams, tips for exam preparation, exam preparation strategies, examination preparation tips"})
      this.metaService.removeStructuredData();``
     // this.metaService.insertSchema(this.websiteSchema);
     var ogTitle='Exam Preparation Tips and Tricks     ';
     var ogDescription="Ziyyara's online tutors are helping students to excel in the exams, by providing examination preparation tips & tricks and exam preparation strategies."
       this.meta.updateTag({property:"og:title", content:ogTitle})
       this.meta.updateTag({property:"og:description", content:ogDescription})
       this.meta.updateTag({name:"twitter:title", content:ogTitle})
       this.meta.updateTag({name:"twitter:description", content:ogDescription});
 
   
   }
  }


