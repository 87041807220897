import { Component, OnInit, OnDestroy, Input, Output, EventEmitter} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators, FormArray
} from "@angular/forms";
import { ERROR_MESSAGES, Regex, CONFIG } from "../../../constants";
import { CommonUtil } from "../../../util";
import { AppService, HttpService } from "../../../services";
import { ApiResponse, AppState, IPROPERTY } from "../../../interfaces";
import { Store, select } from "@ngrx/store";
import * as AppActions from "../../../store/actions/app.actions";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, timer, Observable } from "rxjs";
import { AppStateService } from "../../../services/app-state.service";
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';


@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.css"]
})
export class ViewProfileComponent implements OnInit {
  profileForm: FormGroup;
  images;
  reviewsCount;
  reviewsReview;
  @Input() rating: number;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  inputName1: string;
  inputName: string;
  imgSrc;
  type;
  loader;
  profileData:any;
  listing;
  per_days_hour;
  languages;
  timezoneTeacher;
  timezoneStudent;
  hoursDays=[];
  foods = [];
  property: Observable<IPROPERTY>;

  constructor(
    public util: CommonUtil,
    private appState: AppStateService,
    private _fb: FormBuilder,
    private store: Store<AppState>,
    private _appSer: AppService,
    private _api: HttpService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _util: CommonUtil,
    private titleService: Title,

  ) { }
  CONFIG = CONFIG;

  Experiences;
  Subjects;
  Topics;
  id;
  experience;
  reviews=[];
  ngOnInit() {
    this.titleService.setTitle('Profile | Ziyyara best Online Home tuition ');
    this.rating = 4;
    this.fetchSubjects();
    this._api.getReqAuth('language').subscribe(
      res => this.successL(res),
      err => this.error(err),
      () => this.loader = false
    );
    this.property = this.store.pipe(select<any>('applicationState'));
    this.property.subscribe((item) => {
      if (item && item['property']) {

        this.profileData = item['property'];
        this.timezoneStudent = item['property'].timezone;
       
      }
    });


    // this.createForm();
    this._route.params.subscribe((param) => {
      if (param && param['id']) {
        this.id = param['id'];
        this.getApplication(param['id']);
        this.fetchReviews();
        this.fetchReviewsCount();
      }

    });
    
  }
  successReviewCount(res: ApiResponse) {
    if (res.status == true) {
     
  ////////console.log(res.result)
  if(res.result.length>0){
  this.reviewsCount=res.result[0].rating;
  //alert(this.reviewsCount)
  this.reviewsReview=res.result[0].total_review;
  }else{
   // this._appSer.addSnackBar({message:res.message})
  }
    }else{
     // this._appSer.addSnackBar({message:res.message})
    }
  }
 
successReview(res: ApiResponse) {
  if (res.status == true) {
    this.reviews=res.result?res.result:[];
//////console.log(this.reviews)

  }else{
   // ////////console.log('Something went wrong');
  }
}

successL(res) {
  if (res.status == true) {
    this.languages=res.result?res.result:[];


  }else{
    
      if(res.code==900101){
        this._appSer.doblocked();
      }
    
   // ////////console.log('Something went wrong');
  }
}
  fetchSubjects() {
    this._api.getReqAuth('subject').subscribe(
      res => this.successSubject(res),
      err => this.error(err),
      () => this.loader = false
    );
  }
  fetchReviewsCount() {
    this._api.postReqAuth('rnr_user_review_count',{'user_id':this.id}).subscribe(
      res => this.successReviewCount(res),
      err => this.error(err),
      () => this.loader = false
    );
  }
  fetchReviews() {
    this._api.postReqAuth('rnr_user_review',{'user_id':this.id}).subscribe(
      res => this.successReview(res),
      err => this.error(err),
      () => this.loader = false
    );
  }
  successSubject(res) {
    if(res.status==true){
    this.Subjects = res.result;
    ////////console.log(this.Subjects)
    }
    
  }
  error(err: Error) {
    ////////console.log(err);
  }
  // convertTime(sec) {
  //   var hours = Math.floor(sec / 3600);
  //   (hours >= 1) ? sec = sec - (hours * 3600) : hours = Number('00');
  //   var min = Math.floor(sec / 60);
  //   (min >= 1) ? sec = sec - (min * 60) : min = 0;
  //   (sec < 1) ? sec = '00' : void 0;
  //   (min.toString().length == 1) ? min = 0 + min : void 0;
  //   (sec.toString().length == 1) ? sec = 0 + sec : void 0;
  //   hours = (hours > 24)?(hours-24):hours;
  //   if (min == 0) {
  //     return ((hours<10 )?"0"+hours:hours) + ':00';

  //   } else {
  //     return ((hours<10 )?"0"+hours:hours) + ':' + min;

  //   }
  // }

  convertTime(sec){ 
    /* extend the String by using prototypical inheritance */
     var seconds1 = parseInt(sec, 10); // don't forget the second param
     var hours1   = Math.floor(seconds1 / 3600);
     var minutes1 = Math.floor((seconds1 - (hours1 * 3600)) / 60);
     seconds1 = seconds1 - (hours1 * 3600) - (minutes1 * 60);
     ////////console.log(hours1)
    //////////console.log(this.te(hours1));
    if( hours1 > 0 && hours1<=23 ){
     if (hours1   < 10) {hours1   = Number("0"+hours1);}
     if (minutes1 < 10) {minutes1 = Number("0"+minutes1);}
     if (seconds1 < 10) {seconds1 = Number("0"+seconds1);}
     if(minutes1==0){
      var time    = hours1+':0'+minutes1;
     }else{
      var time    = hours1+':'+minutes1;
     }
     
     
     return time;
    } else if( hours1 == 0 || hours1 == 24){
     // ////////console.log('-----'+hours1)
     // if (hours1   < 10) {hours1   = Number("0"+hours1);}
      if (minutes1 < 10) {minutes1 = Number("0"+minutes1);}
      if (seconds1 < 10) {seconds1 = Number("0"+seconds1);}
      //var time    = '00:'+minutes1;
      if(minutes1==0){
        var time    = '00:0'+minutes1;
       }else{
        var time    = '00:'+minutes1;
       }
      return time;
     }
     else if( hours1 > 24 ){
       hours1=hours1-24;
       if (hours1   < 10) {hours1   = Number("0"+hours1);}
       if (minutes1 < 10) {minutes1 = Number("0"+minutes1);}
       if (seconds1 < 10) {seconds1 = Number("0"+seconds1);}
       var time    = hours1+':'+minutes1;
       if(minutes1==0){
        var time    = hours1+':0'+minutes1;
       }else{
        var time    = hours1+':'+minutes1;
       }
       return time;
      }
    else{
      var hours1=24+hours1;
      ////////console.log(hours1)
      if (hours1   < 10) {hours1   = Number("0"+hours1);}
      if (minutes1 < 10) {minutes1 = Number("0"+minutes1);}
      if (seconds1 < 10) {seconds1 = Number("0"+seconds1);}
      var time    = hours1+':'+minutes1;
      if(minutes1==0){
        var time    = hours1+':0'+minutes1;
       }else{
        var time    = hours1+':'+minutes1;
       }
      return time;
    }
    
     //var ho=this.te(hours1);
    // ////////console.log(hours1)
    
 }
 te(str){
  str.replace(/-/g, '');
 }

  getApplication(id) {
    this._api.getReqAuth('users_complete_profile/' + id)
      .subscribe((res) => {
        this.profileData = res.result;
          ////////console.log(this.profileData);
        if(this.profileData.if_teacher.teaching_experience==0){
        this.experience='Less than a year';
        }else if(this.profileData.if_teacher.teaching_experience==1){
          this.experience='1 year';
          }else{
          this.experience= this.profileData.if_teacher.teaching_experience+' years';
        }
        this.per_days_hour = this.profileData.if_teacher.per_day_hours;
        this.timezoneTeacher = this.profileData.timezone;

        if(this.timezoneTeacher!="" && this.timezoneStudent!=""){
        var test2 = this.timezoneStudent * 3600;
        var test3 = this.timezoneTeacher * 3600;
     //   alert(parseInt(this.timezoneTeacher)-parseInt(this.timezoneStudent))
        var difference = test2 - test3;
       // alert(difference)
        for (let child of this.per_days_hour) {
          var hours = child;
          var timeInminutes = Number(hours * 60 * 60);
          var data1 = timeInminutes +difference;
        //  alert(data1)
          var data = this.convertTime(data1);
          ////////console.log(data)
          var endData = this.convertTime(data1+3600);
          this.hoursDays.push({ 'start': data, 'end': endData });
         }
        }
      }
      );

  }
  objectFindByKeyS(element) {
    var array = this.Subjects;
    for (var i = 0; i < array.length; i++) {
      if (array[i]._id == element) {
        return array[i].subject;
      }
    }
  }
  objectFindByKeyL(element) {
    var array = this.languages;
    for (var i = 0; i < array.length; i++) {
      if (array[i]._id == element) {
        return array[i].language;
      }
    }
  }

}

