import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material";
import {
  FormBuilder,
  FormControl,
  FormGroup, 
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginComponent } from '../login/login.component';
import { ResetComponent } from '../reset/reset.component';
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { HttpService,AppService } from "../../../../services";
import { ApiResponse, AppState, IPROPERTY } from "../../../../interfaces";


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  forgotForm: any;
  CONFIG = CONFIG;
  errorMessage;
  loader=false;
  FORM_ERROR = {
    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern:ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`
    },
   
  };
  constructor(public _matDialogRef: MatDialogRef<ForgotComponent>,
    public dialogRef: MatDialogRef<ForgotComponent>,
    private _appSer: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private _fb: FormBuilder, private _router: Router,private _api:HttpService,private _route: ActivatedRoute, public _util: CommonUtil) { }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
    };
    this._matDialogRef.updatePosition(matDialogConfig.position);
    this.createForm();
    
  }
  /*****************Show signup and login popup************************* */
  login(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '425px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  reset(email): void {
  //  alert(email)
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(ResetComponent, {
      width: '425px',
      height: 'auto',
      data:{  content: { title: email }}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  /*****************End Show signup and login popup************************* */
  createForm() {
    this.forgotForm = this._fb.group({
      email: ["", [Validators.required, Validators.pattern(Regex.email)]],
    });
  }

  get email(): FormControl {
    return this.forgotForm.get("email") as FormControl;
  }
  submit() {
    if (this.forgotForm.valid) {
     
      this.loader = true;

      var stripped = this.forgotForm.value.email;

  
      this._api
        .postReqUnauth("users_fp", this.forgotForm.value)
        .subscribe(
          res => this.success(res,stripped),
          err => this.error(err),
          () => (this.loader = false)
        );
    } else {
      this._util.markError(this.forgotForm);
    }
  }

  success(res: ApiResponse,stripped) {
    if (res.status == true) {
     
        this.reset(stripped);
        this._appSer.addSnackBar({ message: "Your otp has been sent to your mail account." });
    
    } else {
      this._appSer.addSnackBar({message:'Email id not exist '});
    
    }
  }

 
  error(err) {
    this._util.message(err.message);
  }
}
