import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-thesis-writing',
  templateUrl: './thesis-writing.component.html',
  styleUrls: ['./thesis-writing.component.css', '../languages/languages/languages.component.css']
})
export class ThesisWritingComponent implements OnInit {
  websiteSchema=
{

    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How long is a thesis?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There’s no exact page count of a thesis. As per experts bachelor's thesis is often 40–60 pages long. A diploma thesis and a master's thesis usually lies between 60–100 pages."
      }
    },{
      "@type": "Question",
      "name": "How much should a thesis cost?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To know the price of an undergraduate, master's or doctoral thesis at Ziyyara, you just have to register with a few easy steps. Or else you can directly chat with us on WhatsApp and can also call our experts on +91-9654271931."
      }
    },{
      "@type": "Question",
      "name": "What words start a thesis statement?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A thesis statement is the most important part of a thesis. It‘s better to start writing thesis statements with a question word like why, where, who, how, etc. Remember  to start your thesis statement by using keywords in your question."
      }
    },{
      "@type": "Question",
      "name": "What is a thesis statement in an essay?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In simple terms a thesis statement in an essay is a short statement which indicates the gist of the research paper or essay that directly answers the thesis question."
      }
    },{
      "@type": "Question",
      "name": "How long should my thesis statement be?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you wonder, “how long is a thesis statement”? Generally it should not be longer than two lines, the maximum word limit should be between 30 to 40 words only."
      }
    },{
      "@type": "Question",
      "name": "How do you write a thesis?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The thesis writing requires an ample amount of technical knowledge along with research skills. However you can follow some steps in order to write a thesis: * Enunciate your topic  * Express your main idea about the topic  * Provide at least 3 reasons to support your main idea * Incorporate an opposing frame of reference into your main idea, if applicable."
      }
    },{
      "@type": "Question",
      "name": "How do you make a thesis step by step?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In order to write a thesis step by step, follow the below-mentioned points: *Start with a vague, poorly articulated question. *Don’t take the time to see what research has already been done regarding the question. *Collect the data and opinions that support your gut and undertake a flimsy analysis. *Draw a conclusion, based on that analysis."

      }
    }]
}
  constructor(
    private titleService: Title,
    private metaService: MetaServicesService , 
    private meta: Meta
  ) { }

 

    ngOnInit() {
      var host=  location.pathname;
     
      this.metaService.createCanonicalURL(host);
    //  this.titleService.setTitle( "Online Arabic Classes in Australia | Speak, Read, Write, Communicate ");
      this.titleService.setTitle( "Thesis Writing Services | Get 10% Discount on First Order");
      this.meta.updateTag({name:"description", content:"Ziyyara provides online thesis writing services at an affordable price. We have a team of professionals who are quite specialized in Writing thesis services."})
      this.meta.updateTag({name:"keywords", content:"thesis statement example, thesis statement format, thesis statement meaning, thesis writing services, thesis writing skills, thesis writing help online, online help for thesis writing, Best Online Thesis Writing, Online Tuition For Thesis, Online Classes For Thesis Writing, thesis writing help, online thesis writing, what is thesis writing "})
      this.metaService.removeStructuredData();
     // this.metaService.insertSchema(this.websiteSchema);
       var ogTitle='Thesis Writing Services | Get 10% Discount on First Order';
       var ogDescription="Ziyyara provides online thesis writing services at an affordable price. We have a team of professionals who are quite specialized in Writing thesis services."
       this.meta.updateTag({property:"og:title", content:ogTitle})
       this.meta.updateTag({property:"og:description", content:ogDescription})
       this.meta.updateTag({name:"twitter:title", content:ogTitle})
       this.meta.updateTag({name:"twitter:description", content:ogDescription});
       this.metaService.insertSchema(this.websiteSchema);
 
   
   }
  }


