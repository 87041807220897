<div class="pg-footer">
    <footer class="footer" style="padding: 0px !important;">
        <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
      </svg>
        <div class="footer-content">
            <div class="footer-content-column">
                <div class="footer-logo">
                    <a class="footer-logo-link" href="#">
                        <span class="hidden-link-text">LOGO</span>
                        <img src="https://ziyyara.com/assets/images/ZiyyaraLogo.png" alt="" width="58">
                    </a>
                </div>
                <div class="footer-menu">
                    <h2 class="footer-menu-name">Get Online Tuition</h2>
                    <ul id="menu-get-started" class="footer-menu-list">
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/best-online-tuition-in-bahrain" class="cool-link">Tuition in Bahrain</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-qatar" class="cool-link">Tuition in Qatar</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-kuwait" class="cool-link">Tuition in Kuwait</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-oman" class="cool-link">Tuition in Oman</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-uae" class="cool-link">Tuition in UAE</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-malaysia" class="cool-link">Tuition in Malaysia</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-maldives" class="cool-link">Tuition in Maldives</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-singapore" class="cool-link">Tuition in Singapore</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-thailand" class="cool-link">Tuition in Thailand</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-hong-kong" class="cool-link">Tuition in Hong Kong</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-product">
                            <a routerLink="/tuition/online-home-tuition-in-saudi-arabia" class="cool-link">Tuition in Saudi Arabia</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name">English Languages</h2>
                    <ul id="menu-company" class="footer-menu-list">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/english/online-english-classes-in-bahrain" class="cool-link">English Language in Bahrain</a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                            <a routerLink="/languages/english/online-english-classes-in-qatar" class="cool-link">English Language in Qatar</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/english/online-english-classes-in-oman" class="cool-link">English Language in Oman</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/english/online-english-classes-in-uae" class="cool-link">English Language in UAE</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/english/online-english-classes-in-saudi-arabia" class="cool-link">English Language in Saudi Arabia</a>
                        </li>

                    </ul>
                </div>
                <div class="footer-menu">
                    <h2 class="footer-menu-name">Arabic Languages</h2>
                    <ul id="menu-legal" class="footer-menu-list">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-uk" class="cool-link">Arabic Language in UK</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-us" class="cool-link">Arabic Language in US</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-australia" class="cool-link">Arabic Language in Australia</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-canada" class="cool-link">Arabic Language in Canada</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-jordan" class="cool-link">Arabic Language in Jordan</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-turkey" class="cool-link">Arabic Language in Turkey</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-indonesia" class="cool-link">Arabic Language in Indonesia</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-malaysia" class="cool-link">Arabic Language in Malaysia</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a routerLink="/languages/arabic/online-arabic-classes-in-bangladesh" class="cool-link">Arabic Language in Bangladesh</a>
                        </li>


                    </ul>
                </div>
            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name">Learn Languages</h2>
                    <ul id="menu-quick-links" class="footer-menu-list">
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="/languages/arabic">Arabic Language</a>
                        </li>
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="/languages/english">English Language</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/hindi" class="cool-link">Hindi Language</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/french" class="cool-link">French Language</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="/languages/german" class="cool-link">German Language</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/languages/spanish" class="cool-link">Spanish Language</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name">Our Pages</h2>
                    <ul id="menu-quick-links" class="footer-menu-list">
                        <li class="menu-item menu-item-type-custom menu-item-object-custom ">
                            <a class="cool-link" rel="noopener noreferrer" routerLink="/faq">FAQ's</a>
                        </li>
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a class="cool-link" rel="noopener noreferrer" href="https://ziyyara.com/blog" target="_blank">Blogs</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a class="cool-link" routerLink="/career">Career</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/contact-us" class="cool-link">Contact Us</a>
                        </li>
                        <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/leadership-team" class="cool-link">Leaders</a>
                        </li> -->
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="/find-tutors" class="cool-link">Tutors</a></li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/investor-relations" class="cool-link">Investors</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a href="/assets/sitemap.xml" class="cool-link">Sitemap</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/privacy-policy" class="cool-link">Privacy Policy </a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/terms-conditions" class="cool-link">Terms and Condition </a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="/ziyyara-foundation" class="cool-link">You Learn They Learn </a>
                        </li>
                    </ul>
                </div>
                <!-- <div class="footer-call-to-action">
                    <h2 class="footer-call-to-action-title">Our Pages</h2>
                    <p class="footer-call-to-action-description"> FAQ's</p>
                    <a class="footer-call-to-action-button button" href="#" target="_self"> Get in Touch </a>
                </div>
                <div class="footer-call-to-action">
                    <h2 class="footer-call-to-action-title"> You Call Us</h2>
                    <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:0124-64XXXX" target="_self"> 0124-64XXXX </a></p>
                </div> -->
            </div>
            <!-- <div class="footer-social-links"> 
                
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55 55" style="enable-background:new 0 0 504 504;" xml:space="preserve" width="55" height="55"><g><g><path d="M41.206 0H13.75C6.198 0 0 6.198 0 13.794V41.25c0 7.552 6.198 13.75 13.75 13.75h27.456c7.595 0 13.794 -6.198 13.794 -13.794V13.794C55 6.198 48.802 0 41.206 0zM34.877 27.5H29.683v17.024h-6.548V27.5h-3.492v-6.984h3.056v-2.968c0 -2.794 1.397 -7.202 7.29 -7.202H35.357V16.151h-3.798c-0.611 0 -1.44 0.393 -1.44 1.746v2.619h5.369L34.877 27.5z"/></g></g></svg>
           
           

           
            </div> -->
        </div>

<!-- <div > -->
    <div class="social-icons text-right menu_17 text_m_right  icon-c">

        <a href="https://www.facebook.com/Ziyyara_learning-304325823599424/?modal=admin_todo_tour" class="facebook social-icon">
            <i class="fa fa-facebook mr-3 size-font"></i>
        </a>
    

        <a href="https://www.instagram.com/ziyyaralearning/" class="instagram social-icon">
            <i class="fa fa-instagram mr-3 size-font"></i>
        </a>
    

        <a href="https://twitter.com/ZiyyaraLearning" class="twitter social-icon">
            <i class="fa fa-twitter mr-3 size-font"></i>
        </a>
    

        <a href="https://in.linkedin.com/company/ziyyara" class="linkedin social-icon">
            <i class="fa fa-linkedin mr-3 size-font"></i>
        </a>
    

        <a href="https://www.youtube.com/channel/UCzf9w3sDmfXEG_vZt3x285Q" class="youtube social-icon">
            <i class="fa fa-youtube mr-3 size-font"></i>
        </a>
    
</div>
        <div class="footer-copyright">
            <div class="footer-copyright-wrapper">
                <p class="footer-copyright-text">
                    <a class="footer-copyright-link" href="#" target="_self"> &copy; Since 2018, ZIYYARA. All Rights Reserved. </a>
                </p>
            </div>
            <!-- <div class="top-right-menu"> -->
                
                    <!-- <li>
                        <a href="https://in.linkedin.com/company/ziyyara" class="linkedin social-icon">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </li> -->

                </div>
            <!-- </div> -->
        <!-- </div> -->
    </footer>
</div>