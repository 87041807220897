

<div class="modal-wrapper">
  
  <div class="success-modal">
    <div class="success-inner">
      <img src="assets/images/modal/success.svg">    </div>
    <div class="">
      <h4>{{data?.content?.heading}}
       </h4>
      <p>{{data?.content?.title}}</p>
    </div>
  </div>
 </div>