import { Component, OnInit ,Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig } from '@angular/material';
import { PopupMessage } from '../../../../interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtil } from '../../../../util';
import { AppStateService } from '../../../../services/app-state.service';
import { AppService, HttpService } from '../../../../services';
import { Store, select } from '@ngrx/store';
import * as AppActions from '../../../../store/actions/app.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ERROR_MESSAGES, Regex, CONFIG, countries } from '../../../../constants';
import { Observable, Subscription } from 'rxjs';
import * as SignupActions from '../../../../store/actions/signup.actions';
import { IPROPERTY, AppState, ApiResponse } from '../../../../interfaces';
import { MatDialog } from '@angular/material';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.css']
})
export class AssignmentDetailsComponent implements OnInit {
  showDownload=false;
  constructor(
    public dialogRef: MatDialogRef<AssignmentDetailsComponent>,
    public _util: CommonUtil,
    private appState: AppStateService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private appSer: AppService,
    private _api: HttpService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { }
  assign_doc;
  ngOnInit() {

     //console.log(this.data);
  // this.assign_doc=this.data

  this.assign_doc=this.data.content.assignment_file;
  //console.log(this.assign_doc);


  }

   
  yes() {
    this.dialogRef.close({ response: true });
  }


  no() {
    this.dialogRef.close({ response: false });
  }


  // saveFile = (data: Blob, filename, content) => {
  //   //////////console.log("->filename",filename)
  //   //////////console.log("->content",content)
  //   var file = new Blob([data], { type: content });
  //   saveAs(file,filename);
  // };

  downloadFile(data) {

    if(data==true){
      console.log(data);
      console.log(data.content)

    }
    // console.log(data)
    //  const booking_id = data._id;
    //  const user_id = data.user_id;
    //  this._api.getBooking(data._id)
    //    .subscribe(result => {
    //      //////////console.log(data._id)
    //      //////////console.log("result-->", result)
    //      this._api.getDownloadFile('uploads?user_id=' + user_id + '&request_id=' +booking_id)
    //        .subscribe(response => {
             
    //         const fileContent=  response.fileContent;
    //         const fileName = response.fileName;
    //         const contentType = response.contentType;
    //           //////////console.log(response)
    //          this.saveFile(fileContent,fileName,contentType)
    //        })
    //    })
  }

}
