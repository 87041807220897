import { Component, OnInit, Inject } from '@angular/core';




  



import { MatDialog } from "@angular/material";
import * as AOS from 'aos';
@Component({
  selector: 'app-online-tuition-anytime',
  templateUrl: './online-tuition-anytime.component.html',
  styleUrls: ['./online-tuition-anytime.component.css']
})
export class OnlineTuitionAnytimeComponent implements OnInit {

  constructor(  ) { }

  ngOnInit() {
   
    AOS.init({
      duration: 1200,
      delay: 200,
      once: true
    });
  }

}