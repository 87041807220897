<app-header-front></app-header-front>

<div class="how-it-works">
    <div class="bgc">
        <div class="pdbt">
            <h1 class="arttext">
                11th & 12th Grade Chapters (Science) </h1>
        </div>
    </div>
    <div class="fixhd " id="stickyHeader">
        <div class="bgc">
            <div class="stpdbt">
                <h4 class="center">
                    11th & 12th Grade Chapters (Science) </h4>
            </div>
        </div>
    </div>
    <div class="mcm">
        <h1 class="center"> 11th Grade Chapters </h1>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/cbse/icon-english.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        English</h5>

                    <ol>
                        <div class="df">
                            <div>
                                <h5> Book - Hornbill Prose </h5>

                                <li> Chapter 1 The Portrait of a Lady </li>
                                <li> Chapter 2 We’re Not Afraid to Die… If We Can All Be Together </li>
                                <li> Chapter 3 Discovering Tut: The Saga Continues </li>
                                <li> Chapter 4 Landscape of the Soul </li>
                                <li> Chapter 5 The Ailing Planet: the Green Movement’s Role </li>
                                <li> Chapter 6 The Browning Version </li>
                                <li> Chapter 7 The Adventure </li>
                                <li> Chapter 8 Silk Road </li>
                            </div>
                            <div>
                                <h5> Book - Hornbill Poem </h5>

                                <li> Poem 1 A Photograph </li>
                                <li> Poem 2 The Laburnum Top </li>
                                <li> Poem 3 The Voice of the Rain </li>
                                <li> Poem 4 Childhood </li>
                                <li> Poem 5 Father to Son </li>
                            </div>
                            <div>
                                <h5> Book - Snapshots </h5>

                                <li> Chapter 1 The Summer of the Beautiful White Horse </li>
                                <li> Chapter 2 The Address </li>
                                <li> Chapter 3 Ranga’s Marriage </li>
                                <li> Chapter 4 Albert Einstein at School </li>
                                <li> Chapter 5 Mother’s Day </li>
                                <li> Chapter 6 The Ghat of the Only World </li>
                                <li> Chapter 7 Birth </li>
                                <li> Chapter 8 The Tale of Melon City </li>
                            </div>
                        </div>
                    </ol>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>




        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-math.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Maths </h5>
                    <ol>
                        <div class="df">
                            <div>
                                <h5> Chapter 1 Sets </h5>

                                <li> Chapter 2 Relations and Functions </li>
                                <li> Chapter 3 Trigonometric Functions </li>
                                <li> Chapter 4 Principle of Mathematical Induction </li>
                                <li> Chapter 5 Complex Numbers and Quadratic Equations </li>
                                <li> Chapter 6 Linear Inequalities </li>
                                <li> Chapter 7 Permutation and Combinations </li>

                            </div>
                            <div>
                                <li> Chapter 8 Binomial Theorem </li>
                                <li> Chapter 9 Sequences and Series </li>

                                <li> Chapter 10
                                    <ul>
                                        <li> Straight Lines Exercise 10.1 </li>
                                        <li> Straight Lines Exercise 10.2 </li>
                                        <li> Straight Lines Exercise 10.3 </li>
                                        <li> Straight Lines Exercise Miscellaneous Questions </li>
                                    </ul>

                                </li>
                            </div>
                            <div>
                                <li> Chapter 11 Conic Sections </li>
                                <li> Chapter 12 Introduction to Three Dimensional Geometry </li>
                                <li> Chapter 13 Limits and Derivatives </li>
                                <li> Chapter 14 Mathematical Reasoning </li>
                                <li> Chapter 15 Statistics </li>
                                <li> Chapter 16 Probability </li>

                            </div>
                        </div>
                    </ol>


                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-bio.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Biology </h5>
                    <ol>
                        <div class="df">
                            <div>
                                <li> Chapter 1 The Living World </li>
                                <li> Chapter 2 Biological Classification </li>
                                <li> Chapter 3 Plant Kingdom </li>
                                <li> Chapter 4 Animal Kingdom </li>
                                <li> Chapter 5 Morphology of Flowering Plants </li>
                                <li> Chapter 6 Anatomy of Flowering Plants </li>
                                <li> Chapter 7 Structural Organisation in Animals </li>
                            </div>
                            <div>
                                <li> Chapter 8 Cell The Unit of Life </li>
                                <li> Chapter 9 Biomolecules </li>
                                <li> Chapter 10 Cell Cycle and Cell Division </li>
                                <li> Chapter 11 Transport in Plants </li>
                                <li> Chapter 12 Mineral Nutrition </li>
                                <li> Chapter 13 Photosynthesis in Higher Plants </li>
                                <li> Chapter 14 Respiration in Plants </li>
                            </div>

                            <div>
                                <li> Chapter 15 Plant Growth and Development </li>
                                <li> Chapter 16 Digestion and Absorption </li>
                                <li> Chapter 17 Breathing and Exchange of Gases </li>
                                <li> Chapter 18 Body Fluids and Circulation </li>
                                <li> Chapter 19 Excretory Products and their Elimination </li>
                                <li> Chapter 20 Locomotion and Movement </li>
                                <li> Chapter 21 Neural Control and Coordination </li>
                                <li> Chapter 22 Chemical Coordination and Integration </li>
                            </div>

                        </div>
                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-physics.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Physics </h5>
                    <ol>
                        <div class="df">
                            <div>
                                <li> Chapter 1 Physical World </li>
                                <li> Chapter 2 Units and Measurements </li>
                                <li> Chapter 3 Motion in a Straight Line </li>
                                <li> Chapter 4 Motion in a plane </li>
                                <li> Chapter 5 Laws of motion </li>
                            </div>
                            <div>
                                <li> Chapter 6 Work Energy and power </li>
                                <li> Chapter 7 System of particles and Rotational Motion </li>
                                <li> Chapter 8 Gravitation </li>
                                <li> Chapter 9 Mechanical Properties Of Solids </li>
                                <li> Chapter 10 Mechanical Properties Of Fluids </li>
                            </div>
                            <div>
                                <li> Chapter 11 Thermal Properties of matter </li>
                                <li> Chapter 12 Thermodynamics </li>
                                <li> Chapter 13 Kinetic Theory </li>
                                <li> Chapter 14 Oscillations </li>
                                <li> Chapter 15 Waves </li>
                            </div>
                        </div>

                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>






        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-chemistry.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Chemistry </h5>
                    <ol>
                        <div class="df">

                            <div>
                                <li> Chapter 1 Some Basic Concepts of Chemistry </li>
                                <li> Chapter 2 Structure of The Atom </li>
                                <li> Chapter 3 Classification of Elements and Periodicity in Properties </li>
                                <li> Chapter 4 Chemical Bonding and Molecular Structure </li>
                                <li> Chapter 5 States of Matter </li>
                            </div>
                            <div>
                                <li> Chapter 6 Thermodynamics </li>
                                <li> Chapter 7 Equilibrium </li>
                                <li> Chapter 8 Redox Reactions </li>
                                <li> Chapter 9 Hydrogen </li>
                                <li> Chapter 10 The sBlock Elements </li>
                            </div>
                            <div>
                                <li> Chapter 11 The pBlock Elements </li>
                                <li> Chapter 12 Organic Chemistry: Some Basic Principles and Techniques </li>
                                <li> Chapter 13 Hydrocarbons </li>
                                <li> Chapter 14 Environmental Chemistry </li>
                            </div>

                        </div>


                    </ol>

                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>


    </div>
    <div class="mcm">
        <h1 class="center"> 12th Grade Chapters </h1>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/cbse/icon-english.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        English</h5>

                    <ol>
                        <div class="df">
                            <div>






                                <h5> English Flamingo Prose </h5>

                                <li> The Last Lesson </li>
                                <li> Lost Spring </li>
                                <li> Deep Water </li>
                                <li> The Rattrap </li>
                                <li> Indigo </li>
                                <li> Poets and Pancakes </li>
                                <li> The Interview </li>
                                <li> Going Places </li>
                            </div>
                            <div>
                                <h5> English Flamingo Poem </h5>

                                <li> My Mother at Sixty-six </li>
                                <li> An Elementary School Classroom in a Slum </li>
                                <li> Keeping Quiet </li>
                                <li> A Thing of Beauty </li>
                                <li> A Roadside Stand </li>
                                <li> Aunt Jennifer’s Tigers </li>
                            </div>
                            <div>
                                <h5> English Vistas </h5>

                                <li> Third Level </li>
                                <li> The Tiger King </li>
                                <li> The Enemy </li>
                                <li> Journey to the End of the Earth </li>
                                <li> Should a Wizard Hit Mommy? </li>
                                <li> On the Face of It </li>
                                <li> Chapter 7 Evans Tries an O-level </li>
                                <li> Chapter 8 Memories of Childhood </li>
                            </div>

                        </div>
                    </ol>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>




        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-math.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        Maths
                    </h5>




                    <ol>
                        <div class="df">
                            <div>

                                <li> Relations and Functions </li>
                                <li> Inverse Trigonometric Functions </li>
                                <li> Matrices </li>
                                <li> Determinants </li>

                            </div>
                            <div>


                                <li> Continuity and Differentiability </li>
                                <li> Application of Derivatives </li>
                                <li> Integrals </li>
                                <li> Application of Integrals </li>
                                <li> Differential Equations </li>





                            </div>
                            <div>


                                <li> Vector Algebra </li>
                                <li> Three Dimensional Geometry </li>
                                <li> Linear Programming </li>
                                <li> Probability </li>

                            </div>




                        </div>
                    </ol>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-bio.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Biology </h5>
                    <ol>
                        <div class="df">
                            <div>



                                <li> Reproduction in Organisms </li>
                                <li> Sexual Reproduction in Flowering Plants </li>
                                <li> Human Reproduction </li>
                                <li> Reproductive Health </li>
                                <li> Principles of Inheritance and Variation </li>
                                <li> Molecular Basis of Inheritance </li>


                            </div>
                            <div>
                                <li> Evolution </li>
                                <li> Human Health and Disease </li>
                                <li> Strategies for Enhancement in Food Production </li>
                                <li> Microbes in Human Welfare </li>
                                <li> Biotechnology:Principles And Processes </li>
                                <li> Biotechnology and its Applications </li>
                            </div>
                            <div>
                                <li> Organisms and Populations </li>
                                <li> Ecosystem </li>
                                <li> Biodiversity and Conservation </li>
                                <li> Environmental Issues </li>
                            </div>
                        </div>
                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-physics.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Physics </h5>
                    <ol>
                        <div class="df">
                            <div>



                                <li> Electric Charges And Fields </li>
                                <li> Electrostatic Potential And Capacitance </li>
                                <li> Current Electricity </li>
                                <li> Moving Charges And Magnetism </li>
                                <li> Magnetism And Matter </li>
                                <li> Electromagnetic Induction </li>





                            </div>
                            <div>
                                <li> Alternating Current </li>
                                <li> Electromagnetic Waves </li>
                                <li> Ray Optics and Optical Instruments </li>
                                <li> Wave Optics </li>
                                <li> Dual Nature Of Radiation And Matter </li>
                                <li> Atoms </li>

                            </div>
                            <div>
                                <li> Nuclei </li>
                                <li> Semiconductor Electronics Materials Devices And Simple Circuits </li>
                                <li> Communication Systems </li>
                            </div>
                        </div>

                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>






        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-chemistry.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Chemistry </h5>
                    <ol>
                        <div class="df">

                            <div>
                                <li> The Solid State </li>
                                <li> Solutions </li>
                                <li> Electrochemistry </li>
                                <li> Chemical Kinetics </li>
                                <li> Surface Chemistry </li>
                                <li> General Principles and Processes of Isolation of Elements </li>






                            </div>
                            <div>

                                <li> The p Block Elements </li>
                                <li> The d and f Block Elements </li>
                                <li> Coordination Compounds </li>
                                <li> Haloalkanes and Haloarenes </li>
                                <li> Alcohols Phenols and Ethers </li>


                            </div>
                            <div>
                                <li> Aldehydes Ketones and Carboxylic Acids </li>
                                <li> Amines </li>
                                <li> Biomolecules </li>
                                <li> Polymers </li>
                                <li> Chemistry in Everyday Life </li>


                            </div>
                        </div>


                    </ol>

                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>


    </div>



</div>


<app-footer-front></app-footer-front>