import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common'; 
import { SharedModule } from '../../shared/shared.module';
import { CommonComponentModule } from '../../common-component/common-component.module';
import { AngularMaterialModule } from '../../../angular-material/angular-material.module';
import { MathAssignmentComponent } from './math-assignment.component';

const pageRoutes: Routes = [

    { path: '', component: MathAssignmentComponent },
   
  ]; 
@NgModule({
  imports:[
    CommonComponentModule,
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [MathAssignmentComponent],
  entryComponents: []
})

export class MathAssignmentsModule {

}


