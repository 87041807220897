<app-header-front></app-header-front>

<div class="padd_top"></div>

<div class="main-section">
    <div class="flex">

        <div class=" first-part">
            <div class="hero-content">
                <img class="img-bg" src="/assets/images/images/teacher-signup.png">

            </div>

        </div>
        <div class="second-part">
            <div class="reg-part">
                <div class="popup-form-inner">
                    <div class="popup-form-role">
                        <a routerLink="/studentSignup" class="inactive"> Student </a>
                        <a routerLink="/teacherSignup" class="active"> Teacher </a>
                    </div>
                    <div class="popup-form-heading">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>{{"signup.register" | mytranslate}} As Teacher</h5>
                            <!-- <span (click)="no()">
              <i class="fa fa-close color-primary cursor-pointer"></i>
            </span>  -->
                        </div>
                        <!-- <span>Please  to your Ziyyara Account</span> -->
                    </div>
                    <form id="Login" [formGroup]="signupForm" (ngSubmit)="submit()">

                        <!-- <div class="form-group">
              <div class="mat-app-background basic-container">
                <mat-radio-group [formControl]="type">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button  value="1">{{"signup.reg_as_student" | mytranslate}}</mat-radio-button>
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button value="2">{{"signup.reg_as_tutor" | mytranslate}}</mat-radio-button>
                    </div>
                  </div>
                  <mat-error>{{_util.errorMessage(type,FORM_ERROR?.type)}}</mat-error>
                </mat-radio-group>
              </div>
            </div> -->



                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                    <mat-label>{{"signup.ful_name" | mytranslate}}</mat-label>
                                    <input matInput type="text" placeholder="" [formControl]="fullName" [maxlength]="CONFIG?.NAME_LENGTH">
                                </mat-form-field>
                                <mat-error>{{_util.errorMessage(fullName,FORM_ERROR?.fullName)}}</mat-error>

                            </div>
                        </div>


                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                    <mat-label>{{"signup.email" | mytranslate}}</mat-label>
                                    <input matInput type="email" [formControl]="email" placeholder="" [maxlength]="CONFIG?.EMAIL_LENGTH">
                                </mat-form-field>
                                <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="mat-app-background basic-container">

                                <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                    <mat-label>{{"signup.countryCode" | mytranslate}}</mat-label>
                                    <input matInput type="text" placeholder="" name="extra_country" value="{{countryCode}} {{country}}" readonly>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <mat-form-field appearance="fill" autocomplete='disabled' class="mat-form-custom padding-10px">
                                    <mat-label>Mobile no</mat-label>
                                    <input matInput appOnlyNumber [formControl]="contactNo" [maxlength]="CONFIG?.MOBILE_LENGTH">
                                </mat-form-field>
                                <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                            </div>
                        </div>




                        <div class="mb-2">
                            <button type="submit" [disabled]="disabled" class="btn m-0 btn-ziyyara">{{"signup.register" | mytranslate}}</button>
                            <!-- <input id="submit_form" class="btn m-0 btn-ziyyara" type="submit" value="Register" /> -->
                        </div>


                    </form>
                    <p class="text-center pd-22"><span class="reola-tc-size-bold">{{"signup.already_registered" | mytranslate}} </span>&nbsp; <a class="hrefclass befault-color" routerLink="/login">{{"signup.login_title" | mytranslate}}
          </a></p>








                </div>
            </div>
        </div>
    </div>


</div>



<app-footer-front></app-footer-front>