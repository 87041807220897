<app-test></app-test>
<header>
    <div class="em40_header_area_main">
        <div class="poket-header-top">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-8">
                        <div class="top-address text-left menu_17">
                            <p>
                                <a routerLink="/ad-contact"> <span>REQUEST A FREE DEMO  <i class="fa fa-hand-o-right " style="margin-left: 5px; font-size: 18px;"></i></span></a>
                                <a href="tel:9654271931"> <img alt="no-img" src="/assets/images/flags/IN-India-Flag-icon.png" width="20" height="26" class="flag-icn-set"> &nbsp;<i class="fa fa-phone"></i>+91-9654271931
                                </a>
                                <!-- <a href="#">
                                    <i class="fa fa-phone"></i> +968-71912179
                                </a> -->
                            </p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-sm-12 col-lg-4">
                        <div class="top-right-menu">
                            <ul class="social-icons text-right menu_17 text_m_right">
                                <li>
                                    <a href="https://www.facebook.com/Ziyyara_learning-304325823599424/?modal=admin_todo_tour" class="facebook social-icon">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/ziyyaralearning/" class="instagram social-icon">
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/ZiyyaraLearning" class="twitter social-icon">
                                        <i class="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="https://in.linkedin.com/company/ziyyara" class="linkedin social-icon">
                                        <i class="fa fa-linkedin"></i>
                                    </a>
                                </li> -->
                                <div class="top-address em-login">
                                    <p>
                                        <a routerLink="/login"><i class="fa fa-sign-in"></i>Login</a>

                                    </p>


                                    <div class="dropdown">
                                        <button class="dropbtn">Register <i class="fa fa-caret-down"></i></button>
                                        <div class="dropdown-content">
                                            <a class="cool-link " routerLink="/studentSignup">Student Signup</a>
                                            <a class="cool-link " routerLink="/teacherSignup">Teacher Signup</a>

                                        </div>
                                    </div>








                                </div>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="tx_top2_relative">
            <div class="">
                <div class="mobile_logo_area hidden-md hidden-lg">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mobile_menu_logo text-center">
                                    <a href="#" title="ziyara_logo">
                                        <img src="https://ziyyara.com/assets/images/ZiyyaraLogo.png" alt="ziyyara">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tx_relative_m">
                    <div class="">
                        <div class="mainmenu_width_tx">
                            <div class="poket-main-menu hidden-xs hidden-sm one_page witr_h_h19 scroll-to-fixed-fixed" style="z-index: 1000; position: fixed; top: 0px; margin-left: 0px; width: 1583px; left: 0px;; top: auto;">
                                <div class="poket_nav_area scroll_fixed postfix prefix">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</header>



<nav class="primary-navigation nav-head navbar" role="navigation">
    <a href="https://ziyyara.com/">
        <img src="https://ziyyara.com/assets/images/ZiyyaraLogo.png" alt="" width="58">
        <!-- <img style="margin: -15px auto;" src="assets/images/logo/newchristmaslogorleftcap.png" alt="ziyyara-logo" width="70"> -->
    </a>


    <label for="menu-bar" class="menubar-label">☰</label>


    <ul class="">
        <li><a href="#" class="cool-link">Boards <i class="fa fa-caret-down"></i></a>
            <ul>
                <li><a routerLink="/tuition/cbse-online-tuition-tutoring-in-all-countries">CBSE</a></li>
                <li><a href="#">Cambridge <i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/cambridge-a-level-tuition">A Level</a></li>
                        <li><a routerLink="/cambridge-o-level-tuition">O Level</a></li>
                        <li><a routerLink="/cambridge-as-level-tuition">AS Level</a></li>
                        <!-- <li><a routerLink="">Curriculum</a></li> -->
                    </ul>
                </li>
                <li><a href="#">International Baccalaureate<i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/ib-curriculum/ib-pyp-tuition">PYP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-myp-tuition">MYP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-dp-tuition">DP</a></li>
                        <li><a routerLink="/ib-curriculum/ib-cp-tuition">CP</a></li>
                    </ul>

                </li>




            </ul>
        </li>
        <li><a href="#" class="cool-link">Countries <i class="fa fa-caret-down"></i></a>
            <ul>
                <li><a routerLink="/tuition/online-home-tuition-in-saudi-arabia">Saudi Arabia</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-qatar">Qatar</a></li>
                <li><a routerLink="/tuition/online-home-tuition-oman">Oman</a></li>
                <li><a routerLink="/tuition/best-online-tuition-in-bahrain">Bahrain</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-united-arab-emirates">UAE</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-kuwait">Kuwait</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-hong-kong">Hong Kong</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-maldives">Maldives</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-malaysia">Malaysia</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-singapore">Singapore</a></li>
                <li><a routerLink="/tuition/online-home-tuition-in-thailand">Thailand</a></li>

            </ul>
        </li>
        <li><a  routerLink="/find-tutors" class="cool-link">Get a Tutor <i class="fa fa-caret-down"></i></a>
            <ul>
                <li><a routerLink="/find-tutors">Search a Tutor</a></li>
<!-- 
                <li><a href="#">Countries <i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/tuition/online-home-tuition-in-saudi-arabia">Saudia Arabia</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-qatar">Qatar</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-oman">Oman</a></li>
                        <li><a routerLink="/tuition/best-online-tuition-in-bahrain">Bahrain</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-united-arab-emirates">UAE</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-kuwait">Kuwait</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-hong-kong">Hong Kong</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-maldives">Maldives</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-malaysia">Malaysia</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-singapore">Singapore</a></li>
                        <li><a routerLink="/tuition/online-home-tuition-in-thailand">Thailand</a></li>
                    </ul>
                </li>
                <li><a routerLink="/thesis-writing-services">Thesis Writing</a></li>
                <li><a routerLink="/professional-online-assignment-help-and-academic">Assignment Help</a></li>
                <li><a href="https://ziyyara.com/blog/one-on-one-online-tuitions-std-xi-xii-commerce-stream.html">Commerce</a></li> -->
            </ul>
        </li>
          <li><a routerLink="">Classes <i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/tuition/online-tuition-for-class-1">Class-1</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-2">Class-2</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-3">Class-3</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-4">Class-4</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-5">Class-5</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-6">Class-6</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-7">Class-7</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-8">Class-8</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-9">Class-9</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-10">Class-10</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-11">Class-11</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-12">Class-12</a></li>
                    </ul>
                </li>
        <li><a href="#" class="cool-link">Offered Programmes <i class="fa fa-caret-down"></i></a>
            <ul>
                <li><a href="https://ziyyara.com/ielts-preparation/">IELTS</a></li>
                <!-- <li><a routerLink="">Classes <i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/tuition/online-tuition-for-class-1">Class-1</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-2">Class-2</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-3">Class-3</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-4">Class-4</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-5">Class-5</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-6">Class-6</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-7">Class-7</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-8">Class-8</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-9">Class-9</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-10">Class-10</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-11">Class-11</a></li>
                        <li><a routerLink="/tuition/online-tuition-for-class-12">Class-12</a></li>
                    </ul>
                </li> -->
                <li><a routerLink="/languages">Languages <i class="fa fa-caret-down"></i></a>
                    <ul>
                        <li><a routerLink="/languages/arabic">Arabic</a></li>
                        <li><a routerLink="/languages/english">English</a></li>
                        <li><a routerLink="/languages/french">French</a></li>
                        <li><a routerLink="/languages/german">German</a></li>
                        <li><a routerLink="/languages/hindi">Hindi</a></li>
                        <li><a routerLink="/languages/spanish">Spanish</a></li>
                    </ul>
                </li>
                <li><a routerLink="/tuition">Online Tutoring</a></li>
                <li><a routerLink="/thesis-writing-services">Thesis Writing</a></li>
                <li><a routerLink="/professional-online-assignment-help-and-academic">Assignment Help</a></li>
                <li><a routerLink="/exam-preparation-tips-and-tricks">Exam Preparation</a></li>
                <li><a routerLink="/online-maths-assignment-help-and-tutoring">Math Help</a></li>
                <li><a href="https://ziyyara.com/digital-marketing/">Digital Marketing</a></li>
            </ul>
        </li>
        <li><a routerLink="/ziyyara-foundation">Ziyyara Foundation</a></li>
        <li><a routerLink="/studentSignup" class="cool-link">Register</a></li>
        <li><a routerLink="/login" class="cool-link">Login</a></li>
    </ul>
</nav>