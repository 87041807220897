import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, ApiResponse } from '../../interfaces';
import * as AppActions from '../../store/actions/app.actions';
import { AppService, HttpService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class PropertyGuard implements CanLoad {
  constructor(private store: Store<AppState>, private router: Router,
    private appSer: AppService, private api: HttpService) {

  }
  
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
   return new Promise((resolve) => {

      // If no token found
      if (!localStorage.getItem('token1')) {
        this.appSer.logout1();
        resolve(false);
      }else {
       const token= localStorage.getItem('token1');
      this.api.getReqAuth('users/'+token).subscribe(

        (res: ApiResponse) => {
          if (res && res.status == true) {
            if (res.result.u_type == "1") {
             // res.result['_id']=res.result.id?res.result.id:res.result._id;
              this.store.dispatch(new AppActions.PropertySignup(res.result));
              localStorage.setItem('token1', res.result.auth_token);
           
              resolve(true);
            } else if (res.result.u_type == "2") {
             // res.result['id']=res.result._id;
              this.store.dispatch(new AppActions.PropertySignup(res.result));
              localStorage.setItem('token1', res.result.auth_token);
           
              resolve(true);
            }else {
             this.appSer.logout1();
              resolve(false);
            }
          } else {
            this.appSer.doblocked();
            resolve(false)
          }
        },
        (err) => {
         this.appSer.logout1();
          resolve(false);
        }
      );
      }

    });
  }
}
