import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
  
@Component({
  selector: 'app-cbse-nigeria',
  templateUrl: './cbse-nigeria.component.html',
  styleUrls: ['./cbse-nigeria.component.css','../../languages/languages/languages.component.css']
})
export class CbseNigeriaComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {

    
  }

  ngOnInit() {
    var host=  location.pathname;
   
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "CBSE Online Tuition in Nigeria");
    this.meta.updateTag({name:"description", content:" -Try Ziyyara's online tuition for CBSE Board in Nigeria & get the experienced tutors offering home tuition for CBSE Board in Nigeria."})
    this.metaService.removeStructuredData();
   // this.metaService.insertSchema(this.websiteSchema);
 this.meta.updateTag({name:"keywords", content:"home tuition for cbse board in nigeria, home tuition near me for cbse board in nigeria, home tuitions for cbse board in nigeria, home tutor for cbse board in nigeria, home tutors near me for cbse board in nigeria, online classes for cbse in nigeria, online tuition classes for cbse in nigeria, online tuition for cbse board in nigeria, online tutor for cbse board in nigeria, online tutoring for cbse board in nigeria, private tuition for cbse board in nigeria, private tutor for cbse board in nigeria, tuition at home for cbse board in nigeria, tuition centre for cbse board in nigeria, tuition classes for cbse in nigeria"})
 
 
   var ogTitle='CBSE Online Tuition in Nigeria';
   var ogDescription="-Try Ziyyara's online tuition for CBSE Board in Nigeria & get the experienced tutors offering home tuition for CBSE Board in Nigeria."
     this.meta.updateTag({property:"og:title", content:ogTitle})
     this.meta.updateTag({property:"og:description", content:ogDescription})
     this.meta.updateTag({name:"twitter:title", content:ogTitle})
     this.meta.updateTag({name:"twitter:description", content:ogDescription});
 
  }

}
