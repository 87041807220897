import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { ERROR_MESSAGES, Regex, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { AppService, HttpService } from "../../../../services";
import { AppStateService } from "../../../../services/app-state.service";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material";
import { Subscription, Observable } from "rxjs";
import { TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SignupComponent } from "../../../shared/components/signup/signup.component";
import { LoginComponent } from "../../../shared/components/login/login.component";
import { OtpComponent } from "../../../shared/components/otp/otp.component";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IPROPERTY, AppState, ApiResponse } from "../../../../interfaces";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { SuccessComponent } from "../../../shared/components/success/success.component";
@Component({
  selector: 'app-new-registration-form',
  templateUrl: './new-registration-form.component.html',
  styleUrls: ['./new-registration-form.component.css']
})
export class NewRegistrationFormComponent implements OnInit {
  
  tmzone:any;
  value2: any;
  value3: any;
  value4: number;
  value5: number;
  value6:number;
  tmzoninnumber: number;
  tmzoninnumber2: number;
  finalvalue: any ;
  finalvalues:string;

  Location: any;
  country: any;
  countryCode: any 

  lselected;
  adcontactForm: FormGroup;
  submit;
  signupForm: FormGroup;
  submitted = false;
  modalRef: BsModalRef;
  // countryCodes:any;
  loaderButton;
  errorMessage;
  disabled = false;
  CONFIG = CONFIG;
  FORM_ERROR = {
    name: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },

    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`,
    },
    contactNo: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`,
    },

    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    },
    confirmPassword: {
      required: ERROR_MESSAGES.CONFIRM_PASSWORD,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
      matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH,
    },
    type: {
      required: ERROR_MESSAGES.USER_TYPES,
    },
  };
  Grade: any = [
    "Class-1",
    "Class-2",
    "Class-3",
    "Class-4",
    "Class-5",
    "Class-6",
    "Class-8",
    "Class-9",
    "Class-10",
    "Class-11",
    "Class-12",
  ];

  tailUrl = "";
  @Input() title: String;
  @Input() subTitle: String;

  constructor(
    public dialog: MatDialog,
    private form: FormBuilder,
    public _util: CommonUtil,
    private appService: AppService,
    private httpService: HttpService,
    public appStateSer: AppStateService,
    private modalService: BsModalService,
    private _fb: FormBuilder
  ) {
    var url = location.pathname;
    if (url.includes("-in-")) {
      this.tailUrl = ("in-" + url.split("-in-")[1]).replace(/-/g, " ");
    } else if (url.includes("-for-")) {
      this.tailUrl = ("for-" + url.split("-for-")[1]).replace(/-/g, " ");
    } else if (url.includes("languages")) {
      this.tailUrl =
        "for" + url.split("/languages")[1].replace("/", " ") + " Language";
    }
    console.log(this.tailUrl);
    this.createForm();
  }



  createForm() {
    this.signupForm = this._fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.maxLength(200),
          Validators.pattern(Regex.spacecharacter),
        ],
      ],
      email: ["", [Validators.required, Validators.pattern(Regex.email)]],

      contactNo: [
        "",
        [
          Validators.required,
          Validators.pattern(Regex.phoneNumbers),
          Validators.minLength(CONFIG.MOBILE_MIN_LENGTH),
          
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(Regex.password),
          Validators.pattern(Regex.spaces),
        ],
      ],
      u_type: ["", [Validators.required]],
    });
  }
  generatePassword(length) {
    var digits = "0123456789";
    let pass = "";
    for (let i = 0; i < 8; i++) {
      pass += digits[Math.floor(Math.random() * 10)];
    }
    return pass;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.httpService.getLocation().subscribe((Response)=>{
      this.Location=Response;
      this.country=this.Location.country_name;
      this.countryCode=this.Location.country_calling_code;
  
      this.tmzone=this.Location.utc_offset;
      this.tmzoninnumber= parseInt(this.tmzone);
  
      if(this.tmzoninnumber>0){
      this.value2=(this.tmzoninnumber / 100).toFixed(2);
      this.tmzoninnumber2=this.value2;
      this.value3= (this.tmzoninnumber2 + "").split(".");
      this.value4=parseInt(this.value3[1]);
      this.value6=this.value4 / 60;
      this.value5=parseInt(this.value3[0]);
      this.finalvalue=this.value5+this.value6;
      this.finalvalues=this.finalvalue.toString();
      //  console.log(this.finalvalue);
  
      }else if(this.tmzoninnumber<0){
        this.value2=(this.tmzoninnumber / 100).toFixed(2);
        this.tmzoninnumber2=this.value2;
  
        this.value3= (this.tmzoninnumber2 + "").split(".");
        this.value4=parseInt(this.value3[1]);
        this.value6=this.value4 / 60;
        this.value5=parseInt(this.value3[0]);
        this.finalvalue=this.value5-this.value6;
        this.finalvalues=this.finalvalue.toString();
        // console.log(this.finalvalue);
  
      }
  
    })
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`,
    };
    let ltype = localStorage.getItem("mylanguage");
    if (ltype == "ar") {
      this.lselected = "ar";
      this.submit = "تقديم الطلب ";
    } else {
      this.lselected = "en";
      this.submit = "Submit";
    }
  }
    signup(template: TemplateRef<any>): void {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "425px",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((result) => {});

    this.modalRef.hide();
  }

  submitUser() {
    //console.log("cliekced")
    this.signupForm.get("password").setValue(this.generatePassword(9));
    this.signupForm.get("u_type").setValue(1);

    if (this.signupForm.valid) {
 
      this.disabled = true;
      //  console.log("inside if");
      this.signupForm.value.name = this.signupForm.value.name.toString().trim();
      this.signupForm.value.festive_offer =localStorage.getItem("festive-offer");
      this.signupForm.value.origin_page = "https://ziyyara.com"+location.pathname;
       // edit by nazimahmed
       this.signupForm.value.country=this.country.trim();
       this.signupForm.value.countryCode=this.countryCode.trim();

       this.signupForm.value.timezone=this.finalvalues;
 //end
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      // console.log(this.signupForm.value);
      this.httpService.postReqUnauth("users", this.signupForm.value).subscribe(
        (res) => this.success(res),
        (err) => this.error(err.message),
        () => {
          (this.loaderButton = false), (this.disabled = false);
        }
      );
    } else {
      this._util.markError(this.signupForm);
    }
  }
  get name(): FormControl {
    return this.signupForm.get("name") as FormControl;
  }
  get email(): FormControl {
    return this.signupForm.get("email") as FormControl;
  }
  get password(): FormControl {
    return this.signupForm.get("password") as FormControl;
  }
  get u_type(): FormControl {
    return this.signupForm.get("u_type") as FormControl;
  }
  get contactNo(): FormControl {
    return this.signupForm.get("contactNo") as FormControl;
  }

  success(res: ApiResponse) {
    if (res.status == true) {
      // this.dialogRef.close(false);
      const value = "Sign Up";
      const content =
        "Your registration has been done successfully! Please check your email for auto generated password to login";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active",
        },
        // disableClose: true
      });

      this.sendEmailtoUser();
      dialogRef.afterClosed().subscribe((result) => {
        this.signupForm.reset();
        dialogRef.close(false);
      });
    } else {
      
        const message = res.message;
        // just to add the country code objects
  

      
        this.appService.addSnackBar({ message });
      }
  }

  successsResend(res) {}
  popupmessage(data) {
    const value = "Sign Up";
    const content =
      "Please enter the verification code which is sent to your email.";
    const dialogRef = this.dialog.open(OtpComponent, {
      // maxWidth: "500px",
      width: "500px",
      height: "auto",
      data: {
        content: {
          title: content,
          heading: value,
          message1: 1,
          ok: data.email,
        },
        action: "active",
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.dialogRef.close(false);
    });
  }
  error(message) {
    // //console.log("error")
    this.appService.addSnackBar({ message });
  }
  error1(message) {
    this.appService.addSnackBar({ message });
  }


  sendEmailtoUser() {
    ////console.log("sending email to suer");
    this.httpService
      .postReqUnauth("sendEmailToUser", this.signupForm.value)
      .subscribe(
        (res) => this.successEmail(res),
        (err) => this.errorEmail(err.message),
        () => (this.loaderButton = false)
      );
  }

  successEmail(res) {
    //console.log("email sent")
  }

  errorEmail(msg) {
    //console.log("")
  }
  
  

  
}

