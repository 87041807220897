<app-header-front>
</app-header-front>

<div class="how-it-works">

    <div class="container">

        <div class="mdlle">
            <h1>MAKING SURE THAT NO CHILD IS LEFT UNEDUCATED</h1>
        </div>
        <p>If it’s not treated as a disease, the day when illiteracy will encapsulate the entire global population, is not so far. Constant learning is an important task for any living being, but there are some people who do not believe in learning, and
            only have faith in dictating others. And those who like to dictate others what to do, are the ones who enjoy oppressing the most. However, the most interesting thing about oppression is that it has to end one day.</p>

        <p>The Ziyyara Foundation has taken the initiative to do the same, by presenting every child who wants to study, with the opportunity to fulfil their dream of at least being functionally literate. When we look at Zanzibar, we don’t see literacy that
            once peaked at 81%. Rather, we see kids being hired for child labour, or being prepared for marriage. If not, the underprivileged children are forcibly drowned in poverty, and are made to work regular shifts for a tummy-filling meal. Our foundation
            has started schooling for these kids, along with providing all the material that they’ll need to study on their own. In our endeavour, we came through a lot of kids who needed our help. But out of all the kids, Naushaad was among the most
            wilful ones. What made him different was his determination to challenge the oppression, and educate himself. Thus, what we seek to eradicate from Zanzibar, can be best understood by Naushaad’s story.
        </p>
        <p>
            Naushaad was a fifteen-year-old orphan who served as an assistant to anyone who was willing to give him a full day’s work, and food to eat. When someone asked him why not night shifts? He replied that he cannot compromise with his studies. This is what
            Naushaad said to the contractor who wanted to hire him as a labourer for a building construction project.
        </p>
        <p>
            Obsession towards education, can surely be seen as a tabooed concept in Zanzibar, but even after that, we cannot ignore how important it is to be educated. Therefore, Naushaad’s need for educating himself; kept on reflecting in his behaviour, so that
            his intentions are always clear.
        </p>
        <p>But since he had no family, it was easy for people to manipulate him. Construction, and other projects in Zanzibar, are always in need of cheap labour. And the most organic way of satisfying this need, was to convince kids like Naushaad, to come
            work as a minimum wage labourer. But Naushaad was stubborn. Even if he was offered a higher pay in night shifts, he chose not to go with it.
        </p>
        <p>However, hunger and poverty got the best of him, and he started looking for night shift jobs. This struggle of his, goes on to explain just how difficult it is for any underprivileged kid to keep on with their education. When Naushaad went to
            the contractor who offered him a job in the building construction project, he found out that the terms of employment have been drastically changed. The new terms not only restricted him for the entire duration of the project, but also made
            him obligated to serve the company for the next few projects in the coming seven years.

        </p>
        <p>Naushaad wasn’t surprised at all. In fact, he was looking forward to facing such terms. Not only Naushaad had to refrain himself from studying further, but he also had to let go of his dream of becoming a professor.

        </p>
        <p>
            We are aware of the fact that it’s a difficult task, but the Ziyyara Foundation is proud to be in the initial stage of an action that all the companies out there are taking part in. By making education available to these areas, we are hoping to secure
            the bright minds of the future, so that they can enlighten us and others, even further. On the ground level, our foundation has started working towards developing suitable infrastructure for the students, so that they can learn in peace. And
            as far as accommodation for the kids is concerned, we have made sure that the students’ basic requirements regarding the classroom are fulfilled.
        </p>


        <p>With our impeccable guidance, we make sure that our services in the education niche are unparalleled, so that more and more learners are drawn towards our classes, and we make more in terms of profits. A fragment of these profits is reinvested
            into the school development projects in Zanzibar, so as to ensure the security of the initiative that our foundation has taken.
        </p>
        <p>Therefore, to make sure that their learning experience isn’t hindered at all, our foundation has also started free traditional classes. These are closer to the neighbourhoods that still consist of people for whom making a 10 km round trip to school
            isn't possible. While all of our efforts are focused on children who do not have access to education, we are also determined to focus on those individuals as well, who, due to some reasons, had to discontinue their education. With this, we
            are not only giving all the drop-outs a second chance at their education, but we’re also making sure that seeing these learners, all the others get motivated, and join to complete their education. Because an educated world is a self-sustaining
            one as well.
        </p>
        <p>With this belief in our vision, we have stayed focused on our goal, and it might be the reason why our goals seem achievable to us. We’re not sure that we’ll see a world where no child is left uneducated, but one thing that we are sure of, is
            that we are doing everything in our might, and we will not stop, until we have educated the last Naushaad that we see. In other words, we won’t stop until we have claimed the goal of educating all.
        </p>
        <br>
        <div class="btnn-cntr">
            <a routerLink="/ziyyara-foundation" class="anchor">Help Them Learn</a>
        </div>
        <br>
    </div>


    <app-footer-front></app-footer-front>