import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material";
import { PopupMessage } from "../../../../interfaces";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { CommonUtil } from "../../../../util";
import { AppStateService } from "../../../../services/app-state.service";
import { AppService, HttpService } from "../../../../services";
import { Store, select } from "@ngrx/store";
import * as AppActions from "../../../../store/actions/app.actions";
import { Router, ActivatedRoute } from "@angular/router";
import { ERROR_MESSAGES, Regex, CONFIG, countries } from "../../../../constants";
import { Observable, Subscription, interval } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import * as SignupActions from "../../../../store/actions/signup.actions";
import { IPROPERTY, AppState, ApiResponse } from "../../../../interfaces";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "../login/login.component";
import { SuccessComponent } from "../success/success.component";
import { ResetComponent } from "../reset/reset.component";

//import { SuccessComponent } from "./modules/property/shared/components/success/success.component";
// import { ResetComponent } from "./modules/property/shared/components/reset/reset.component";
// import { LoginComponent } from "./modules/property/shared/components/import/import.component";

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.css"]
})
export class OtpComponent implements OnInit {
  CONFIG = CONFIG;
  loader = false;
  otpForm: FormGroup;
  timer: Observable<string>;
  loaderButton;
  errorMessage;
  currentDate;
  FORM_ERROR = {
    otp: {
      required: ERROR_MESSAGES.OTPREQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MAXOTP_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MINOTP_LENGTH}`
    }
  };
  constructor(
    //public _matDialogRef: MatDialogRef<ForgotComponent>,
    public dialogRef: MatDialogRef<OtpComponent>,
    public _util: CommonUtil,
    private appState: AppStateService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private appSer: AppService,
    private _api: HttpService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createForm();
    this.resendOtpTimer();
  }

  createForm() {
    this.otpForm = this._fb.group({
      otp: ["", [Validators.required]]
    });
  }

  get otp(): FormControl {
    return this.otpForm.get("otp") as FormControl;
  }

  no() {
    this.dialogRef.close(false);
  }
  submit(id) {
   
    if (this.otpForm.valid) {
      if (this.otpForm.value.otp.length < 4) {
        //this.appSer.addSnackBar({ message: "Please enter valid otp" });
        this.errorMessage = "Please enter valid verification code";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      } else {
        this.loader = true;
        this.otpForm.value.email=id;
        this.otpForm.value.verification_code=this.otpForm.value.otp;
       // alert(id);
        this._api
          .postReqUnauth("users_self_verification", this.otpForm.value)
          .subscribe(
            res => this.successs(res),
            err => this.error(err),
            () => (this.loader = false)
          );
      }
    } else {
      this._util.markError(this.otpForm);
    }
  }

  success(res) {
    if (res.status == true) {
      this.dialogRef.close(false);
      const content = "";
      const dialogRef = this.dialog.open(ResetComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content },
          action: "active"
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(false);
      });
    } else {
      //this.appSer.addSnackBar({ message: res.result.message });
      this.errorMessage = res.result.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    }
  }

  successs(res) {
    if (res.status == true) {
     this.store.dispatch(new AppActions.PropertySignup(res.result));
     localStorage.setItem("ziyyara_token", res.result.identity);
     if(res.result.verification==1){
      localStorage.setItem("token1", res.result.auth_token);
      localStorage.setItem("ziyyara_token", res.result.identity);
      localStorage.setItem("user_name", res.result.name);
      localStorage.setItem("user_dp", res.result.dp);
      localStorage.setItem("country", res.result.country);
      localStorage.setItem('user_type',res.result.u_type)
      localStorage.setItem("group_class", res.result.isEnabledForGroup);
     }
     
     this.dialogRef.close(true);

      const value = "Sign Up";
      const content = "Your account has been verified successfully! ";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "620px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active"
        }
      });
      //this._router.navigate(['/profile']);
      setTimeout(() => {
         dialogRef.close(false);
         this._router.navigate(['/dashboard']);
         this.update_login_count(res.result._id)

      }, 2500);

     
    } else {
      this.errorMessage = res.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    }
  }
  error(err) {
    this._util.message(err.message);
  }
  resendOtp(email) {
   
    this._api
      .postReqUnauth("users_send_verification_code", {
        email: email,
        
      })
      .subscribe(
        res => this.successsResend(res),
        err => this.error(err.message),
        () => (this.loaderButton = false)
      );
  }
  successsResend(res) {
    if (res.status == true) {
      this.resendOtpTimer();
      this.errorMessage = res.result.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    } else {
      this.errorMessage = res.result.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    }
  }

  resendOtpTimer() {
    this.currentDate = new Date();
    this.timer = interval(500).pipe(
      map(tick => {
        const ti =
          60 -
          Math.floor(
            (new Date().getTime() - this.currentDate.getTime()) / 1000
          );
        if (ti < 0) {
          this.timer = null;
          this.currentDate = null;
        } else {
          return Math.floor(ti / 60) + ":" + ("0" + (ti % 60)).slice(-2);
        }
      })
    );
  }
  
  login() {
    this.dialogRef.close(false);
    const value = "Sign In";
    const content =
      "Please enter the verification which is sent to your phone number.";
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "425px",
      height: "auto",
      data: {
        content: { title: content, heading: value },
        action: "active"
      },
      disableClose: true
    });
    

    dialogRef.afterClosed().subscribe(result => {});
  }
  submitReset(data) {
    if (this.otpForm.valid) {
      if (this.otpForm.value.otp.length < 4) {
        // this.appSer.addSnackBar({ message: "Please enter valid otp" });
        this.errorMessage = "Please enter valid otp";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      } else {
        if (this.data.content.element == "1") {
          this.loader = true;
          this.otpForm.value.email = this.data.content.key;

          this._api
            .postReqAuths("user/verifyForgotPasswordMail", this.otpForm.value)
            .subscribe(
              res => this.success(res),
              err => this.error(err),
              () => (this.loader = false)
            );
        } else if (this.data.content.element == "2") {
          this.loader = true;
          this._api
            .postReqAuths("user/verifyOtp", this.otpForm.value)
            .subscribe(
              res => this.success(res),
              err => this.error(err),
              () => (this.loader = false)
            );
        }
      }
    } else {
      this._util.markError(this.otpForm);
    }
  }
  successpopup(res) {
    ////////console.log(res);
    if (res.status == true) {
      this.dialogRef.close(false);
      const value = "Sign Up";
      const content = "Your Password has been reset successfully!";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active"
        }
        // disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(false);
      });
    } else {
      this.errorMessage = res.result.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    }
  }

  successsReset(res) {
    this.dialogRef.close(false);
    const value = "Sign In";
    const content =
      "Please enter the verification which is sent to your phone number.";
    const dialogRef = this.dialog.open(ResetComponent, {
      width: "700px",
      height: "auto",
      data: {
        content: { title: content, heading: value },
        action: "active"
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }


  update_login_count(user_id){


    this._api
          .postReqUnauth("update_login_count",{"user_id":user_id} )
          .subscribe(
            res => this.update_login_count_success(res),
            err => this.update_login_count_error(err),
            () => (this.loader = false)
          );
  }


update_login_count_success(res){

  //////console.log('login count updated')
//////console.log(res.result)

}

update_login_count_error(err){


}

}
