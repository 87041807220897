<!-- popup for image -->
<div class="wrapper">
    <style>
        .wrapper {
            position: relative;
        }
    </style>
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="onNoClick()">&times;</button>
    </div>
    <!-- <a href="https://ziyyara.com/spin-and-win/"> -->
    <a href="https://ziyyara.com/online-maths-assignment-help-and-tutoring">
        <!-- <img width="100%" src="/assets/images/logo/newyear-2023.jpg" alt="Republic day 2023"> -->
        <img width="100%" src="/assets/images/logo/popup.gif" alt="EXAMS ARE APPROCHING">
    </a>
</div>
<!-- end popup for image -->




<!-- form popup start -->
<!-- <div class="wrapper login">
    <div class="container-fluid _cont">

        <div class="col-left">
            <div class="login-text">
                <div class="image">
                    <img src="ZiyyaraLogo.png" alt="logo">
                </div>
                <div class="demo">
                    <h6>Book A Free Demo<br>Now!</h6>
                </div>
                <a routerLink="/login" class="btn">Login</a>


            </div>
        </div>

        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" (click)="onNoClick()">&times;</button>
        </div>


        <div class="col-right">
       
            <div class="form-main">
                <form [formGroup]="signupForm" autocomplete="off" (ngSubmit)="submitUser()">
                    <h4 class="mb-3">Book Now</h4>
                    <div class="form-input">
                  
                        <input type="text" id="name" required class="input" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH" autocomplete="off">

                        <label for="name" class="input-label">Name*:</label><br>
                        <mat-error class="text-center">{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>

                    </div>


                    <div class="form-input">
                        <input type="text" id="name" required class="input" formControlName="email" autocomplete="off">

                        <label for="name" class="input-label">Email:</label>
                        <br>
                        <mat-error class="text-center">{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                    </div>
                    <div class="form-input">
                        <input type="text" id="name" required class="input" value="{{countryCode}} {{country}}" readonly>

                    </div>


                    <div class="form-input">
                        <input appOnlyNumber [formControl]="contactNo" id="name" required class="input" [maxlength]="CONFIG?.MOBILE_LENGTH" autocomplete="off">

                        <label for="name" class="input-label">Mobile:</label>
                        <br>
                        <mat-error class="text-center"> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                    </div>



                    <p>
                        <input type="submit" [disabled]="disabled" class="form-sub" name="" id="" value="Book Now" autocomplete="off">

                    </p>




                </form>
                <div class="Sr-main">
                    <div class="icons">
                        <a href="https://www.facebook.com/Ziyyara_learning-304325823599424/?modal=admin_todo_tour">
                            <i class="fa fa-facebook"></i>
                        </a>
                    </div>2wdx
                    <div class="icons">
                        <a href="https://www.instagram.com/ziyyaralearning/">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>
                    <div class="icons">
                        <a href="https://twitter.com/ZiyyaraLearning">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </div>
                    <div class="icons">
                        <a href="https://in.linkedin.com/company/ziyyara">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </div>

                </div>

            </div>
 

        </div>
    </div>
</div> -->

<!-- form popup start -->