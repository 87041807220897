import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { HttpService,AppService } from "../../../../services";
import { IPROPERTY, AppState, ApiResponse ,PopupMessage} from "../../../../interfaces";
import { LoginComponent } from '../login/login.component';
import { AutofillMonitor } from "@angular/cdk/text-field";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  resetForm: any;
  CONFIG = CONFIG;
  errorMessage;
  loader;
  disabled=false;
  FORM_ERROR = {
    otp: {
      required: ERROR_MESSAGES.OTPREQUIRED,
      pattern: ERROR_MESSAGES.ONLY_NUMBER_ALLOWED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MAXOTP_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MINOTP_LENGTH}`,
     
    },
    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,

    },
    confirmPassword: {
      required: ERROR_MESSAGES.CONFIRM_PASSWORD,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX, matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH
    },
  };
  constructor(public _matDialogRef: MatDialogRef<LoginComponent>,
    public dialogRef: MatDialogRef<LoginComponent>,
    private appser:AppService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage, public dialog: MatDialog, private _fb: FormBuilder, private _router: Router, private _api: HttpService, private _route: ActivatedRoute, public _util: CommonUtil) { 
    
    }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {

    };

    this._matDialogRef.updatePosition(matDialogConfig.position);
    this.createForm();
   
  }
  login(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(LoginComponent, {
     width:'425px',
     height:'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  createForm() {
    this.resetForm = this._fb.group({
      otp: ["", [Validators.required,Validators.maxLength(4),Validators.required,Validators.minLength(4), Validators.pattern(Regex.phoneNumbers)]],
      password: ['', [Validators.required, Validators.pattern(Regex.password), Validators.pattern(Regex.spaces), Validators.pattern(Regex.spacecharacter1)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(Regex.password), Validators.pattern(Regex.spaces),Validators.pattern(Regex.spacecharacter1)]],

    },
      { validator: this.checkPasswords });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    if (group.controls.confirmPassword.value !== "") {
      let pass = group.controls.password.value;
      let confirmPass = group.controls.confirmPassword.value;

      return pass === confirmPass ? null : { notSame: true }
    }
  }

  get otp(): FormControl {
    return this.resetForm.get("otp") as FormControl;
  }
  get password(): FormControl {
    return this.resetForm.get("password") as FormControl;
  }
  get confirmPassword(): FormControl {
    return this.resetForm.get("confirmPassword") as FormControl;
  }
  get email(): FormControl {
    return this.resetForm.get("email") as FormControl;
  }


  submit() {
    if (this.resetForm.valid) {
      this.loader = true;
      this.disabled=true;
      this.resetForm.value.newPassword = this.resetForm.value.password;
      this.resetForm.value.email=this.data.content.title;
      this.resetForm.value.token=this.resetForm.value.otp;
      this._api
        .postReqUnauth("users_reset_password", this.resetForm.value)
        .subscribe(
          res => this.success(res),
          err => this.error(err),
          () => (this.loader = false,   this.disabled=false)
        );
    } else {
      this._util.markError(this.resetForm);
    }
  }

  success(res: ApiResponse) {
    if (res.status == true) {
      this.appser.addSnackBar({message:'Password has been reset succesfully'});
      this.login();
    } else {
     this.appser.addSnackBar({message:res.message})
    }
  }

  error(err) {
    this._util.message(err.message);
  }



}
