import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { CommonComponentModule } from '../common-component/common-component.module';
import { BestOnlineHomeTuitionComponent } from './best-online-home-tuition/best-online-home-tuition.component';


const pageRoutes: Routes = [

  { path: '', component: BestOnlineHomeTuitionComponent },

];

@NgModule({
  imports: [
    CommonModule,
  
    SharedModule,
    CommonComponentModule,
    
    RouterModule.forChild(pageRoutes),

  ],
  declarations: [BestOnlineHomeTuitionComponent],
  entryComponents: []
})
export class BestOnlineHomeTuitionModule { }


