import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from "@angular/material";
import { AfterViewInit, OnDestroy, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../constants";
import { CommonUtil } from "../../../util";
import { HttpService, AppService } from "../../../services";

// import { LoginComponent } from "../../../login/login.component";
import { IPROPERTY, AppState, ApiResponse } from "../../../interfaces";
import { SuccessComponent } from "../../shared/components/success/success.component";
import { OtpComponent } from "../../shared/components/otp/otp.component";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
// import { SuccessComponent } from "../success/success.component";
// import { OtpComponent } from "../otp/otp.component";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  //  bank= [
  //   {name: 'Bank A (Switzerland)', id: 'A'},
  //   {name: 'Bank B (Switzerland)', id: 'B'},
  //   {name: 'Bank C (France)', id: 'C'},
  //   {name: 'Bank D (France)', id: 'D'},
  //   {name: 'Bank E (France)', id: 'E'},
  //   {name: 'Bank F (Italy)', id: 'F'},
  //   {name: 'Bank G (Italy)', id: 'G'},
  //   {name: 'Bank H (Italy)', id: 'H'},
  //   {name: 'Bank I (Italy)', id: 'I'},
  //   {name: 'Bank J (Italy)', id: 'J'},
  //   {name: 'Bank Kolombia (United States of America)', id: 'K'},
  //   {name: 'Bank L (Germany)', id: 'L'},
  //   {name: 'Bank M (Germany)', id: 'M'},
  //   {name: 'Bank N (Germany)', id: 'N'},
  //   {name: 'Bank O (Germany)', id: 'O'},
  //   {name: 'Bank P (Germany)', id: 'P'},
  //   {name: 'Bank Q (Germany)', id: 'Q'},
  //   {name: 'Bank R (Germany)', id: 'R'}
  // ];
  tmzone:any;
  value2: any;
  value3: any;
  value4: number;
  value5: number;
  value6:number;
  tmzoninnumber: number;
  tmzoninnumber2: number;
  finalvalue: any ;
  finalvalues:string;

  Location: any;
  country: any;
  countryCode: any

  signupForm: any;
  loaderButton;
  disabled = false;
  CONFIG = CONFIG;
  FORM_ERROR = {
    fullName: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },

    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`,
    },
    contactNo: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      // maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      // minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`,
      // Validators: pattern('[- +()0-9]+')
      contactNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]  
      
    },

    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    },
    // confirmPassword: {
    //   required: ERROR_MESSAGES.CONFIRM_PASSWORD,
    //   maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
    //   pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    //   matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH,
    // },
    type: {
      required: ERROR_MESSAGES.USER_TYPES,
    },
  };
  selectedValue: any;
  getsub: any;



  constructor(
    // public _matDialogRef: MatDialogRef<LoginComponent>,
    // public dialogRef: MatDialogRef<LoginComponent>,
    private _appSer: AppService,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _router: Router,
    private _api: HttpService,
    private _route: ActivatedRoute,
    public _util: CommonUtil
  ) {}

  ngOnInit() {
    window.scrollTo(0,0);
    this._api.getLocation().subscribe((Response)=>{
      this.Location=Response;
      this.country=this.Location.country_name;
      this.countryCode=this.Location.country_calling_code;

      this.tmzone=this.Location.utc_offset;
      this.tmzoninnumber= parseInt(this.tmzone);

      if(this.tmzoninnumber>0){
      this.value2=(this.tmzoninnumber / 100).toFixed(2);
      this.tmzoninnumber2=this.value2;
      this.value3= (this.tmzoninnumber2 + "").split(".");
      this.value4=parseInt(this.value3[1]);
      this.value6=this.value4 / 60;
      this.value5=parseInt(this.value3[0]);
      this.finalvalue=this.value5+this.value6;
      this.finalvalues=this.finalvalue.toString();

       console.log(this.finalvalue);
  
  
      //  var searchArr = []
      //   if(this.finalvalues){ 
      //   this.timezones.forEach(function(one){
      //   searchArr[one.code]=one;
      //   })
        // this.timezoneValue=searchArr[this.finalvalues];
        // console.log(this.timezoneValue);
        
      //  }
  
      }else if(this.tmzoninnumber<0){
        this.value2=(this.tmzoninnumber / 100).toFixed(2);
        this.tmzoninnumber2=this.value2;

        this.value3= (this.tmzoninnumber2 + "").split(".");
        this.value4=parseInt(this.value3[1]);
        this.value6=this.value4 / 60;
        this.value5=parseInt(this.value3[0]);
        this.finalvalue=this.value5-this.value6;
        this.finalvalues=this.finalvalue.toString();
        console.log(this.finalvalue);

      }

    })

    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`,
    };

    // this._matDialogRef.updatePosition(matDialogConfig.position);

    let stateObj = { id: "100" };

    // window.history.replaceState(stateObj, "index", "/register");
    this.createForm();
    if (localStorage.getItem("getstarted")) {
      console.log(localStorage.getItem("getstarted"))
    
      

      if (localStorage.getItem("getstarted") == "undefined") {
        this.selectedValue = []
      } else if (localStorage.getItem("getstarted") == null) {
        this.selectedValue = []
      } else {
        this.selectedValue =  localStorage.getItem("getstarted");
      }
     
    }

  

    // set initial selection

  
  }


  createForm() {
    this.signupForm = this._fb.group({

        fullName: ["",[Validators.required,Validators.maxLength(200),Validators.pattern(Regex.spacecharacter),],],
        email: ["", [Validators.required, Validators.pattern(Regex.email)]],

        contactNo: [
          "",
          [
            Validators.required,
            Validators.minLength(CONFIG.MOBILE_MIN_LENGTH),
            Validators.pattern(Regex.phoneNumbers),
          ],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(Regex.password),
            Validators.pattern(Regex.spaces),
          ],
        ],
        // confirmPassword: [
        //   "",
        //   [
        //     Validators.required,
        //     Validators.maxLength(25),
        //     Validators.pattern(Regex.password),
        //     Validators.pattern(Regex.spaces),
        //   ],
        // ],
        u_type: ["1", [Validators.required]],
      }
      // { validator: this.checkPasswords }
    );

    if (
      localStorage.getItem("enteredEmail") != "undefined" &&
      localStorage.getItem("enteredEmail")
    ) {
      this.signupForm
        .get("email")
        .setValue(localStorage.getItem("enteredEmail"));
    }
  }
  // checkPasswords(group: FormGroup) {

  //   if (group.controls.confirmPassword.value !== "") {
  //     let pass = group.controls.password.value;
  //     let confirmPass = group.controls.confirmPassword.value;

  //     return pass === confirmPass ? null : { notSame: true };
  //   }
  // }

  submit() {
    // generating the temp password for the user

    this.signupForm.get("password").setValue(this.generatePassword(9));
    // setting default user type
    this.signupForm.get("u_type").setValue(1);
    


  
    if (this.signupForm.valid) {


   
      this.disabled = true;
     
     
      this.signupForm.value.name = this.signupForm.value.fullName.toString().trim();
      this.signupForm.value.origin_page = "https://ziyyara.com"+location.pathname;
      // edit by nazimahmed
      this.signupForm.value.country=this.country.trim();
      this.signupForm.value.countryCode=this.countryCode.trim();
      this.signupForm.value.timezone=this.finalvalues;

   
          // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      this._api.postReqUnauth("users", this.signupForm.value).subscribe(
        

        (res) => this.success(res),
        (err) => this.error(err.message),
        () => ((this.loaderButton = false), (this.disabled = false))
      );
    } else {
      this._util.markError(this.signupForm);
    }
  }

  generatePassword(length) {
    var digits = "0123456789";
    let pass = "";
    for (let i = 0; i < 8; i++) {
      pass += digits[Math.floor(Math.random() * 10)];
    }
    return pass;
  }

  get fullName(): FormControl {
    return this.signupForm.get("fullName") as FormControl;
  }
  get email(): FormControl {
    return this.signupForm.get("email") as FormControl;
  }
  get password(): FormControl {
    return this.signupForm.get("password") as FormControl;
  }

  get u_type(): FormControl {
    return this.signupForm.get("u_type") as FormControl;
  }

  get contactNo(): FormControl {
    return this.signupForm.get("contactNo") as FormControl;
  }

 
  success(res: ApiResponse) {
    if (res.status == true) {
      // this.dialogRef.close(false);
      this.disabled = true;
      const value = "Sign Up";

      const content =
        "Your registration has been done successfully! Please check your email for auto generated password to login";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active",
        },
        // disableClose: true
      });

      this.sendEmailtoUser();
      dialogRef.afterClosed().subscribe((result) => {
        this.signupForm.reset();
        dialogRef.close(false);
      });
    } else {
      const message = res.message;
      // just to add the country code objects


    
      this._appSer.addSnackBar({ message });
    }
  }

  successsResend(res) {}
  popupmessage(data) {
    const value = "Sign Up";
    const content =
      "Please enter the verification code which is sent to your email.";
    const dialogRef = this.dialog.open(OtpComponent, {
      // maxWidth: "500px",
      width: "500px",
      height: "auto",
      data: {
        content: {
          title: content,
          heading: value,
          message1: 1,
          ok: data.email,
        },
        action: "active",
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.dialogRef.close(false);
    });
  }
  error(message) {

    this._appSer.addSnackBar({ message });
  }
  error1(message) {
    this._appSer.addSnackBar({ message });
  }


  sendEmailtoUser() {
    this._api.postReqUnauth("sendEmailToUser", this.signupForm.value).subscribe(
      (res) => this.successEmail(res),
      (err) => this.errorEmail(err.message),
      () => (this.loaderButton = false)
    );
  }

  successEmail(res) {
    //console.log("email sent")
  }

  errorEmail(msg) {
    //console.log("")
  }

  ngAfterViewInit() {

    var firstElem = document.getElementsByClassName(
      "mat-form-field-appearance-fill"
    );

    var secondElem = document.getElementsByClassName("mat-form-field-flex");

    var thirdElem = document.getElementsByClassName("mat-form-field-infix");

    // .mat-form-field-appearance-fill .mat-form-field-infix

    for (var i = 0; i < firstElem.length; i++) {
      firstElem[i].classList.add("custom-flex");
    }
    for (var i = 0; i < secondElem.length; i++) {
      secondElem[i].classList.add("custom-flex");
    }

    for (var i = 0; i < firstElem.length; i++) {
      thirdElem[i].classList.add("custom-infix");
    }
  }

  no() {}
  login() {}

  ngOnDestroy() {

    localStorage.removeItem("enteredEmail")
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */



}
