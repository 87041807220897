import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LanguagesComponent } from './languages/languages.component';
import { CommonComponentModule } from '../common-component/common-component.module';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';

  
const pageRoutes: Routes = [

  { path: '', component: LanguagesComponent },
  { path: 'arabic', loadChildren: './arabic/arabic.module#ArabicModule'},
  { path: 'english', loadChildren: './english/english.module#EnglishModule'},
  { path: 'hindi', loadChildren: './hindi/hindi.module#HindiModule'},
  { path: 'spanish', loadChildren: './spanish/spanish.module#SpanishModule'},
  { path: 'french', loadChildren: './french/french.module#FrenchModule'},
  { path: 'german', loadChildren: './german/german.module#GermanModule'},
 
];

@NgModule({
  imports: [
    CommonComponentModule,
    CommonModule,
    SharedModule,
    RouterModule,
    AngularMaterialModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [LanguagesComponent],
})
export class LanguagesModule {

constructor(){




}

}

