import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';  
@Component({
  selector: 'app-cbse-india',
  templateUrl: './cbse-india.component.html',
  styleUrls: ['./cbse-india.component.css','../../languages/languages/languages.component.css','../../cbse-nigeria/cbse-nigeria/cbse-nigeria.component.css']
})
export class CbseIndiaComponent implements OnInit {
constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {
  
  }

  ngOnInit() {
    var host=  location.pathname;
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "CBSE Online Tuition in India | Tutoring in India");
    this.meta.updateTag({name:"description", content:"Need online tuition for CBSE Board in India? Enroll at Ziyyara which provides home tuition for CBSE Board in India for all subjects to all grade students."})
    this.metaService.removeStructuredData();
    //this.metaService.insertSchema(this.websiteSchema);
     this.meta.updateTag({name:"keywords", content:"home tuition for cbse board in india, home tuition near me for cbse board in india, home tuitions for cbse board in india, home tutor for cbse board in india, home tutors near me for cbse board in india, online classes for cbse in india, online tuition classes for cbse in india, online tuition for cbse board in india, online tutor for cbse board in india, online tutoring for cbse board in india, private tuition for cbse board in india, private tutor for cbse board in india, tuition at home for cbse board in india, tuition centre for cbse board in india, tuition classes for cbse in india"})
     var ogTitle='CBSE Online Tuition in India | Tutoring in India';
     var ogDescription="home tuition for cbse board in india, home tuition near me for cbse board in india, home tuitions for cbse board in india, home tutor for cbse board in india, home tutors near me for cbse board in india, online classes for cbse in india, online tuition classes for cbse in india, online tuition for cbse board in india, online tutor for cbse board in india, online tutoring for cbse board in india, private tuition for cbse board in india, private tutor for cbse board in india, tuition at home for cbse board in india, tuition centre for cbse board in india, tuition classes for cbse in india"
     this.meta.updateTag({property:"og:title", content:ogTitle});
     this.meta.updateTag({property:"og:description", content:ogDescription});
     this.meta.updateTag({name:"twitter:title", content:ogTitle});
     this.meta.updateTag({name:"twitter:description", content:ogDescription});
 
  }

}
