
import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { ERROR_MESSAGES, Regex, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { AppService, HttpService } from "../../../../services";
import { AppStateService } from "../../../../services/app-state.service";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material";
import { Subscription, Observable } from "rxjs";
import { TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SignupComponent } from "../../../shared/components/signup/signup.component";
import { LoginComponent } from "../../../shared/components/login/login.component";
import { OtpComponent } from "../../../shared/components/otp/otp.component";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IPROPERTY, AppState, ApiResponse } from "../../../../interfaces";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { SuccessComponent } from "../../../shared/components/success/success.component";





@Component({
  selector: 'app-assignment-form',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['./assignment-form.component.css']
})
export class AssignmentFormComponent implements OnInit {
  minDate = new Date();
  maxDate = new Date();
  lselected;
  images;
  imgSrc;
  finalCountryCode;
  adcontactForm: FormGroup;
  submit;
  signupForm: FormGroup;
  submitted = false;
  modalRef: BsModalRef;
  // countryCodes:any;
  loaderButton;
  errorMessage;
  disabled = false;
  CONFIG = CONFIG;
  FORM_ERROR = {
    stu_name: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },

    stu_email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`,
    },
    stu_mobile: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`,
    },
    stu_subject: {
      required: ERROR_MESSAGES.SUBJECT,
    },
    submission_date: {
      required: ERROR_MESSAGES.DATE_REQUIRED,
    },

    word_count: {
      required: ERROR_MESSAGES.WORD_COUNT,
    },
    imageUrl : {
      required: ERROR_MESSAGES.ASSIGNMENT_DOC_REQUIRED,
    },

    countryCode: {
      required: ERROR_MESSAGES.COUNTRYCODE_REQUIRED_MAIN,
    },
    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
    },
    confirmPassword: {
      required: ERROR_MESSAGES.CONFIRM_PASSWORD,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,
      matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH,
    },
    type: {
      required: ERROR_MESSAGES.USER_TYPES,
    },
  
   
  };



  tailUrl = "";
  // @Input() title: String;
  // @Input() subTitle: String;
  cntry: any;
  constructor(
    public dialog: MatDialog,
    private form: FormBuilder,
    public _util: CommonUtil,
    private appService: AppService,
    private httpService: HttpService,
    public appStateSer: AppStateService,
    private modalService: BsModalService,
    private _fb: FormBuilder
  ) {
   
    this.createForm();
  }

  /** list of banks */

  /** control for the selected bank */
  // public countryCode: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filteredBanks = new ReplaySubject(1);
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  countryCodes = [];
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  bank = [];
  banks = [];

  noEntriesFoundLabel = "No Such Country";
  placeholderLabel = "Search";

  createForm() {
    this.signupForm = this._fb.group({
      stu_name: ["",[Validators.required,Validators.maxLength(200),Validators.pattern(Regex.spacecharacter),],],
      stu_email: ["", [Validators.required, Validators.pattern(Regex.email)]],
      stu_mobile: ["",[Validators.required,Validators.pattern(Regex.phoneNumbers),Validators.minLength(CONFIG.MOBILE_MIN_LENGTH),],],
      stu_subject: ['', [Validators.required]],
      submission_date: ['', [Validators.required]],
      word_count: ['', [Validators.required]],
      imageUrl: ["", [Validators.required]],
      stu_message: ["", []],
      countryCode: ["", [Validators.required]],
      country: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(Regex.password),
          Validators.pattern(Regex.spaces),
        ],
      ],
      u_type: ["", [Validators.required]],
    });
  }
  generatePassword(length) {
    var digits = "0123456789";
    let pass = "";
    for (let i = 0; i < 8; i++) {
      pass += digits[Math.floor(Math.random() * 10)];
    }
    return pass;
  }
 

  ngOnInit() {

    window.scrollTo(0, 0);
    this.countryCodes = this.appService.returnCountryList();
    this.bank = this.countryCodes;
    this.banks = this.bank;
    // console.log(this.bank);
    // load the initial bank list
    this.filteredBanks.next(this.banks.slice());
    // listen for search field value changes
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`,
    };
    let ltype = localStorage.getItem("mylanguage");
    if (ltype == "ar") {
      this.lselected = "ar";
      this.submit = "تقديم الطلب ";
    } else {
      this.lselected = "en";
      this.submit = "Submit";
    }
  }
    signup(template: TemplateRef<any>): void {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "425px",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((result) => {});

    this.modalRef.hide();
  }

  submitUser() {
  

    this.signupForm.get("password").setValue(this.generatePassword(9));
    this.signupForm.get("u_type").setValue(1);

    this.finalCountryCode = this.signupForm.get("countryCode").value;
    if (this.signupForm.get("countryCode").value) {
      //here we separte country name  from the countryCode object
      this.signupForm
        .get("country")
        .patchValue(this.signupForm.get("countryCode").value.name);
    }
    if (this.signupForm.valid) {
     // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      if (this.signupForm.get("countryCode").value) {
        //here we separate countryCode   from the countryCode object
        this.signupForm.get("countryCode").patchValue(this.signupForm.get("countryCode").value.code);
      }
      this.disabled = true;
 
       this.signupForm.value.stu_name = this.signupForm.value.stu_name.toString().trim();
  
      this.signupForm.value.origin_page = "https://ziyyara.com"+location.pathname;
      this.signupForm.value.stu_assignment = this.signupForm.value.imageUrl;
       // edit by nazimahmed
        this.cntry = this.signupForm.value.country.replace(/[^a-z]/gi,' ');
        this.signupForm.value.country=this.cntry.trim();
        // console.log(this.signupForm.value);
        //end
       
      //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      // console.log(this.signupForm.value);
      this.httpService.postReqUnauth("postassignmenthelp", this.signupForm.value).subscribe(
        (res) => this.success(res),
        (err) => this.error(err.message),
        () => {
          (this.loaderButton = false), (this.disabled = false);
        }
      );

     this.signupForm.value.name = this.signupForm.value.stu_name.toString().trim();
     this.signupForm.value.email = this.signupForm.value.stu_email;
     this.signupForm.value.contactNo = this.signupForm.value.stu_mobile;

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      this.httpService.postReqUnauth("users", this.signupForm.value).subscribe(
        // (res) => this.success(res),
        // (err) => this.error(err.message),
        // () => {
        //   (this.loaderButton = false), (this.disabled = false);
        // }
      );
    } else {

      this._util.markError(this.signupForm);
    }
  }
  get stu_name(): FormControl {
    return this.signupForm.get("stu_name") as FormControl;
  }
  get stu_email(): FormControl {
    return this.signupForm.get("stu_email") as FormControl;
  }

  get stu_mobile(): FormControl {
    return this.signupForm.get("stu_mobile") as FormControl;
  }
  get stu_subject(): FormControl {
    return this.signupForm.get("stu_subject") as FormControl;
  }
  get submission_date(): FormControl {
    return this.signupForm.get("submission_date") as FormControl;
  }
  get word_count(): FormControl {
    return this.signupForm.get("word_count") as FormControl;
  }
  get imageUrl(): FormControl {
    return this.signupForm.get("imageUrl") as FormControl;
  }
  get stu_message(): FormControl {
    return this.signupForm.get("stu_message") as FormControl;
  }
 
  get countryCode(): FormControl {
    return this.signupForm.get("countryCode") as FormControl;
  }
  get country(): FormControl {
    return this.signupForm.get("country") as FormControl;
  }
  get password(): FormControl {
    return this.signupForm.get("password") as FormControl;
  }
  get u_type(): FormControl {
    return this.signupForm.get("u_type") as FormControl;
  }


  success(res: ApiResponse) {
    if (res.status == true) {
      // this.dialogRef.close(false);
      const value = "Sign Up";
      const content =
        "Your assignment enquiry has been done successfully! We will right back soon";
      const dialogRef = this.dialog.open(SuccessComponent, {
        // maxWidth: "500px",
        width: "700px",
        height: "auto",
        data: {
          content: { title: content, heading: "Success" },
          action: "active",
        },
        // disableClose: true
      });

     
      dialogRef.afterClosed().subscribe((result) => {
        this.signupForm.reset();
        dialogRef.close(false);
      });
    } else {
      const message = res.message;
      this.appService.addSnackBar({ message });
    }
  }

  successsResend(res) {}

  error(message) {
    // //console.log("error")
    this.appService.addSnackBar({ message });
  }
  error1(message) {
    this.appService.addSnackBar({ message });
  }
  onChangeCode(event) {
    console.log("country");
    console.log(event.value);
    var temp = this.countryCodes.filter((ele) => ele.code == event.value);
    console.log(temp);
    this.signupForm.get("country").patchValue(temp[0].name);
  }


  uploaded(fileName: string) {
    this.images = fileName;
    this.imgSrc = fileName;
  }
  changeTimezone(args) {
    //////console.log(args);
  }
  changeCountryCode(args) {
    //////console.log(args);
  }
 getFileExtension(filename) {
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      // alert(event.target.files[0].name)
      var extension = this.getFileExtension(event.target.files[0].name);
      var allowedExtensions = ["jpg", "jpeg", "bmp", "gif", "png",'pdf'];
      if (allowedExtensions.indexOf(extension) === -1) {
        this.appService.addSnackBar({
          message:
            "Invalid file Format. Only " +
            allowedExtensions.join(", ") +
            " are allowed.",
        });
        return false;
      }
      formData.append(
        "image",
        event.target.files[0],
        
        event.target.files[0].name
      );
    //  console.log(":~formData",formData)
    
      this.httpService.postReqUnauths("image_upload", formData).subscribe(
        (res) => this.uploadImage(res),
        (err) => this.error(err.message)
      );
    }
  }
  uploadImage(res) {
  
    if (res.imageUrl) {
      this.images = this.imgSrc = res.imageUrl;
      this.signupForm.get("imageUrl").patchValue(this.imgSrc)
    }
  }



  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }









}


