<app-header></app-header>
<!-- <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll(activData)">
    -->
<div class="reola-breadcum">
  <div class="container">
    <div class="col-md-12">
      <ol class="breadcrumb pt-3">
        <li class="breadcrumb-item " routerLink="/dashboard" aria-current="page">Home</li>
        <li class="breadcrumb-item" routerLink="/search" aria-current="page">Search a Tutor</li>
        <li class="breadcrumb-item active" aria-current="page">View profile</li>

      </ol>
    </div>



  </div>
</div>

<div class="container">
  <div class="property-header">
    <h1>Tutor Profile</h1>
  </div>
</div>


<div class="min-height">
  <div class="container">
    <div class="property-listing">

      <div class="listing-body">
        <div class="row">
          <div class="col-md-3">
            <!--left col-->

            <div class="card"> 
              <div class="card-body">
                <div class="profile-reola relative">
                  <div class="reola-profile-image">
                    <div class="profile-image-wrapper  "
                      [ngStyle]="{background: (profileData?.dp && 'url('+ profileData?.dp  +')') || 'url(assets/images/about/three.jpeg)'}">
                    </div>
                    <div class="image-content d-flex align-items-center justify-content-center">
                      <img [src]="profileData?.dp || 'assets/images/about/three.jpeg'">
                    </div>
                  </div>
                  <!-- [style.backgroundImage]="'url( profileData?.dp ? 'profileData?.dp': 'assets/images/about/three.jpeg')'"> -->

                </div>
                <div>
                  <div class="panel panel-default mb-2 mt-2">
                    <div class="panel-heading">
                      <p><span class="font-weight-bold">Name:</span> {{profileData.name}}</p>
                    </div>
                  </div>

                  <div class="panel panel-default mb-3">
                    <div class="panel-heading">
                      <p><span class="font-weight-bold" >Experience:</span>
                  {{experience}}</p>
    
                      </div>
                    <div class="panel-body">
                      <span class="inline-block">
                      </span>
                    </div>
                  </div>
                  <div class="panel panel-default mb-3">
                    <div class="panel-heading">
                      <p class="font-weight-bold">Board: </p>
                    </div>
                    <div class="panel-body">
                      <span class="inline-block" *ngFor="let board of profileData.board">
                        {{board}}</span>
                    </div>
                  </div>

                  <div class="panel panel-default mb-3">
                    <div class="panel-heading">
                      <p class="font-weight-bold">Language:</p>
                    </div>
                    <div class="panel-body">
                      <span class="inline-block" *ngFor="let languagess of profileData.language">
                        {{objectFindByKeyL(languagess)}}</span>
                    </div>
                  </div>

                  <div class="pt-1">
                    <a [routerLink]="['/search/book',{id :id}]" class="d-block">
                      <button type="button" class="btn btn-ziyyara-success btn-block m-0 ">Book Session</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!--/col-3-->
          <div class="col-md-9 view-profile-tutor" contenteditable="false">
            <div class="card">
              <div class="card-body">
                <div class="panel panel-default mb-3">
                  <div class="panel-heading">
                    <h4>About Me </h4>
                  </div>
                  <p class="">{{profileData.about}}
                  </p>
                </div>
                <div class="panel panel-default mb-3">
                  <div class="panel-heading pb-2">
                    <h4>Available days </h4>
                  </div>
                  <div class="panel-body availabe-hour clearfix">
                    <div class="time-listing float-left"
                      *ngFor="let request of profileData.if_teacher?.availabilty; let i=index">
                      {{request}}</div>
                  </div>
                </div>
                <div class="panel panel-default mb-3">
                  <div class="panel-heading pb-2">
                    <h4>Available hours </h4>
                  </div>
                  <div class="panel-body availabe-hour clearfix">
                    <div class="time-listing float-left" *ngFor="let hoursDay of hoursDays; let i=index">
                      {{hoursDay.start }} - {{hoursDay.end}}
                    </div>
                  </div>
                </div>
                <div class="panel panel-default mb-3">
                  <div class="panel-heading pb-2">
                    <h4>Grade:</h4>
                  </div>
                  <div class="panel-body availabe-hour clearfix">
                    <div class="time-listing float-left" *ngFor="let grade of profileData.grade">
                      Grade {{grade}}
                    </div>
                  </div>
                </div>
 
                <div class="panel panel-default mb-3">
                  <div class="panel-heading pb-2">
                    <h4>Subjects:</h4>
                  </div>

                  <div class="panel-body availabe-hour clearfix">
                    <div *ngFor="let subjectss of profileData.if_teacher?.subjects">
                      <div class="time-listing float-left" *ngIf="objectFindByKeyS(subjectss)">
                        {{objectFindByKeyS(subjectss)}}
                      </div>
                    </div>
                  </div>


                  <hr style="border:3px solid #f1f1f1" hidden>
  
                  <div *ngIf="reviews.length>0" hidden>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4>Rating & Reviews</h4>
                    </div>

                    <div class="panel-body ">
                      <div class="">
                        <p>Total rating: {{reviewsCount}}</p>
                        <p>All reviews: {{reviewsReview}}</p>
                        <hr style="border:3px solid #f1f1f1">



                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    

                    <div class="panel-body"  *ngFor="let review of reviews; let i=index">

                      <div class="row">
                        <div class="col-md-2" *ngIf="!review.given_by[0]?.dp"><img class="card-img-top rounded img-card" src='assets/images/about/three.jpeg'
                            alt="Card image cap">
                          <br>

                        </div>
                        <div class="col-md-2" *ngIf="review.given_by[0]?.dp"><img class="card-img-top rounded img-card" [src]="review.given_by[0]?.dp"
                          alt="Card image cap">
                        <br>

                      </div>
                        <div class="col-md-10">
                          <div class="card-body">
                            <h3 class="" style="margin:0px;">{{review.given_by[0]?.name}}</h3>
                            <div class="">
                                <fieldset class="rating">
                                    <input type="radio" [name]="inputName" value="5" [checked]="review.rating===5" />
                                    <label title="Rocks!" >5 stars</label>
                                  
                                    <input type="radio" [name]="inputName" value="4" [checked]="review.rating===4" />
                                    <label title="Pretty good" >4 stars</label>
                                  
                                    <input type="radio" [name]="inputName" value="3" [checked]="review.rating===3" />
                                    <label title="Meh" >3 stars</label>
                                  
                                    <input type="radio" [name]="inputName" value="2" [checked]="review.rating===2" />
                                    <label title="Kinda bad" >2 stars</label>
                                  
                                    <input type="radio" [name]="inputName" value="1" [checked]="review.rating===1" />
                                    <label title="Sucks big time" >1 star</label>
                                  </fieldset>
                                    </div>
                            <p class="card-text pull-left" style="width:80%">{{review?.review}}
                          
                            </p>



                          </div>

                        </div>



                      </div>
                    </div>
                  </div> 
                
                </div>
                <div *ngIf="reviews.length==0" hidden>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4>Rating & Reviews</h4>
                      </div>
  
                      <div class="panel-body ">
                        <div class="">
  
                          <p>No review and rating</p>
                          <hr style="border:3px solid #f1f1f1">
  
  
  
                        </div>
                      </div>
                    </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 
</div> -->
<div class="property-footer">
  <app-footer></app-footer>
</div>