
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common'; 

import { SharedModule } from '../shared/shared.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { FormsModule } from '@angular/forms';
import { ReferComponent } from './refer/refer.component';


const pageRoutes: Routes = [

  { path: '', component: ReferComponent },
  
]; 

@NgModule({
  imports: [
    CommonComponentModule,
    CommonModule,
    SharedModule,
    FormsModule,
    AngularMaterialModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [ReferComponent],
  entryComponents: []
})
export class ReferModule { }
