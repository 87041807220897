<app-header-front></app-header-front>

<div class="how-it-works">
    <div class="bgc">
        <div class="pdbt">
            <h1 class="arttext">
                11th & 12th Grade Chapters (Commerce)</h1>
        </div>
    </div>
    <div class="fixhd " id="stickyHeader">
        <div class="bgc">
            <div class="stpdbt">
                <h4 class="center">
                    11th & 12th Grade Chapters (Commerce)</h4>
            </div>
        </div>
    </div>
    <div class="mcm">
        <h1 class="center"> 11th Grade Chapters </h1>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/cbse/icon-english.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        English</h5>


                    <div class="df">
                        <div>
                            <h5> Book - Hornbill Prose </h5>

                            <ol>
                                <li> Chapter 1 The Portrait of a Lady </li>
                                <li> Chapter 2 We’re Not Afraid to Die… If We Can All Be Together </li>
                                <li> Chapter 3 Discovering Tut: The Saga Continues </li>
                                <li> Chapter 4 Landscape of the Soul </li>
                                <li> Chapter 5 The Ailing Planet: the Green Movement’s Role </li>
                                <li> Chapter 6 The Browning Version </li>
                                <li> Chapter 7 The Adventure </li>
                                <li> Chapter 8 Silk Road </li>
                            </ol>
                        </div>
                        <div>
                            <h5> Book - Hornbill Poem </h5>
                            <ol>
                                <li> Poem 1 A Photograph </li>
                                <li> Poem 2 The Laburnum Top </li>
                                <li> Poem 3 The Voice of the Rain </li>
                                <li> Poem 4 Childhood </li>
                                <li> Poem 5 Father to Son </li>
                            </ol>
                        </div>
                        <div>
                            <h5>Book - Snapshots </h5>
                            <ol>
                                <li> Chapter 1 The Summer of the Beautiful White Horse </li>
                                <li> Chapter 2 The Address </li>
                                <li> Chapter 3 Ranga’s Marriage </li>
                                <li> Chapter 4 Albert Einstein at School </li>
                                <li> Chapter 5 Mother’s Day </li>
                                <li> Chapter 6 The Ghat of the Only World </li>
                                <li> Chapter 7 Birth </li>
                                <li> Chapter 8 The Tale of Melon City </li>
                            </ol>
                        </div>

                    </div>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>




        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-hindi.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        Book - आरोह, भाग-1 (पाठ्यपुस्तक)
                    </h5>


                    <div class="df">

                        <div>

                            <h5>काव्य भाग </h5>
                            <ol>
                                <li> Chapter 1 हम तौ एक एक करि जांनां, संतों देखत जग बौराना </li>
                                <li> Chapter 2 मेरे तो गिरधर गोपाल दूसरो न कोई, पग घुँघरू बाधि मीरां नाची </li>
                                <li> Chapter 3 पथिक </li>
                                <li> Chapter 4 वे आँखें </li>
                                <li> Chapter 5 घर की याद </li>
                                <li> Chapter 6 चंपा काले-काले अच्छर नहीं चीन्हती </li>
                                <li> Chapter 7 गजल </li>
                                <li> Chapter 8 हे भूख! मत मचल, हे मेरे जूही के फूल जैसे ईश्वर </li>
                                <li> Chapter 9 सबसे खतरनाक </li>
                                <li> Chapter 10 आओ, मिलकर बचाएँ </li>
                            </ol>
                        </div>
                        <div>
                            <h5> गद्य भाग </h5>
                            <ol>
                                <li> Chapter 11 नमक का दारोगा </li>
                                <li>Chapter 12 मियाँ नसीरुद्दीन </li>
                                <li> Chapter 13 अपू के साथ ढाई साल </li>
                                <li> Chapter 14 विदाई-संभाषण </li>
                                <li> Chapter 15 गलता लोहा </li>
                                <li> Chapter 16 स्पीति में बारिश </li>
                                <li> Chapter 17 रजनी </li>
                                <li> Chapter 18 जामुन का पेड़ </li>
                                <li> Chapter 19 भारत माता </li>
                                <li> Chapter 20 आत्मा का ताप </li>
                            </ol>
                        </div>
                        <div>
                            <h5>Book - वितान, भाग-1 (पूरक पाठ्यपुस्तक) </h5>
                            <ol>

                                <li> Chapter 1 भारतीय गायिकाओं में बेजोड़ : लता मंगेशकर </li>
                                <li> Chapter 2 राजस्थान की रजत बूँदें </li>
                                <li> Chapter 3 आलो-आँधारि </li>
                            </ol>
                        </div>
                    </div>
                    <h5 class="card-title">
                        Hindi (Part II)
                    </h5>

                    <div class="df">

                        <div>
                            <h5>Book - Antra </h5>
                            <ol>
                                <li> Chapter 1 ईदगाह </li>
                                <li> Chapter 2 दोपहर का भोजन </li>
                                <li> Chapter 3 टार्च बेचनेवाले </li>
                                <li> Chapter 4 गूँगे </li>
                                <li> Chapter 5 ज्योतिबा फुले </li>
                                <li> Chapter 6 खानाबदोश </li>
                                <li> Chapter 7 नए की जन्म कुंडली: एक </li>
                                <li> Chapter 8 उसकी माँ </li>
                                <li> Chapter 9 भारतवर्ष की उन्नति कैसे हो सकती है? </li>
                                <li> Chapter 10 कबीर </li>
                                <li> Chapter 11 सूरदास </li>
                                <li> Chapter 12 देव </li>
                                <li> Chapter 13 पद्माकर </li>
                                <li> Chapter 14 सुमित्रानंदन पंत </li>
                                <li> Chapter 15 महादेवी वर्मा </li>
                                <li> Chapter 16 नरेंद्र शर्मा </li>
                                <li> Chapter 17 नागार्जुन </li>
                                <li> Chapter 18 श्रीकांत वर्मा </li>
                                <li> Chapter 19 धूमिल </li>
                            </ol>
                        </div>
                        <div>
                            <h5>Book - Antral </h5>
                            <ol>
                                <li> Chapter 1 अंडे के छिलके </li>
                                <li> Chapter 2 हुसैन की कहानी अपनी ज़बानी </li>
                                <li> Chapter 3 आवारा मसीहा </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-economics.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Economics</h5>


                    <div class="df">
                        <div>

                            <h5> Book - Indian Economic Development </h5>

                            <li> Chapter 1 Indian Economy on the Eve of Independence </li>
                            <li> Chapter 2 Indian Economy 1950-1990 </li>
                            <li> Chapter 3 Liberalisation, Privatisation and Globalisation -An Appraisal </li>
                            <li> Chapter 4 Poverty </li>
                            <li> Chapter 5 Human Capital Formation in India </li>
                            <li> Chapter 6 Rural Development </li>
                            <li> Chapter 7 Employment-Growth, Informalisation and Related Issues </li>
                            <li> Chapter 8 Infrastructure </li>
                            <li> Chapter 9 Environment Sustainable Development </li>
                            <li> Chapter 10 Comparative Development Experience of India with its Neighbours </li>



                        </div>
                        <div>

                            <h5> Book - Statistics for Economics </h5>

                            <li> Chapter 1 Introduction </li>
                            <li> Chapter 2 Collection of Data </li>
                            <li> Chapter 3 Organisation of Data </li>
                            <li> Chapter 4 Presentation of Data </li>
                            <li> Chapter 5 Measures of Central Tendency </li>
                            <li> Chapter 6 Measures of Dispersion </li>
                            <li> Chapter 7 Correlation </li>
                            <li> Chapter 8 Index Numbers </li>


                        </div>

                    </div>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-accounts.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Accountancy</h5>
                    <div class="df">
                        <div>
                            <h5>Book - Part 1 Financial Accounting </h5>
                            <ol>
                                <li> Chapter 1 Introduction to Accounting </li>
                                <li> Chapter 2 Theory Base of Accounting </li>
                                <li> Chapter 3 Recording of Transactions – I </li>
                                <li> Chapter 4 Recording of Transactions – II </li>
                                <li> Chapter 5 Bank Reconciliation Statement </li>
                                <li> Chapter 6 Trial Balance and Rectification of Errors </li>
                                <li> Chapter 7 Depreciation, Provisions and Reserves </li>
                                <li> Chapter 8 Bills of Exchange </li>
                            </ol>
                        </div>
                        <div>

                            <h5>Book - Part 1 Financial Accounting </h5>

                            <ol>
                                <li> Chapter 1 Financial Statements – I </li>
                                <li> Chapter 2 Financial Statements </li>
                                <li> Chapter 3 Accounts from Incomplete Records </li>
                                <li> Chapter 4 Accounting for Not-for-Profit Organisation </li>
                                <li> Chapter 5 Applications of Computers in Accounting </li>
                                <li> Chapter 6 Computerized Accounting System </li>
                                <li> Chapter 7 Structuring Database for Accounting </li>
                                <li> Chapter 8 Accounting System Using Database Management System </li>
                            </ol>
                        </div>

                    </div>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-math.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Maths </h5>
                    <ol>
                        <div class="df">
                            <div>
                                <h5> Chapter 1 Sets </h5>

                                <li> Chapter 2 Relations and Functions </li>
                                <li> Chapter 3 Trigonometric Functions </li>
                                <li> Chapter 4 Principle of Mathematical Induction </li>
                                <li> Chapter 5 Complex Numbers and Quadratic Equations </li>
                                <li> Chapter 6 Linear Inequalities </li>
                                <li> Chapter 7 Permutation and Combinations </li>

                            </div>
                            <div>
                                <li> Chapter 8 Binomial Theorem </li>
                                <li> Chapter 9 Sequences and Series </li>

                                <li> Chapter 10
                                    <ul>
                                        <li> Straight Lines Exercise 10.1 </li>
                                        <li> Straight Lines Exercise 10.2 </li>
                                        <li> Straight Lines Exercise 10.3 </li>
                                        <li> Straight Lines Exercise Miscellaneous Questions </li>
                                    </ul>

                                </li>
                            </div>
                            <div>
                                <li> Chapter 11 Conic Sections </li>
                                <li> Chapter 12 Introduction to Three Dimensional Geometry </li>
                                <li> Chapter 13 Limits and Derivatives </li>
                                <li> Chapter 14 Mathematical Reasoning </li>
                                <li> Chapter 15 Statistics </li>
                                <li> Chapter 16 Probability </li>

                            </div>
                        </div>
                    </ol>


                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>







        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-business-studies.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Business Studies</h5>
                    <ol>
                        <div class="df">


                            <!-- <h5>  Business Studies </h5> -->
                            <div>
                                <li> Chapter 1 Nature and Purpose of Business </li>
                                <li> Chapter 2 Forms of Business Organisation </li>
                                <li> Chapter 3 Private, Public and Global Enterprises </li>
                                <li> Chapter 4 Business Services </li>
                            </div>
                            <div>
                                <li> Chapter 5 Emerging Modes of Business </li>
                                <li> Chapter 6 Social Responsibilities of Business and Business Ethics </li>
                                <li> Chapter 7 Formation of a Company </li>
                                <li> Chapter 8 Sources of Business Finance </li>
                            </div>
                            <div>
                                <li> Chapter 9 Small Business </li>
                                <li> Chapter 10 Internal Trade </li>
                                <li> Chapter 11 International Business-I </li>
                                <li> Chapter 12 International Business-II </li>

                            </div>


                        </div>
                    </ol>


                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>
    </div>




    <div class="mcm">
        <h1 class="center"> 12th Grade Chapters </h1>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/cbse/icon-english.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        English</h5>


                    <div class="df">
                        <div>






                            <h5> English Flamingo Prose </h5>
                            <ol>
                                <li> The Last Lesson </li>
                                <li> Lost Spring </li>
                                <li> Deep Water </li>
                                <li> The Rattrap </li>
                                <li> Indigo </li>
                                <li> Poets and Pancakes </li>
                                <li> The Interview </li>
                                <li> Going Places </li>
                            </ol>
                        </div>
                        <div>
                            <h5> English Flamingo Poem </h5>
                            <ol>
                                <li> My Mother at Sixty-six </li>
                                <li> An Elementary School Classroom in a Slum </li>
                                <li> Keeping Quiet </li>
                                <li> A Thing of Beauty </li>
                                <li> A Roadside Stand </li>
                                <li> Aunt Jennifer’s Tigers </li>

                            </ol>
                        </div>
                        <div>
                            <h5> English Vistas </h5>
                            <ol>
                                <li> Third Level </li>
                                <li> The Tiger King </li>
                                <li> The Enemy </li>
                                <li> Journey to the End of the Earth </li>
                                <li> Should a Wizard Hit Mommy? </li>
                                <li> On the Face of It </li>
                                <li> Chapter 7 Evans Tries an O-level </li>
                                <li> Chapter 8 Memories of Childhood </li>
                            </ol>
                        </div>

                    </div>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>




        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-hindi.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title">
                        Hindi आरोह, भाग-2 (पाठ्यपुस्तक)
                    </h5>


                    <div class="df">
                        <div>






                            <h5> (अ) काव्य भाग </h5>
                            <ol>
                                <li> आत्म-परिचय, एक गीत </li>
                                <li> पतंग </li>
                                <li> कविता के बहाने, बात सीधी थी पर </li>
                                <li> कैमरे में बंद अपाहिज </li>
                                <li> सहर्ष स्वीकारा है </li>
                                <li> उषा </li>
                                <li> बादल राग </li>
                                <li> कवितावली (उत्तर कांड से), लक्ष्मण-मूच्छ और राम का विलाप </li>
                                <li> रुबाइयाँ, गज़ल </li>
                                <li> छोटा मेरा खेत, बगुलों के पंख </li>
                            </ol>
                        </div>
                        <div>
                            <h5> (ब) गद्य भाग </h5>


                            <ol>
                                <li> भक्तिन </li>
                                <li> बाजार दर्शन </li>
                                <li> काले मेघा पानी दे </li>
                                <li> पहलवान की ढोलक </li>
                                <li> चार्ली चैप्लिन यानी हम सब </li>
                                <li> नमक </li>
                                <li> शिरीष के फूल </li>
                                <li> श्रम-विभाजन और जाति-प्रथा, मेरी कल्पना का आदर्श समाज </li>
                            </ol>
                        </div>
                        <div>
                            <h5> Hindi वितान, भाग-2 (पूरक पाठ्यपुस्तक) </h5>
                            <ol>

                                <li> सिल्वर वैडिंग </li>
                                <li> जूझ </li>
                                <li> अतीत में दबे पाँव </li>
                                <li> डायरी के पन्ने </li>
                            </ol>
                        </div>



                    </div>
                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-economics.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Economics (Macro Economics) </h5>
                    <ol>
                        <div class="df">

                            <div>



                                <li> Introduction to Macroeconomics and its Concepts </li>
                                <li> National Income and Related Aggregates </li>
                                <li> Money </li>
                                <li> Banking </li>


                            </div>
                            <div>
                                <li> Aggregate Demand and Its Related Concepts </li>
                                <li> National Income Determination and Multiplier </li>
                                <li> Excess Demand and Deficient Demand </li>


                            </div>
                            <div>
                                <li> Government Budget and the Economy </li>
                                <li> Foreign Exchange Rate </li>
                                <li> Balance of Payment </li>
                            </div>

                        </div>
                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-economics.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Economics (Micro Economics) </h5>
                    <ol>
                        <div class="df">
                            <div>




                                <li> Introduction to Economics </li>
                                <li> Consumer Equilibrium </li>
                                <li> Demand </li>
                                <li> Elasticity of Demand </li>





                            </div>
                            <div>
                                <li> Production </li>
                                <li> Cost </li>
                                <li> Supply </li>
                                <li> Revenue </li>


                            </div>
                            <div>
                                <li> Producer Equilibrium </li>
                                <li> Perfect Competition </li>
                                <li> Non-Competitive Market </li>
                                <li> Market Equilibrium with Simple Applications </li>
                            </div>
                        </div>
                    </ol>


                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-accounts.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Accountancy Part 1 Partnership Accounts </h5>
                    <ol>
                        <div class="df">
                            <div>
                                <li> Accounting for Partnership : Basic Concepts </li>
                                <li> Accounting for Not for Profit Organisation </li>
                            </div>
                            <div>
                                <li> Reconstitution of a Partnership Firm – Admission of a Partner </li>
                                <li> Reconstitution of a Partnership Firm – Retirement/Death of a Partner </li>
                            </div>
                            <div>
                                <li> Dissolution of Partnership Firm </li>
                            </div>
                        </div>
                    </ol>




                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-accounts.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Accountancy Part 2 Company Accounts and Analysis of Financial Statements </h5>
                    <ol>
                        <div class="df">
                            <div>


                                <li> Accounting for Share Capital </li>
                                <li> Issue and Redemption of Debentures </li>

                            </div>
                            <div>
                                <li> Financial Statements of a Company </li>
                                <li> Analysis of Financial Statements </li>

                            </div>
                            <div>
                                <li> Accounting Ratios </li>
                                <li> Cash Flow Statement </li>
                            </div>
                        </div>

                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-business-studies.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Business Studies Part 1 Principles and Functions of Management</h5>
                    <ol>
                        <div class="df">
                            <div>



                                <li> Nature and Significance of Management </li>
                                <li> Principles of Management </li>


                            </div>
                            <div>
                                <li> Business Environment </li>
                                <li> Planning </li>


                            </div>
                            <div>
                                <li> Organising </li>
                                <li> Staffing </li>
                            </div>
                        </div>

                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icon-business-studies.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Business Studies Part 2 Business Finance and Marketing</h5>
                    <ol>
                        <div class="df">
                            <div>





                                <li> Financial Management </li>
                                <li> Financial Market </li>



                            </div>
                            <div>
                                <li> Marketing </li>
                                <li> Consumer Protection </li>


                            </div>

                        </div>
                    </ol>




                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <img src="assets/images/streams/icons-math.png" class="float-left rounded-circle">
                <div class="message">
                    <h5 class="card-title"> Maths</h5>
                    <ol>
                        <div class="df">
                            <div>





                                <li> Relations and Functions </li>
                                <li> Inverse Trigonometric Functions </li>
                                <li> Matrices </li>
                                <li> Determinants </li>
                                <li> Continuity and Differentiability </li>



                            </div>
                            <div>



                                <li> Application of Derivatives </li>
                                <li> Integrals </li>
                                <li> Application of Integrals </li>
                                <li> Differential Equations </li>



                            </div>
                            <div>
                                <li> Vector Algebra </li>
                                <li> Three Dimensional Geometry </li>
                                <li> Linear Programming </li>
                                <li> Probability </li>
                            </div>

                        </div>

                    </ol>



                </div>
                <div class="actions df">
                    <a routerLink="/ad-contact-humanities" class="card-link btn btn-warning">Free Demo</a>
                    <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                    <a href="https://api.whatsapp.com/send?phone=96871912179" class="card-link btn btn-default">Whatsapp Us</a>
                </div>
            </div>
        </div>
    </div>












</div>


<app-footer-front></app-footer-front>