<div class="modal-wrapper text-center">
    <div class="header-popup"> Booking Details &nbsp;
        <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a>
    </div>
    <div class="success-modal">
        <!-- <div class="success-inner">
        <img src="assets/images/modal/success.svg">    </div> -->
        <div class="">
            <br/>

            <!-- <div class="col-3" *ngFor="let list of edus; let i=index">
          <img class="img-thumbnail" [src]="list">
          <a    [href]="list"><button class="btn btn-success col-12">Download</button></a>
        </div> -->

            <div *ngIf="showDownload" class="detail-div"><b class="detail-p">
          File : </b>
                <p class="detail-b">

                    <a (click)="downloadFile(data)">Download</a>
                </p>
            </div>

            <div class="detail-div"><b class="detail-p">Syllabus : </b>
                <p class="detail-b">{{data?.content?.message}}</p>
            </div>

            <div class="detail-div" *ngIf="data?.content?.element==1"><b class="detail-p">Package Name :</b>
                <p class="detail-b">{{data?.content?.title}}</p>
            </div>
            <div class="detail-div"><b class="detail-p">Number of session(s) :</b>
                <p class="detail-b">{{data?.content?.key}}</p>
            </div>
            <div class="detail-div" *ngIf="data?.content?.element==1"><b class="detail-p">Package Amount(USD) :</b>
                <p class="detail-b"> {{test(data?.content?.heading)}}</p>
            </div>

            <div class="detail-div" *ngIf="data?.content?.message1=='rejectedRequest'"><b class="detail-p">Reason : </b>
                <p class="detail-b">{{data?.content?.ok}}</p>
            </div>
        </div>
    </div>
</div>