import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common'; 
import { CommonComponentModule } from '../common-component/common-component.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { ExamTipsComponent } from './exam-tips.component';
import { SharedModule } from '../shared/shared.module';

const pageRoutes: Routes = [

    { path: '', component: ExamTipsComponent },
   
  ]; 
@NgModule({
  imports:[
    CommonComponentModule,
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [ExamTipsComponent],
  entryComponents: []
})

export class ExamTipsModule {

}


