import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupMessage } from '../../../../interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ERROR_MESSAGES, Regex, CONFIG } from '../../../../constants';
import { CommonUtil } from '../../../../util';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.css']
})
export class ConfirmationBoxComponent implements OnInit {


  reasonForm: FormGroup;
  CONFIG = CONFIG;
  FORM_ERROR = {
    reason: {
      required: ERROR_MESSAGES.REASON_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH} ${CONFIG.NAME_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH} ${CONFIG.MIN_LENGTH}`,
      pattern: "Reason is required"
    }
  };
  constructor(public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    public util: CommonUtil,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage, private _fb: FormBuilder) {

  }



 

  ngOnInit() {
    if (this.data.content.reason) {
      this.createForm();
    }

  }



  createForm() {
    this.reasonForm = this._fb.group({
      reason: ['', [Validators.required, Validators.maxLength(200), Validators.pattern(Regex.avoidOnlySpaceCharaceter)]],
      
    });

    
  }

  get reason(): FormControl {
    return <FormControl>this.reasonForm.get('reason');
  }

  yes() {
    if (this.data.content.reason) {
      if (this.reasonForm.valid) {
        this.dialogRef.close({ response: true, reason: this.reason.value });
      } else {
        this.util.markError(this.reasonForm);
      }
    } else {
      this.dialogRef.close({ response: true });
    }

  }

  no() {
    this.dialogRef.close({ response: false });
  }

}
