<app-header-front></app-header-front>
<div class="ziyyara-team">
    <div class="heading our-team-text">
        <h1>Our Team</h1>
    </div>
    <div class="team-section">
        <div class="team-picture">
            <img src="../../../assets/our-team/nazim-ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>

        <div class="team-picture">
            <img src="../../../assets/our-team/lavkush_ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>

        <div class="team-picture">
            <img src="../../../assets/our-team/lavkush_ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>
    </div>

    <div class="team-section">
        <div class="team-picture">
            <img src="../../../assets/our-team/lavkush_ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>

        <div class="team-picture">
            <img src="../../../assets/our-team/lavkush_ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>

        <div class="team-picture">
            <img src="../../../assets/our-team/lavkush_ziyyara.png" alt="">
            <div class="team-name">
                <h3>DMK Arun</h3>
                <span>kuch bhi</span>
            </div>
        </div>
    </div>
</div>

<div class="svg">

</div>
<app-footer-front></app-footer-front>