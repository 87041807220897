import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-online-home-tuitions-for-all-subjects',
  templateUrl: './online-home-tuitions-for-all-subjects.component.html',
  styleUrls: ['./online-home-tuitions-for-all-subjects.component.css']
})
export class OnlineHomeTuitionsForAllSubjectsComponent implements OnInit {

  constructor(  ) { }

  ngOnInit() {
   
  }

}
