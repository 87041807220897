import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DonationComponent } from './donation/donation.component';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonComponentModule } from '../common-component/common-component.module';
import { BeninComponent } from './benin/benin.component';
import { DrcComponent } from './drc/drc.component';
import { TanzaniaComponent } from './tanzania/tanzania.component';
import { ZanzibarComponent } from './zanzibar/zanzibar.component';


const pageRoutes: Routes = [

  { path: '', component: DonationComponent },
  { path: 'benin', component: BeninComponent },
  { path: 'drc', component: DrcComponent },
  { path: 'tanzania', component: TanzaniaComponent },
  { path: 'zanzibar', component: ZanzibarComponent },




 
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
     RouterModule.forChild(pageRoutes),CommonComponentModule
    
  ],
  declarations: [
    DonationComponent,
    BeninComponent,
    DrcComponent,
    TanzaniaComponent,
    ZanzibarComponent
    
  ],
  entryComponents: []
})
export class DonationModule { }


