import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'our-tutors-card',
  templateUrl: './our-tutors-card.component.html',
  styleUrls: ['./our-tutors-card.component.css', '../../oman-tuition/online-home-tuition-oman/online-home-tuition-oman.component.css']
})
export class OurTutorsCardComponent implements OnInit {
@Input() tutorData;

  constructor() { }

  ngOnInit() {
    console.log(this.tutorData);
  }

}
