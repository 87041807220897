<!-- <div class="loader-wrapper" *ngIf="loader"> 
<ngx-ui-loader [bgsType]="loaderService?.config?.bgsType"></ngx-ui-loader>
 </div> -->

<router-outlet (activate)="onActivate($event)"></router-outlet>

<div class="loader-wrapper" *ngIf="loaderMain">
    <div class="loader-cont">
        <div class="dl">
            <img src="assets/images/z2.png">
            <div class="dl__container">
                <div class="dl__corner--top"></div>
                <div class="dl__corner--bottom"></div>
            </div>
            <div class="dl__square"></div>
        </div>
    </div>
</div>


<span class="fixed" title="go to top" (click)="scroltop()">
<i id="yu" class="fa fa-arrow-circle-up"></i>
</span>