<app-header-front>


</app-header-front>

<div class="how-it-works">

    <div class="container">

        <div class="mdlle">
            <h1>STORY OF A KID FROM BENIN : ABAN</h1>
        </div>
        <p>Meet Aban, a 14 year old boy who lost his father and was compelled to work at a granite Quarry. He works there in the heat for long hours, processing waste- stones of granite which is one of the hazardous jobs for a child of his age. His father
            died due to poor healthcare facilities in Benin hence Aban was left alone as a breadwinner for the family with his disabled mother, 2 younger sisters and a brother. Aban’s family has lost every penny they had in his fathers medical expenses,
            subsequently his siblings had to quit their school as they don't have enough money even to buy food for the family. </p>

        <p>When his father passed away Aban’s mother, just for 2000 franc sent him along with few other boys from the village to work at the granite site. Quarries are one of the main extractive industries that provide employment to the people in an impoverished
            country like Benin. The granite site where Aban works is located in a secluded area which does not have facilities or resources to rely upon. Aban states that occasionally they even have to crawl beneath the ground through the narrow and steep
            tunnels as wide as their bodies to extract stones or rocks. </p>
        <p> In this explosive quarry, where marble, limestones, and ceramic tiles are made for the advancement of elite people. Aban is leading a miserable life underneath the ground where improper ventilation and lack of oxygen levels can even take away
            his life, but he is burdened by the negligible amount he gets to bear the expenses.</p>
        <p>These young kids work strenuously to share the load of earning in an unprotected atmosphere filled with toxic metals and harmful chemicals, besides they are even prone to substance abuse. Aban shares many encounters when he witnessed some serious
            injuries and deaths on the spot at mining work due to lack of supervision and facilities. </p>
        <p>Child labour in Benin has deprived children to relish their childhood minus the responsibilities. Moreover Aban had to give up on his studies at an early age due to extreme poverty and this paved his way to the labour market. </p>
        <p>Children in Benin are subjected to worst form of child labour, where they perform dangerous tasks that often leads to various health hazards. They work at Mining and construction sites, cotton factories, granite Quarries, also forced into domestic
            work and begging which advances in sexual exploitation of thses adolscents. Children like Aban who work for hours in the heat standing on rocky surfaces and at times under the ground picking- up the waste stones just to earn a few coins and
            get harassed by their employers.</p>
        <p>Aban, being the only provider of the family; has a dream of becoming a doctor as he has seen his father suffer immensely due to poor resources. Besides, he wants to support his community through this noble work. These minors are obliged to financially
            help their families and the work- pressure doesn't let them complete their education to fulfil their dreams. Underdeveloped nations like Benin are dealing with a serious issue of school drop- outs and everyday it’s intensifying as nobody gives
            heap to it. </p>
        <p>Whereas reports claim that among many reasons, poverty is skyrocketing in these regions is lack of awareness for education. Young children are involved in labour work to make their ends meet </p>

        <p>however statistics prove that sound knowledge of reading and writing is a bare minimum requirement to lift them out of extreme deprivation. Moreover imparting quality education is a fundamental start to break the cycle of poverty and early schooling
            is way more effective for children to acquire the basic skills and gain a decent job in future. </p>
        <p>Ziyyara foundation is working towards the implementation of education and preventing school dropouts as much as possible by running schools, which work as pillars to support underprivileged children. The foundation has been created to combat illiteracy
            and equip children with education and skills which can empower them holistically to gain employment which further breaks this vicious cycle. </p>
        <p>These adolescents at an early age abandon their education and many of them are employed under illegal conditions furthermore around 40 million are still enslaved around the world. Ziyyara foundation has been investing in better infrastructure
            for the children in need and continues to aid them with classrooms along with basic amenities which are easily accessible and free of cost.</p>
        <p>Ziyyara foundation also plans to support those remote communities of Benin with cost- free offline education by building schools and accommodating them with basic supplies as well as school kits. Moreover, the foundation has an objective of teaching
            the most number of drop-outs like Aban who struggle to complete their education due to a shortfall of resources. The evils of illiteracy must be eradicated and the foundation is working tremendously to render safe and high quality early childhood
            education for children in Benin.</p>
        <p>The intervention of people in this dire situation calls for immediate help, with the support of endowments or donations for children in need to bridge the gap between poverty and education. No amount is inconsequential because as small as $1 can
            prove to be a significant amount even to buy supplies for a kid hence it will surely strengthen the goal of education for the underprivileged. </p>

        <p>Together we can combat the evils of child slavery also can develop academic infrastructure for children of Benin to enable their learning and make their future a significant one. The only solution to erase miseries and sufferings of these young
            minds is to work together as a unit to bring upon the change and fill their admirations and dreams with hope. </p>
        <p>We as citizens of the world should take responsibility with open arms for ABAN who seeks our support and also shares his burden in whatever capacity we can.</p>
        <br>
        <div class="btnn-cntr">
            <a routerLink="/ziyyara-foundation" class="anchor">Help Them Learn</a>
        </div>
        <br>
    </div>


    <app-footer-front></app-footer-front>