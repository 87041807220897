<div class="modal-wrapper text-center">
    <div class="header-popup"> Assignment Details &nbsp;
        <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a>
        <!-- (click)="no()" -->
    </div>
    <div class="success-modal">
        <!-- <div class="success-inner">
      <img src="assets/images/modal/success.svg">    </div> -->
        <div class="">
            <br/>

            <!-- <div class="col-3" *ngFor="let list of edus; let i=index">
        <img class="img-thumbnail" [src]="list">
        <a    [href]="list"><button class="btn btn-success col-12">Download</button></a>
      </div> -->

            <div class="detail-div dflx">
                <!-- *ngIf="showDownload"  -->
                <div>
                    <b class="detail-p">
                    Download File : </b>
                </div>
                <!-- <p class="detail-b"> -->
                <div class="mrg-0a">


                    <a *ngIf="assign_doc[0]" [href]="assign_doc[0]" download="+.pdf">Assigned ⬇</a><br>
                    <a *ngIf="assign_doc[1]" [href]="assign_doc[1]" download="+.pdf">Completed ⬇</a><br>
                    <a *ngIf="assign_doc[2]" [href]="assign_doc[2]" download="+.pdf">Edited ⬇</a>

                    <!-- <a [href]="list" download="/.pdf">assignment file</a><br> -->
                    <!-- <a [href]="assign_doc[2]" download="/.pdf">assignment file</a><br> -->

                    <!-- <a (click)="downloadFile(data)">Download</a> -->
                    <!-- <a download="Hindi A">{{data?.content?.assignment_file}}</a> -->
                </div>
                <!-- </p>-->
            </div>

            <!-- 
            // <div class="col-3" *ngFor="let list of edus; let i=index">
                //        <img class="img-thumbnail" [src]="list">
                //      <a target="_blank" [href]="list"><button class="btn btn-success col-12">Download</button></a>
                //        </div> -->
            <br><br>
            <br><br>
            <div class="detail-div"><b class="detail-p">Description : </b>
                <p class="detail-b"></p>
                {{data?.content?.message}}
            </div>


        </div>
    </div>
</div>