import { NgModule,NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { HeaderFrontComponent } from './components/header-front/header-front.component';
import { ModalSignUpComponent } from './components/modal-sign-up/modal-sign-up.component';
import { MatIconModule } from '@angular/material/icon';
import {  MatMenuModule,} from '@angular/material';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationBoxComponent } from '../shared/components/confirmation-box/confirmation-box.component';
import { FooterFrontComponent } from './components/footer-front/footer.component';
import {RegistrationFormComponent} from './components/registration-form/registration-form.component';
import {MatTabsModule} from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CbseLinkingComponent } from './components/cbse-linking/cbse-linking.component';
import { FooterLinksAllclassComponent } from './components/footer-links-allclass/footer-links-allclass.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { AssignmentFormComponent } from './components/assignment-form/assignment-form.component';
import { NewRegistrationFormComponent } from './components/new-registration-form/new-registration-form.component';
import { NewFormComponent } from './components/new-form/new-form.component';
import { TestComponent } from './components/test/test.component';
import { FrontSectionComponent } from './components/front-section/front-section.component';
import { GareebLogComponent } from './components/gareeb-log/gareeb-log.component';
import { CommonCarouselComponent } from './components/common-carousel/common-carousel.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    SharedModule,
    MatTabsModule,
    MatIconModule,
    NgbModule
  ],
  declarations: [SidenavComponent, ModalSignUpComponent, HeaderComponent, FooterComponent, HeaderFrontComponent, FooterFrontComponent, RegistrationFormComponent,NewRegistrationFormComponent,NewFormComponent, AssignmentFormComponent,  CbseLinkingComponent, FooterLinksAllclassComponent,  SideModalComponent,TestComponent,FrontSectionComponent,GareebLogComponent,CommonCarouselComponent  ],
  exports: [HeaderFrontComponent, HeaderComponent, ModalSignUpComponent, FooterComponent, SidenavComponent, FooterFrontComponent,RegistrationFormComponent,NewRegistrationFormComponent, NewFormComponent,AssignmentFormComponent, FooterLinksAllclassComponent,SideModalComponent,TestComponent,FrontSectionComponent,GareebLogComponent,CommonCarouselComponent],
  entryComponents: [ConfirmationBoxComponent,ModalSignUpComponent,],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonComponentModule {

}
