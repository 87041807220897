import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services'; 

@Component({
  selector: 'app-drc',
  templateUrl: './drc.component.html',
  styleUrls: ['./drc.component.css','../donation/donation.component.css']
})
export class DrcComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {
  }

  ngOnInit() {
    var host=  location.pathname;
    this.metaService.createCanonicalURL(host);
    this.titleService.setTitle( "Help Children in Democratic Republic of Congo By Taking Classes With Ziyyara");
    this.meta.updateTag({name:"description", content:"Ziyyara foundation has been investing in better infrastructure for underprivileged children and continues to aid them with classrooms along with basic amenities in Democratic Republic of Congo."});
     var ogTitle='Help Children in Democratic Republic of Congo By Taking Classes With Ziyyara';
     var ogDescription="Ziyyara foundation has been investing in better infrastructure for underprivileged children and continues to aid them with classrooms along with basic amenities in Democratic Republic of Congo."
     this.meta.updateTag({property:"og:title", content:ogTitle});
     this.meta.updateTag({property:"og:description", content:ogDescription});
  }

}
