import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaServicesService } from 'src/app/services';
@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {

  constructor( private titleService: Title,private metaService: MetaServicesService , private meta: Meta) {

    
  }

 ngOnInit() {
   var host=  location.pathname;
   this.metaService.createCanonicalURL(host);
   this.titleService.setTitle( "Online Language Course | Experienced Language Tutors | Ziyyara");
   this.meta.updateTag({name:"description", content:" Join for Live 1-On-1 Online Arabic & English Language Classes. Register at Ziyyara to take best online language classes with language tutor."})
   
   
   this.metaService.removeStructuredData();


   this.meta.updateTag({name:"keywords", content:"online languages, online languages courses, online language courses, online language learning, best online language courses, best online language learning, online, foreign language courses, best language courses, online language classes, online language tutor"})
  //  this.metaService.insertSchema(this.websiteSchema);
  var ogTitle='Online Language Course | Experienced Language Tutors | Ziyyara';
  var ogDescription='Join for Live 1-On-1 Online Arabic & English Language Classes. Register at Ziyyara to take best online language classes with language tutor.'
    this.meta.updateTag({property:"og:title", content:ogTitle})
    this.meta.updateTag({property:"og:description", content:ogDescription})
    this.meta.updateTag({name:"twitter:title", content:ogTitle})
    this.meta.updateTag({name:"twitter:description", content:ogDescription})


    this.slides1 = this.chunk(this.cards1, 3);
    this.slides2 = this.chunk(this.cards2, 3);
  }
 /****************open login page in ziyara ******************/

  // for the next previous button
  panelOpenState = false;
  step = 0;

  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }


  cards1 = [
    
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-English.jpg'
    },
    {
      title: 'Arabic',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-Arabic.jpg'
    },
    {
      title: 'Spanish',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-Spanish.jpg'
    },
  
  ];

  
  cards2 = [
    
    {
      title: 'German',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-German.jpg'
    },
    {
      title: 'French',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-French.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/languages/online-Hindi.jpg'
    },
  
  ];


  slides1: any = [[]];
  slides2: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
   

      return '/languages/'+subject
    }
  
  
}
