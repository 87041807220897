<app-header-front></app-header-front>

<div class="padd_top"></div>

<div class="main-section">
    <div class="flex">

        <div class=" first-part">
            <div class="hero-content">
                <img class="img-bg" src="/assets/images/images/landing-hero.png">

            </div>

        </div>
        <div class="second-part">
            <div class="reg-part">
                <div class="popup-form-inner login-new-ato">
                    <div class="popup-form-heading">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>Welcome to Ziyyara </h5>
                            <!-- <span (click)="no()">
              <i class="fa fa-close color-primary cursor-pointer"></i>
            </span>  -->
                        </div>
                        <!-- <span>Please  to your Ziyyara Account</span> -->
                    </div>
                    <form id="Login" autocomplete="nope">
                        <p style="color:red">{{errorMessage}}</p>
                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <mat-form-field appearance="fill" class="mat-form-custom ">
                                    <mat-label>Login</mat-label>
                                    <input matInput type="email" [formControl]="email" autocomplete="nope" placeholder="">
                                </mat-form-field>
                                <mat-error class="error">{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <mat-form-field appearance="fill" class="mat-form-custom ">
                                    <mat-label>Password</mat-label>
                                    <input matInput [formControl]="password" autocomplete="new0password" type="password" placeholder="">
                                </mat-form-field>
                                <mat-error>{{_util.errorMessage(password,FORM_ERROR?.password)}}</mat-error>
                            </div>
                        </div>


                        <div class="d-md-flex justify-content-between align-items-center mb-3">
                            <button type="submit" [disabled]="disabled" (click)="submit()" class="btn m-0 btn-ziyyara">{{"login.login_title" | mytranslate}}</button>
                            <a (click)="forgot()" class="pad">{{"login.forgot_password" | mytranslate}}</a>
                        </div>
                    </form>

                    <div class=" text-center">
                        <a (click)="socialSignIn('google',1)" class="btn btn-block btn-success font-weight-bold mb-2"><i
              class="fa fa-google"></i>
            <span class="network-name">&nbsp;&nbsp;{{"login.login_google_stu" | mytranslate}}</span>
          </a>
                        <a (click)="socialSignIn('google',2)" class="btn btn-block font-weight-bold btn-primary"><i
              class="fa fa-google"></i>
            <span class="network-name">&nbsp;&nbsp;{{"login.login_google_tutor" | mytranslate}}</span>
          </a>
                    </div>

                    <p class="text-center mt-3"><span class="reola-tc-size-bold">{{"login.dont_have_account" | mytranslate}}</span>&nbsp; <a class="hrefclass befault-color" routerLink="/studentSignup">{{"login.reister_title" | mytranslate}}</a></p>





                </div>
            </div>
        </div>
    </div>


</div>



<app-footer-front></app-footer-front>